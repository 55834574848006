import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DataGrid from '../../components/DataGrid'
import DeleteButton from '../../components/DeleteButton'
import LoadingOverlay from '../../components/LoadingOverlay'
import TickIcon from '@mui/icons-material/Done'
import CrossIcon from '@mui/icons-material/Close'
import { useGetUserCloudsQuery, useDeleteCloudProviderMutation } from '../../services/cloudApi'
import { BUTTON_LABELS, VIEW_HEADERS } from '../../constants/uiConstants'
import { setError } from '../../datastore/UI/uiSlice'
import AddCloudProviderView from './AddCloudProviderView'
import { Button } from '@mui/material'
import Modal from '../../components/Modal'
import AddIcon from '@mui/icons-material/Add';

const DataView = () => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState(null)
  const [deletingRow, setDeletingRow] = useState(null)
  const [data, setData] = useState([])
  const { data: fetchedData, error, isError, isLoading, isSuccess, refetch } = useGetUserCloudsQuery()
  const [deleteCloudProvider] = useDeleteCloudProviderMutation()

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData])

  const rows = useMemo(() => {
    if (isSuccess && data) {
      return data.map((item) => ({
        id: item.id,
        ...item
      }))
    }

    return []
  }, [data, isSuccess])

  const handleCreate = useCallback(() => {
    setSelectedRow(false)
    refetch()
  }, [refetch])

  const handleDelete = useCallback((row) => {
    setDeletingRow(row) 
    deleteCloudProvider(row.id)
      .unwrap()
      .then(() => {
        setData((state) => state.filter((item) => item.id !== row.id))
        setDeletingRow(null)
      })
      .catch(err => {
        setDeletingRow(null)
        dispatch(setError(err))
      })
  }, [deleteCloudProvider, dispatch])

  return (
    <>
      <Typography variant='h5' gutterBottom>{VIEW_HEADERS.CLOUD_PROVIDERS}</Typography>
      <hr />
      <Container disableGutters maxWidth='lg' sx={{ marginBlockStart: 4 }}>
        <Stack
          direction='row'
          justifyContent='flex-end'
          sx={{ marginBlockEnd: 4 }}
        >
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={() => setSelectedRow(true)}
          >
            <AddIcon />
            {BUTTON_LABELS.ADD_CLOUD_PROVIDER}
          </Button>
        </Stack>
        <Modal
          onClose={() => setSelectedRow(false)}
          open={!!selectedRow}
          title={VIEW_HEADERS.ADD_CLOUD_PROVIDER}
        >
          <AddCloudProviderView close={handleCreate}/>
        </Modal>
        {isLoading && <LoadingOverlay />}
        {isError && <Alert severity='error'>{error?.data?.detail?.[0]?.msg}</Alert>}
        {isSuccess && (
          <DataGrid
            
            columns={[
              {
                field: 'cloud_provider',
                headerName: 'Cloud provider',
                sortable: true,
                width: '200'
              },
              {
                field: 'primary',
                headerName: 'Primary',
                renderCell: ({ value }) => {
                  return value ? <TickIcon color='success' /> : <CrossIcon color='error' />
                },
                sortable: true,
                width: '100'
              },
              {
                field: 'regions',
                headerName: 'Regions',
                valueFormatter: ({ value }) => {
                  return value.join(', ')
                },
                sortable: true,
                flex: 1
              },
              {
                field: 'actions',
                headerName: 'Delete',
                renderCell: ({ row }) => {
                  return (
                    <DeleteButton
                      color='error'
                      loading={deletingRow ? deletingRow.id === row.id : false}
                      onClick={() => handleDelete(row)}
                      size='small'
                      variant='contained'
                    >
                      {BUTTON_LABELS.DELETE_BUTTON}
                    </DeleteButton>
                  )
                }
              }
            ]}
            rows={rows}
          />
        )}
      </Container>
    </>
  )
}

export default DataView
