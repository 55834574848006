import { configureStore } from '@reduxjs/toolkit'
// Redux slices
import uiReducer from './UI/uiSlice'
import userStateReducer from './userSlice'
import filterReducer from './filterSlice'
// Api slices
import artifactsApi from '../services/artifactsApi'
import virtualMountsApi from '../services/virtualMountsApi'
import cloudApi from '../services/cloudApi'
import jobsApi from '../services/jobsApi'
import creditsApi from '../services/creditsApi'
import invoiceApi from '../services/invoiceApi'
import trialApi from '../services/trialApi'
import workstationApi from '../services/workstationApi'
import secretsApi from '../services/secretsApi'
import artifactStorageApi from '../services/artifactStorageApi'
import accessKeysApi from '../services/accessKeysApi'
import userApi from '../services/userApi'
import UsageApi from '../services/usageApi'
import visualizationApi from '../services/visualizationApi'
import templatesApi from '../services/templatesApi'
import eventLogsApi from '../services/logsApi'
import uploadApi from '../services/uploadApi'
import liveLogsApi from '../services/liveLogsApi'
import exchangeAuthCodeApi from '../services/exchangeAuthCodeApi'
import { rtkQueryErrorLogger } from '../middlewares/error'

export const store = configureStore({
  reducer: {
    [uiReducer.name]: uiReducer.reducer,
    [userStateReducer.name]: userStateReducer.reducer,
    [filterReducer.name]: filterReducer.reducer,
    [artifactsApi.reducerPath]: artifactsApi.reducer,
    [artifactStorageApi.reducerPath]: artifactStorageApi.reducer,
    [virtualMountsApi.reducerPath]: virtualMountsApi.reducer,
    [visualizationApi.reducerPath]: visualizationApi.reducer,
    [secretsApi.reducerPath]: secretsApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [accessKeysApi.reducerPath]: accessKeysApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [cloudApi.reducerPath]: cloudApi.reducer,
    [liveLogsApi.reducerPath]: liveLogsApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [UsageApi.reducerPath]: UsageApi.reducer,
    [creditsApi.reducerPath]: creditsApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [trialApi.reducerPath]: trialApi.reducer,
    [workstationApi.reducerPath]: workstationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [eventLogsApi.reducerPath]: eventLogsApi.reducer,
    [exchangeAuthCodeApi.reducerPath]: exchangeAuthCodeApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
    artifactsApi.middleware,
    cloudApi.middleware,
    secretsApi.middleware,
    virtualMountsApi.middleware,
    artifactStorageApi.middleware,
    visualizationApi.middleware,
    accessKeysApi.middleware,
    jobsApi.middleware,
    uploadApi.middleware,
    liveLogsApi.middleware,
    UsageApi.middleware,
    templatesApi.middleware,
    creditsApi.middleware,
    invoiceApi.middleware,
    trialApi.middleware,
    workstationApi.middleware,
    userApi.middleware,
    eventLogsApi.middleware,
    exchangeAuthCodeApi.middleware,
    rtkQueryErrorLogger
  )
})
