import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";
// Aggregating Launch HPT config
export const ADD_CLOUD_PROVIDER_CONFIG = {
  provider: {
    id: "cloud_provider",
    label: "Provider",
    placeholder: "Select a cloud provider",
    options: ['AWS', 'AZURE', 'GCP'],
    required: true,
    type: INPUT_TYPES.SELECT,
    none: false,
  },
  // isPrimary: {
  //   id: "primary",
  //   label: "Primary Cloud",
  //   type: INPUT_TYPES.BOOLEAN
  // },
  regions: {
    id: 'regions',
    disabled: true,
    label: 'Regions',
    options: [],
    placeholder: 'Select one or more regions',
    type: INPUT_TYPES.MULTI_SELECT,
    helperText: 'Note:all regions are selected if the Regions field is left empty.'
  }
};
deepFreeze(ADD_CLOUD_PROVIDER_CONFIG);
export const ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG = {
  bucketName: {
    id: "bucket_name",
    label: "Artifacts bucket",
    required: true,
    type: INPUT_TYPES.STRING,
  }
}
// Bucket name config
deepFreeze(ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG)
export const ADD_CLOUD_PROVIDER_AWS_CREDENTIALS = {
  credentials: {
    id: 'creds.credentials',
    label: 'Provider Credentials',
    type: INPUT_TYPES.NESTED_OBJECT,
    required: true,
    fields: {}
  }
}
deepFreeze(ADD_CLOUD_PROVIDER_AWS_CREDENTIALS)
// Clopd provider credential fields
export const ADD_CLOUD_PROVIDER_AWS_CREDENTIALS_CONFIG = {
  AWS: {
    AWS_ACCESS_KEY_ID: {
      id: 'creds.AWS_ACCESS_KEY_ID',
      label: 'AWS_ACCESS_KEY_ID',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AWS_SECRET_ACCESS_KEY: {
      id: 'creds.AWS_SECRET_ACCESS_KEY',
      label: 'AWS_SECRET_ACCESS_KEY',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  AZURE: {
    AZURE_CLIENT_ID: {
      id: "creds.AZURE_CLIENT_ID",
      label: "AZURE_CLIENT_ID",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    AZURE_CLIENT_SECRET: {
      id: "creds.AZURE_CLIENT_SECRET",
      label: "AZURE_CLIENT_SECRET",
      placeholder: "Enter access key",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    AZURE_TENANT_ID: {
      id: "creds.AZURE_TENANT_ID",
      label: "AZURE_TENANT_ID",
      placeholder: "Enter access key",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    AZURE_SUBSCRIPTION_ID: {
      id: "creds.AZURE_SUBSCRIPTION_ID",
      label: "AZURE_SUBSCRIPTION_ID",
      placeholder: "Enter access key",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  },
  GCP: {
    SERVICE_ACCOUNT_JSON: {
      id: "creds.SERVICE_ACCOUNT_JSON",
      label: "SERVICE_ACCOUNT_JSON",
      placeholder: "Enter access key",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  },
  DATACRUNCH: {
    DATACRUNCH_CLIENT_ID: {
      id: "creds.DATACRUNCH_CLIENT_ID",
      label: "DATACRUNCH_CLIENT_ID",
      placeholder: "Enter access key",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    DATACRUNCH_CLIENT_SECRET: {
      id: "creds.DATACRUNCH_CLIENT_SECRET",
      label: "DATACRUNCH_CLIENT_SECRET",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  }
}
export const USE_CUSTOM_VPC_OPTION = {
  useCustomVPCs: {
    id: "useCustomVPCs",
    label: "Custom VPC(s)",
    type: INPUT_TYPES.BOOLEAN
  },
}
deepFreeze(USE_CUSTOM_VPC_OPTION)

export const AWS_CUSTOM_VPC_CONFIG = {
  customVPC : {
    id: "customVPC",
    label: "Custom VPC",
    type: INPUT_TYPES.OBJECT
  }
}
deepFreeze(AWS_CUSTOM_VPC_CONFIG)

export const USE_AWS_SCRATCHPAD_STORAGE = {
  useScratchpadStroage: {
    id: "useScratchpadStorage",
    label: "Use Scratchpad Storage",
    type: INPUT_TYPES.BOOLEAN
  },
}
deepFreeze(USE_AWS_SCRATCHPAD_STORAGE)

export const AWS_SCRATCHPAD_STORAGE = {
  scratchpadStroage : {
    id: "scratchpadStroage",
    label: "Scratchpad Storage",
    type: INPUT_TYPES.OBJECT,
    object_type: 'immutable'
  }
}
deepFreeze(AWS_SCRATCHPAD_STORAGE)

export const ADD_CLOUD_PROVIDER_DEFAULT_VALUE = {
    cloud_provider: "",
    primary: false,
    regions: [],
    useCustomVPCs: "",
    customVPC: {},
    useScratchpadStroage: "",
    scratchpadStroage: {}
};
deepFreeze(ADD_CLOUD_PROVIDER_DEFAULT_VALUE);
