import { deepFreeze } from '../../utils'
import { 
  CLOUD_PROVIDER_FIELDS_CONFIG,
  CPU_FIELDS_CONFIGS
} from './BaseFormConstants'
import { INPUT_TYPES } from '.'

export const JOB_TYPE_EXPERIMENT = 'EXPERIMENT'
export const JOB_TYPE_SIMPLE_JOB = 'SIMPLE_JOB'
export const JOB_TYPE_HYPERPARAMETER_TRAINING = 'HPTOPT'

// Advance fields
export const JOB_ADVANCE_FIELD_CONFIG = {
  pythonVersion: {
    id: 'pythonVersion',
    label: 'Python Version',
    description: 'Base python version to be used to install dependencies.',
    type: INPUT_TYPES.SELECT,
    options: ['3.7','3.8','3.9','3.10'],
    none: false
  },
  environment: {
    id: 'environment',
    label: 'Environment Variables',
    description: 'Add third party API keys or other credentials that your training script requires.',
    type: INPUT_TYPES.OBJECT,
  }
}
deepFreeze(JOB_ADVANCE_FIELD_CONFIG)

export const ENVIRONMENT = {
  environment: {
    id: 'environment',
    label: 'Environment Variables',
    description: 'Add third party API keys or other credentials that your training script requires.',
    type: INPUT_TYPES.OBJECT,
  }
}
deepFreeze(ENVIRONMENT)


export const USE_DAP = {
  useDAPP: {
    id: 'useDAPP',
    label: 'Use DLOP',
    description: '',
    type: INPUT_TYPES.BOOLEAN,
  }
}
deepFreeze(USE_DAP)

export const DAPP_SETTINGS = {
  dapp: {
    id: 'dapp',
    label: 'Dapp Settings',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      epochs: {
        id: 'dapp.epochs',
        label: 'Epochs',
        placeholder: 'Enter value',
        description: '',
        type: INPUT_TYPES.NUMBER
      }
    }
  }
}
deepFreeze(DAPP_SETTINGS)

export const ARTIFACTS_DESTINATION = {
  artifactsDestination: {
    id: "artifactsDestination",
    label: "Artifacts Destination",
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      name: {
        id: "artifactsDestination.name",
        label: "Name",
        placeholder: "Select Path",
        description: 'Choose Artifacts Storage for your experiments. Artifacts Storage are initially configured by your Administrator, contact them if you dont have any options to select.',
        type: INPUT_TYPES.SELECT,
      },
      filter: {
        id: "artifactsDestination.filter",
        label: "Filter",
        placeholder: "Enter filter value",
        escription: '',
        type: INPUT_TYPES.STRING,
      }
    }
  }
}
deepFreeze(ARTIFACTS_DESTINATION)

export const VIRTUAL_MOUNT = {
  virtualMount: {
    id: 'virtualMounts',
    label: 'Virtual Mounts',
    placeholder: 'Select an option',
    description: 'Virtual mounts are a part of the Data Streaming engine. Add Scaletorch package to your code to use Virtual mounts. Virtual Mounts are initially configured by your Administrator, contact them if you donot have any options to select.',
    link: 'https://scaletorch.gitbook.io/scaletorch-docs/development-guide/data-streaming',
    type: INPUT_TYPES.VIRTUAL_MOUNT_ARRAY
  },
}
deepFreeze(VIRTUAL_MOUNT)

export const SOURCE_VIRTUAL_MOUNT = {
  virtualMount: {
    id: 'sourceVirtualMounts',
    label: 'Source Dataset',
    placeholder: 'Select an option',
    description: 'Set source data virtual mount',
    type: INPUT_TYPES.VIRTUAL_MOUNT_ARRAY
  },
}
deepFreeze(SOURCE_VIRTUAL_MOUNT)

export const TARGET_VIRTUAL_MOUNT = {
  virtualMount: {
    id: 'targetVirtualMounts',
    label: 'Target Dataset',
    placeholder: 'Select an option',
    description: 'Set target data virtual mount',
    type: INPUT_TYPES.VIRTUAL_MOUNT_ARRAY
  },
}
deepFreeze(TARGET_VIRTUAL_MOUNT)

// CPU fields
export const JOB_CPU_FIELDS_CONFIG = {
  maxCPUWorkers: {
    id: 'maxCPUWorkers',
    label: 'Max CPU Workers',
    disabled: true,
    required: true,
    description: 'Maximum number of CPU VMs that can be created for this job.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(JOB_CPU_FIELDS_CONFIG)


// GPU fields
export const JOB_GPU_FIELDS_CONFIG = {
  gpuTypes: {
    id: 'gpuTypes',
    disabled: true,
    label: 'GPU Types',
    placeholder: 'Select an option',
    description: 'Specify GPU Types you want to operate with. If this field is empty, we will use all available types by default',
    type: INPUT_TYPES.MULTI_SELECT
  },
  gpusPerTrial: {
    id: 'gpusPerTrial',
    label: 'GPUs Per Trial',
    required: true,
    description: 'Specify how many GPUs do you want to use per each trial.',
    type: INPUT_TYPES.NUMBER
  },
}
deepFreeze(JOB_GPU_FIELDS_CONFIG)

export const CUDA = {
  cuda: {
    id: 'cuda',
    label: 'Cuda Version',
    options: [11.8, 11.7, 11.6, 11.5, 11.4, 11.3, 11.2, 10.2, 10.1, 10.0],
    placeholder: 'Select an option',
    description: 'Specify a Cuda Version used in your code.',
    type: INPUT_TYPES.SELECT
  }
}
deepFreeze(CUDA)

// Control configs
export const JOB_CONTROL_CONFIGS = {
  IS_REPO_PRIVATE: {
    id: 'IS_REPO_PRIVATE',
    label: 'Is Repo Private?'
  },
  IS_DOCKER_PRIVATE: {
    id: 'IS_DOCKER_PRIVATE',
    label: 'Is Docker Private'
  },
  IS_VISUALIZATION_WANDB: {
    id: 'IS_VISUALIZATION_WANDB',
    label: 'Use Weights & Biases'
  }
}
deepFreeze(JOB_CONTROL_CONFIGS)


// Docker image settings form input configs
export const DOCKER_IMAGE_SETTINGS_FORM_CONFIG = {
  customImage: {
    id: 'customImage',
    label: 'Enter Docker Settings',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      image: {
        id: 'customImage.image',
        label: 'Image',
        placeholder: 'Enter value',
        description: 'Link to your public or private docker image.',
        type: INPUT_TYPES.STRING
      },
      pythonPath: {
        id: 'customImage.pythonPath',
        label: 'Python Path',
        placeholder: 'Enter value',
        description: 'Path to python installation within the image. Defaults to /usr/bin and /usr/local/bin.',
        type: INPUT_TYPES.STRING
      }
    }
  }
}
deepFreeze(DOCKER_IMAGE_SETTINGS_FORM_CONFIG)

export const DAP_SETTINGS = {
  epochs: {
    id: 'epochs',
    label: 'Epochs',
    placeholder: 'Enter value',
    description: '',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(DAP_SETTINGS)

// Manual image settings form input configs
export const MANUAL_IMAGE_SETTINGS_FORM_CONFIG = {
  // requirements: {
  //   id: 'requirements',
  //   label: 'Requirement File Name',
  //   placeholder: 'Enter Value',
  //   required: true,
  //   description: 'Path to python requirements file.',
  //   type: INPUT_TYPES.STRING
  // },
  codeTransfer: {
    id: 'codeTransfer',
    label: 'Code Repo Details',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'codeTransfer.type',
        label: 'Code Repo Host',
        options: [
          'GITHUB_PRIVATE',
          'GITHUB',
          'GITLAB_PRIVATE',
          'GITLAB',
          'BITBUCKET_PRIVATE',
          'BITBUCKET',
          'S3',
          'AZURE',
          'GS',
          'GDRIVE',
          'DROPBOX'
        ],
        placeholder: 'Select an option',
        required: true,
        description: 'Choose a source where your code is stored, eg. "GitLab".',
        type: INPUT_TYPES.SELECT
      },
      repo: {
        id: 'codeTransfer.repo',
        label: 'Repo',
        placeholder: 'Enter value',
        required: true,
        description: 'Enter the name of your existing repository, where your code is located.',
        type: INPUT_TYPES.STRING
      },
      ref: {
        id: 'codeTransfer.ref',
        label: 'Commit hash or Branch name',
        placeholder: 'Enter value',
        description: 'Specific version or branch of your code to use.',
        type: INPUT_TYPES.STRING
      },
      codeDir: {
        id: 'codeTransfer.codeDir',
        label: 'Code Directory',
        placeholder: 'Enter value',
        description: 'Path to the directory containing the script relative to the repo.',
        type: INPUT_TYPES.STRING
      }
    }
  },
}
deepFreeze(MANUAL_IMAGE_SETTINGS_FORM_CONFIG)

export const REQUIREMENTS_FILE = {
  requirements: {
    id: 'requirements',
    label: 'Requirement File Name',
    placeholder: 'Enter Value',
    required: true,
    description: 'Path to python requirements file.',
    type: INPUT_TYPES.STRING
  }
}
deepFreeze(REQUIREMENTS_FILE)

export const MANUAL_PRE_POST_CMD_CONFIG = {
  preJobCommands: {
    id: 'preJobCommands',
    label: 'Pre-Job Commands',
    placeholder: `
    List of commands to run (without sudo). Each new command in new line
    Example:
    apt-get update -y
    apt-get install net-tools
    `,
    type: INPUT_TYPES.MULTI_LINE_ARRAY
  },
  postJobCommands: {
    id: 'postJobCommands',
    label: 'Post-Job Commands',
    placeholder: `
    List of commands to run (without sudo). Each new command in new line
    Example:
    apt-get update -y
    apt-get install net-tools
    `,
    type: INPUT_TYPES.MULTI_LINE_ARRAY
  }
}
deepFreeze(MANUAL_PRE_POST_CMD_CONFIG)

// Docker registry credentials form input config
export const DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG = {
  credentials: {
    id: 'customImage.credentials',
    label: 'Provide Docker Credentials',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      registry: {
        id: 'customImage.credentials.registry',
        label: 'Registry URL',
        placeholder: 'Enter registry url',
        type: INPUT_TYPES.STRING
      },
      username: {
        id: 'customImage.credentials.username',
        label: 'User Name',
        placeholder: 'Enter value',
        type: INPUT_TYPES.STRING
      },
      password: {
        id: 'customImage.credentials.password',
        label: 'Password',
        placeholder: 'Enter value',
        type: INPUT_TYPES.STRING
      }
    }
  }
}
deepFreeze(DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG)
// Code directory credentials form input config
export const CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG = {
  credentials: {
    id: 'codeTransfer.credentials',
    label: 'Provide Private Repo Credentials',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {}
  }
}
deepFreeze(CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG)

// WandB API KEY input field.
export const WANDB_API_KEY_FORM_CONFIG = {
  wandb_api_key: {
    id: 'wandb_api_key',
    label: 'API Key',
    type: INPUT_TYPES.STRING,
    autoComplete: 'off',
    description: 'Enter your Weights & Biases API key',
    errorText: 'Please enter a valid API key'
  }
}
deepFreeze(CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG)

// Code directory credentials input map
export const CODE_DIRECTORY_CREDENTIALS_FIELDS_CONFIG = {
  GITHUB_PRIVATE: {
    GITHUB_PAT: {
      id: 'codeTransfer.credentials.GITHUB_PAT',
      label: 'GITHUB_PAT',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  GITHUB: {},
  GITLAB_PRIVATE: {
    GITLAB_PAT: {
      id: 'codeTransfer.credentials.GITLAB_PAT',
      label: 'GITLAB_PAT',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  GITLAB: {},
  BITBUCKET_PRIVATE: {
    BITBUCKET_APP_PASSWORD: {
      id: 'codeTransfer.credentials.BITBUCKET_APP_PASSWORD',
      label: 'BITBUCKET_APP_PASSWORD',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  BITBUCKET: {},
  S3: {
    AWS_ACCESS_KEY_ID: {
      id: 'codeTransfer.credentials.AWS_ACCESS_KEY_ID',
      label: 'AWS_ACCESS_KEY_ID',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AWS_SECRET_ACCESS_KEY: {
      id: 'codeTransfer.credentials.AWS_SECRET_ACCESS_KEY',
      label: 'AWS_SECRET_ACCESS_KEY',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  AZURE: {
    AZURE_ACCOUNT_NAME: {
      id: 'codeTransfer.credentials.AZURE_ACCOUNT_NAME',
      label: 'AZURE_ACCOUNT_NAME',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AZURE_ACCOUNT_KEY: {
      id: 'codeTransfer.credentials.AZURE_ACCOUNT_KEY',
      label: 'AZURE_ACCOUNT_KEY',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  GS: {
    GS_CREDS: {
      id: 'codeTransfer.credentials.GS_CREDS',
      label: 'GS_CREDS',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  GDRIVE: {
    GDRIVE_CREDS: {
      id: 'codeTransfer.credentials.GDRIVE_CREDS',
      label: 'GDRIVE_CREDS',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  DROPBOX: {
    DROPBOX_TOKEN: {
      id: 'codeTransfer.credentials.DROPBOX_TOKEN',
      label: 'DROPBOX_TOKEN',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  }
}
deepFreeze(CODE_DIRECTORY_CREDENTIALS_FIELDS_CONFIG)
