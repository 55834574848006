import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { BUTTON_LABELS, DIALOG_LABELS, DIALOG_MESSAGES } from '../../constants/uiConstants'

const DeleteButton = ({
  description,
  onClick,
  title,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onAccept = () => {
    if (typeof onClick === 'function') {
      onClick()
    }

    onClose()
  }

  return (
    <>
      <LoadingButton
        onClick={() => {
          setOpen(true)
        }}
        {...otherProps}
      />
      <Dialog
        onClose={onClose}
        open={open}
        title={title}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ flexGrow: 1 }} variant='span'>{title}</Typography>
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={onClose}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size='small'>{BUTTON_LABELS.CANCEL_BUTTON}</Button>
          <Button onClick={onAccept} color='error' size='small'>{title === DIALOG_LABELS.DELETE ? BUTTON_LABELS.DELETE_BUTTON : BUTTON_LABELS.STOP_BUTTON}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteButton.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  ...LoadingButton.propTypes
}

DeleteButton.defaultProps = {
  description: DIALOG_MESSAGES.DELETE,
  title: DIALOG_LABELS.DELETE
}

export default DeleteButton
