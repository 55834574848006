import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const jobsApi = createApi({
  reducerPath: 'jobsApi',
  ...platformAuthConfig(),
  tagTypes: ['Jobs', 'Experiments', 'SimpleJob', 'HYPTs'],
  endpoints: builder => ({
    getJobs: builder.query({
      query: () => '/jobs',
      providesTags: ['Jobs']
    }),
    getExperiments: builder.query({
      query: () => '/jobs',
      transformResponse: (response) => {
        return Array.isArray(response) ? response.filter((data) => data?.spec?.type === 'EXPERIMENT') : []
      },
      providesTags: ['Experiments']
    }),
    getSimpleJobs: builder.query({
      query: () => '/jobs',
      transformResponse: (response) => {
        return Array.isArray(response) ? response.filter((data) => data?.spec?.type === 'SIMPLE_JOB') : []
      },
      providesTags: ['SimpleJob']
    }),
    getHyperparameterTrainings: builder.query({
      query: () => '/jobs',
      transformResponse: (response) => {
        return Array.isArray(response) ? response.filter((data) => data?.spec?.type === 'HPTOPT') : []
      },
      providesTags: ['HYPTs']
    }),
    getJob: builder.query({
      query: (id) => ({
        url: `/job/${id}`
      }),
      providesTags: ['Jobs', 'Experiments', 'SimpleJob', 'HYPTs']
    }),
    initiateVisualization: builder.mutation({
      query: (jobId) => ({
        url: `/job/${jobId}/visualisation?action=start`,
        method: 'PUT',
      })
    }),
    createJob: builder.mutation({
      query: (job) => ({
        url: '/job',
        method: 'POST',
        body: job
      }),
      invalidatesTags: ['Jobs', 'Experiments', 'SimpleJob', 'HYPTs']
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `/job/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Jobs', 'Experiments', 'SimpleJob', 'HYPTs']
    })
  })
})

export const {
  useGetJobsQuery,
  useGetExperimentsQuery,
  useGetSimpleJobsQuery,
  useGetHyperparameterTrainingsQuery,
  useGetJobQuery,
  useCreateJobMutation,
  useDeleteJobMutation,
  useInitiateVisualizationMutation
} = jobsApi

export default jobsApi
