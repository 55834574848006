import { deepFreeze } from '../../utils'
import { INPUT_TYPES } from '.'
var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

// Mandatory fields
export const TUNING = {
  tuning: {
    id: "tuning",
    label: "Tuning Properties",
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      searchSpace: {
        id: 'tuning.searchSpace',
        label: 'Search Space',
        options: ['uniform', 'choice', 'randint', 'quniform', 'loguniform', 'qloguniform', 
          'normal', 'qnormal', 'lognormal', 'mutablelayer'],
        required: true,
        type: INPUT_TYPES.TUNING,
      },
      tuner: {
        id: "tuning.tuner",
        label: "Tuner",
        type: INPUT_TYPES.NESTED_OBJECT,
        fields: {
          name: {
            id: 'tuning.tuner.name',
            label: 'Name',
            // options: ['TPE', 'Random Search', 'Anneal', 'Naive Evolution', 'SMAC', 
            // 'Batch Tuner', 'Grid Search', 'Hyperband', 'Network Morphism', 'BOHB', 
            // 'GP', 'PBT', 'DNGO'],
            options: ['TPE',
              'Random',
              'Anneal',
              'Evolution',
              'SMAC',
              'GridSearch',
              'Hyperband',
              'BOHB',
              'GPTuner',
              'PBTTuner',
              'DNGOTuner'],
            required: true,
            type: INPUT_TYPES.SELECT
          },
          optimizeMode: {
            id: 'tuning.tuner.optimizeMode',
            label: 'Optimize Mode',
            options: ['maximize', 'minimize'],
            required: true,
            type: INPUT_TYPES.SELECT
          }
        }
      }
    }
  }
}
export const HPT_MANDATORY_FIELDS_CONFIG = {
  name: {
    id: 'name',
    label: 'Name',
    placeholder: 'Enter value',
    description: 'Give your tuning job a unique name, e.g. "ScaleTorch Tuning Job"',
    errorFunc: (v) => format.test(v),
    errorText: 'Names should only have alphabets, nums or hyphens',
    type: INPUT_TYPES.STRING,
    display: 'none'
  },
  useSpot: {
    id: 'useSpot',
    label: 'Use spot instances',
    description: 'Spot Instances are cheaper. To use spot instances, you have to add checkpoints to your code and a restart feature.',
    type: INPUT_TYPES.BOOLEAN
  },
  tuning: {
    id: "tuning",
    label: "Tuning Properties",
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      searchSpace: {
        id: 'tuning.searchSpace',
        label: 'Search Space',
        options: ['uniform', 'choice', 'randint', 'quniform', 'loguniform', 'qloguniform', 
          'normal', 'qnormal', 'lognormal', 'mutablelayer'],
        required: true,
        type: INPUT_TYPES.TUNING,
      },
      tuner: {
        id: "tuning.tuner",
        label: "Tuner",
        type: INPUT_TYPES.NESTED_OBJECT,
        fields: {
          name: {
            id: 'tuning.tuner.name',
            label: 'Name',
            // options: ['TPE', 'Random Search', 'Anneal', 'Naive Evolution', 'SMAC', 
            // 'Batch Tuner', 'Grid Search', 'Hyperband', 'Network Morphism', 'BOHB', 
            // 'GP', 'PBT', 'DNGO'],
            options: ['TPE',
              'Random',
              'Anneal',
              'Evolution',
              'SMAC',
              'GridSearch',
              'Hyperband',
              'BOHB',
              'GPTuner',
              'PBTTuner',
              'DNGOTuner'],
            required: true,
            type: INPUT_TYPES.SELECT
          },
          optimizeMode: {
            id: 'tuning.tuner.optimizeMode',
            label: 'Optimize Mode',
            options: ['maximize', 'minimize'],
            required: true,
            type: INPUT_TYPES.SELECT
          }
        }
      }
    }
  }
}
deepFreeze(HPT_MANDATORY_FIELDS_CONFIG)

export const INSTANCE_TYPES = {
  instanceTypes: {
    id: 'instanceTypes',
    disabled: true,
    label: 'Instance Types',
    placeholder: 'Select an option',
    description: 'Specify GPU instance types you want to operate with. If this field is empty, we will use all available types by default',
    type: INPUT_TYPES.MULTI_SELECT
  }
}
deepFreeze(INSTANCE_TYPES)

export const SOURCE_CODE_CONFIG = {
  entrypoint: {
    id: 'entrypoint',
    label: 'Entry Point File Name',
    placeholder: 'Enter Value',
    required: true,
    description: 'Add a file name of your existing script to run on all available clouds, eg. "train.py"',
    type: INPUT_TYPES.STRING
  },

}
deepFreeze(SOURCE_CODE_CONFIG)

export const VISUALISATION_CONFIG = {
  visualisation: {
    id: 'visualisation',
    label: 'Visualisation',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'visualisation.type',
        label: 'Type',
        disabled: false,
        options: ['TENSORBOARD', 'AIM', 'WANDB' , 'COMETML'],
        description: 'Choose Experiments tracking tool to visualize the results of your experiment. We have integration with Tensorboard, AIM and Weghts&Biases, so we can run those tools for you. If you already use other tools for the visualization, skip this field.',
        type: INPUT_TYPES.SELECT
      },
      viz_api_key: {
        id: 'visualisation.viz_api_key',
        label: 'API key',
        description: 'Enter your API key',
        autoComplete: 'off',
        disabled: false,
        errorText: 'Please enter a valid API key',
        error: false,
        edit: true,
        type: INPUT_TYPES.STRING
      },
      startWithJob: {
        id: 'visualisation.startWithJob',
        label: 'Start With Job?',
        type: INPUT_TYPES.BOOLEAN
      }
    }
  }

}
deepFreeze(VISUALISATION_CONFIG)

export const COST_MANAGEMENT_CONFIG = {
  maxCost: {
    id: 'maxCost',
    label: 'Maximum Cost (USD $)',
    description: 'Specify maximum cost that you can spend for this experiment',
    type: INPUT_TYPES.NUMBER
  },
  maxTime: {
    id: 'maxTime',
    label: 'Maximum Time',
    placeholder: 'Examples: 1h15m or 2h or 2h2m',
    description: 'Limit the time you want GPUs to be active.',
    type: INPUT_TYPES.STRING
  },
  maxCostPerHour: {
    id: 'maxCostPerHour',
    label: 'Maximum Cost Per Hour (USD $)',
    description: 'Limit the cost that you can spend per hour for this experiment.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(COST_MANAGEMENT_CONFIG)

export const MAX_GPU_CONFIG = {
  maxGpus: {
    id: 'maxGpus',
    label: 'Maximum GPUs',
    description: 'This is the limitation on how many GPUs you want to use overall. E.g. if you configured batch_size = [16,32], lr = [0.1, 0.01], GPU per trial = 4  it will run 16 GPUs. You can limit it to 10 GPUs only or any number of your choice.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(MAX_GPU_CONFIG)

export const UPLOAD_CODE = {
  codeZip: {
    id: "codeZip",
    label: "Upload Code",
    description: 'Upload your code base as a zip file.',
    type: INPUT_TYPES.FILE,
    maxSize: 5000,
    accept: '.zip',
    upload: true,
  },
  // requirements: {
  //   id: 'requirements',
  //   label: 'Requirement File Name',
  //   placeholder: 'Enter Value',
  //   required: true,
  //   description: 'Path to python requirements file.',
  //   type: INPUT_TYPES.STRING
  // },
  codeTransfer: {
    id: 'codeTransfer',
    label: 'Code Repo Details',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'codeTransfer.type',
        label: 'Code Repo Host',
        options: [
          'B2'
        ],
        placeholder: 'Select an option',
        required: true,
        description: 'Choose a source where your code is stored, eg. "GitLab".',
        type: INPUT_TYPES.SELECT,
        display:'none'
      },
      repo: {
        id: 'codeTransfer.repo',
        label: 'Repo',
        placeholder: 'Enter value',
        required: true,
        description: 'Enter the name of your existing repository, where your code is located.',
        type: INPUT_TYPES.STRING,
        display:'none'
      },
      ref: {
        id: 'codeTransfer.ref',
        label: 'Commit hash or Branch name',
        placeholder: 'Enter value',
        description: 'Specific version or branch of your code to use.',
        type: INPUT_TYPES.STRING,
        display:'none'
      },
      codeDir: {
        id: 'codeTransfer.codeDir',
        label: 'Code Directory',
        placeholder: 'Enter value',
        description: 'Path to the directory containing the script relative to the repo.',
        type: INPUT_TYPES.STRING
      }
    }
  }
}

deepFreeze(UPLOAD_CODE)

// Yaml Config field
export const IMPORT_YML = {
  config: {
    id: "config",
    label: "IMPORT YML CONFIG",
    description: 'Use a config file(.yml) to instantly start up your tuning job.',
    type: INPUT_TYPES.FILE,
    accept: '.yml'
  }
}

deepFreeze(IMPORT_YML)

export const MAX_TRIALS = {
  maxTrials : {
    id: 'maxTrials',
    label: 'Maximum Trials',
    description: 'The maximum number of trials to be generated by the Tuner Algorithm. A trial is a version of your training script with a set of parameters picked by the Tuner Algoritm from the search space provided.',
    type: INPUT_TYPES.NUMBER
  },
}

// Launch experiment default value
export const LAUNCH_HYPERPARAMETER_TUNING_JOB_FORM_DEFAULT_VALUE = {
  name: '',
  cuda: null,
  useSpot: false,
  // -------------------------
  cloudProviders: [],
  cloudRegions: [],
  gpuTypes: [],
  // -------------------------
  minvCPUs: -1,
  minMemory: -1,
  // -------------------------
  gpusPerTrial: 1,
  maxGpus: 1,
  maxTrials: 20,
  pythonVersion: '3.8',
  environment: null,
  maxCost: -1,
  maxTime: '',
  maxCostPerHour: 1,
  maxCPUWorkers: 1,
  visualisation: {
    type: '',
    viz_api_key: '',
    startWithJob: false
  },
  useDAPP: false,
  epochs: -1,
  // -------------------------
  customImage: {
    image: '',
    credentials: {
      registry: '',
      username: '',
      password: ''
    }
  },
  // -------------------------
  entrypoint: '',
  requirements: '',
  codeTransfer: {
    type: null,
    repo: '',
    ref: '',
    codeDir: '',
    credentials: {
      GS_CREDS: '',
      AWS_SECRET_ACCESS_KEY: '',
      AWS_ACCESS_KEY_ID: '',
      DROPBOX_TOKEN: '',
      GITLAB_PAT: '',
      AZURE_ACCOUNT_KEY: '',
      BITBUCKET_APP_PASSWORD: '',
      GITHUB_PAT: '',
      GDRIVE_CREDS: '',
      AZURE_ACCOUNT_NAME: ''
    }
  },
  preJobCommands: [],
  postJobCommands: [],
  // -------------------------
  tuning: {
    searchSpace: {},
    tuner: {
      name: '',
      optimizeMode: ''
    }
  },
  virtualMounts: {},
  artifactsDestination:{
    name: '',
    filter: '',
  },
  config: ""
}
deepFreeze(LAUNCH_HYPERPARAMETER_TUNING_JOB_FORM_DEFAULT_VALUE)
