import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";

export const EDIT_GT2_CACHE = {
    G2Cache: {
      id: "G2Cache",
      label: "",
      type: INPUT_TYPES.BOOLEAN,
    },
  };
  
  deepFreeze(EDIT_GT2_CACHE);
  
  export const EDIT_GT2_CACHE_DEFAULT_VALUE = {
    G2Cache: false,
  };
  deepFreeze(EDIT_GT2_CACHE_DEFAULT_VALUE);