import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const usageApi = createApi({
  reducerPath: 'usageApi',
  ...platformAuthConfig(),
  tagTypes: ['Usage'],
  endpoints: builder => ({
    getUsage: builder.mutation({
      query: ({month, year}) => `/usage?month=${month}&year=${year}`,
      providesTags: ['Usage']
    }),
  })
})

export const {
  useGetUsageMutation
} = usageApi

export default usageApi
