import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";

export const ADD_ARTIFACT_STORAGE_CONFIG = {
  name: {
    id: "name",
    label: "Storage Name",
    required: true,
    type: INPUT_TYPES.STRING,
    errorFunc: (v) => v.includes('_') || v.includes(' '),
    errorText: 'Name should not contain an underscore or spaces("_" or " ")'
  },
  type: {
    id: "storage_type",
    label: "Storage Type",
    placeholder: "Select a storage type",
    options: ['S3', 'S3_LIKE', 'AZURE_BLOB_STORAGE', 'DROPBOX', 'GOOGLE_CLOUD_STORAGE', 'GOOGLE_DRIVE', 'SFTP'],
    required: true,
    type: INPUT_TYPES.SELECT
  }
};

deepFreeze(ADD_ARTIFACT_STORAGE_CONFIG);

export const ADD_ARTIFACT_PATH_CONFIG = {
  path: {
    id: "path",
    label: "Storage Path",
    required: true,
    prefix: '',
    type: INPUT_TYPES.STRING,
  }
}

deepFreeze(ADD_ARTIFACT_PATH_CONFIG)

export const ADD_ARTIFACT_STORAGE_CREDENTIALS = {
  credentials: {
    id: 'credentials.credentials',
    label: 'Storage Credentials',
    type: INPUT_TYPES.NESTED_OBJECT,
    required: true,
    fields: {}
  }
}
deepFreeze(ADD_ARTIFACT_STORAGE_CREDENTIALS)

export const EDIT_ARTIFACT_STORAGE_CONFIG = {
  name: {
    id: "name",
    label: "Storage Name",
    required: true,
    disabled: true,
    type: INPUT_TYPES.STRING,
    errorFunc: (v) => v.includes('_'),
    errorText: 'Name should not contain an underscore("_")'
  },
  path: {
    id: "path",
    label: "Storage Path",
    required: true,
    type: INPUT_TYPES.STRING,
  },
  credentials: {
    id: "credentials",
    label: "Storage Credentials",
    required: true,
    type: INPUT_TYPES.OBJECT,
  },
};

deepFreeze(EDIT_ARTIFACT_STORAGE_CONFIG);

// Clopd provider credential fields
export const ADD_ARTIFACT_STORAGE_CREDENTIALS_CONFIG = {
  S3: {
    AWS_ACCESS_KEY_ID: {
      id: 'credentials.AWS_ACCESS_KEY_ID',
      label: 'AWS_ACCESS_KEY_ID',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AWS_SECRET_ACCESS_KEY: {
      id: 'credentials.AWS_SECRET_ACCESS_KEY',
      label: 'AWS_SECRET_ACCESS_KEY',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  S3_LIKE: {
    ENDPOINT_URL: {
      id: 'credentials.ENDPOINT_URL',
      label: 'ENDPOINT_URL',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AWS_ACCESS_KEY_ID: {
      id: 'credentials.AWS_ACCESS_KEY_ID',
      label: 'AWS_ACCESS_KEY_ID',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    },
    AWS_SECRET_ACCESS_KEY: {
      id: 'credentials.AWS_SECRET_ACCESS_KEY',
      label: 'AWS_SECRET_ACCESS_KEY',
      placeholder: 'Enter value',
      autoComplete: 'off',
      type: INPUT_TYPES.STRING
    }
  },
  AZURE_BLOB_STORAGE: {
    AZURE_ACCOUNT_NAME: {
      id: "credentials.AZURE_ACCOUNT_NAME",
      label: "AZURE_ACCOUNT_NAME",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    AZURE_ACCOUNT_KEY: {
      id: "credentials.AZURE_ACCOUNT_KEY",
      label: "AZURE_ACCOUNT_KEY",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
  },
  DROPBOX: {
    DROPBOX_TOKEN: {
      id: "credentials.DROPBOX_TOKEN",
      label: "DROPBOX_TOKEN",
      placeholder: "Enter token",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  },
  GOOGLE_CLOUD_STORAGE: {
    GS_CREDS: {
      id: "credentials.GS_CREDS",
      label: "GS_CREDS",
      placeholder: "Enter credentials",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  },
  GOOGLE_DRIVE: {
    GDRIVE_CREDS: {
      id: "credentials.GDRIVE_CREDS",
      label: "GDRIVE_CREDS",
      placeholder: "Enter credentials",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  },
  SFTP: {
    SFTP_USERNAME: {
      id: "credentials.SFTP_USERNAME",
      label: "SFTP_USERNAME",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    SFTP_PASSWORD: {
      id: "credentials.SFTP_PASSWORD",
      label: "SFTP_PASSWORD/SFTP_PRIVATE_KEY",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    SFTP_PORT: {
      id: "credentials.SFTP_PORT",
      label: "SFTP_PORT",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    },
    SFTP_HOST: {
      id: "credentials.SFTP_HOST",
      label: "SFTP_HOST",
      placeholder: "Enter value",
      autoComplete: 'off',
      type: INPUT_TYPES.STRING,
    }
  }
}
deepFreeze(ADD_ARTIFACT_STORAGE_CREDENTIALS_CONFIG)

export const PREFIX_MAPPING = {
  S3: 's3://',
  S3_LIKE: 's3://',
  AZURE_BLOB_STORAGE: 'az://',
  DROPBOX: 'dropbox://',
  GOOGLE_CLOUD_STORAGE: 'gs://',
  GOOGLE_DRIVE: 'gdrive://',
  SFTP: 'sftp://'
}
deepFreeze(PREFIX_MAPPING)

export const ADD_ARTIFACT_STORAGE_DEFAULT_VALUE = {
  name: "",
  path: "",
};
deepFreeze(ADD_ARTIFACT_STORAGE_DEFAULT_VALUE);
