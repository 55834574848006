import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import YAML from 'yaml'
import yaml from 'js-yaml'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import Modal from "../../components/Modal"
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Tabs from '@mui/material/Tabs'
import Divider from '@mui/material/Divider'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import Form from '../../components/Form'
import { LoadingButton } from '@mui/lab'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay"
import AddVirtualMountView from "./AddVirtualMountView"
import AddAsTemplate from "./AddAsTemplate"
import { DIALOG_LABELS, VIEW_HEADERS, VIEW_ROUTES } from '../../constants/uiConstants'
import { uniqueNamesGenerator, adjectives, names } from 'unique-names-generator';
import {
    TAB_CONFIGS,
    CLOUD_PROVIDER_FIELDS_CONFIG,
    TEMPLATE_FIELDS
} from '../../constants/FormConstants/BaseFormConstants.js'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
    WS_CONTROL_CONFIGS,
    CUSTOM_IMAGE_CREDS_CONFIG,
    CREATE_WORKSTATION_FORM_DEFAULT_VALUE,
    WS_MANDATORY_FIELDS_CONFIG,
    MAX_CPU_WORKERS_CONFIG,
    WS_CPU_FIELDS_CONFIG,
    WS_GPU_FIELDS_CONFIG,
    WS_ADVANCE_FIELD_CONFIG,
    IMPORT_YML,
    NOT_BARE_BONE_FIELDS,
    ARTIFACTS_DESTINATION,
    VIRTUAL_MOUNT,
    VISUALISATION_CONFIG,
    CUDA_CONFIG,
    ENVIRONMENT_VAR
} from "../../constants/FormConstants/CreateWorkstationFormConstants";
import { useGetTemplatesQuery, useCreateTemplateMutation } from '../../services/templatesApi'
import { useCreateWorkstationMutation, useGetWorkstationQuery } from "../../services/workstationApi";
import {
    useGetVisualizationQuery,
    useRegisterVisualizationMutation
} from '../../services/visualizationApi'
import {
    useGetUserCloudSubscriptionsQuery,
    useGetGpuTypesMutation,
} from "../../services/cloudApi";
import { deletePropertyPath } from "../../utils";
import { setError } from '../../datastore/UI/uiSlice'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"

import { validatorFactory } from "../../utils/validator.js";
import { workstationSchema } from "../../utils/schema.js";
import CustomizedSteppers from '../../components/Stepper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from 'axios';

const wksValidation = validatorFactory(workstationSchema)

const CreateWorkstationForm = ({
    watchConfigFileUpload,
    cloudSubscriptionsData,
    allSecretKeys,
    allTemplates,
    getGpuTypes,
    gpuTypesData,
    cloudSubscriptionsApiError,
    artifactsStoragePaths,
    virtualMounts,
    allVizKeys,
    wk_data,
    createTemplate,
    submit,
    isLoading,
    isLoadingCreateTemplate,
    wkDataIsLoading,
    isLoadingAllTemplates,
    dispatch,
    navigate,
    type,
    name
}) => {
    const platformBearer = useSelector((state) => state.userState.platformBearer)
    const [autoFillData, setAutoFillData] = useState(type === "retry" || type === "templating")
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [downloadingYaml, setDownloadingYaml] = useState(false);
    const [stage, setStage] = useState(0);
    const stages = ['Code', 'Data', 'Configure', 'Deploy'];
    const [config, setConfig] = useState(TAB_CONFIGS.CONFIG.id)
    const [currentNameOfWks, SetCurrentNameOfWks] = useState(name);
    const [templateCredentials, setTemplateCredentials] = useState(null)
    const [formErrors, setFormErrors] = useState(null)


    // State variables for all the controlled accordions
    const [expandedConfig, setExpandedConfig] = React.useState(false);

    const handleChangeConfig = () => (event) => {
        setExpandedConfig(expandedConfig ? false : true);
    };

    const [expandedStreaming, setExpandedStreaming] = React.useState(false);

    const handleChangeStreaming = () => (event) => {
        setExpandedStreaming(expandedStreaming ? false : true);
    };

    const [expandedStorage, setExpandedStorage] = React.useState(false);

    const handleChangeStorage = () => (event) => {
        setExpandedStorage(expandedStorage ? false : true);
    };

    const [expandedCloud, setExpandedCloud] = React.useState(false);

    const handleChangeCloud = () => (event) => {
        setExpandedCloud(expandedCloud ? false : true);
    };

    const [expandedEnvironment, setExpandedEnvironment] = React.useState(false);

    const handleChangeEnvironment = () => (event) => {
        setExpandedEnvironment(expandedEnvironment ? false : true);
    };

    const [expandedTracking, setExpandedTracking] = React.useState(false);

    const handleChangeTracking = () => (event) => {
        setExpandedTracking(expandedTracking ? false : true);
    };
    /**
     * State to toggle between manual and docker image form fields
     */
    const [imageSettings, setImageSettings] = useState(TAB_CONFIGS.MANUAL_SETTINGS.id)
    /**
     * State to hold the error message
     */
    const [errorMessage, setErrorMessage] = useState("");
    /**
     * State to toggle credential fields
     */
    const [showCredentials, setShowCredentials] = useState(false);

    /**
     * State to toggle between CPU and GPU fields
     */
    const [cpuOrGpu, setCpuOrGpu] = useState(TAB_CONFIGS.GPU_SETTINGS.id)

    /**
     * State to hold the form config. We keep it in a separate state as its sbjected to changes 
     */
    const [gpuFieldsConfig, setGpuFieldsConfig] = useState(WS_GPU_FIELDS_CONFIG)
    const [cloudFieldsConfig, setCloudFieldsConfig] = useState(CLOUD_PROVIDER_FIELDS_CONFIG)
    const [artifactsDestinationConfig, setArtifactsDestinationConfig] = useState(ARTIFACTS_DESTINATION)
    const [virtualMountConfig, setVirtualMountConfig] = useState(VIRTUAL_MOUNT)
    const [visualisationConfig, setVisualisationConfig] = useState(VISUALISATION_CONFIG)
    const [templateData, setTemplateData] = useState(null)
    const [credentials, setCredentials] = useState(null)

    /**
     * Initialize form state
     */
    const { control, handleSubmit, getValues, setValue, formState, watch, reset } = useForm({
        defaultValues: CREATE_WORKSTATION_FORM_DEFAULT_VALUE,
    });
    /**
     * Create watchers on specific fields
     */
    const watchCloudProviders = watch("cloudProviders");
    const watchCloudRegions = watch("cloudRegions");
    const watchIsImagePrivate = watch("cloudRegions");
    const watchVisualisationSelect = watch('visualisation.type')
    // const watchConfigFileUpload = watch('configFile')
    /**
     * Set the initial values for fields from API
     */
    useEffect(() => {
        if (cloudSubscriptionsData) {
            if (getValues()[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id].length === 0) {
                const [cloudProviderOptions] = cloudSubscriptionsData
                setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id, cloudProviderOptions)
            }
        }
    }, [cloudSubscriptionsData, setValue])

    useEffect(() => {
        if (cloudSubscriptionsData && Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0) {
            // if (getValues()[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id].length === 0) {
            //     const { 1: cloudRegionOptions } = cloudSubscriptionsData;
            //     setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id, cloudRegionOptions);
            // }
            const { 1: cloudRegionOptions } = cloudSubscriptionsData;
            setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id, cloudRegionOptions);
        }
    }, [setValue, watchCloudProviders, cloudSubscriptionsData])

    useEffect(() => {
        if (allTemplates) {
            const templateObject = allTemplates.filter(s => s.id === location.pathname.split('/').pop())
            if (templateObject.length > 0) {
                setTemplateData(templateObject[0])
            }
        }
    }, [allTemplates])

    useEffect(() => {
        if (allSecretKeys && type === "templating") {
            const templateSecrets = allSecretKeys.filter(s => s.split(':')[1].slice(0, s.split(':')[1].indexOf('_')) === location.pathname.split('/').pop())
            let requests = []
            const baseUrl = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : process.env.REACT_APP_BASE_API_URL;
            templateSecrets.forEach((key) => {
                requests.push(axios.get(baseUrl + `/secret/${key}`,
                    {
                        headers: {
                            Authorization: `Bearer ${platformBearer}`,
                        },
                    }
                ))
            })
            axios.all(requests)
                .then(axios.spread((...res) => {
                    // Both requests are now complete
                    setTemplateCredentials(Object.assign({}, ...res.map(r => r.data.secret)));
                }));
        }
    }, [allSecretKeys])

    /**
     * Set the options for fields from API data and dependent field values
     */
    useEffect(() => {
        if (cloudSubscriptionsData) {
            const [cloudProviderOptions, cloudRegionOptions] = cloudSubscriptionsData;
            function update(state) {
                return {
                    ...state,
                    [CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id]: {
                        ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id],
                        options: Array.isArray(cloudProviderOptions)
                            ? cloudProviderOptions
                            : [],
                    },
                    [CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id]: {
                        ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id],
                        disabled: !(
                            Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0
                        ),
                        options: Array.isArray(cloudRegionOptions)
                            ? cloudRegionOptions.filter((region) =>
                                watchCloudProviders.some(
                                    (provider) => region.indexOf(provider) === 0
                                )
                            )
                            : [],
                    },
                    [WS_GPU_FIELDS_CONFIG.gpuTypes.id]: {
                        ...state[WS_GPU_FIELDS_CONFIG.gpuTypes.id],
                        disabled: !(
                            Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0
                        ),
                        options: Array.isArray(gpuTypesData) ? gpuTypesData : [],
                    }
                }
            }

            setGpuFieldsConfig(update)
            setCloudFieldsConfig(update)
        }
    }, [
        cloudSubscriptionsData,
        gpuTypesData,
        watchCloudProviders,
        watchCloudRegions,
    ]);
    /**
     * Filter the values of cloud regions on cloud provider change
     */
    useEffect(() => {
        setValue(
            CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id,
            getValues(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id).filter((region) =>
                watchCloudProviders.some((provider) => region.indexOf(provider) === 0)
            )
        );
    }, [getValues, setValue, watchCloudProviders]);
    /**
     * Reset the value of `gpuTypes` when `cloudProvider` or `cloudRegion` data changes
     */
    useEffect(() => {
        if (gpuTypesData) {
            const updatedGpus = getValues()[WS_GPU_FIELDS_CONFIG.gpuTypes.id]?.filter(item => gpuTypesData.includes(item));
            setValue(WS_GPU_FIELDS_CONFIG.gpuTypes.id, updatedGpus)
        }
    }, [setValue, gpuTypesData, watchCloudProviders, watchCloudRegions]);
    /**
     * Call getGpuTypes API in response to the changes in dependent fields
     */
    useEffect(() => {
        if (
            Array.isArray(watchCloudProviders) &&
            watchCloudProviders.length > 0 &&
            Array.isArray(watchCloudRegions) &&
            watchCloudRegions.length > 0
        ) {
            // getGpuTypes(watchCloudProviders, watchCloudRegions)
            getGpuTypes({
                cloudProviders: watchCloudProviders,
                cloudRegions: watchCloudRegions,
            })
                .unwrap()
                .catch((error) => console.log(error?.data));

        }
    }, [getGpuTypes, watchCloudProviders, watchCloudRegions]);
    /**
     * Clear custom image config if is private is false
     */
    useEffect(() => {
        if (!watchIsImagePrivate) {
            setValue(CUSTOM_IMAGE_CREDS_CONFIG.credentials.id, {});
        }
    }, [setValue, watchIsImagePrivate]);

    useEffect(() => {
        if (credentials) {
            setValue(CUSTOM_IMAGE_CREDS_CONFIG.credentials.id, credentials);
            setCredentials(null);
        }
    }, [setValue, credentials])
    /**
     * Set error message to `cloudSubscriptionsApiError`
     */
    useEffect(() => {
        if (cloudSubscriptionsApiError) {
            setErrorMessage(cloudSubscriptionsApiError?.data);
        }
    }, [cloudSubscriptionsApiError]);

    /**
     * Set the options for fields for ArtifactStoragePath
     */
    useEffect(() => {
        if (artifactsStoragePaths) {

            const optionValues = artifactsStoragePaths.map((item) => item.name + ":" + item.path)
            // optionValues.push('WANDB');
            // optionValues.push('COMETML');
            function update(state) {
                const newObject = structuredClone(state)
                newObject.artifactsDestination.fields.name.options = Array.isArray(artifactsStoragePaths) ?
                    optionValues : [] //['WANDB', 'COMETML']

                return newObject
            }

            setArtifactsDestinationConfig(update)
            setValue(ARTIFACTS_DESTINATION.artifactsDestination.fields.name.id,
                optionValues[0])
        }
    }, [artifactsStoragePaths, setValue])

    /**
     * Set the options for fields for Virtual Mounts
     */
    useEffect(() => {
        if (virtualMounts) {
            const allNames = Array.isArray(virtualMounts) ? virtualMounts.map(i => i.name) : []
            setVirtualMountConfig((state) => {
                const newObject = structuredClone(state)
                newObject.virtualMount.options = Array.isArray(allNames) ? allNames : []
                return newObject
            })
            if(virtualMounts.length > 0 && type==="new"){
                addData(virtualMounts[0])
              }
        }
    }, [virtualMounts, setValue]);

    useEffect(() => {
        if (allVizKeys) {
            if (watchVisualisationSelect) {
                const selectedVizArray = allVizKeys.filter(s => s.type.includes(watchVisualisationSelect));
                setVisualisationConfig((state) => {
                    const newObject = structuredClone(state)
                    if (watchVisualisationSelect === "WANDB" || watchVisualisationSelect === "COMETML") {
                        newObject.visualisation.fields.viz_api_key.display = "";
                    } else {
                        newObject.visualisation.fields.viz_api_key.display = "none";
                    }
                    newObject.visualisation.fields.viz_api_key.disabled = true;
                    return newObject
                })
                setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, selectedVizArray.length ? selectedVizArray[0].key : "");

            } else {
                setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
            }
        }
    }, [watchVisualisationSelect, allVizKeys])

    // Add the selected Virtual Mount to the object array
    function addData(virtualMount) {
        const currentVirtualMountValues = getValues()[VIRTUAL_MOUNT.virtualMount.id];
        virtualMount = [virtualMount]
        let virtualMountValues = Object.assign(currentVirtualMountValues ?? {}, ...virtualMount.map((x) => ({ [x.name]: x })));
        setValue(VIRTUAL_MOUNT.virtualMount.id, virtualMountValues);
        setOpenCreateModal(false);
    }

    // Toggle disability of api key field
    function disableField() {
        setVisualisationConfig((state) => {
            const newObject = structuredClone(state)
            newObject.visualisation.fields.viz_api_key.disabled = !newObject.visualisation.fields.viz_api_key.disabled;
            return newObject
        })
        setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
    }

    function openModal() {
        setOpenCreateModal(true);
    }

    // Auto fill the form if experiment is being retried or templated or imported
    useEffect(() => {
        if ((autoFillData || watchConfigFileUpload) && artifactsStoragePaths && virtualMounts && cloudSubscriptionsData && (wk_data || (templateData && templateCredentials) || watchConfigFileUpload)) {
            let dataToBeFilled;
            if (watchConfigFileUpload) {
                dataToBeFilled = yaml.load(watchConfigFileUpload);
                try {
                    const result = wksValidation.verify(dataToBeFilled);
                } catch (error) {
                    // console.error(error);
                    dispatch(setError(error.message))
                    return;
                }
                reset();
            } else {
                if (type === "retry")
                    dataToBeFilled = JSON.parse(JSON.stringify(wk_data?.config));
                else
                    dataToBeFilled = JSON.parse(JSON.stringify(templateData.config));
            }
            const [cloudProviderOptions, cloudRegionOptions] = cloudSubscriptionsData;
            let cldProviders = []
            let cldRegions = []
            dataToBeFilled.cloudProviders.forEach(
                (cldObject) => {
                    if (cloudProviderOptions.find(cp => cp === cldObject.name)) {
                        cldProviders.push(cldObject.name)
                    }
                    cldObject.regions.forEach(r => {
                        if (cloudRegionOptions.find(cr => cr === cldObject.name + ':' + r)) {
                            cldRegions.push(cldObject.name + ':' + r)
                        }
                    })
                });
            setValue('cloudProviders', cldProviders)
            setValue('cloudRegions', cldRegions)
            if (dataToBeFilled.visualisation) {
                setValue('visualisation.type', dataToBeFilled.visualisation)
            }
            if (watchConfigFileUpload && dataToBeFilled.environment) {
                let envArgObject = {}
                Object.entries(dataToBeFilled.environment).forEach(
                    ([name, value]) => {
                        envArgObject[name] = value;
                    });
                setValue('environment', envArgObject)
            }
            if (dataToBeFilled.artifactsDestination) {
                const selectedartifactsDestination = artifactsStoragePaths.filter(s => s.name.includes(dataToBeFilled.artifactsDestination.name))[0];
                if (selectedartifactsDestination) {
                    setValue('artifactsDestination.name', selectedartifactsDestination.name + ':' + selectedartifactsDestination.path)
                    setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
                } else {
                    setValue('artifactsDestination.name', dataToBeFilled.artifactsDestination.name)
                    setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
                }

            }
            if (templateData && templateCredentials) {
                const envSecretNames = (dataToBeFilled.environment ? Object.keys(dataToBeFilled.environment) : []);
                let newTemplateCredsObject = {}
                let envArgObject = {}
                Object.keys(templateCredentials).forEach(key => {
                    const tempKey = key.split(':')[1].slice(key.split(':')[1].indexOf('_') + 1)
                    if (tempKey.split('_')[0] === 'DOCKER') {
                        newTemplateCredsObject[tempKey.split('_')[1].toLowerCase()] = templateCredentials[key]
                    } else if (envSecretNames.indexOf(tempKey) > -1) {
                        envArgObject[tempKey] = templateCredentials[key]
                    } else {
                        newTemplateCredsObject[tempKey] = templateCredentials[key]
                    }
                })
                setValue('environment', envArgObject);
                if (Object.keys(newTemplateCredsObject).length > 0) {
                    setShowCredentials(true);
                    setCredentials(newTemplateCredsObject);
                }
            }
            if (dataToBeFilled.virtualMounts) {
                setValue(VIRTUAL_MOUNT.virtualMount.id, {})
                dataToBeFilled.virtualMounts.forEach(
                    (vMount) => {
                        if (virtualMounts.find(v => v.name === vMount.name))
                            addData(vMount);
                    });
            }
            if (dataToBeFilled.maxCPUWorkers) {
                setCpuOrGpu(TAB_CONFIGS.CPU_SETTINGS.id)
            } else {
                setCpuOrGpu(TAB_CONFIGS.GPU_SETTINGS.id)
                delete dataToBeFilled['maxCPUWorkers'];
            }
            if (dataToBeFilled.customImage) {
                setImageSettings(TAB_CONFIGS.DOCKER_SETTINGS.id);
                setValue('customImage.image', dataToBeFilled.customImage.image)
                if (watchConfigFileUpload && dataToBeFilled.customImage.credentials) {
                    setShowCredentials(true)
                    setValue('customImage.credentials', dataToBeFilled.customImage.credentials)
                }
            }
            if (templateData && type === "templating") {
                setValue('name', templateData.id + '-' + uniqueNamesGenerator({ dictionaries: [adjectives], length: 1 }))
            }
            delete dataToBeFilled['name'];
            delete dataToBeFilled['customImage'];
            delete dataToBeFilled['environment'];
            delete dataToBeFilled['cloudProviders'];
            delete dataToBeFilled['visualisation'];
            delete dataToBeFilled['artifactsDestination'];
            delete dataToBeFilled['virtualMounts'];
            Object.entries(dataToBeFilled).forEach(
                ([name, value]) => {
                    // console.log(name, value)
                    setValue(name, value)
                });
            setAutoFillData(null);
            setExpandedConfig(true);
            setExpandedCloud(true);
            setExpandedEnvironment(true);
            setExpandedStorage(true);
            setExpandedStreaming(true);
            setExpandedTracking(true);
        }
    }, [watchConfigFileUpload, wk_data, templateData, templateCredentials, autoFillData, artifactsStoragePaths, virtualMounts, cloudSubscriptionsData, setValue])

    useEffect(() => {
        if (formState.errors
            && Object.keys(formState.errors).length > 0
            && Object.getPrototypeOf(formState.errors) === Object.prototype) {
            setFormErrors(formState.errors)
        } else {
            setFormErrors(null)
        }
    }, [formState])

    /**
     * Function to submit the form data to `submit` API
     * @param {object} formData
     */
    const onSubmit = useCallback(
        (formData) => {
            const data = {
                ...formData,
            };
            delete data['configFile'];
            data['name'] = currentNameOfWks;
            if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
                const cludProviderIds = Object.keys(CLOUD_PROVIDER_FIELDS_CONFIG)
                // manually setting max cpu workers to be 1 in case of cpu dependent
                data['maxCPUWorkers'] = 1;
                Object.values(WS_GPU_FIELDS_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                })
                deletePropertyPath(data, CUDA_CONFIG.cuda.id)
            }
            else {
                deletePropertyPath(data, MAX_CPU_WORKERS_CONFIG.maxCPUWorkers.id)
            }
            // creating the cloudProvider object
            let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
            let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
            const getRegions = (cp) => {
                return crs
                    .filter((cr) => cr.startsWith(cp))
                    .map((cr) => cr.split(":")[1]);
            };
            const cloudProviders = cps.map((cp) => ({
                name: cp,
                regions: getRegions(cp),
            }));
            data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
            deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);
            if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
                deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);
            else
                data[VISUALISATION_CONFIG.visualisation.id] = data[VISUALISATION_CONFIG.visualisation.id].type

            if (data[ENVIRONMENT_VAR.environment.id] === undefined) {
                deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);
            }
            if (data[ENVIRONMENT_VAR.environment.id] && data[ENVIRONMENT_VAR.environment.id][""] != undefined) {
                delete data[ENVIRONMENT_VAR.environment.id][""];
            }

            if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
                deletePropertyPath(data, NOT_BARE_BONE_FIELDS.customImage.id)
                deletePropertyPath(data, 'customImage');
                deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id)
            } else if (imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id) {
                Object.values(WS_ADVANCE_FIELD_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                });
                if (data['customImage']['image'] == '')
                    deletePropertyPath(data, 'customImage');
                if (!showCredentials) {
                    deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
                }
            }

            data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
                (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

            if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
                deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

            //creating the selected virtualMount object
            data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})
            // console.log(data[VIRTUAL_MOUNT.virtualMount.id]);
            if (!showCredentials) {
                deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
            }
            deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);
            // console.log(data);
            if (data[ENVIRONMENT_VAR.environment.id] === undefined) {
                deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);
            }
            if (data[ENVIRONMENT_VAR.environment.id] && data[ENVIRONMENT_VAR.environment.id][""] != undefined) {
                delete data[ENVIRONMENT_VAR.environment.id][""];
            }
            if (type === 'templating') {
                data['from_template'] = templateData.name;
            }
            submit(data)
                .unwrap()
                .then((res) => {
                    setTimeout(() => {
                        navigate(`../${VIEW_ROUTES.ALL_WORKSTATIONS}`, { replace: true })
                    }, 1000)
                })
                .catch((error) => {
                    dispatch(setError(error))
                });
        },
        [cpuOrGpu, dispatch, navigate, virtualMounts, currentNameOfWks, showCredentials, watchVisualisationSelect, imageSettings, submit]
    );
    /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */
    const onSavingAsTemplate = useCallback(
        (formData) => {
            const data = {
                ...getValues(),
                ...formData
            };
            data['name'] = data['templateName']
            delete data['configFile'];
            if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
                const cludProviderIds = Object.keys(CLOUD_PROVIDER_FIELDS_CONFIG)
                // manually setting max cpu workers to be 1 in case of cpu dependent
                data['maxCPUWorkers'] = 1;
                Object.values(WS_GPU_FIELDS_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                })
                deletePropertyPath(data, CUDA_CONFIG.cuda.id)
            }
            else {
                deletePropertyPath(data, MAX_CPU_WORKERS_CONFIG.maxCPUWorkers.id)
            }

            // creating the cloudProvider object
            let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
            let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
            const getRegions = (cp) => {
                return crs
                    .filter((cr) => cr.startsWith(cp))
                    .map((cr) => cr.split(":")[1]);
            };
            const cloudProviders = cps.map((cp) => ({
                name: cp,
                regions: getRegions(cp),
            }));
            data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
            deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);
            if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
                deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);
            else
                data[VISUALISATION_CONFIG.visualisation.id] = data[VISUALISATION_CONFIG.visualisation.id].type

            if (data[ENVIRONMENT_VAR.environment.id] === undefined) {
                deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);
            }
            if (data[ENVIRONMENT_VAR.environment.id] && data[ENVIRONMENT_VAR.environment.id][""] != undefined) {
                delete data[ENVIRONMENT_VAR.environment.id][""];
            }

            if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
                deletePropertyPath(data, NOT_BARE_BONE_FIELDS.customImage.id)
                deletePropertyPath(data, 'customImage')
                deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id)
            } else if (imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id) {
                Object.values(WS_ADVANCE_FIELD_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                });
                if (data['customImage']['image'] == '')
                    deletePropertyPath(data, 'customImage');
                if (!showCredentials) {
                    deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
                }
            }

            data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
                (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

            if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
                deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

            //creating the selected virtualMount object
            data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})
            // console.log(data[VIRTUAL_MOUNT.virtualMount.id]);

            if (!showCredentials) {
                deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
            }
            deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);
            // console.log(data);
            deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);

            createTemplate({
                id: data.templateName,
                name: data.templateName,
                config: data,
                type: 'WORKSTATION',
            })
                .unwrap()
                .then(() => {
                    setTimeout(() => {
                        navigate(`../${VIEW_ROUTES.ALL_WORKSTATIONS}`, { replace: true })
                    }, 1000)
                })
                .catch((error) => {
                    dispatch(setError(error))
                });
        },
        [cpuOrGpu, dispatch, navigate, virtualMounts, showCredentials, watchVisualisationSelect, imageSettings]
    );

    /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */
    const onDownloadYAML = useCallback(
        (formData) => {
            setDownloadingYaml(true)
            const data = {
                ...getValues(),
                ...formData
            };
            delete data['configFile'];
            if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
                const cludProviderIds = Object.keys(CLOUD_PROVIDER_FIELDS_CONFIG)
                // manually setting max cpu workers to be 1 in case of cpu dependent
                data['maxCPUWorkers'] = 1;
                Object.values(WS_GPU_FIELDS_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                })
                deletePropertyPath(data, CUDA_CONFIG.cuda.id)
            }
            else {
                deletePropertyPath(data, MAX_CPU_WORKERS_CONFIG.maxCPUWorkers.id)
            }

            // creating the cloudProvider object
            let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
            let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
            const getRegions = (cp) => {
                return crs
                    .filter((cr) => cr.startsWith(cp))
                    .map((cr) => cr.split(":")[1]);
            };
            const cloudProviders = cps.map((cp) => ({
                name: cp,
                regions: getRegions(cp),
            }));
            data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
            deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);
            if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
                deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);
            else
                data[VISUALISATION_CONFIG.visualisation.id] = data[VISUALISATION_CONFIG.visualisation.id].type

            if (data[ENVIRONMENT_VAR.environment.id] === undefined) {
                deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);
            }
            if (data[ENVIRONMENT_VAR.environment.id] && data[ENVIRONMENT_VAR.environment.id][""] != undefined) {
                delete data[ENVIRONMENT_VAR.environment.id][""];
            }

            if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
                deletePropertyPath(data, NOT_BARE_BONE_FIELDS.customImage.id)
                deletePropertyPath(data, 'customImage')
                deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id)
            } else if (imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id) {
                Object.values(WS_ADVANCE_FIELD_CONFIG).forEach(element => {
                    deletePropertyPath(data, element.id)
                });
                if (data['customImage']['image'] == '')
                    deletePropertyPath(data, 'customImage');
                if (!showCredentials) {
                    deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
                }
            }

            data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
                (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

            if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
                deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

            //creating the selected virtualMount object
            data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})
            deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);
            // console.log(data);
            // start the download
            const element = document.createElement("a");
            const file = new Blob([YAML.stringify(data)], { type: 'text/yaml' });
            element.href = URL.createObjectURL(file);
            element.download = "WorkstationConfig.yml";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setDownloadingYaml(false)
        },
        [cpuOrGpu, dispatch, navigate, virtualMounts, showCredentials, watchVisualisationSelect, imageSettings]
    );


    return (
        <>
            <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
                <Stack alignItems="center" direction="row" spacing={4}>
                    <Typography variant="h5">
                        {VIEW_HEADERS.CREATE_NEW_WORKSTATIONS}
                    </Typography>
                    <FormControl sx={{ m: 1 }} variant="outlined" size="small">
                        <InputLabel htmlFor="outlined-adornment-job-name">Enter Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-job-name"
                            type='text'
                            value={currentNameOfWks}
                            endAdornment={
                                <InputAdornment position="end">
                                    <EditIcon />
                                </InputAdornment>
                            }
                            label="Enter Job Name"
                            onChange={(event) => {
                                SetCurrentNameOfWks(event.target.value)
                                setValue('name', event.target.value)
                            }}
                        />
                    </FormControl>
                </Stack>
            </Container>
            <br />
            <CustomizedSteppers step={stage} steps={['Data', 'Configure', 'Deploy']} setStage={setStage} />
            
            <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
                {formErrors &&
                    <Alert severity="error">
                        <AlertTitle>Form Error</AlertTitle>
                        Please check the following fields — <strong>[{Object.keys(formErrors).join(', ')}]</strong>
                    </Alert>
                }
                {(wkDataIsLoading || isLoadingAllTemplates) && (type === "retry" || type === "templating") && type === "retry" && <LoadingOverlay />}
                <Form
                    control={control}
                    fields={{}}
                    loading={isLoading || isLoadingCreateTemplate}
                    submitAtEnd={true}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {/* <FormFields control={control} fields={IMPORT_YML} /> */}
                    {<Box sx={stage != 0 ?{display: 'none'} : {}}>
                        <Typography gutterBottom variant="h5" component="div">
                            Data Sources
                        </Typography>
                        <FormFields control={control} fields={virtualMountConfig} openModal={openModal} />
                        <Modal
                            onClose={() => setOpenCreateModal(false)}
                            open={openCreateModal}
                            title="Add Virtual Mount"
                        >
                            <AddVirtualMountView
                                addData={addData}
                            />
                        </Modal>
                        <br />
                        <Typography gutterBottom variant="h5" component="div">
                            Artifact Storage
                        </Typography>
                        <FormFields control={control} fields={artifactsDestinationConfig} />
                        <br />
                        <Typography gutterBottom variant="h5" component="div">
                            Experiments Tracking
                        </Typography>
                        <FormFields control={control} fields={visualisationConfig} disableField={disableField} toValidate={watchVisualisationSelect} validateInput={true} />
                        <br />
                        <Stack direction="row" justifyContent="flex-end">
                            <Button
                                color='secondary'
                                onClick={() => {
                                    setStage(stage + 1)
                                }}
                                variant='contained'
                                sx={{ borderRadius: '20px' }}
                            >
                                Next
                                <ArrowForwardIcon />
                            </Button>
                        </Stack>
                    </Box>}
                    {<Box sx={stage != 1 ?{display: 'none'} : {}}>
                        {stage===1 && <Tabs sx={{ m: 1 }} value={config} onChange={(e, value) => setConfig(value)}>
                            <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CONFIG.label} value={TAB_CONFIGS.CONFIG.id} />
                            <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.ENVIRONMENT_CONFIG.label} value={TAB_CONFIGS.ENVIRONMENT_CONFIG.id} />
                        </Tabs>}
                        {config === TAB_CONFIGS.CONFIG.id && (
                            <>
                                <Stack>
                                    <FormFields control={control} fields={WS_MANDATORY_FIELDS_CONFIG} />
                                    {stage===1 && <Tabs sx={{ m: 1 }} value={cpuOrGpu} onChange={(e, value) => setCpuOrGpu(value)}>
                                        <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CPU_SETTINGS.label} value={TAB_CONFIGS.CPU_SETTINGS.id} />
                                        <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.GPU_SETTINGS.label} value={TAB_CONFIGS.GPU_SETTINGS.id} />
                                    </Tabs>}
                                    {cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id && (
                                        <FormFields control={control} fields={MAX_CPU_WORKERS_CONFIG} />
                                    )}
                                    {cpuOrGpu === TAB_CONFIGS.GPU_SETTINGS.id && (
                                        <>
                                            <FormFields control={control} fields={gpuFieldsConfig} />
                                            <FormFields control={control} fields={CUDA_CONFIG} />
                                        </>
                                    )}
                                </Stack>
                                <br />
                                <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#292929', borderRadius: '8px' }} expanded={expandedConfig} onChange={handleChangeConfig()}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Stack>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Advanced
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Specify necessary parameters to start the experiment
                                            </Typography>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Divider sx={{ m: 1 }}>
                                        </Divider>
                                        <FormFields control={control} fields={cloudFieldsConfig} />
                                        <FormFields control={control} fields={WS_CPU_FIELDS_CONFIG} />
                                    </AccordionDetails>
                                </Accordion>
                                <br />
                                <Stack direction="row" justifyContent="flex-end">
                                    <Button
                                        color='secondary'
                                        onClick={() => {
                                            setStage(stage + 1)
                                        }}
                                        variant='contained'
                                        sx={{ borderRadius: '20px', m: 1 }}
                                    >
                                        Review & Deploy
                                        <ArrowForwardIcon />
                                    </Button>
                                    <Button
                                        color='secondary'
                                        onClick={() => {
                                            setConfig(TAB_CONFIGS.ENVIRONMENT_CONFIG.id)
                                        }}
                                        variant='contained'
                                        sx={{ borderRadius: '20px', m: 1 }}
                                    >
                                        Next
                                        <ArrowForwardIcon />
                                    </Button>
                                </Stack>
                            </>

                        )}
                        {config === TAB_CONFIGS.ENVIRONMENT_CONFIG.id && (
                            <>
                                <Stack>
                                    <FormFields control={control} fields={ENVIRONMENT_VAR} />
                                    {stage===1 && <Tabs sx={{ ml: 1, mr: 1, mb: 2, mt: 2 }} value={imageSettings} onChange={(e, value) => setImageSettings(value)}>
                                        <Tab label={TAB_CONFIGS.MANUAL_SETTINGS.label} value={TAB_CONFIGS.MANUAL_SETTINGS.id} />
                                        <Tab label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} />
                                    </Tabs>}
                                    {imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id && (
                                        <>
                                            <FormFields control={control} fields={WS_ADVANCE_FIELD_CONFIG} />
                                        </>
                                    )}
                                    {imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id && (
                                        <>
                                            <FormFields control={control} fields={NOT_BARE_BONE_FIELDS} />
                                            <FormControlLabel
                                                sx={{ m: 1 }}
                                                id={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.id}
                                                label={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.label}
                                                control={
                                                    <Switch
                                                        checked={showCredentials}
                                                        value={showCredentials}
                                                        onChange={(e) => setShowCredentials(e.target.checked)}
                                                    />
                                                }
                                            />
                                            {showCredentials && (
                                                <FormFields control={control} fields={CUSTOM_IMAGE_CREDS_CONFIG} />
                                            )}
                                        </>
                                    )}
                                </Stack>
                                <br />
                                <Stack direction="row" justifyContent="flex-end">
                                    <Button
                                        color='secondary'
                                        onClick={() => {
                                            setStage(stage + 1)
                                        }}
                                        variant='contained'
                                        sx={{ borderRadius: '20px', m: 1 }}
                                    >
                                        Deploy
                                        <ArrowForwardIcon />
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Box>}
                    {<Box sx={stage != 2 ?{display: 'none'} : {}}>
                        <Stack direction='row' spacing={2}>
                            <Modal
                                onClose={() => setSaveAsTemplate(false)}
                                open={!!saveAsTemplate}
                                title={'Save configuration as a template?'}
                            >
                                <AddAsTemplate
                                    saveTemplate={onSavingAsTemplate}
                                    isLoading={isLoadingCreateTemplate}
                                />
                            </Modal>
                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                <LoadingButton
                                    color='secondary'
                                    loading={isLoadingCreateTemplate}
                                    onClick={() => setSaveAsTemplate(true)}
                                    variant='contained'
                                    sx={{ borderRadius: '20px' }}
                                >
                                    Save as Template
                                </LoadingButton>
                                <LoadingButton

                                    color='secondary'
                                    loading={downloadingYaml}
                                    onClick={() => onDownloadYAML(true)}
                                    variant='contained'
                                    sx={{ borderRadius: '20px' }}
                                >
                                    Download as YAML config
                                </LoadingButton>
                            </Stack>
                        </Stack>
                        <br />
                        <Stack direction="row" justifyContent="flex-end">
                            <LoadingButton
                                fullWidth
                                color='success'
                                onClick={handleSubmit(onSubmit)}
                                variant='contained'
                                sx={{ borderRadius: '20px', }}
                                role='SUBMIT'
                                data-testid='submit'
                                loading={isLoading || isLoadingCreateTemplate}
                                type='submit'
                            >
                                <PlayArrowIcon />
                                Run
                            </LoadingButton>
                        </Stack>
                    </Box>}
                </Form>
            </Container>

            <Dialog
                open={errorMessage.length > 0}
                onClose={() => setErrorMessage("")}
            >
                <Alert severity="error">
                    <AlertTitle>{DIALOG_LABELS.ERROR}</AlertTitle>
                    <strong>{errorMessage}</strong>
                </Alert>
            </Dialog>
        </>
    )
};

export default CreateWorkstationForm;
