import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Form from "../../components/Form";
import FormFields from "../../components/Form/FormFields";
import FolderDrawer from "../../components/FolderDrawer";
import {
  ADD_ARTIFACT_STORAGE_CONFIG,
  EDIT_ARTIFACT_STORAGE_CONFIG,
  ADD_ARTIFACT_STORAGE_DEFAULT_VALUE,
  ADD_ARTIFACT_STORAGE_CREDENTIALS,
  ADD_ARTIFACT_STORAGE_CREDENTIALS_CONFIG,
  ADD_ARTIFACT_PATH_CONFIG,
  PREFIX_MAPPING
} from "../../constants/FormConstants/AddArtifactStorageConstants";
import { setError } from "../../datastore/UI/uiSlice";
import { deletePropertyPath } from "../../utils";
import { GoogleOAuthProvider, useGoogleLogin   } from '@react-oauth/google';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useGetTokensMutation } from "../../services/exchangeAuthCodeApi";

const AddArtifactsStorageView = ({ defaultData, edit, close, submit, isLoading }) => {
  const [open, setOpen] = useState(false);
  const [isGdriveLoading, setIsGdriveLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [folders, setFolders] = useState(null);
  const [currentFolderDetails, setCurrentFolderDetails] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderStack,setFolderStack] = useState([]);
  const dispatch = useDispatch();
  const [exchangeTokens, { isLoading: isLoadingExchangeTokens }] = useGetTokensMutation()
  /**
   * Sate to hold the form config. We keep it in a separate state as its subjected to changes
   */
  const [formConfig] = useState(edit ? EDIT_ARTIFACT_STORAGE_CONFIG : ADD_ARTIFACT_STORAGE_CONFIG);
  /**
   * Cloud provider credentials config
   */
   const [storageCredentialFormConfig, setStorageCredentialFormConfig] = useState(ADD_ARTIFACT_STORAGE_CREDENTIALS)
   const [storagePathFormConfig, setStoragePathFormConfig] = useState(ADD_ARTIFACT_PATH_CONFIG)
  /**
   * Initialize form state
   */
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: !!defaultData
      ? defaultData
      : ADD_ARTIFACT_STORAGE_DEFAULT_VALUE,
  });
  /**
   * Create watchers
   */
   const watchType = watch(ADD_ARTIFACT_STORAGE_CONFIG.type.id)
   /**
   * Add appropriate credential fields as per the provider selected
   */
  useEffect(() => {
    if (watchType) {
      setStoragePathFormConfig((state) => {
        const newObject = structuredClone(state)
        newObject.path.prefix = PREFIX_MAPPING[watchType]
        return newObject
      })
      setStorageCredentialFormConfig((state) => {
        return {
          ...state,
          credentials: {
            ...ADD_ARTIFACT_STORAGE_CREDENTIALS.credentials,
            fields: ADD_ARTIFACT_STORAGE_CREDENTIALS_CONFIG[watchType]
          }
        }
      })
    }
  }, [watchType])
  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */
  const onSubmit = useCallback(
    (formData) => {
      const data = {
        ...formData,
      };
      
      if(ADD_ARTIFACT_STORAGE_CONFIG.name.errorFunc(
        data[ADD_ARTIFACT_STORAGE_CONFIG.name.id])) {
        return
      }
      if(!edit){
        data[ADD_ARTIFACT_PATH_CONFIG.path.id] = PREFIX_MAPPING[data[ADD_ARTIFACT_STORAGE_CONFIG.type.id]] + data[ADD_ARTIFACT_PATH_CONFIG.path.id];
        deletePropertyPath(data,ADD_ARTIFACT_STORAGE_CONFIG.type.id)
      }
      // console.log(data)
      submit(data)
        .unwrap()
        .then(() => {
          setTimeout(() => {
            close();
          }, 500);
        })
        .catch((error) => dispatch(setError(error)));
    },
    [close, dispatch, submit]
  );
  const getTokens =  (codeResponse) => {
    // console.log(codeResponse)
    exchangeTokens(codeResponse.code)
    .then((resp) => {
      // console.log(resp)
      if(resp.data.error){
        dispatch(setError(resp.data.error))
      }else{
        setIsSignedIn(true)
        setRefreshToken(resp.data.refresh_token)
        setAccessToken(resp.data.access_token)
        handleListFolders(resp.data.access_token)
      }
    })
  };

  const handleListFolders = async (token) => {
    setIsGdriveLoading(true)
    try {
      const response = await axios.get(
        'https://www.googleapis.com/drive/v3/files',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            q: 'mimeType=\'application/vnd.google-apps.folder\' and \'root\' in parents',
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
          },
        }
      );
      setFolderStack([...folderStack,{folderName: 'root', folderID: 'root'}])
      setFolders(response.data.files);
      setCurrentFolderDetails({folderName: 'root', folderID: 'root'})
      setOpen(true)
      setIsGdriveLoading(false)
    } catch (error) {
      console.error(error);
      setIsGdriveLoading(false)
    }
  };

  const handleGetSubFolders = async (folderName, folderID, type) => {
    setIsGdriveLoading(true)
    try {
      const response = await axios.get(
        'https://www.googleapis.com/drive/v3/files',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            // mimeType: 'application/vnd.google-apps.folder',
            q: `mimeType=\'application/vnd.google-apps.folder\' and \'${folderID}\' in parents`,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
          },
        }
      );
      setFolderStack([...folderStack,{folderName,folderID}]) 
      setFolders(response.data.files);
      setCurrentFolderDetails({folderName, folderID})
      setIsGdriveLoading(false)
    } catch (error) {
      console.error(error);
      setIsGdriveLoading(false)
    }
  };

  const handleGoBack = async (folderName, folderID) => {
    setIsGdriveLoading(true)
    const temp = folderStack.filter(folder => folder.folderID != folderID);
    setFolderStack(folderStack.filter(folder => folder.folderID != folderID))
    if(temp.length===1){
      try {
        const response = await axios.get(
          'https://www.googleapis.com/drive/v3/files',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              // mimeType: 'application/vnd.google-apps.folder',
              q: `mimeType=\'application/vnd.google-apps.folder\' and \'root\' in parents`,
              fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
            },
          }
        );
        setFolders(response.data.files);
        setCurrentFolderDetails({folderName: 'root', folderID: 'root'})
        setIsGdriveLoading(false)
      } catch (error) {
        console.error(error);
        setIsGdriveLoading(false)
      }
    }else{
      try {
        const response = await axios.get(
          'https://www.googleapis.com/drive/v3/files',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              // mimeType: 'application/vnd.google-apps.folder',
              q: `mimeType=\'application/vnd.google-apps.folder\' and \'${temp[temp.length - 1].folderID}\' in parents`,
              fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
            },
          }
        );
        setFolders(response.data.files);
        setCurrentFolderDetails({folderName: temp[temp.length - 1].folderName, folderID: temp[temp.length - 1].folderID})
        setIsGdriveLoading(false)
      } catch (error) {
        console.error(error);
        setIsGdriveLoading(false)
      }
    }
  };
  const handleJumpBack = async (folderID) => {
    setIsGdriveLoading(true)
    // getting the index of the required folder id and removing the elements coming after that index
    const temp = folderStack
    const idx = temp.map(function(e) { return e.folderID; }).indexOf(folderID);
    temp.length = idx + 1;
    // setting for future rendering
    setFolderStack(temp)
    if(temp.length===1){
      try {
        const response = await axios.get(
          'https://www.googleapis.com/drive/v3/files',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              // mimeType: 'application/vnd.google-apps.folder',
              q: `mimeType=\'application/vnd.google-apps.folder\' and \'root\' in parents`,
              fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
            },
          }
        );
        setFolders(response.data.files);
        setCurrentFolderDetails({folderName: 'root', folderID: 'root'})
        setIsGdriveLoading(false)
      } catch (error) {
        console.error(error);
        setIsGdriveLoading(false)
      }
    }else{
      try {
        const response = await axios.get(
          'https://www.googleapis.com/drive/v3/files',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              // mimeType: 'application/vnd.google-apps.folder',
              q: `mimeType=\'application/vnd.google-apps.folder\' and \'${temp[temp.length - 1].folderID}\' in parents`,
              fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
            },
          }
        );
        setFolders(response.data.files);
        setCurrentFolderDetails({folderName: temp[temp.length - 1].folderName, folderID: temp[temp.length - 1].folderID})
        setIsGdriveLoading(false)
      } catch (error) {
        console.error(error);
        setIsGdriveLoading(false)
      }
    }
  };



  const login = useGoogleLogin({
    onSuccess: codeResponse => getTokens(codeResponse),
    scope: 'https://www.googleapis.com/auth/drive',
    flow: 'auth-code',
  });

  const selectFolder = (folderID) => {
    setSelectedFolder(folderID)
    setValue(ADD_ARTIFACT_PATH_CONFIG.path.id, folderID)
    setValue('credentials.GDRIVE_CREDS', JSON.stringify({refresh_token: refreshToken}))
    setOpen(false)
  }

  return (
    <Container disableGutters maxWidth="sm" sx={{ marginBlockStart: 4 }}>
      <Form
        control={control}
        fields={formConfig}
        loading={isLoading}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!edit && <FormFields control={control} fields={storagePathFormConfig} />}
        {!edit && watchType && watchType!='GOOGLE_DRIVE' && <FormFields control={control} fields={storageCredentialFormConfig} />}
        {watchType === 'GOOGLE_DRIVE' && 
          <>
          { !isSignedIn &&
            <LoadingButton 
              onClick={() => login()}
              fullWidth
              color='secondary'
              variant='contained'
              sx={{borderRadius: '20px'}}
            >
              Sign in with google
            </LoadingButton>
          }
          { isSignedIn &&
            <div>
              {selectedFolder && <FormFields control={control} fields={storageCredentialFormConfig} />}
              <br/>
              <LoadingButton 
                onClick={() => setOpen(true)}
                fullWidth
                color='secondary'
                variant='contained'
                sx={{borderRadius: '20px'}}
              >
                Select Different Folder
              </LoadingButton>
              <FolderDrawer
                folders={folders}
                getSubFolders={handleGetSubFolders}
                handleGoBack={handleGoBack}
                handleJumpBack={handleJumpBack}
                currentFolderDetails={currentFolderDetails}
                selectFolder={selectFolder}
                open={open}
                isLoading={isGdriveLoading}
                folderStack={folderStack}
              />
            </div>
          }
          </>
         }
      </Form>
    </Container>
  );
};

export default AddArtifactsStorageView;
