import React from 'react'
import PropTypes from 'prop-types'
import dedent from 'dedent'
import TextField from '@mui/material/TextField'

const MultiLineArrayInput = ({
  disabled,
  id,
  label,
  onBlur,
  onChange,
  placeholder,
  value
}) => {
  const handleBlur = (event) => {
    const arr = event.target.value.split('\n')
    onBlur({
      target: {
        value: arr
      }
    })
  }

  const handleChange = (event) => {
    const arr = event.target.value.split('\n')
    onChange({
      target: {
        value: arr
      }
    })
  }

  return (
    <TextField
      disabled={disabled}
      id={id}
      
      label={label}
      minRows={4}
      sx={{ m: 1}}
      multiline
      fullWidth 
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={dedent(placeholder)}
      size='small'
      type='text'
      value={value.join('\n')}
      variant='outlined'
    />
  )
}

MultiLineArrayInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string)
}

MultiLineArrayInput.defaultProps = {
  onBlur: () => null,
  onChange: () => null,
  value: []
}

export default MultiLineArrayInput
