import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PostAddIcon from '@mui/icons-material/PostAdd';
import MemoryIcon from '@mui/icons-material/Memory';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const CustomizedSteppers = ({steps, step, setStage})  => {
  const ENUM_STATES = {
    'Code' :<Stack direction="row" alignItems="center" gap={1}>
                <IconButton onClick={() => setStage(steps.indexOf('Code'))} aria-label="delete" data-testid='Code'>
                  <PostAddIcon fontSize='small'/>
                </IconButton>
                <Typography variant="body1">Code</Typography>
            </Stack>,
    'Data': <Stack direction="row" alignItems="center" gap={1}>
                <IconButton onClick={() => setStage(steps.indexOf('Data'))} aria-label="delete" data-testid='Data'>
                  <MemoryIcon fontSize='small'/>
                </IconButton>
                <Typography variant="body1">Data</Typography>
            </Stack>,
    'Configure': <Stack direction="row" alignItems="center" gap={1}>
                    <IconButton onClick={() => setStage(steps.indexOf('Configure'))} aria-label="delete" data-testid='Configure'>
                      <DeviceHubIcon fontSize='small'/>
                    </IconButton>
                    <Typography variant="body1">Configure</Typography>
                </Stack>,
    'Deploy': <Stack direction="row" alignItems="center" gap={1}>
                <IconButton onClick={() => setStage(steps.indexOf('Deploy'))} aria-label="delete" data-testid='Deploy'>
                      <NextWeekIcon fontSize='small'/>
                    </IconButton>
                <Typography variant="body1">Deploy</Typography>
            </Stack>,
  };
  function EnumState({ state }) {
    return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}} >{ENUM_STATES[state]}</div>;
  }
  return (
    // console.log(step),
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={step} connector={<QontoConnector />}>
        {steps.map((label) => (
            <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{<EnumState state={label}/>}</StepLabel>
            </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default CustomizedSteppers