import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Form from "../../components/Form";
import {
  ADD_SECRET_CONFIG,
  ADD_SECRET_DEFAULT_VALUE,
} from "../../constants/FormConstants/AddSecretConstants";
import { setError } from "../../datastore/UI/uiSlice";
import { useAddSecretMutation } from "../../services/secretsApi";

const AddSecretView = ({ close }) => {
  const dispatch = useDispatch();
  /**
   * Sate to hold the form config. We keep it in a separate state as its sbjected to changes
   */
  const [formConfig] = useState(ADD_SECRET_CONFIG);
  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useAddSecretMutation();
  /**
   * Initialize form state
   */
  const { control, handleSubmit } = useForm({
    defaultValues: ADD_SECRET_DEFAULT_VALUE,
  });
  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */
  const onSubmit = useCallback(
    (formData) => {
      const data = {
        ...formData,
      };
      console.log(data);
      submit(formData)
        .unwrap()
        .then(() => {
          setTimeout(() => {
            close()
          }, 1000)
        })
        .catch((error) => dispatch(setError(error)));
    },
    [close, dispatch, submit]
  );

  return (
    <Container disableGutters maxWidth="sm" sx={{ marginBlockStart: 4 }}>
      <Form
        control={control}
        fields={formConfig}
        loading={isLoading}
        onSubmit={handleSubmit(onSubmit)}
      ></Form>
    </Container>
  );
};

export default AddSecretView;
