import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import DataGrid from "../../components/DataGrid"
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FolderIcon from '@mui/icons-material/Folder';

const FolderDrawer = ({folders, getSubFolders, handleGoBack, handleJumpBack, currentFolderDetails, selectFolder, open, folderStack, isLoading}) => {
    const [rootFolderId, setRootFolderId] = React.useState('');
    const toggleDrawer = (action) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
        setOpen(action)
    };
    const rows = useMemo(() => {
        if (folders) {
          return folders.map((item) => ({
            id: item.id,
            folder_name: item.name,
          }));
        }
        return [];
      }, [folders]);

    const handleOnCellClick = (params) => {
        getSubFolders(params.folder_name, params.id,'next');
    };

    const handleGoBackCaller = () => {
        handleGoBack(currentFolderDetails.folderName, currentFolderDetails.folderID);
    };
    const handleSelectFolder = (row) => {
        selectFolder(row.id)
    }
    const handleJumpBackCaller = (id) => {
        handleJumpBack(id)
    }
    return (
        <Drawer
            variant="persistent"
            anchor='right'
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
                width: 900,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 900,
                },
              }}
        >   
            {isLoading &&
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress sx={{mt: '45%'}}/>
                </Box>
            }
            {!isLoading && folders &&
                <>
                    <div
                    >
                    {/* {currentFolderDetails.folderName != 'root' && <LoadingButton
                        sx={{width: 450, m: '10px', }}
                        color='info'
                        onClick={() => handleGoBackCaller()}
                        size='small'
                        variant='contained'
                        >
                        Go Back
                    </LoadingButton>} */}
                    <Stack direction='row'>
                        {folderStack.map((item)=>
                            <React.Fragment>
                                {item.folderID === folderStack[folderStack.length - 1].folderID && <a
                                    id = {item.folderID}
                                    style={{color: 'white',margin: '10px'}}
                                    >
                                    {item.folderName}
                                </a>} 
                                {item.folderID != folderStack[folderStack.length - 1].folderID && <a
                                    id = {item.folderID}
                                    color='info'
                                    style={{margin: '10px', cursor: 'pointer'}}
                                    onClick={(e) => {handleJumpBackCaller(e.target.id)}}
                                    >
                                    {item.folderName}
                                </a>}
                                {item.folderID != folderStack[folderStack.length - 1].folderID  && <div style={{margin: '10px'}}>
                                    \
                                </div>}
                            </React.Fragment>
                        )}
                    </Stack>
                    <DataGrid
                        sx={{
                        boxShadow: 4,
                        m: '10px'
                        }}
                        columns={[
                            {
                                field: "folder_name",
                                headerName: "Folder Name",
                                sortable: true,
                                flex: 1,
                                renderCell: ({ row }) => {
                                    return (
                                        <Stack direction='row'>
                                            <FolderIcon/>
                                            <a
                                                color='info'
                                                style={{marginTop: '4px', marginLeft: '4px', cursor: 'pointer'}}
                                                onClick={() => handleOnCellClick(row)}
                                                >
                                                {row.folder_name}
                                            </a>
                                        </Stack>
                                    )
                                }
                            },
                            {
                                field: 'action',
                                headerName: 'Select',
                                width: '150',
                                renderCell: ({ row }) => {
                                    return (
                                        <LoadingButton
                                            color='info'
                                            onClick={() => handleSelectFolder(row)}
                                            size='small'
                                            variant='contained'
                                            description="Are you sure you want to delete this Secret?"
                                            >
                                            Select
                                        </LoadingButton>
                                    )
                                }
                            }
                        ]}
                        rows={rows}
                    />
                    </div>
                </> 
            }
        </Drawer>
    );
}

FolderDrawer.propTypes = {
    folders: PropTypes.array,
    getSubFolders: PropTypes.func,
    handleGoBack: PropTypes.func,
    handleJumpBack: PropTypes.func,
    currentFolderDetails: PropTypes.object,
    selectFolder: PropTypes.func,
    isLoading: PropTypes.bool,
    open: PropTypes.bool,
    folderStack: PropTypes.array
  }
  
export default FolderDrawer