import React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@mui/x-data-grid'

const DataGridComponent = ({
  columns,
  rows,
  ...otherProps
}) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      {...otherProps}
    />
  )
}

DataGridComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.node,
    type: PropTypes.string,
    sortable: PropTypes.bool
  })),
  rows: PropTypes.arrayOf(PropTypes.object),
}

export default DataGridComponent
