import { deepFreeze } from '../../utils'
import { INPUT_TYPES } from '.'
var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/
// Tab configs
export const TAB_CONFIGS = {
  REQUIREMENTS: {
    id: 'REQUIREMENTS',
    label: 'Requirements File'
  },
  MANUAL_SETTINGS: {
    id: 'MANUAL_SETTINGS',
    label: 'Code from Repo'
  },
  DOCKER_SETTINGS: {
    id: 'DOCKER_SETTINGS',
    label: 'Docker Image'
  },
  UPLOAD_CODE: {
    id: 'UPLOAD ZIP FILE',
    label: 'Upload code as zip file'
  },
  CPU_SETTINGS: {
    id: 'CPU_SETTINGS',
    label: 'CPU'
  },
  GPU_SETTINGS: {
    id: 'GPU_SETTINGS',
    label: 'GPU'
  },
  DEFAULT_SETTINGS: {
    id: 'DEFAULT_SETTINGS',
    label: 'Use Default Settings'
  },
  CUSTOM_SETTINGS: {
    id: 'CUSTOM_SETTINGS',
    label: 'Use Custom Settings'
  },
  CONFIG: {
    id: 'CONFIG',
    label: 'Configuration'
  },
  ENVIRONMENT_CONFIG: {
    id: 'ENVIRONMENT_CONFIG',
    label: 'Environment Configuration'
  },
  PICK_INSTANCE: {
    id: 'PICK_INSTANCE',
    label: 'Pick Instances Types'
  },
  PICK_GPU: {
    id: 'PICK_GPU',
    label: 'Pick GPUs'
  },
}
deepFreeze(TAB_CONFIGS)

// Cloud provider fields
export const CLOUD_PROVIDER_FIELDS_CONFIG = {
  cloudProviders: {
    id: 'cloudProviders',
    label: 'Cloud Providers',
    placeholder: 'Select an option',
    description: 'Specify which cloud providers do you want to run this experiment with.',
    type: INPUT_TYPES.MULTI_SELECT
  },
  cloudRegions: {
    id: 'cloudRegions',
    disabled: true,
    label: 'Cloud Regions',
    placeholder: 'Select an option',
    description: 'Specify which regions do you want to operate in.',
    type: INPUT_TYPES.MULTI_SELECT
  }
}
deepFreeze(CLOUD_PROVIDER_FIELDS_CONFIG)

// Template name
export const TEMPLATE_FIELDS = {
  templateName: {
    id: 'templateName',
    label: 'Template Name',
    required: true,
    errorFunc: (v) => format.test(v),
    errorText: 'Names should only have alphabets, nums or hyphens',
    type: INPUT_TYPES.STRING
  }
}
deepFreeze(TEMPLATE_FIELDS)

// CPU Cofigs
export const CPU_FIELDS_CONFIGS = {
  minvCPUs: {
    id: 'minvCPUs',
    label: 'Minimum vCPUs',
    description: 'Min. amount of cores/vCPUs required in the worker VMs.',
    type: INPUT_TYPES.NUMBER
  },
  minMemory: {
    id: 'minMemory',
    label: 'Minimum Memory',
    description: 'Min. amount of memory/RAM required in the worker VMs.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(CPU_FIELDS_CONFIGS)