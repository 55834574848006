import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Button from '@mui/material/Button'
import Form from "../../components/Form";
import AddIcon from "@mui/icons-material/Add";
import FormFields from '../../components/Form/FormFields'
import {
    TAB_CONFIGS,
  } from '../../constants/FormConstants/BaseFormConstants.js'
import {
    SELECT_VIRTUAL_MOUNT_CONFIG,
    SELECTED_VIRTUAL_MOUNT_CONFIG,
    SELECTED_VIRTUAL_MOUNT_DEFAULT_VALUE,
} from "../../constants/FormConstants/SelectVirtualMountConstants";
import { setError } from "../../datastore/UI/uiSlice";
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"

const AddVirtualMountView = ({ defaultData, close, submit, isLoading, addData }) => {
  const dispatch = useDispatch();
  /**
   * Get Virtual Mounts
   */
   const {
    data: allVirtualMounts
  } = useGetVirtualMountsQuery()
  /**
   * Sate to hold the form config. We keep it in a separate state as its subjected to changes
   */
  const [virtualMountSettings, setVirtualMountSettings] = useState(TAB_CONFIGS.DEFAULT_SETTINGS.id)
  const [virtualMountConfig,setVirtualMountConfig] = useState(SELECT_VIRTUAL_MOUNT_CONFIG);
  const [selectedVirtualMountConfig,setSelectedVirtualMountConfig] = useState(SELECTED_VIRTUAL_MOUNT_CONFIG);
  const [customVirtualMountConfig,setCustomVirtualMountConfigVirtualMountConfig] = useState(SELECTED_VIRTUAL_MOUNT_CONFIG);
  const [selectedVM,setSelectedVM] = useState(null);
  /**
   * Initialize form state
   */
  const { control, setValue, getValues } = useForm({
    defaultValues: !!defaultData
      ? defaultData
      : SELECTED_VIRTUAL_MOUNT_DEFAULT_VALUE,
  });
  /**
   * Set the options for fields for available VirtualMounts
   */
   useEffect(() => {
    if (allVirtualMounts) {

      const optionValues = allVirtualMounts.map((item) => item.name)
      function update (state) {
        const newObject = structuredClone(state)
        newObject.name.options = Array.isArray(allVirtualMounts) ? 
        optionValues : []

        return newObject
      }

      setVirtualMountConfig(update)
    }
  }, [allVirtualMounts])
  
  function updateOther (name) {
    const selectedVirtualMount = allVirtualMounts.filter((d) => 
        d.name == name)[0]
    setValue(SELECTED_VIRTUAL_MOUNT_CONFIG.src.id, selectedVirtualMount.src)
    setValue(SELECTED_VIRTUAL_MOUNT_CONFIG.dest.id, selectedVirtualMount.dest)
    setValue(SELECTED_VIRTUAL_MOUNT_CONFIG.filter.id, selectedVirtualMount.filter)
    setValue(SELECTED_VIRTUAL_MOUNT_CONFIG.prefetch.id, selectedVirtualMount.prefetch)
    setValue(SELECTED_VIRTUAL_MOUNT_CONFIG.unravelArchives.id, selectedVirtualMount.unravelArchives)
    setSelectedVirtualMountConfig(SELECTED_VIRTUAL_MOUNT_CONFIG)
  }
  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */

  function handleAdd(){
    addData(getValues());
  }

  return (
    <Container disableGutters maxWidth="sm" sx={{ marginBlockStart: 4 }}>
      <Form
        control={control}
        loading={isLoading}
        buttonLabel="Add"
        onSubmit={handleAdd}
        modalOnly= {false}
      >
        <FormFields control={control} fields={virtualMountConfig} updateOther={updateOther} select={true}/>
        <FormFields control={control} fields={selectedVirtualMountConfig}/>
        {/* <Button
                  fullWidth={false}
                  variant="contained"
                  color="primary"
                  style={{ height: 40 }}
                  onClick={handleAdd}
                >
                  Add
        </Button> */}
      </Form>
    </Container>
  );
};

export default AddVirtualMountView;
