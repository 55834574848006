import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import Modal from "../../components/Modal"
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Tabs from '@mui/material/Tabs'
import Divider from '@mui/material/Divider'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import Form from '../../components/Form'
import { LoadingButton } from '@mui/lab'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay"
import AddVirtualMountView from "./AddVirtualMountView"
import { DIALOG_LABELS, VIEW_HEADERS, VIEW_ROUTES } from '../../constants/uiConstants'
import {
  TAB_CONFIGS,
  CLOUD_PROVIDER_FIELDS_CONFIG,
  TEMPLATE_FIELDS
} from '../../constants/FormConstants/BaseFormConstants.js'
import {
  WS_CONTROL_CONFIGS,
  CUSTOM_IMAGE_CREDS_CONFIG,
  CREATE_WORKSTATION_FORM_DEFAULT_VALUE,
  WS_MANDATORY_FIELDS_CONFIG,
  MAX_CPU_WORKERS_CONFIG,
  WS_CPU_FIELDS_CONFIG,
  WS_GPU_FIELDS_CONFIG,
  WS_ADVANCE_FIELD_CONFIG,
  NOT_BARE_BONE_FIELDS,
  ARTIFACTS_DESTINATION,
  VIRTUAL_MOUNT,
  VISUALISATION_CONFIG,
  CUDA_CONFIG,
  ENVIRONMENT_VAR
} from "../../constants/FormConstants/CreateWorkstationTemplateFormConstants";
import { useGetTemplatesQuery, useCreateTemplateMutation, useEditTemplateMutation, useDeleteTemplateMutation } from '../../services/templatesApi'
import {
  useGetVisualizationQuery,
  useRegisterVisualizationMutation
} from '../../services/visualizationApi'
import {
  useGetUserCloudSubscriptionsQuery,
  useGetGpuTypesMutation,
} from "../../services/cloudApi";
import { deletePropertyPath } from "../../utils";
import { setError } from '../../datastore/UI/uiSlice'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"
import { useGetSecretsQuery } from '../../services/secretsApi'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomizedSteppers from '../../components/Stepper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import axios from 'axios';

const LaunchWorkstationTemplateView = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const platformBearer = useSelector((state) => state.userState.platformBearer)
  const [templateCredentials, setTemplateCredentials] = useState(null)
  const [autoFillData, setAutoFillData] = useState(type === "templating")
  const [openAccordions, setOpenAccordions] = useState(type === "templating")
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [stage, setStage] = useState(0);
  const stages = ['Code', 'Data', 'Configure', 'Deploy'];
  const [config, setConfig] = useState(TAB_CONFIGS.CONFIG.id)
  const [currentNameOfWks, SetCurrentNameOfWks] = useState('');
  const [formErrors, setFormErrors] = useState(null)

  // State variables for all the controlled accordions
  const [expandedConfig, setExpandedConfig] = React.useState(false);

  const handleChangeConfig = () => (event) => {
    setExpandedConfig(expandedConfig ? false : true);
  };
  /**
   * State to toggle between manual and docker image form fields
   */
  const [imageSettings, setImageSettings] = useState(TAB_CONFIGS.MANUAL_SETTINGS.id)
  /**
   * State to hold the error message
   */
  const [errorMessage, setErrorMessage] = useState("");
  /**
   * State to toggle credential fields
   */
  const [showCredentials, setShowCredentials] = useState(false);
  /**
   * Get user cloud providers
   */
  const { data: cloudSubscriptionsData, error: cloudSubscriptionsApiError } =
    useGetUserCloudSubscriptionsQuery();

  /**
   * State to toggle between CPU and GPU fields
   */
  const [cpuOrGpu, setCpuOrGpu] = useState(TAB_CONFIGS.GPU_SETTINGS.id)

  /**
   * Get GPU types
   */
  const [getGpuTypes, { data: gpuTypesData }] = useGetGpuTypesMutation();
  /**
   * Get Templates
   */
  const {
    data: allTemplates, isLoading: isLoadingAllTemplates
  } = useGetTemplatesQuery()

  /**
   * Get Visualisation keys
   */
  const {
    data: allVizKeys
  } = useGetVisualizationQuery()

  /**
   * Get Secret keys
   */
  const {
    data: allSecretKeys
  } = useGetSecretsQuery('TEMPLATE')

  /**
   * State to hold the form config. We keep it in a separate state as its sbjected to changes 
   */
  const [gpuFieldsConfig, setGpuFieldsConfig] = useState(WS_GPU_FIELDS_CONFIG)
  const [cloudFieldsConfig, setCloudFieldsConfig] = useState(CLOUD_PROVIDER_FIELDS_CONFIG)
  const [artifactsDestinationConfig, setArtifactsDestinationConfig] = useState(ARTIFACTS_DESTINATION)
  const [virtualMountConfig, setVirtualMountConfig] = useState(VIRTUAL_MOUNT)
  const [visualisationConfig, setVisualisationConfig] = useState(VISUALISATION_CONFIG)
  const [templateData, setTemplateData] = useState(null)
  const [credentials, setCredentials] = useState(null)

  /**
   * State to toggle advance fields collapse
   */
  const [showAdvanceFields, setShowAdvanceFields] = useState(false)

  /**
   * State to toggle not bare bone fields
   */
  const [isBareBoneVM, setBareBoneVM] = useState(false)
  /**
   * Get Artifacts Storage
   */
  const {
    data: artifactsStoragePaths
  } = useGetArtifactStoragePathsQuery()

  /**
   * Get Virtual Mounts
   */
  const {
    data: virtualMounts
  } = useGetVirtualMountsQuery()
  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useCreateTemplateMutation();
  /**
   * API to update template
   */
  const [update, { isLoading: isLoadingUpdate }] = useEditTemplateMutation();
  /**
  * API to delete template
  */
  const [deleteTemplate, { isLoading: isLoadingDelete }] = useDeleteTemplateMutation();

  /**
   * Initialize form state
   */
  const { control, formState, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: CREATE_WORKSTATION_FORM_DEFAULT_VALUE,
  });
  /**
   * Create watchers on specific fields
   */
  const watchCloudProviders = watch("cloudProviders");
  const watchCloudRegions = watch("cloudRegions");
  const watchIsImagePrivate = watch("cloudRegions");
  const watchVisualisationSelect = watch('visualisation.type')
  /**
   * Set the initial values for fields from API
   */
  useEffect(() => {
    if (cloudSubscriptionsData) {
      if (getValues()[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id].length === 0) {
        const [cloudProviderOptions] = cloudSubscriptionsData
        setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id, cloudProviderOptions)
      }
    }
  }, [cloudSubscriptionsData, setValue])

  useEffect(() => {
    if (cloudSubscriptionsData && Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0) {
      // if(getValues()[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id].length === 0){
      //   const { 1:cloudRegionOptions } = cloudSubscriptionsData;
      //   setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id, cloudRegionOptions);
      // }
      const { 1: cloudRegionOptions } = cloudSubscriptionsData;
      setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id, cloudRegionOptions);
    }
  }, [setValue, watchCloudProviders, cloudSubscriptionsData])

  useEffect(() => {
    if (allTemplates) {
      const templateObject = allTemplates.filter(s => s.id === location.pathname.split('/').pop())
      if (templateObject.length > 0) {
        setTemplateData(templateObject[0])
      }
    }
  }, [allTemplates])

  useEffect(()=>{
    if(allSecretKeys && type==="templating"){
      const templateSecrets = allSecretKeys.filter(s => s.split(':')[1].slice(0,s.split(':')[1].indexOf('_'))===location.pathname.split('/').pop())
      let requests = []
      const baseUrl = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : process.env.REACT_APP_BASE_API_URL;
      templateSecrets.forEach((key)=>{
        requests.push(axios.get(baseUrl + `/secret/${key}`,
        {
          headers: {
            Authorization: `Bearer ${platformBearer}`,
          },
        }
        ))
      })
      axios.all(requests)
      .then(axios.spread((...res) => {
        // Both requests are now complete
        setTemplateCredentials(Object.assign({}, ...res.map(r => r.data.secret)));
      }));
    }
  },[allSecretKeys])

  /**
   * Set the options for fields from API data and dependent field values
   */
  useEffect(() => {
    if (cloudSubscriptionsData) {
      const [cloudProviderOptions, cloudRegionOptions] = cloudSubscriptionsData;

      function update(state) {
        return {
          ...state,
          [CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id]: {
            ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id],
            options: Array.isArray(cloudProviderOptions)
              ? cloudProviderOptions
              : [],
          },
          [CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id]: {
            ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id],
            disabled: !(
              Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0
            ),
            options: Array.isArray(cloudRegionOptions)
              ? cloudRegionOptions.filter((region) =>
                watchCloudProviders.some(
                  (provider) => region.indexOf(provider) === 0
                )
              )
              : [],
          },
          [WS_GPU_FIELDS_CONFIG.gpuTypes.id]: {
            ...state[WS_GPU_FIELDS_CONFIG.gpuTypes.id],
            disabled: !(
              Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0
            ),
            options: Array.isArray(gpuTypesData) ? gpuTypesData : [],
          }
        }
      }

      setGpuFieldsConfig(update)
      setCloudFieldsConfig(update)
    }
  }, [
    cloudSubscriptionsData,
    gpuTypesData,
    watchCloudProviders,
    watchCloudRegions,
  ]);
  /**
   * Filter the values of cloud regions on cloud provider change
   */
  useEffect(() => {
    setValue(
      CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id,
      getValues(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id).filter((region) =>
        watchCloudProviders.some((provider) => region.indexOf(provider) === 0)
      )
    );
  }, [getValues, setValue, watchCloudProviders]);
  /**
   * Reset the value of `gpuTypes` when `cloudProvider` or `cloudRegion` data changes
   */
  useEffect(() => {
    if (gpuTypesData) {
      const updatedGpus = getValues()[WS_GPU_FIELDS_CONFIG.gpuTypes.id]?.filter(item => gpuTypesData.includes(item));
      setValue(WS_GPU_FIELDS_CONFIG.gpuTypes.id, updatedGpus)
    }
  }, [setValue, gpuTypesData, watchCloudProviders, watchCloudRegions]);
  /**
   * Call getGpuTypes API in response to the changes in dependent fields
   */
  useEffect(() => {
    if (
      Array.isArray(watchCloudProviders) &&
      watchCloudProviders.length > 0 &&
      Array.isArray(watchCloudRegions) &&
      watchCloudRegions.length > 0
    ) {
      getGpuTypes({
        cloudProviders: watchCloudProviders,
        cloudRegions: watchCloudRegions,
      })
        .unwrap()
        .catch((error) => console.log(error?.data));
    }
  }, [getGpuTypes, watchCloudProviders, watchCloudRegions]);
  /**
   * Clear custom image config if is private is false
   */
  useEffect(() => {
    if (!watchIsImagePrivate) {
      setValue(CUSTOM_IMAGE_CREDS_CONFIG.credentials.id, {});
    }
  }, [setValue, watchIsImagePrivate]);

  useEffect(() => {
    if(credentials){
      setValue(CUSTOM_IMAGE_CREDS_CONFIG.credentials.id, credentials);
      setCredentials(null);
    }
  }, [setValue, credentials])

  /**
   * Set error message to `cloudSubscriptionsApiError`
   */
  useEffect(() => {
    if (cloudSubscriptionsApiError) {
      setErrorMessage(cloudSubscriptionsApiError?.data);
    }
  }, [cloudSubscriptionsApiError]);

  /**
   * Set the options for fields for ArtifactStoragePath
   */
  useEffect(() => {
    if (artifactsStoragePaths) {

      const optionValues = artifactsStoragePaths.map((item) => item.name + ":" + item.path)
      // optionValues.push('WANDB');
      // optionValues.push('COMETML');
      function update(state) {
        const newObject = structuredClone(state)
        newObject.artifactsDestination.fields.name.options = Array.isArray(artifactsStoragePaths) ?
          optionValues : [] //['WANDB', 'COMETML']

        return newObject
      }

      setArtifactsDestinationConfig(update)
      setValue(ARTIFACTS_DESTINATION.artifactsDestination.fields.name.id,
        optionValues[0])
    }
  }, [artifactsStoragePaths, setValue])

  /**
   * Set the options for fields for Virtual Mounts
   */
  useEffect(() => {
    if (virtualMounts) {
      const allNames = Array.isArray(virtualMounts) ? virtualMounts.map(i => i.name) : []
      setVirtualMountConfig((state) => {
        const newObject = structuredClone(state)
        newObject.virtualMount.options = Array.isArray(allNames) ? allNames : []
        return newObject
      })
      if(virtualMounts.length > 0 && type==="new"){
        addData(virtualMounts[0])
      }
    }
  }, [virtualMounts, setValue]);

  useEffect(() => {
    if (allVizKeys) {
      if (watchVisualisationSelect) {
        const selectedVizArray = allVizKeys.filter(s => s.type.includes(watchVisualisationSelect));
        setVisualisationConfig((state) => {
          const newObject = structuredClone(state)
          if (watchVisualisationSelect === "WANDB" || watchVisualisationSelect === "COMETML") {
            newObject.visualisation.fields.viz_api_key.display = "";
          } else {
            newObject.visualisation.fields.viz_api_key.display = "none";
          }
          newObject.visualisation.fields.viz_api_key.disabled = true;
          return newObject
        })
        setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, selectedVizArray.length ? selectedVizArray[0].key : "");

      } else {
        setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
      }
    }
  }, [watchVisualisationSelect, allVizKeys])

  // Add the selected Virtual Mount to the object array
  function addData(virtualMount) {
    const currentVirtualMountValues = getValues()[VIRTUAL_MOUNT.virtualMount.id];
    virtualMount = [virtualMount]
    let virtualMountValues = Object.assign(currentVirtualMountValues ?? {}, ...virtualMount.map((x) => ({ [x.name]: x })));
    setValue(VIRTUAL_MOUNT.virtualMount.id, virtualMountValues);
    setOpenCreateModal(false);
  }

  // Toggle disability of api key field
  function disableField() {
    setVisualisationConfig((state) => {
      const newObject = structuredClone(state)
      newObject.visualisation.fields.viz_api_key.disabled = !newObject.visualisation.fields.viz_api_key.disabled;
      return newObject
    })
    setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
  }
  // Auto fill the form if experiment is being retried
  useEffect(() => {
    if (autoFillData && artifactsStoragePaths && templateData && templateCredentials) {
      let dataToBeFilled = JSON.parse(JSON.stringify(templateData.config));
      if (templateData && templateCredentials) {
        const envSecretNames = (dataToBeFilled.environment ? Object.keys(dataToBeFilled.environment) : []);
        let newTemplateCredsObject = {}
        let envArgObject = {}
        Object.keys(templateCredentials).forEach(key => {
          const tempKey = key.split(':')[1].slice(key.split(':')[1].indexOf('_') + 1)
          if (tempKey.split('_')[0] === 'DOCKER') {
            newTemplateCredsObject[tempKey.split('_')[1].toLowerCase()] = templateCredentials[key]
          } else if (envSecretNames.indexOf(tempKey) > -1) {
            envArgObject[tempKey] = templateCredentials[key]
          } else {
            newTemplateCredsObject[tempKey] = templateCredentials[key]
          }
        })
        setValue('environment', envArgObject);
        if (Object.keys(newTemplateCredsObject).length > 0){
          setShowCredentials(true);
          setCredentials(newTemplateCredsObject);
        }
      }
      let cldProviders = []
      let cldRegions = []
      dataToBeFilled.cloudProviders.forEach(
        (cldObject) => {
          cldProviders.push(cldObject.name)
          cldObject.regions.forEach(r => {
            cldRegions.push(cldObject.name + ':' + r)
          })
        });
      setValue('cloudProviders', cldProviders)
      setValue('cloudRegions', cldRegions)
      if (dataToBeFilled.visualisation) {
        setValue('visualisation.type', dataToBeFilled.visualisation)
      }
      if (dataToBeFilled.artifactsDestination) {
        const selectedartifactsDestination = artifactsStoragePaths.filter(s => s.name.includes(dataToBeFilled.artifactsDestination.name))[0];
        if (selectedartifactsDestination) {
          setValue('artifactsDestination.name', selectedartifactsDestination.name + ':' + selectedartifactsDestination.path)
          setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
        } else {
          setValue('artifactsDestination.name', dataToBeFilled.artifactsDestination.name)
          setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
        }

      }
      if (dataToBeFilled.virtualMounts) {
        setValue(VIRTUAL_MOUNT.virtualMount.id, {})
        dataToBeFilled.virtualMounts.forEach(
          (vMount) => {
            addData(vMount);
          });
      }
      if (dataToBeFilled.maxCPUWorkers) {
        setCpuOrGpu(TAB_CONFIGS.CPU_SETTINGS.id)
      } else {
        delete dataToBeFilled['maxCPUWorkers'];
      }
      if (dataToBeFilled.customImage) {
        setImageSettings(TAB_CONFIGS.DOCKER_SETTINGS.id);
        setValue('customImage.image', dataToBeFilled.customImage.image)
      }
      SetCurrentNameOfWks(dataToBeFilled['name'])
      delete dataToBeFilled['customImage'];
      delete dataToBeFilled['environment'];
      delete dataToBeFilled['cloudProviders'];
      delete dataToBeFilled['visualisation'];
      delete dataToBeFilled['artifactsDestination'];
      delete dataToBeFilled['virtualMounts'];
      Object.entries(dataToBeFilled).forEach(
        ([name, value]) => {
          // console.log(name, value)
          setValue(name, value)
        });
      setAutoFillData(null);
    }
  }, [templateData, templateCredentials, autoFillData, artifactsStoragePaths, setValue])

  useEffect(() => {
    if (formState.errors
      && Object.keys(formState.errors).length > 0
      && Object.getPrototypeOf(formState.errors) === Object.prototype) {
      setFormErrors(formState.errors)
    } else {
      setFormErrors(null)
    }
  }, [formState])

  /**
 * Function to submit the form data to `submit` API
 * @param {object} formData
 */
  const onSubmit = useCallback(
    (formData) => {
      const data = {
        ...formData
      };
      data['name'] = currentNameOfWks;
      if (data['name'] === '' && type === 'new'){
          setStage(0)
          dispatch(setError("Please enter a name for your template."))
          return;
      }
      // console.log(data);
      if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
        const cludProviderIds = Object.keys(CLOUD_PROVIDER_FIELDS_CONFIG)
        // manually setting max cpu workers to be 1 in case of cpu dependent
        data['maxCPUWorkers'] = 1;
        Object.values(WS_GPU_FIELDS_CONFIG).forEach(element => {
          deletePropertyPath(data, element.id)
        })
        deletePropertyPath(data, CUDA_CONFIG.cuda.id)
      }
      else {
        deletePropertyPath(data, MAX_CPU_WORKERS_CONFIG.maxCPUWorkers.id)
      }

      // creating the cloudProvider object
      let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
      let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
      const getRegions = (cp) => {
        return crs
          .filter((cr) => cr.startsWith(cp))
          .map((cr) => cr.split(":")[1]);
      };
      const cloudProviders = cps.map((cp) => ({
        name: cp,
        regions: getRegions(cp),
      }));
      data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
      deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);
      if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
        deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);
      else
        data[VISUALISATION_CONFIG.visualisation.id] = data[VISUALISATION_CONFIG.visualisation.id].type


      if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
        deletePropertyPath(data, NOT_BARE_BONE_FIELDS.customImage.id)
        deletePropertyPath(data, 'customImage')
        deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id)
      } else if (imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id) {
        Object.values(WS_ADVANCE_FIELD_CONFIG).forEach(element => {
          deletePropertyPath(data, element.id)
        });
        if (data['customImage']['image'] == '')
          deletePropertyPath(data, 'customImage');
        if (!showCredentials) {
          deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
        }
      }

      data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
        (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

      if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
        deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

      //creating the selected virtualMount object
      data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})

      if (!showCredentials) {
        deletePropertyPath(data, CUSTOM_IMAGE_CREDS_CONFIG.credentials.id);
      }
      deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);
      // deletePropertyPath(data, ENVIRONMENT_VAR.environment.id);

      if (type === 'templating') {
        update({
          id: location.pathname.split('/').pop(),
          config: data
        })
          .unwrap()
          .then(() => {
            setTimeout(() => {
              navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
            }, 1000)
          })
          .catch((error) => {
            dispatch(setError(error))
          });
      } else {
        submit({
          id: data.name,
          name: data.name,
          config: data,
          type: 'WORKSTATION',
        })
          .unwrap()
          .then(() => {
            setTimeout(() => {
              navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
            }, 1000)
          })
          .catch((error) => {
            dispatch(setError(error))
          });
      }
    },
    [cpuOrGpu, dispatch, navigate, virtualMounts, showCredentials, watchVisualisationSelect, imageSettings, submit]
  );

  const onDelete =
    () => {
      deleteTemplate(
        location.pathname.split('/').pop()
      )
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
          }, 1000)
        })
        .catch((error) => {
          dispatch(setError(error))
        });
    }

  function openModal() {
    setOpenCreateModal(true);
  }

  return (
    <>

      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Typography variant="h5">
            {VIEW_HEADERS.CREATE_WORKSTATION_TEMPLATE}
          </Typography>
          <FormControl sx={{ m: 1 }} variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-job-name">Enter Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-job-name"
              type='text'
              value={currentNameOfWks}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon />
                </InputAdornment>
              }
              label="Enter Job Name"
              onChange={(event) => {
                SetCurrentNameOfWks(event.target.value)
                setValue('name', event.target.value)
              }}
            />
          </FormControl>
        </Stack>
      </Container>
      <br />
      <CustomizedSteppers step={stage} steps={['Data', 'Configure', 'Deploy']} setStage={setStage} />
      
      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        {formErrors &&
          <Alert severity="error">
            <AlertTitle>Form Error</AlertTitle>
            Please check the following fields — <strong>[{Object.keys(formErrors).join(', ')}]</strong>
          </Alert>
        }
        {(isLoadingAllTemplates) && type === "templating" && <LoadingOverlay />}
        <Form
          control={control}
          fields={{}}
          loading={isLoading || isLoadingUpdate || isLoadingDelete}
          buttonLabel={type === "new" ? 'Create Workstation Template' : 'Update Workstation Template'}
          onSubmit={handleSubmit(onSubmit)}
          submitAtEnd={true}
        >
          {<Box sx={stage != 0 ?{display: 'none'} : {}}>
            <Typography gutterBottom variant="h5" component="div">
              Data Sources
            </Typography>
            <FormFields control={control} fields={virtualMountConfig} openModal={openModal} />
            <Modal
              onClose={() => setOpenCreateModal(false)}
              open={openCreateModal}
              title="Add Virtual Mount"
            >
              <AddVirtualMountView
                addData={addData}
              />
            </Modal>
            <br />
            <Typography gutterBottom variant="h5" component="div">
              Artifact Storage
            </Typography>
            <FormFields control={control} fields={artifactsDestinationConfig} />
            <br />
            <Typography gutterBottom variant="h5" component="div">
              Experiments Tracking
            </Typography>
            <FormFields control={control} fields={visualisationConfig} disableField={disableField} toValidate={watchVisualisationSelect} validateInput={true} />
            <br />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                color='secondary'
                onClick={() => {
                  setStage(stage + 1)
                }}
                variant='contained'
                sx={{ borderRadius: '20px' }}
              >
                Next
                <ArrowForwardIcon />
              </Button>
            </Stack>
          </Box>}
          {<Box sx={stage != 1 ?{display: 'none'} : {}}>
            {stage===1 && <Tabs sx={{ m: 1 }} value={config} onChange={(e, value) => setConfig(value)}>
              <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CONFIG.label} value={TAB_CONFIGS.CONFIG.id} />
              <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.ENVIRONMENT_CONFIG.label} value={TAB_CONFIGS.ENVIRONMENT_CONFIG.id} />
            </Tabs>}
            {config === TAB_CONFIGS.CONFIG.id && (
              <>
                <Stack>
                  <FormFields control={control} fields={WS_MANDATORY_FIELDS_CONFIG} />
                  {stage===1 && <Tabs sx={{ m: 1 }} value={cpuOrGpu} onChange={(e, value) => setCpuOrGpu(value)}>
                    <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CPU_SETTINGS.label} value={TAB_CONFIGS.CPU_SETTINGS.id} />
                    <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.GPU_SETTINGS.label} value={TAB_CONFIGS.GPU_SETTINGS.id} />
                  </Tabs>}
                  {cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id && (
                    <FormFields control={control} fields={MAX_CPU_WORKERS_CONFIG} />
                  )}
                  {cpuOrGpu === TAB_CONFIGS.GPU_SETTINGS.id && (
                    <>
                      <FormFields control={control} fields={gpuFieldsConfig} />
                      <FormFields control={control} fields={CUDA_CONFIG} />
                    </>
                  )}
                </Stack>
                <br />
                <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#292929', borderRadius: '8px' }} expanded={expandedConfig} onChange={handleChangeConfig()}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Stack>
                      <Typography gutterBottom variant="h5" component="div">
                        Advanced
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Specify necessary parameters to start the experiment
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails >
                    <Divider sx={{ m: 1 }}>
                    </Divider>
                    <FormFields control={control} fields={cloudFieldsConfig} />
                    <FormFields control={control} fields={WS_CPU_FIELDS_CONFIG} />
                  </AccordionDetails>
                </Accordion>
                <br />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color='secondary'
                    onClick={() => {
                      setStage(stage + 1)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Review & Deploy
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setConfig(TAB_CONFIGS.ENVIRONMENT_CONFIG.id)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Next
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </>

            )}
            {config === TAB_CONFIGS.ENVIRONMENT_CONFIG.id && (
              <>
                <Stack>
                  <FormFields control={control} fields={ENVIRONMENT_VAR} />
                  {stage===1 && <Tabs sx={{ ml: 1, mr: 1, mb: 2, mt: 2 }} value={imageSettings} onChange={(e, value) => setImageSettings(value)}>
                    <Tab label={TAB_CONFIGS.MANUAL_SETTINGS.label} value={TAB_CONFIGS.MANUAL_SETTINGS.id} />
                    <Tab label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} />
                  </Tabs>}
                  {imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id && (
                    <>
                      <FormFields control={control} fields={WS_ADVANCE_FIELD_CONFIG} />
                    </>
                  )}
                  {imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id && (
                    <>
                      <FormFields control={control} fields={NOT_BARE_BONE_FIELDS} />
                      <FormControlLabel
                        sx={{ m: 1 }}
                        id={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.id}
                        label={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.label}
                        control={
                          <Switch
                            checked={showCredentials}
                            value={showCredentials}
                            onChange={(e) => setShowCredentials(e.target.checked)}
                          />
                        }
                      />
                      {showCredentials && (
                        <FormFields control={control} fields={CUSTOM_IMAGE_CREDS_CONFIG} />
                      )}
                    </>
                  )}
                </Stack>
                <br />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color='secondary'
                    onClick={() => {
                      setStage(stage + 1)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Deploy
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </>
            )}
          </Box>}
          {<Box sx={stage != 2 ?{display: 'none'} : {}}>
            <br/>
            <br/>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                fullWidth
                color='success'
                onClick={handleSubmit(onSubmit)}
                variant='contained'
                sx={{ borderRadius: '20px',}}
                role='SUBMIT' 
                data-testid='submit' 
                loading={isLoading || isLoadingUpdate} 
                type='submit' 
              >
                {type==="new" ?
                    <>
                        <CreateIcon sx={{mr:1}}/>
                    </> :
                    <>
                        <UpdateIcon sx={{mr:1}}/>
                    </>
                }
                {type==="new" ? 'Create Experiment Template' : 'Update Experiment Template'}
              </LoadingButton>
            </Stack>
            <br/>
            {type==='templating' && 
                <Stack direction="row" justifyContent="flex-end">
                    <LoadingButton
                        fullWidth
                        color='error'
                        loading={isLoadingDelete}
                        onClick={() => onDelete()}
                        variant='contained'
                        sx={{borderRadius: '20px'}}
                    >
                        <DeleteIcon sx={{mr:1}}/>
                        Delete Template
                    </LoadingButton>
                </Stack>
            }
          </Box>}
          {/* <Divider textAlign="left">
            <Typography variant="h4" component="h2">
              Basic Configuration
            </Typography>
          </Divider>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Workstation Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Specify necessary parameters to start the experiment
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={WS_MANDATORY_FIELDS_CONFIG} />
                <Tabs sx={{ m: 1 }} value={cpuOrGpu} onChange={(e, value) => setCpuOrGpu(value)}>
                  <Tab label={TAB_CONFIGS.CPU_SETTINGS.label} value={TAB_CONFIGS.CPU_SETTINGS.id} />
                  <Tab label={TAB_CONFIGS.GPU_SETTINGS.label} value={TAB_CONFIGS.GPU_SETTINGS.id} />
                </Tabs>
                {cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id && (
                  <FormFields control={control} fields={MAX_CPU_WORKERS_CONFIG} />
                )}
                {cpuOrGpu === TAB_CONFIGS.GPU_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={gpuFieldsConfig} />
                    <FormFields control={control} fields={CUDA_CONFIG} />
                  </>
                )}
                <FormFields control={control} fields={WS_CPU_FIELDS_CONFIG} />
                <Modal
                  onClose={() => setOpenCreateModal(false)}
                  open={openCreateModal}
                  title="Add Virtual Mount"
                >
                  <AddVirtualMountView
                    addData={addData}
                  />
                </Modal>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Data Streaming
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do we get your data from?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={virtualMountConfig} openModal={openModal} />
              <Modal
                onClose={() => setOpenCreateModal(false)}
                open={openCreateModal}
                title="Add Virtual Mount"
              >
                <AddVirtualMountView
                  addData={addData}
                />
              </Modal>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Artifact Storage
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where should we store your trained models, results, checkpoints?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={artifactsDestinationConfig} />
            </AccordionDetails>
          </Accordion>
          <Divider textAlign="left">
            <Typography variant="h4" component="h2">
              Advanced Configuration
            </Typography>
          </Divider>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Cloud Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where should we store your trained models, results, checkpoints?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={cloudFieldsConfig} />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Environment Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where should we store your trained models, results, checkpoints?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={ENVIRONMENT_VAR} />
                <Tabs sx={{ ml: 1, mr: 1, mb: 2, mt: 2 }} value={imageSettings} onChange={(e, value) => setImageSettings(value)}>
                  <Tab label={TAB_CONFIGS.MANUAL_SETTINGS.label} value={TAB_CONFIGS.MANUAL_SETTINGS.id} />
                  <Tab label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} />
                </Tabs>
                {imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={WS_ADVANCE_FIELD_CONFIG} />
                  </>
                )}
                {imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={NOT_BARE_BONE_FIELDS} />
                    <FormControlLabel
                      sx={{ m: 1 }}
                      id={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.id}
                      label={WS_CONTROL_CONFIGS.IS_CUST_IMG_PRIVATE.label}
                      control={
                        <Switch
                          checked={showCredentials}
                          value={showCredentials}
                          onChange={(e) => setShowCredentials(e.target.checked)}
                        />
                      }
                    />
                    {showCredentials && (
                      <FormFields control={control} fields={CUSTOM_IMAGE_CREDS_CONFIG} />
                    )}
                  </>
                )}

              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Experiments Tracking
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do you want to see the results?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={visualisationConfig} disableField={disableField} toValidate={watchVisualisationSelect} validateInput={true} />
            </AccordionDetails>
          </Accordion>
          {type === 'templating' && <LoadingButton
            color='error'
            loading={isLoadingDelete}
            onClick={() => onDelete()}
            variant='contained'
            sx={{ borderRadius: '20px' }}
          >
            Delete Template
          </LoadingButton>} */}
        </Form>
      </Container>
      <Dialog
        open={errorMessage.length > 0}
        onClose={() => setErrorMessage("")}
      >
        <Alert severity="error">
          <AlertTitle>{DIALOG_LABELS.ERROR}</AlertTitle>
          <strong>{errorMessage}</strong>
        </Alert>
      </Dialog>
    </>
  );
}
LaunchWorkstationTemplateView.propTypes = {
  type: PropTypes.string
}

LaunchWorkstationTemplateView.defaultProps = {
  type: 'new'
}
export default LaunchWorkstationTemplateView
