export const simpleJobSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    useSpot: {
      type: "boolean",
    },
    maxCPUWorkers: {
      type: "integer",
    },
    minvCPUs: {
      type: "integer",
    },
    minMemory: {
      type: "integer",
    },
    gpuTypes: {
      type: "array",
      items: {
          type: "string"
      }
    },
    instanceTypes: {
      type: "array",
      items: {
          type: "string"
      }
    },
    gpusPerTrial: {
      type: "integer",
    },
    cuda: {
      type: ["number","null","string"],
    },
    virtualMounts: {
      type: "array",
      items: {
          type: 'object',
          properties: {
              src : {
                  type: 'string'
              },
              dest : {
                  type: 'string'
              },
              filter : {
                  type: 'string'
              },
              prefetch : {
                  type: 'boolean'
              },
              unravelArchives : {
                  type: 'boolean'
              },
              name : {
                  type: 'string'
              },
          }
      }
    },
    artifactsDestination: {
      type: "object",
      properties: {
          name :  {
              type : "string"
          },
          filter :  {
              type : "string"
          },
      }
    },
    visualisation: {
      type: "object",
      properties: {
          type :  {
              type : "string"
          },
          startWithJob :  {
              type : "boolean"
          },
          viz_api_key : {
              type : "string"
          }
      }
    },
    cloudProviders: {
      type: "array",
      items: {
          type: "object",
          properties: {
              name :  {
                  type : "string"
              },
              regions : {
                  type : "array",
                  items : {
                      type : "string"
                  }
              } 
          }
      }
    },
    environment: {
      type: "object",
      additionalProperties : {
        type : "string",
        
      }
    },
    preJobCommands: {
      type: "array",
      items: {
        type: "string"
      }
    },
    postJobCommands: {
      type: "array",
      items: {
        type: "string"
      }
    },
    entrypoint: {
      type: "string"
    },
    codeTransfer: {
      type: "object",
      properties: {
        type: {
          type : "string"
        },
        repo: {
          type: "string"
        },
        ref: {
          type: "string"
        },
        codeDir: {
          type: "string"
        },
        credentials: {
          type: "object",
          additionalProperties : {
            type : "string"
          }
        }
      }
    },
    customImage: {
      type: "object",
      properties: {
          image :  {
              type : "string"
          },
          credentials :  {
              type : "object",
              properties: {
                  registry :  {
                      type : "string"
                  },
                  username :  {
                      type : "string"
                  },
                  password :  {
                      type : "string"
                  },
              }
          },
      }
    },
    useDAPP: {
      type: 'boolean',
    },
    dapp: {
      type: "object",
      properties: {
        epochs: {
          type: "integer"
        }
      }
    },
    uploadCode: {
      type: "string",
    },
    requirements: {
      type: "string",
    },
    bareBone: {
      type: "boolean",
    },
    pythonVersion: {
      type: "string",
    },
    maxCost: {
      type: "integer",
    },
    maxTime: {
      type: "string",
    },
    maxCostPerHour: {
      type: "integer",
    },
    wandb_api_key: {
      type: "string",
    },
    numNodes: {
      type: "integer",
    },
  },
  additionalProperties: true,
};
export const experimentSchema = {
    type: "object",
    properties: {
      name: {
        type: "string",
      },
      useSpot: {
        type: "boolean",
      },
      useDAPP: {
        type: 'boolean',
      },
      dapp: {
        type: "object",
        properties: {
          epochs: {
            type: "integer"
          }
        }
      },
      maxGpus: {
        type: "integer",
      },
      maxCPUWorkers: {
        type: "integer",
      },
      minvCPUs: {
        type: "integer",
      },
      minMemory: {
        type: "integer",
      },
      gpuTypes: {
        type: "array",
        items: {
            type: "string"
        }
      },
      instanceTypes: {
        type: "array",
        items: {
            type: "string"
        }
      },
      gpusPerTrial: {
        type: "integer",
      },
      cuda: {
        type: ["number","null","string"],
      },
      virtualMounts: {
        type: "array",
        items: {
            type: 'object',
            properties: {
                src : {
                    type: 'string'
                },
                dest : {
                    type: 'string'
                },
                filter : {
                    type: 'string'
                },
                prefetch : {
                    type: 'boolean'
                },
                unravelArchives : {
                    type: 'boolean'
                },
                name : {
                    type: 'string'
                },
            }
        }
      },
      artifactsDestination: {
        type: "object",
        properties: {
            name :  {
                type : "string"
            },
            filter :  {
                type : "string"
            },
        }
      },
      visualisation: {
        type: "object",
        properties: {
            type :  {
                type : "string"
            },
            startWithJob :  {
                type : "boolean"
            },
            viz_api_key : {
                type : "string"
            }
        }
      },
      cloudProviders: {
        type: "array",
        items: {
            type: "object",
            properties: {
                name :  {
                    type : "string"
                },
                regions : {
                    type : "array",
                    items : {
                        type : "string"
                    }
                } 
            }
        }
      },
      environment: {
        type: "object",
        additionalProperties : {
          type : "string",
          
        }
      },
      preJobCommands: {
        type: "array",
        items: {
          type: "string"
        }
      },
      postJobCommands: {
        type: "array",
        items: {
          type: "string"
        }
      },
      experiment: {
        type: "object",
        properties: {
          args: {
            type: "object",
            additionalProperties : {
              type : "array",
              items : {
                type: "number"
              }
            }
          },

        }
      },
      dapp: {
        type: "object",
        properties: {
          epochs: {
            type: "integer"
          }
        }
      },
      entrypoint: {
        type: "string"
      },
      codeTransfer: {
        type: "object",
        properties: {
          type: {
            type : "string"
          },
          repo: {
            type: "string"
          },
          ref: {
            type: "string"
          },
          codeDir: {
            type: "string"
          },
          credentials: {
            type: "object",
            additionalProperties : {
              type : "string"
            }
          }
        }
      },
      customImage: {
        type: "object",
        properties: {
            image :  {
                type : "string"
            },
            credentials :  {
                type : "object",
                properties: {
                    registry :  {
                        type : "string"
                    },
                    username :  {
                        type : "string"
                    },
                    password :  {
                        type : "string"
                    },
                }
            },
        }
      },
      requirements: {
        type: "string",
      },
      bareBone: {
        type: "boolean",
      },
      pythonVersion: {
        type: "string",
      },
      maxCost: {
        type: "integer",
      },
      maxTime: {
        type: "string",
      },
      maxCostPerHour: {
        type: "integer",
      },
      wandb_api_key: {
        type: "string",
      }
    },
    additionalProperties: false,
  };

export const workstationSchema = {
    type: "object",
    properties: {
      name: {
        type: "string",
      },
      useSpot: {
        type: "boolean",
      },
      numWorkstations: {
        type: "integer",
      },
      maxCPUWorkers: {
        type: "integer",
      },
      minvCPUs: {
        type: "integer",
      },
      minMemory: {
        type: "integer",
      },
      gpuTypes: {
        type: "array",
        items: {
            type: "string"
        }
      },
      gpuCount: {
        type: "integer",
      },
      cuda: {
        type: ["number","null","string"],
      },
      virtualMounts: {
        type: "array",
        items: {
            type: 'object',
            properties: {
                src : {
                    type: 'string'
                },
                dest : {
                    type: 'string'
                },
                filter : {
                    type: 'string'
                },
                prefetch : {
                    type: 'boolean'
                },
                unravelArchives : {
                    type: 'boolean'
                },
                name : {
                    type: 'string'
                },
            }
        }
      },
      artifactsDestination: {
        type: "object",
        properties: {
            name :  {
                type : "string"
            },
            filter :  {
                type : "string"
            },
        }
      },
      cloudProviders: {
        type: "array",
        items: {
            type: "object",
            properties: {
                name :  {
                    type : "string"
                },
                regions : {
                    type : "array",
                    items : {
                        type : "string"
                    }
                } 
            }
        }
      },
      environment: {
        type: "object",
        additionalProperties : {
          type : "string",
  
        }
      },
      setupCommands: {
        type: "array",
        items: {
          type: "string"
        }
      },
      customImage: {
        type: "object",
        properties: {
            image :  {
                type : "string"
            },
            credentials :  {
                type : "object",
                properties: {
                    registry :  {
                        type : "string"
                    },
                    username :  {
                        type : "string"
                    },
                    password :  {
                        type : "string"
                    },
                }
            },
        }
      },
      visualisation: {
        type: "string",
      },
      requirements: {
        type: "string",
      },
      bareBone: {
        type: "boolean",
      },
      pythonVersion: {
        type: "string",
      },
    },
    additionalProperties: false,
  };

export const hptSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    useSpot: {
      type: "boolean",
    },
    useDAPP: {
      type: 'boolean',
    },
    dapp: {
      type: "object",
      properties: {
        epochs: {
          type: "integer"
        }
      }
    },
    tuning: {
      type: "object",
      properties: {
        searchSpace :  {
              type : "object",
              additionalProperties : {
                type : "object",
                properties : {
                  value : {
                      type : "array",
                      items: {
                        type: "integer"
                      }
                  },
                  type : {
                    type : "string"
                  }
              }
              }
          },
        tuner :  {
              type : "object",
              properties: {
                name: {
                  type: "string"
                },
                optimizeMode: {
                  type: "string"
                }
              }
          },
      }
    },
    maxGpus: {
      type: "integer",
    },
    maxTrials: {
      type: "number",
    },
    maxCPUWorkers: {
      type: "integer",
    },
    minvCPUs: {
      type: "integer",
    },
    minMemory: {
      type: "integer",
    },
    gpuTypes: {
      type: "array",
      items: {
          type: "string"
      }
    },
    instanceTypes: {
      type: "array",
      items: {
          type: "string"
      }
    },
    gpusPerTrial: {
      type: "integer",
    },
    cuda: {
      type: ["number","null","string"],
    },
    virtualMounts: {
      type: "array",
      items: {
          type: 'object',
          properties: {
              src : {
                  type: 'string'
              },
              dest : {
                  type: 'string'
              },
              filter : {
                  type: 'string'
              },
              prefetch : {
                  type: 'boolean'
              },
              unravelArchives : {
                  type: 'boolean'
              },
              name : {
                  type: 'string'
              },
          }
      }
    },
    artifactsDestination: {
      type: "object",
      properties: {
          name :  {
              type : "string"
          },
          filter :  {
              type : "string"
          },
      }
    },
    visualisation: {
      type: "object",
      properties: {
          type :  {
              type : "string"
          },
          startWithJob :  {
              type : "boolean"
          },
          viz_api_key : {
              type : "string"
          }
      }
    },
    cloudProviders: {
      type: "array",
      items: {
          type: "object",
          properties: {
              name :  {
                  type : "string"
              },
              regions : {
                  type : "array",
                  items : {
                      type : "string"
                  }
              } 
          }
      }
    },
    environment: {
      type: "object",
      additionalProperties : {
        type : "string",

      }
    },
    preJobCommands: {
      type: "array",
      items: {
        type: "string"
      }
    },
    postJobCommands: {
      type: "array",
      items: {
        type: "string"
      }
    },
    experiment: {
      type: "object",
      properties: {
        args: {
          type: "object",
          additionalProperties : {
            type : "array",
            items : {
              type: "number"
            }
          }
        },

      }
    },
    entrypoint: {
      type: "string"
    },
    codeTransfer: {
      type: "object",
      properties: {
        type: {
          type : "string"
        },
        repo: {
          type: "string"
        },
        ref: {
          type: "string"
        },
        codeDir: {
          type: "string"
        },
        credentials: {
          type: "object",
          additionalProperties : {
            type : "string"
          }
        }
      }
    },
    customImage: {
      type: "object",
      properties: {
          image :  {
              type : "string"
          },
          credentials :  {
              type : "object",
              properties: {
                  registry :  {
                      type : "string"
                  },
                  username :  {
                      type : "string"
                  },
                  password :  {
                      type : "string"
                  },
              }
          },
      }
    },
    requirements: {
      type: "string",
    },
    bareBone: {
      type: "boolean",
    },
    pythonVersion: {
      type: "string",
    },
    maxCost: {
      type: "integer",
    },
    maxTime: {
      type: "string",
    },
    maxCostPerHour: {
      type: "integer",
    },
    wandb_api_key: {
      type: "string",
    }
  },
  additionalProperties: false,
  };