import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const visualizationApi = createApi({
  reducerPath: 'visualizationApi',
  tagTypes: ['Visualization'],
  ...platformAuthConfig(),
  endpoints: builder => ({
    getVisualization: builder.query({
      query: () => '/visualisation',
      providesTags: ['Visualization']
    }),
    registerVisualization: builder.mutation({
      query: (visConfig) => ({
        url: '/visualisation',
        method: 'POST',
        body: visConfig
      }),
      invalidatesTags: ['Visualization']
    })
  })
})

export const {
  useGetVisualizationQuery,
  useRegisterVisualizationMutation
} = visualizationApi

export default visualizationApi
