import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import Container from '@mui/material/Container'
import Form from "../../components/Form";
import FormFields from "../../components/Form/FormFields";
import LoadingOverlay from '../../components/LoadingOverlay';
import {
  ADD_CLOUD_PROVIDER_CONFIG,
  ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG,
  ADD_CLOUD_PROVIDER_DEFAULT_VALUE,
  ADD_CLOUD_PROVIDER_AWS_CREDENTIALS,
  ADD_CLOUD_PROVIDER_AWS_CREDENTIALS_CONFIG,
  USE_CUSTOM_VPC_OPTION,
  AWS_CUSTOM_VPC_CONFIG,
  USE_AWS_SCRATCHPAD_STORAGE,
  AWS_SCRATCHPAD_STORAGE
} from "../../constants/FormConstants/AddCloudProviderConstants";
import { useLazyGetCloudProviderRegionsQuery, useAddCloudProviderMutation } from "../../services/cloudApi";
import { setError } from '../../datastore/UI/uiSlice'
import { deletePropertyPath } from "../../utils";

const DATACRUNCH = 'DATACRUNCH'

const AddCloudProviderView = ({
  close
}) => {
  const dispatch = useDispatch()
  const [changingLoadingProvider,setChangingLoadingProvider] = useState(false);
  /**
   * Sate to hold the form config. We keep it in a separate state as its sbjected to changes
   */
  const [formConfig, setFormConfig] = useState(ADD_CLOUD_PROVIDER_CONFIG);
  /**
   * Cloud provider credentials config
   */
  const [cloudCredentialFormConfig, setCloudCredentialFormConfig] = useState(ADD_CLOUD_PROVIDER_AWS_CREDENTIALS)
  /**
   * API to get regions for provider
   */
  const [getProviderRegions, { data: providerRegionsData, isLoading: isLoadingProviderRegions }] = useLazyGetCloudProviderRegionsQuery()
  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useAddCloudProviderMutation();
  /**
   * Initialize form state
   */
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: ADD_CLOUD_PROVIDER_DEFAULT_VALUE,
  });
  /**
   * Create watchers
   */
  const watchProvider = watch(ADD_CLOUD_PROVIDER_CONFIG.provider.id)
  const watchRegions = watch(ADD_CLOUD_PROVIDER_CONFIG.regions.id)
  const watchAWSuseCustomVPC = watch("useCustomVPCs")
  const watchAWSuseScratchpadStorage = watch("useScratchpadStorage")
  /**
   * Add appropriate credential fields as per the provider selected
   */
  useEffect(() => {
    setValue(ADD_CLOUD_PROVIDER_CONFIG.regions.id, [])

    // if (watchProvider === DATACRUNCH) {
    //   setValue(ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG.bucketName.id, '')
    // }

    if (watchProvider) {
      getProviderRegions(watchProvider)
      setCloudCredentialFormConfig((state) => {
        return {
          ...state,
          credentials: {
            ...ADD_CLOUD_PROVIDER_AWS_CREDENTIALS.credentials,
            fields: ADD_CLOUD_PROVIDER_AWS_CREDENTIALS_CONFIG[watchProvider]
          }
        }
      })
    }
  }, [getProviderRegions, setValue, watchProvider])
  /**
   * Set the options for regions
   */
  useEffect(() => {
    if (providerRegionsData) {
      setFormConfig(state => ({
        ...state,
        [ADD_CLOUD_PROVIDER_CONFIG.regions.id]: {
          ...ADD_CLOUD_PROVIDER_CONFIG.regions,
          disabled: false,
          options: providerRegionsData,
        }
      }))
    }
    
    if (!watchProvider || watchProvider.length === 0 || !(providerRegionsData && providerRegionsData.length > 0)) {
      setFormConfig(state => ({
        ...state,
        [ADD_CLOUD_PROVIDER_CONFIG.regions.id]: {
          ...ADD_CLOUD_PROVIDER_CONFIG.regions,
          disabled: true,
          options: [],
        }
      }))
    }
  }, [providerRegionsData, watchProvider])

  useEffect(()=>{
    const regionBucketObject = {}
    if(Array.isArray(watchRegions)){
      watchRegions.forEach((item)=>{
        regionBucketObject[item] = ''
      })
    }
    setValue('scratchpadStroage', regionBucketObject)
  },[watchRegions])
  
  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */
  const onSubmit = useCallback(
    (formData) => {
      const data = {
        ...formData,
      };
      data[ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG.bucketName.id] = 'RandomText'+Math.random();
      function update(obj) {
        const keyValues = Object.keys(obj).map(key => {
          const newKey = "AWS_" + key + "_VPC_ID";
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
      }

      function updateBuckets(obj) {
        const keyValues = Object.keys(obj).map(key => {
          const newKey = "CLOUD_AWS_" + key + "_SCRATCHPAD_BUCKET";
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
      }
      // console.log(AWS_CUSTOM_VPC_CONFIG.customVPC.id)
      if(watchProvider === "AWS"){
        if(data[AWS_CUSTOM_VPC_CONFIG.customVPC.id] && data[AWS_CUSTOM_VPC_CONFIG.customVPC.id][""] != undefined){
          delete data[AWS_CUSTOM_VPC_CONFIG.customVPC.id][""];
        }
        if(data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id] && data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id][""] != undefined){
          delete data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id][""];
        }
        if(watchAWSuseCustomVPC){
          data[AWS_CUSTOM_VPC_CONFIG.customVPC.id] = update(data[AWS_CUSTOM_VPC_CONFIG.customVPC.id])
          Object.assign(data["creds"],data[AWS_CUSTOM_VPC_CONFIG.customVPC.id]);
        }
        if(watchAWSuseScratchpadStorage){
          data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id] = updateBuckets(data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id])
          Object.assign(data["creds"],data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id]);
        }
        deletePropertyPath(data,AWS_CUSTOM_VPC_CONFIG.customVPC.id);
        deletePropertyPath(data,USE_CUSTOM_VPC_OPTION.useCustomVPCs.id);
        deletePropertyPath(data,AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id);
        deletePropertyPath(data,USE_AWS_SCRATCHPAD_STORAGE.useScratchpadStroage.id);
      }
      else if(watchProvider === "GCP"){
        data["creds"]['GS_CREDS'] = data["creds"]['SERVICE_ACCOUNT_JSON']
        delete data["creds"]['SERVICE_ACCOUNT_JSON']
        if(data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id] && data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id][""] != undefined){
          delete data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id][""];
        }
        if(watchAWSuseScratchpadStorage){
          data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id] = updateBuckets(data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id])
          Object.assign(data["creds"],data[AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id]);
        }
        deletePropertyPath(data,AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id);
        deletePropertyPath(data,USE_AWS_SCRATCHPAD_STORAGE.useScratchpadStroage.id);
      }
      else{
        deletePropertyPath(data,AWS_CUSTOM_VPC_CONFIG.customVPC.id);
        deletePropertyPath(data,USE_CUSTOM_VPC_OPTION.useCustomVPCs.id);
        deletePropertyPath(data,AWS_SCRATCHPAD_STORAGE.scratchpadStroage.id);
        deletePropertyPath(data,USE_AWS_SCRATCHPAD_STORAGE.useScratchpadStroage.id);
      }
      // console.log(data);
      submit(data)
      .unwrap()
      .then(() => {
        setTimeout(() => {
          close()
        }, 1000)
      })
      .catch(error => dispatch(setError(error)))
    },
    [close, watchProvider, watchAWSuseCustomVPC, watchAWSuseScratchpadStorage, dispatch, submit]
  );

  return (
    <Container disableGutters maxWidth='sm' sx={{ marginBlockStart: 4 }}>
      {isLoadingProviderRegions && <LoadingOverlay/>}
      <Form
        control={control}
        fields={formConfig}
        loading={isLoading}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* {watchProvider !== DATACRUNCH && <FormFields control={control} fields={ADD_CLOUD_PROVIDER_BUCKET_NAME_CONFIG} />} */}
        {watchProvider && <FormFields control={control} fields={cloudCredentialFormConfig} />}
        {watchProvider === "AWS" && < FormFields control={control} fields={USE_CUSTOM_VPC_OPTION} />}
        {watchProvider === "AWS" && watchAWSuseCustomVPC && <FormFields control={control} fields={AWS_CUSTOM_VPC_CONFIG} keyLabel="Region" valueLabel="VPC_ID" />}
        {(watchProvider === "AWS" || watchProvider === "GCP") && < FormFields control={control} fields={USE_AWS_SCRATCHPAD_STORAGE} />}
        {(watchProvider === "AWS" || watchProvider === "GCP") && watchAWSuseScratchpadStorage && <FormFields control={control} fields={AWS_SCRATCHPAD_STORAGE} keyLabel="Region" valueLabel="Bucket" />}
      </Form>
    </Container>
  );
}

export default AddCloudProviderView
