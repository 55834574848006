import { deepFreeze } from '../../utils'
import { INPUT_TYPES } from '.'
var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

export const TEMPLATE_FILTER = {
  template_filter: {
    id: 'template_filter',
    label: 'Choose Template',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'template_filter.template',
        label: 'Template',
        description: 'Choose a template to quickly set up your experiment. However, you can modify the settings below before creating your experiment.',
        type: INPUT_TYPES.SELECT
      }
    }
  }

}
deepFreeze(TEMPLATE_FILTER)

// GPU fields
export const JOB_GPU_FIELDS_CONFIG = {
  gpuTypes: {
    id: 'gpuTypes',
    disabled: true,
    label: 'GPU Types',
    placeholder: 'Select an option',
    description: 'Specify GPU Types you want to operate with. If this field is empty, we will use all available types by default',
    type: INPUT_TYPES.MULTI_SELECT
  },
  cuda: {
    id: 'cuda',
    label: 'Cuda Version',
    options: [11.8, 11.7, 11.6, 11.5, 11.4, 11.3, 11.2, 10.2, 10.1, 10.0],
    placeholder: 'Select an option',
    description: 'Specify a Cuda Version used in your code.',
    type: INPUT_TYPES.SELECT
  },
  gpusPerTrial: {
    id: 'gpusPerTrial',
    label: 'Number of GPUs',
    required: true,
    description: 'Specify how many GPUs do you want to use per each trial.',
    type: INPUT_TYPES.NUMBER
  },
}
deepFreeze(JOB_GPU_FIELDS_CONFIG)

// Mandatory fields
export const EXP_MANDATORY_FIELDS_CONFIG = {
  name: {
    id: 'name',
    label: 'Name',
    placeholder: 'Enter value',
    type: INPUT_TYPES.STRING
  },
  experiment: {
    id: 'experiment.args',
    label: 'Experiment Arguments',
    type: INPUT_TYPES.OBJECT_ARRAY,
  },
  entrypoint: {
    id: 'entrypoint',
    label: 'Entry Point File Name',
    placeholder: 'Enter Value',
    required: true,
    type: INPUT_TYPES.STRING
  }
}
deepFreeze(EXP_MANDATORY_FIELDS_CONFIG)

export const EXP_MAIN_CONFIG = {
  name: {
    id: 'name',
    label: 'Name',
    placeholder: 'Enter value',
    description: 'Give your experiment a unique name, e.g. "ScaleTorch Experiment"',
    errorFunc: (v) => format.test(v),
    errorText: 'Names should only have alphabets, nums or hyphens',
    type: INPUT_TYPES.STRING,
    display: 'none'
  },
  // experiment: {
  //   id: 'experiment.args',
  //   label: 'Experiment Arguments',
  //   description: 'Add parameters you want to experiment with, e.g. batch_size = [16,32], lr = [0.1, 0.01]',
  //   type: INPUT_TYPES.OBJECT_ARRAY,
  //   errorFunc: (num) => {
  //     return (num === 0);
  //   },
  //   errorText: 'Please enter atleast 1 argument for your experiment.'
  // },
  useSpot: {
    id: 'useSpot',
    label: 'Use spot instances',
    description: 'Spot Instances are cheaper. To use spot instances, you have to add checkpoints to your code and a restart feature.',
    link: 'https://www.scaletorch.ai/blog/spot-instances-automation-or-how-to-reduce-your-ai-training-cost-up-to-80-zh3hm',
    type: INPUT_TYPES.BOOLEAN
  }
}
deepFreeze(EXP_MAIN_CONFIG)

// GPU fields
export const SOURCE_CODE_CONFIG = {
  entrypoint: {
    id: 'entrypoint',
    label: 'Entry Point File Name',
    placeholder: 'Enter Value',
    required: true,
    description: 'Add a file name of your existing script to run on all available clouds, eg. "train.py"',
    type: INPUT_TYPES.STRING
  }
}
deepFreeze(SOURCE_CODE_CONFIG)

export const INSTANCE_TYPES = {
  instanceTypes: {
    id: 'instanceTypes',
    disabled: true,
    label: 'Instance Types',
    placeholder: 'Select an option',
    description: 'Specify GPU instance types you want to operate with. If this field is empty, we will use all available types by default',
    type: INPUT_TYPES.MULTI_SELECT
  }
}
deepFreeze(INSTANCE_TYPES)

export const UPLOAD_CODE = {
  codeZip: {
    id: "codeZip",
    label: "Upload Code",
    description: 'Upload your code base as a zip file.',
    maxSize: 5000,
    type: INPUT_TYPES.FILE,
    accept: '.zip',
    upload: true,
  },
  // requirements: {
  //   id: 'requirements',
  //   label: 'Requirement File Name',
  //   placeholder: 'Enter Value',
  //   required: true,
  //   description: 'Path to python requirements file.',
  //   type: INPUT_TYPES.STRING
  // },
  codeTransfer: {
    id: 'codeTransfer',
    label: 'Code Repo Details',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'codeTransfer.type',
        label: 'Code Repo Host',
        options: [
          'B2'
        ],
        placeholder: 'Select an option',
        required: true,
        description: 'Choose a source where your code is stored, eg. "GitLab".',
        type: INPUT_TYPES.SELECT,
        display:'none'
      },
      repo: {
        id: 'codeTransfer.repo',
        label: 'Repo',
        placeholder: 'Enter value',
        required: true,
        description: 'Enter the name of your existing repository, where your code is located.',
        type: INPUT_TYPES.STRING,
        display:'none'
      },
      ref: {
        id: 'codeTransfer.ref',
        label: 'Commit hash or Branch name',
        placeholder: 'Enter value',
        description: 'Specific version or branch of your code to use.',
        type: INPUT_TYPES.STRING,
        display:'none'
      },
      codeDir: {
        id: 'codeTransfer.codeDir',
        label: 'Code Directory',
        placeholder: 'Enter value',
        description: 'Path to the directory containing the script relative to the repo.',
        type: INPUT_TYPES.STRING,
      }
    }
  }
}

deepFreeze(UPLOAD_CODE)

export const VISUALISATION_CONFIG = {
  visualisation: {
    id: 'visualisation',
    label: 'Visualisation',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'visualisation.type',
        label: 'Type',
        disabled: false,
        options: ['TENSORBOARD', 'AIM', 'WANDB', 'COMETML'],
        description: 'Choose Experiments tracking tool to visualize the results of your experiment. We have integration with Tensorboard, AIM and Weghts&Biases, so we can run those tools for you. If you already use other tools for the visualization, skip this field.',
        type: INPUT_TYPES.SELECT
      },
      viz_api_key: {
        id: 'visualisation.viz_api_key',
        label: 'API key',
        description: 'Enter your API key',
        disabled: false,
        autoComplete: 'off',
        errorText: 'Please enter a valid API key',
        error: false,
        edit: true,
        type: INPUT_TYPES.STRING
      },
      startWithJob: {
        id: 'visualisation.startWithJob',
        label: 'Start With Job?',
        type: INPUT_TYPES.BOOLEAN
      }
    }
  }

}
deepFreeze(VISUALISATION_CONFIG)

export const COST_MANAGEMENT_CONFIG = {
  maxCost: {
    id: 'maxCost',
    label: 'Maximum Cost (USD $)',
    description: 'Specify maximum cost that you can spend for this experiment',
    type: INPUT_TYPES.NUMBER
  },
  maxTime: {
    id: 'maxTime',
    label: 'Maximum Time',
    placeholder: 'Examples: 1h15m or 2h or 2h2m',
    description: 'Limit the time you want GPUs to be active.',
    type: INPUT_TYPES.STRING
  },
  maxCostPerHour: {
    id: 'maxCostPerHour',
    label: 'Maximum Cost Per Hour (USD $)',
    description: 'Limit the cost that you can spend per hour for this experiment.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(COST_MANAGEMENT_CONFIG)

// Environment fields
export const MAX_GPU_CONFIG = {
  maxGpus: {
    id: 'maxGpus',
    label: 'Maximum GPUs',
    description: 'This is the limitation on how many GPUs you want to use overall. E.g. if you configured batch_size = [16,32], lr = [0.1, 0.01], GPU per trial = 4  it will run 16 GPUs. You can limit it to 10 GPUs only or any number of your choice.',
    type: INPUT_TYPES.NUMBER,
    display: 'none'
  }
}
deepFreeze(MAX_GPU_CONFIG)

// Environment fields
export const MAX_CPU_WORKERS_CONFIG = {
  maxCPUWorkers: {
    id: 'maxCPUWorkers',
    label: 'Max CPU Workers',
    required: true,
    description: 'Maximum number of CPU VMs that can be created for this job.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(MAX_CPU_WORKERS_CONFIG)

// Environment fields
export const ENVIRONMENT_CONFIG = {
  pythonVersion: {
    id: 'pythonVersion',
    label: 'Python Version',
    description: 'Base python version to be used to install dependencies.',
    type: INPUT_TYPES.SELECT,
    options: ['3.7','3.8','3.9','3.10'],
    none: false
  },
  environment: {
    id: 'environment',
    label: 'Environment Variables',
    description: 'Add third party API keys or other credentials that your training script requires.',
    type: INPUT_TYPES.OBJECT,
  }
}
deepFreeze(ENVIRONMENT_CONFIG)

// Yaml Config field
export const IMPORT_YML = {
  config: {
    id: "config",
    label: "Use yml config",
    // description: 'Use a config file(.yml) to instantly start up your experiment.',
    type: INPUT_TYPES.CONFIG_FILE,
    accept: '.yml'
  }
}

deepFreeze(IMPORT_YML)

// Launch experiment default value
export const LAUNCH_EXPERIMENT_FORM_DEFAULT_VALUE = {
  name: '',
  cuda: null,
  useSpot: false,
  // -------------------------
  cloudProviders: [],
  cloudRegions: [],
  gpuTypes: [],
  // -------------------------
  minvCPUs: -1,
  minMemory: -1,
  // -------------------------
  gpusPerTrial: 1,
  maxGpus: 1,
  pythonVersion: '3.8',
  environment: null,
  maxCost: -1,
  maxTime: '',
  maxCPUWorkers: 1,
  maxCostPerHour: 1,
  visualisation: {
    type: '',
    viz_api_key: '',
    startWithJob: false
  },
  // -------------------------
  customImage: {
    image: '',
    credentials: {
      registry: '',
      username: '',
      password: ''
    }
  },
  // -------------------------
  entrypoint: '',
  requirements: '',

  codeTransfer: {
    type: null,
    repo: '',
    ref: '',
    codeDir: '',
    credentials: {
      GS_CREDS: '',
      AWS_SECRET_ACCESS_KEY: '',
      AWS_ACCESS_KEY_ID: '',
      DROPBOX_TOKEN: '',
      GITLAB_PAT: '',
      AZURE_ACCOUNT_KEY: '',
      BITBUCKET_APP_PASSWORD: '',
      GITHUB_PAT: '',
      GDRIVE_CREDS: '',
      AZURE_ACCOUNT_NAME: ''
    }
  },
  virtualMounts: {},
  artifactsDestination:{
    name: '',
    filter: '',
  },
  preJobCommands: [],
  postJobCommands: [],
  // ------------------------
  experiment: {
    args: { " " : [" "]}
  },
  useDAPP: false,
  epochs: -1,
  // ------------------------
  wandb_api_key: '',
  config: ""
}
deepFreeze(LAUNCH_EXPERIMENT_FORM_DEFAULT_VALUE)
