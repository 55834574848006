import { createSlice } from '@reduxjs/toolkit'
import { STATUS_OPTIONS, TEMPLATE_OPTIONS, MONTHS, START_TIME_FILTERS, END_TIME_FILTERS, COST_FILTERS } from '../constants/uiConstants'


export const initialState = {
  templates: [TEMPLATE_OPTIONS.WORKSTATION, TEMPLATE_OPTIONS.EXPERIMENT],
  experiments: [],
  trainings: [],
  workstations: [],
  months: [MONTHS.JANUARY],
  startTime: '',
  endTime:'',
  cost: ''
}

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setTemplatesFilter: (state, action) => {
      state.templates = action.payload
    },
    setExperimentFilter: (state, action) => {
      state.experiments = action.payload
    },
    setTrainingFilter: (state, action) => {
      state.trainings = action.payload
    },
    setWorkstationFilter: (state, action) => {
      state.workstations = action.payload
    },
    setMonthsFilter: (state, action) => {
      state.months = action.payload
    },
    setEndTimeFilter: (state, action) => {
      state.endTime = action.payload
    },
    setStartTimeFilter: (state, action) => {
      state.startTime = action.payload
    },
    setCostFilter: (state, action) => {
      state.cost = action.payload
    }
  }
})

export const {
  setTemplatesFilter,
  setExperimentFilter,
  setTrainingFilter,
  setWorkstationFilter,
  setMonthsFilter,
  setStartTimeFilter,
  setEndTimeFilter,
  setCostFilter
} = filterSlice.actions

export default filterSlice
