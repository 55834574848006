import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { EMPTY_OPTION_LABEL } from '../../constants/FormConstants'

const SelectComponent = ({
  disabled,
  fullWidth,
  id,
  label,
  onChange,
  options,
  value,
  none,
  display,
  showLabel,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth={fullWidth} size='small' variant='outlined'>
      {showLabel && <InputLabel sx={{m: 1}} id={`${id}-label`}>{label}</InputLabel>}
      <Select
        {...otherProps}
        disabled={disabled}
        id={id}
        label={showLabel ? label : null}
        labelId={`${id}-label`}
        multiple={false}
        onChange={onChange}
        value={value}
      >
        {none && <MenuItem value=''>
          <em>{EMPTY_OPTION_LABEL}</em>
        </MenuItem>}
        {Array.isArray(options) && options.length > 0 && options.map(item => (
          <MenuItem
            key={item}
            value={item}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectComponent.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  none: PropTypes.bool,
  showLabel: PropTypes.bool
}

SelectComponent.defaultProps = {
  onChange: () => null,
  options: [],
  value: '',
  showLabel: false,
  none: true,
}

export default SelectComponent
