import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  ...platformAuthConfig(),
  endpoints: builder => ({
    getInvoices: builder.query({
      query: () => '/invoices',
      method: 'GET'
    })
  })
})

export const {
    useGetInvoicesQuery
} = invoiceApi

export default invoiceApi