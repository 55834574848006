import React, {useState, useMemo, useCallback} from 'react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useFormState } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import InfoIcon from '@mui/icons-material/Info'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Tooltip from '@mui/material/Tooltip'
import FileUpload from './FileUpload'
import ConfigFileUpload from './ConfigFileUpload'
import MultiLineArrayInput from './MultiLineArrayInput'
import MultiSelect from './MultiSelect'
import ObjectInput from './ObjectInput'
import VirtualMountInput from './VirtualMountInput'
import Select from './Select'
import TuningInput from './TuningInput'
import { INPUT_TYPES } from '../../constants/FormConstants'
import { ERROR_MESSAGES } from '../../constants/uiConstants'
import { responsiveFontSizes } from '@mui/material'
import {
  useRegisterVisualizationMutation
} from '../../services/visualizationApi'
import { useRef } from 'react'
const FormFields = ({
  control,
  register,
  disabled: disabledProp,
  fields,
  updateOther,
  select,
  validateInput,
  openModal,
  disableField,
  toValidate,
  keyLabel,
  valueLabel,
  updateUploadedFileId,
  uploadRequired
}) => {
  const { errors } = useFormState({ control })
  const parse = (type, event) => {
    if (type === INPUT_TYPES.NUMBER) {
      return {
        ...event,
        target: {
          ...event.target,
          value: Number(event.target.value)
        }
      }
    }

    return event
  }
  const [registerVisualization, { isLoading: isCheckVizKeyLoading }] = useRegisterVisualizationMutation()
  const [validationFailed,setValdidationFailed] = useState(false);
  function validate (api_key, target){
    registerVisualization({
      type : target,
      key : api_key
    })
    .unwrap()
    .then((payload) => {
      setValdidationFailed(false)
    })
    .catch((error) => {
      setValdidationFailed(true)
    });
  }
  const debouncedValidator = useMemo(
    () => debounce(validate, 500)
  , []);
  const onToggleEdit = useCallback(() => {
    setValdidationFailed(false);
    disableField();
  });
  return (
    <>
      {Object.entries(fields).map(([key, config], index) => {
        const name = config.id
        const disabled = config.disabled || disabledProp
        const label = `${config.label}${config.required ? ' *' : ''}`
        let fieldComponent = null
        switch (config.type) {
          case INPUT_TYPES.BOOLEAN: {
            fieldComponent = (
              <Box sx={{ flexGrow: 1 }}>
                <Grid >
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                      {config.description}{config.link && '('}{config.link && <a target='_blank' href={config.link ?? ''}>{config.link ?? ''}</a>}{config.link && ')'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                      <Controller
                      control={control}
                      name={name}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                        <FormControlLabel
                          id={name}
                          sx={{ m: 1 }}
                          label={label}
                          control={<Switch disabled={disabled} onChange={onChange} value={value} checked={value}/>}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )

            break
          }
          case INPUT_TYPES.FILE: {
            fieldComponent = (
              <Box sx={{ flexGrow: 1, mt : 1, mb: 1 }}>
                <Grid >
                  <Grid container item >
                    <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                      {config.label}{config.required ? '*' : ''} 
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                      {config.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      
                      name={name}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                        <FileUpload
                          id={name}
                          disabled={disabled}
                          label={label}
                          onChange={onChange}
                          value={value}
                          accept={config.accept ?? '.txt'}
                          upload={config.upload ?? false}
                          updateUploadedFileId={updateUploadedFileId ?? (() => {})}
                          maxSize={config.maxSize ?? 10}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )

            break
          }
          case INPUT_TYPES.CONFIG_FILE: {
            fieldComponent = (
              <Box sx={{ flexGrow: 1, pl: '10px', pr: '10px'}}>
                <Grid  container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      
                      name={name}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                        <ConfigFileUpload
                          id={name}
                          disabled={disabled}
                          label={label}
                          onChange={onChange}
                          value={value}
                          accept={config.accept ?? '.txt'}
                          upload={config.upload ?? false}
                          updateUploadedFileId={updateUploadedFileId ?? (() => {})}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )
            break
          }
          case INPUT_TYPES.OBJECT:
          case INPUT_TYPES.OBJECT_ARRAY:{
            fieldComponent = (
              <Box sx={{ flexGrow: 1 }}>
                <Grid>
                  <Grid container item >
                    <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                      {config.label}{config.required ? '*' : ''}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                      ({config.description})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name={name}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                        <ObjectInput
                          id={name}
                          disabled={disabled}
                          label={label}
                          onChange={onChange}
                          keyLabel = {keyLabel}
                          valueLabel = {valueLabel}
                          type={config.type === INPUT_TYPES.OBJECT_ARRAY ? 'array' : 'text'}
                          value={value}
                          desc = {config.description ?? ""}
                          object_type = {config.object_type ?? ""}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              
            )

            break
          }
          case INPUT_TYPES.VIRTUAL_MOUNT_ARRAY:{
            fieldComponent = (
              <Box sx={{ flexGrow: 1 }}>
                <Grid >
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ m: 2 }}>
                      {config.description}{config.link && '('}{config.link && <a target='_blank' href={config.link ?? ''}>{config.link ?? ''}</a>}{config.link && ')'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      
                      name={name}
                      render={({
                        field: {
                          onChange,
                          value
                        }
                      }) => (
                        <VirtualMountInput
                          id={name}
                          disabled={disabled}
                          label={label}
                          onChange={onChange}
                          type={config.type === INPUT_TYPES.VIRTUAL_MOUNT_ARRAY ? 'array' : 'text'}
                          value={value}
                          openModal={openModal}
                          desc = {config.description ?? ""}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              
            )

            break
          }
          case INPUT_TYPES.SELECT: {
            fieldComponent = (
              <>
              {(!config.display || config.display!='none') && <Box sx={{flexGrow: 1 }} >
                <Grid >
                      <Grid container item >
                        <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                          {config.label}{config.required ? '*' : ''}  
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                          {config.description ? `(${config.description})` : ''}
                        </Typography>
                      </Grid>
                      <Grid item sx={{width: config.description ? '33%' : '100%'}}>
                        <Controller
                          control={control}
                          
                          name={name}
                          render={({
                            field: {
                              onChange,
                              value
                            }
                          }) => (
                            <>
                            <FormControl  fullWidth size='small'>
                              <Select
                                {...register(name)}
                                disabled={config.disabled}
                                data-testid={name}
                                fullWidth
                                sx={{ m: 1 }}
                                onChange={(e) => {
                                  onChange(e);
                                  if(validateInput)
                                    setValdidationFailed(false);
                                  if(select){
                                    updateOther(e.target.value)
                                  }
                                }}
                                options={config.options || []}
                                id={name}
                                label={label}
                                value={value ?? ''}
                                none={config.none}
                                display={config.display}
                                
                              />
                            </FormControl>
                            
                            {/* {config.helperText && 
                            <FormHelperText><i>{config.helperText}</i></FormHelperText>} */}
                            </>
                          )}
                          rules={{
                            required: !!config.required
                          }}
                        />
                      </Grid>
                </Grid>
              </Box>}
              </>
              
            )

            break
          }
          case INPUT_TYPES.MULTI_LINE_ARRAY: {
            fieldComponent = (
              <Box sx={{ flexGrow: 1 }}>
                <Grid  >
                  <Grid item >
                    <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                      {config.description}
                    </Typography>
                  </Grid>
                  <Grid item >
                    <Controller
                      control={control}
                      
                      name={name}
                      render={({
                        field: {
                          onBlur,
                          onChange,
                          value
                        }
                      }) => (
                        <MultiLineArrayInput
                          disabled={disabled}
                          id={name}
                          label={label}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={config.placeholder}
                          value={value ?? []}
                        />
                      )}
                      rules={{
                        required: !!config.required
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )

            break
          }
          case INPUT_TYPES.MULTI_SELECT: {
            fieldComponent = (
              <Box sx={{ flexGrow: 1 }}>
                <Grid>
                        <Grid container item >
                          <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                            {config.label}{config.required ? '*' : ''}  
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{display: config.display ?? 'flex', m: 1 }}>
                            {config.description ? `(${config.description})` : ''}
                          </Typography>
                        </Grid>
                        <Grid item >
                          <Controller
                            control={control}
                            name={name}
                            render={({
                              field: {
                                onChange,
                                value
                              }
                            }) => (
                              <>
                              <MultiSelect
                                disabled={disabled}
                                onChange={onChange}
                                options={config.options || []}
                                id={name}
                                desc={config.description ?? ""}
                                label={label}
                                value={value ?? []}
                                
                              />
                              {config.helperText && 
                              <FormHelperText><i>{config.helperText}</i></FormHelperText>}
                              </>
                            )}
                            rules={{
                              required: !!config.required
                            }}
                          />
                        </Grid>
                </Grid>
              </Box>
              
            )

            break
          }
          case INPUT_TYPES.NESTED_OBJECT: {
            fieldComponent = (
              <fieldset style={{margin:'8px'}}>
                <Typography variant="h5" sx={{display: 'flex', m: 1}}>
                  {config.label}{config.required ? '*' : ''} 
                </Typography>
                <hr/>
                {/* <FormLabel component='legend' id={`${name}-label`}>{label}</FormLabel> */}
                <Stack direction='column' spacing={4} sx={{ m: 1 }}>
                  <FormFields
                    control={control}
                    disabled={disabled}
                    fields={config.fields}
                    prefix={key}
                    validate={validate}
                    disableField={disableField}
                    validateInput={validateInput}
                    toValidate={toValidate}
                  />
                </Stack>
              </fieldset>
            )

            break
          }
          case INPUT_TYPES.NUMBER:
          case INPUT_TYPES.STRING: {
            fieldComponent = (
              <>
              {(!config.display || config.display!='none') && <Box sx={{ flexGrow: 1 }}>
                <Grid >
                  {config.description ?
                  <>
                    <Grid container item>
                      <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                        {config.label}{config.required ? '*' : ''}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{display: config.display ?? 'flex', m: 1}}>
                        {config.description ? `(${config.description})` : ''}
                      </Typography>
                    </Grid>
                    <Grid item sx={{maxWidth: '33%'}}>
                      <Controller
                        control={control}
                        
                        name={name}
                        render={({
                          field: {
                            onBlur,
                            onChange,
                            value
                          }
                        }) => (
                          <TextField
                            {...register(name)}
                            disabled={disabled}
                            sx={{ display: config.display ?? 'flex', m: 1}}
                            data-testid={name}
                            id={name}
                            // label={label}
                            onBlur={(event) => {
                                onBlur(parse(config.type, event));
                                
                              }
                            }
                            onChange={(event) => {
                                if(validationFailed){
                                  setValdidationFailed(false);
                                }
                                onChange(parse(config.type, event));
                                if(validateInput){
                                  debouncedValidator(event.target.value, toValidate);
                                }                
                              }
                            }
                            placeholder={config.placeholder}
                            size='small'
                            autoComplete={config.autoComplete ?? 'on'}
                            type={{
                              [INPUT_TYPES.STRING]: 'text',
                              [INPUT_TYPES.NUMBER]: 'number'
                            }[config.type]}
                            value={value}
                            error={validationFailed || (!!config.errorFunc && config.errorFunc(value))}
                            helperText={(validationFailed ? config.errorText : '') || ((!!config.errorFunc && config.errorFunc(value)) && (config.errorText ?? ''))}
                            variant='outlined'
                            InputProps={config.prefix ? {
                              startAdornment: <InputAdornment position="start">{config.prefix}</InputAdornment>,
                            } : undefined}
                            
                          />
                        )}
                        rules={{
                          required: !!config.required
                        }}
                      />
                    </Grid>
                    {config.edit && config.disabled && <Grid item >
                      <IconButton aria-label="delete" onClick={onToggleEdit} sx={{ display: config.display ?? '', m: 1}}>
                        <EditIcon />
                      </IconButton>
                    </Grid>}
                  </> :
                  <>
                    <Grid container item>
                      <Typography variant="h6" sx={{display: config.display ?? 'flex', ml: 1}}>
                        {config.label}{config.required ? '*' : ''}  
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{display: config.display ?? 'flex', m: 1}}>
                        {config.description ? `(${config.description})` : ''}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        
                        name={name}
                        render={({
                          field: {
                            onBlur,
                            onChange,
                            value
                          }
                        }) => (
                          <TextField
                            {...register(name)}
                            disabled={disabled}
                            sx={{ display: config.display ?? 'flex', m: 1}}
                            data-testid={name}
                            id={name}
                            // label={label}
                            onBlur={(event) => {
                                onBlur(parse(config.type, event));
                                
                              }
                            }
                            onChange={(event) => {
                                if(validationFailed){
                                  setValdidationFailed(false);
                                }
                                onChange(parse(config.type, event));
                                if(validateInput){
                                  debouncedValidator(event.target.value, toValidate);
                                }                
                              }
                            }
                            placeholder={config.placeholder}
                            size='small'
                            autoComplete={config.autoComplete ?? 'on'}
                            type={{
                              [INPUT_TYPES.STRING]: 'text',
                              [INPUT_TYPES.NUMBER]: 'number'
                            }[config.type]}
                            value={value}
                            error={validationFailed || (!!config.errorFunc && config.errorFunc(value))}
                            helperText={(validationFailed ? config.errorText : '') || ((!!config.errorFunc && config.errorFunc(value)) && (config.errorText ?? ''))}
                            variant='outlined'
                            InputProps={config.prefix ? {
                              startAdornment: <InputAdornment position="start">{config.prefix}</InputAdornment>,
                            } : undefined}
                            
                          />
                        )}
                        rules={{
                          required: !!config.required
                        }}
                      />
                    </Grid>
                  </>
                  }
                </Grid>
              </Box>}
              </>
              
            )

            break
          }
          case INPUT_TYPES.TUNING: {
            fieldComponent = (
              <Controller
                control={control}
                
                name={name}
                render={({
                  field: {
                    onBlur,
                    onChange,
                    value
                  }
                }) => (
                  <TuningInput
                    id={name}
                    disabled={disabled}
                    label={label}
                    onChange={onChange}
                    options={config.options || []}
                    value={value}
                  />
                )}
                rules={{
                  required: !!config.required
                }}
              />
            )

            break
          }
          default: break
        }

        return (
          <React.Fragment key={`${name}-${index}`}>
            {fieldComponent}
            <ErrorMessage
              errors={errors}
              name={name}
              render={() => {
                return (
                  <Typography color='error' sx={{ marginTop: '10px !important' }} variant='body2'>
                    {ERROR_MESSAGES.FIELD_REQUIRED_ERROR}
                  </Typography>
                )
              }
            }
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

FormFields.propTypes = {
  control: PropTypes.object,
  register: PropTypes.func,
  disabled: PropTypes.bool,
  fields: PropTypes.object,
  updateOther: PropTypes.func,
  select: PropTypes.bool,
  validateInput: PropTypes.bool,
  disableField: PropTypes.func,
  toValidate: PropTypes.string,
  keyLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  uploadRequired: PropTypes.bool
}

FormFields.defaultProps = {
  fields: {},
  updateOther: () => {},
  register: () => {},
  select: true,
  validateInput: false,
  toValidate: "",
  disableField: () => {},
  keyLabel: null,
  valueLabel: null,
  uploadRequired: true
}

export default FormFields
