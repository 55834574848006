import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useGetSecretsQuery } from '../../services/secretsApi'
import { useGetTemplatesQuery, useCreateTemplateMutation } from '../../services/templatesApi'
import { useCreateJobMutation, useGetJobQuery } from '../../services/jobsApi'
import {
  useGetVisualizationQuery,
} from '../../services/visualizationApi'
import { useGetUserCloudSubscriptionsQuery, useGetGpuTypesMutation, useGetInstanceTypesMutation } from '../../services/cloudApi'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"
import LaunchZeroLabelJobForm from './LaunchZeroLabelJobForm';


const LaunchZeroLabelJobView = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState(false)
  const [name, setName] = useState('')
  const [skip, setSkip] = useState(type === 'new' ? false : true)
  const [watchConfigFileUpload, setWatchConfigFileUpload] = useState(null)
  
  const {
    data: cloudSubscriptionsData,
    error: cloudSubscriptionsApiError
  } = useGetUserCloudSubscriptionsQuery()
  /**
   * Get GPU types
   */
  const [getGpuTypes, {
    data: gpuTypesData
  }] = useGetGpuTypesMutation()

  /**
   * Get GPU types
   */
  const [getInstances, {
    data: getInstanceTypes
  }] = useGetInstanceTypesMutation()

  /**
   * Get Templates
   */
  const {
    data: allTemplates, isLoading: isLoadingAllTemplates
  } = useGetTemplatesQuery()
  /**
   * Get Artifacts Storage
   */
  const {
    data: artifactsStoragePaths
  } = useGetArtifactStoragePathsQuery()
  /**
   * Get Virtual Mounts
   */
  const {
    data: allVirtualMounts
  } = useGetVirtualMountsQuery()

  /**
   * Get Visualisation keys
   */
  const {
    data: allVizKeys
  } = useGetVisualizationQuery()

  /**
   * Get Secret keys
   */
  const {
    data: allSecretKeys
  } = useGetSecretsQuery('TEMPLATE')

  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useCreateJobMutation()
  const [createTemplate, { isLoading: isLoadingCreateTemplate }] = useCreateTemplateMutation()
  const { data: job_data, isLoading: jobDataIsLoading } = useGetJobQuery(location.pathname.split('/').pop(), { skip: type === "retry" ? false : true })
  const navigateTo = (base) => {
    setSkip(true)
    navigate(base)
  }
  

  return (
    <>
      <LaunchZeroLabelJobForm
        name={name}
        watchConfigFileUpload={watchConfigFileUpload}
        cloudSubscriptionsData={cloudSubscriptionsData}
        allTemplates={allTemplates}
        getInstances={getInstances}
        getInstanceTypes={getInstanceTypes}
        getGpuTypes={getGpuTypes}
        gpuTypesData={gpuTypesData}
        cloudSubscriptionsApiError={cloudSubscriptionsApiError}
        artifactsStoragePaths={artifactsStoragePaths}
        allVirtualMounts={allVirtualMounts}
        allSecretKeys={allSecretKeys}
        allVizKeys={allVizKeys}
        job_data={job_data}
        createTemplate={createTemplate}
        submit={submit}
        isLoading={isLoading}
        isLoadingCreateTemplate={isLoadingCreateTemplate}
        jobDataIsLoading={jobDataIsLoading}
        isLoadingAllTemplates={isLoadingAllTemplates}
        dispatch={dispatch}
        navigate={navigate}
        type={type}
      />
    </>
  )
}
LaunchZeroLabelJobView.propTypes = {
  type: PropTypes.string
}

LaunchZeroLabelJobView.defaultProps = {
  type: 'new'
}
export default LaunchZeroLabelJobView
