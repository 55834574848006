import React from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Row from './Row'
import { FIELD_LABELS } from '../../../constants/uiConstants'

const TuningInput = ({
  id,
  disabled,
  label,
  onChange,
  options,
  value: valueProp
}) => {
  const handleChange = (oldKey, newVal) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    obj[newVal.key] = {
      value: newVal.value,
      type: newVal.type,
    }
    onChange(obj)
  }

  const handleCreate = (newVal) => {
    const obj = {...valueProp}
    obj[newVal.key] = {
      value: newVal.value,
      type: newVal.type
    }
    onChange(obj)
  }

  const handleDelete = (oldKey) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    onChange(obj)
  }

  return (
    <fieldset style={{margin:'8px'}}>
      <Typography variant="h5" sx={{display: 'flex', m: 1}}>
        {label} 
      </Typography>
      <hr/>
      {/* <FormLabel component='legend' id={`${id}-label`}>{label}</FormLabel> */}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography>{FIELD_LABELS.PROPERTY}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{FIELD_LABELS.TYPE}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{FIELD_LABELS.VALUE}</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        {Object.entries(valueProp ?? {}).map(([key, value]) => <Row
          disabled={disabled}
          key={key}
          onChange={handleChange.bind(this, key)}
          onDelete={handleDelete.bind(this, key)}
          options={options}
          value={{ key, value: value?.value ?? '', type: value?.type ?? '' }}
        />)}
        <Row
          disabled={disabled}
          create={true}
          onChange={handleCreate}
          options={options}
          value={{}}
        />
      </Grid>
    </fieldset>
  )
}

TuningInput.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.object
}

TuningInput.defaultProps = {
  onChange: () => null,
  options: [],
  value: {}
}

export default TuningInput
