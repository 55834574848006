import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetSecretsQuery,
  useDeleteSecretMutation,
} from "../../services/secretsApi";
import { BUTTON_LABELS, VIEW_HEADERS } from "../../constants/uiConstants";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../components/Modal";
import DataGrid from "../../components/DataGrid";
import DeleteButton from "../../components/DeleteButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { setError } from "../../datastore/UI/uiSlice";
import AddSecretView from "./AddSecretView";

const SecretsView = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [deletingRow, setDeletingRow] = useState(null);
  const [data, setData] = useState([]);
  const {
    data: fetchedData,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetSecretsQuery();

  const [deleteSecret] = useDeleteSecretMutation();

  useEffect(() => {
    if (fetchedData) setData(fetchedData);
  }, [fetchedData]);

  const rows = useMemo(() => {
    if (isSuccess && data) {
      return data.map((item) => ({
        id: item,
        secret_name: item.substr(4),
      }));
    }
    return [];
  }, [data, isSuccess]);

  const handleCreate = useCallback(() => {
    setSelectedRow(false);
    refetch();
  }, [refetch]);

  const handleDelete = useCallback(
    (row) => {
      setDeletingRow(row);
      deleteSecret(row.id)
        .unwrap()
        .then(() => {
          setData((state) => state.filter((item) => item.id !== row._id));
          setDeletingRow(null);
        })
        .catch((err) => {
          setDeletingRow(null);
          dispatch(setError(err));
        });
    },
    [deleteSecret, dispatch]
  );

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.SECRETS}
      </Typography>
      <hr />
      <Container disableGutters maxWidth="lg" sx={{ marginBlockStart: 4 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ marginBlockEnd: 4 }}
        >
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={() => setSelectedRow(true)}
          >
            <AddIcon />
            {BUTTON_LABELS.ADD_SECRET}
          </Button>
        </Stack>
        <Modal
          onClose={() => setSelectedRow(false)}
          open={!!selectedRow}
          title={VIEW_HEADERS.ADD_SECRET}
        >
          <AddSecretView close={handleCreate}/>
        </Modal>
        {isLoading && <LoadingOverlay />}
        {isError && (
          <Alert severity="error">{error?.data?.detail?.[0]?.msg}</Alert>
        )}
        {isSuccess && (
          <DataGrid
            
            columns={[
              {
                field: "secret_name",
                headerName: "Secret Name",
                sortable: true,
                flex: 1,
              },
              {
                field: 'actions',
                headerName: 'Delete',
                width: '150',
                renderCell: ({ row }) => {
                  return (
                    <DeleteButton
                      color='error'
                      loading={deletingRow ? deletingRow.id === row.id : false}
                      onClick={() => handleDelete(row)}
                      size='small'
                      variant='contained'
                      description="Are you sure you want to delete this Secret?"
                    >
                      {BUTTON_LABELS.DELETE_BUTTON}
                    </DeleteButton>
                  )
                }
              }
            ]}
            rows={rows}
          />
        )}
      </Container>
    </>
  );
};

export default SecretsView;
