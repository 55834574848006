import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";

export const EDIT_GT2_CACHE = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Enable/Disable GT2 Cache",
    type: INPUT_TYPES.BOOLEAN,
  },
};

deepFreeze(EDIT_GT2_CACHE);

export const EDIT_GT2_CACHE_DEFAULT_VALUE = {
  entity_type: "GT2",
  entity_ids: false,
};
deepFreeze(EDIT_GT2_CACHE_DEFAULT_VALUE);

export const EDIT_ARTIFACTS_STORAGE = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Storage Names",
    type: INPUT_TYPES.MULTI_SELECT,
  },
};

deepFreeze(EDIT_ARTIFACTS_STORAGE);

export const EDIT_ARTIFACTS_STORAGE_DEFAULT_VALUE = {
  entity_type: "ARTIFACTS_STORAGE",
  entity_ids: [],
};
deepFreeze(EDIT_ARTIFACTS_STORAGE_DEFAULT_VALUE);

export const EDIT_SECRETS = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Secret Names",
    type: INPUT_TYPES.MULTI_SELECT,
  },
};

deepFreeze(EDIT_SECRETS);

export const EDIT_SECRETS_DEFAULT_VALUE = {
  entity_type: "SECRET",
  entity_ids: [],
};
deepFreeze(EDIT_SECRETS_DEFAULT_VALUE);

export const EDIT_VIRTUAL_MOUNTS = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Virtual Mount Names",
    type: INPUT_TYPES.MULTI_SELECT,
  },
};

deepFreeze(EDIT_VIRTUAL_MOUNTS);

export const EDIT_VIRTUAL_MOUNTS_DEFAULT_VALUE = {
  entity_type: "VIRTUAL_MOUNT",
  entity_ids: [],
};
deepFreeze(EDIT_VIRTUAL_MOUNTS_DEFAULT_VALUE);

export const PERMISSION_CLOUD_PROVIDER_CONSTANTS = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Cloud Provider Names",
    type: INPUT_TYPES.MULTI_SELECT,
  },
};

deepFreeze(PERMISSION_CLOUD_PROVIDER_CONSTANTS);

export const PERMISSION_CLOUD_PROVIDER_DEFAULT_VALUE = {
  entity_type: "CLOUD_PROVIDER",
  entity_ids: [],
};
deepFreeze(PERMISSION_CLOUD_PROVIDER_DEFAULT_VALUE);

export const PERMISSION_VIRTUAL_MOUNT_CONSTANTS = {
  entity_type: {
    id: "entity_type",
    label: "entity_type",
    disabled: true,
    display: "none",
    type: INPUT_TYPES.STRING,
  },
  entity_ids: {
    id: "entity_ids",
    label: "Virtual Mount Names",
    type: INPUT_TYPES.MULTI_SELECT,
  },
};

deepFreeze(PERMISSION_VIRTUAL_MOUNT_CONSTANTS);

export const PERMISSION_VIRTUAL_MOUNT_DEFAULT_VALUE = {
  entity_type: "VIRTUAL_MOUNT",
  entity_ids: [],
};
deepFreeze(PERMISSION_VIRTUAL_MOUNT_DEFAULT_VALUE);