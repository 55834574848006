import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    }
  }
}

function getStyles(item, value, theme) {
  return {
    fontWeight:
    value.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
      
    whiteSpace: "normal",
       
    
  }
}

const MultiSelect = ({
  disabled,
  id,
  label,
  onChange,
  options,
  value,
  desc
}) => {
  const theme = useTheme()

  return (
    <FormControl  style={{minWidth: '33%'}} size='small'>
      {/* <InputLabel id={`${id}-label`} sx={{color:'white'}}>{label}</InputLabel> */}
      <Select
        data-testid={id}
        disabled={disabled}
        id={id}
        sx={{ m: 1 }}
        input={<OutlinedInput disabled={disabled}  size='small'/>}
        labelId={`${id}-label`}
        MenuProps={menuProps}
        multiple
        onChange={onChange}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {Array.isArray(selected) && selected.map(value => (
              <Chip key={value} 
                    label={value}
                    sx={{
                      transition: 'all .2s ease-in-out',
                      borderColor: theme.palette.primary.dark,
                  }}
              />
            ))}
          </Box>
        )}
        value={value}
        
      >
        {Array.isArray(options) && options.length > 0 && options.map(item => (
          <MenuItem
            key={item}
            value={item}
            style={getStyles(item, value, theme) }
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.array,
  desc: PropTypes.string
}

MultiSelect.defaultProps = {
  onChange: () => null,
  options: [],
  value: []
}

export default MultiSelect
