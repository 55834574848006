import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack'
import Modal from '../../components/Modal'
import MenuItem from '@mui/material/MenuItem'
import { VIEW_HEADERS } from '../../constants/uiConstants'
import { useGetUsageMutation } from '../../services/usageApi'
import LoadingOverlay from '../../components/LoadingOverlay'
import UsageList from '../../components/UsageList'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { setError } from '../../datastore/UI/uiSlice'
import { setMonthsFilter } from '../../datastore/filterSlice'
import { MONTHS, STATUS_OPTIONS_FOR_JOBS } from '../../constants/uiConstants'
import JobUsageView from './JobUsageView'
import Container from '@mui/material/Container'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    }
  }
}
const month = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"];

const AllUsageView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filter = useSelector((state) => state.filters.months)
  const [showDetails, setShowDetails] = useState(null);
  const [ getUsage, {
    isLoading, isSuccess
  } ] = useGetUsageMutation()
  // const { data: fetchedData, error, isError, isLoading, isSuccess } = useGetUsageQuery({month: Math.max(month.indexOf(filter),0) + 1, year: '2023'})
  const [totalJob,setTotalJob] = useState(0)
  const [platformJob, setPlatformJob] = useState(0)
  const [totalTimeJob, setTotalTimeJob] = useState(0)
  const [totalExp,setTotalExp] = useState(0)
  const [platformExp, setPlatformExp] = useState(0)
  const [totalTimeExp, setTotalTimeExp] = useState(0)
  const [totalHpt,setTotalHpt] = useState(0)
  const [platformHpt, setPlatformHpt] = useState(0)
  const [totalTimeHpt, setTotalTimeHpt] = useState(0)
  const [totalWks,setTotalWks] = useState(0)
  const [platformWks, setPlatformWks] = useState(0)
  const [totalTimeWks, setTotalTimeWks] = useState(0)
  const [startLoading, setStartLoading] =useState(false)
  const [jobData, setJobData] = useState([])
  const [expData, setExpData] = useState([])
  const [hptData, setHptData] = useState([])
  const [wksData, setWksData] = useState([])

  useEffect (() => {
    setStartLoading(true)
    getUsage({month: Math.max(month.indexOf(filter),0) + 1, year: '2023'})
      .unwrap()
      .then((res) => {
        // console.log(res)
        const filteredDataExp = res.message.filter((item) => filter.includes(month[new Date(item.timestamp).getMonth()]) && (item.job_type!=undefined && item.job_type==='EXPERIMENT'))
        let total_cost = 0
        let platform_cost = 0
        let time = 0
        filteredDataExp.forEach(item => {
          item.nodes.forEach(node => {
            total_cost =  total_cost + node.cost
            time = time + node.hours
          })
          platform_cost = platform_cost + (item.platform_cost ?? 0)
        })
        setPlatformExp(platform_cost)
        setTotalExp(total_cost)
        setTotalTimeExp(time)
        setExpData(filteredDataExp)
        setStartLoading(false)

        const filteredDataJob = res.message.filter((item) => filter.includes(month[new Date(item.timestamp).getMonth()]) && (item.job_type==undefined || item.job_type==='SIMPLE_JOB'))
        total_cost = 0
        platform_cost = 0
        time = 0
        filteredDataJob.forEach(item => {
          item.nodes.forEach(node => {
            total_cost =  total_cost + node.cost
            time = time + node.hours
          })
          platform_cost = platform_cost + (item.platform_cost ?? 0)
        })
        setPlatformJob(platform_cost)
        setTotalJob(total_cost)
        setTotalTimeJob(time)
        setJobData(filteredDataJob);

        const filteredDataHpt = res.message.filter((item) => filter.includes(month[new Date(item.timestamp).getMonth()]) && (item.job_type!=undefined && item.job_type==='HPTOPT'))
        total_cost = 0
        platform_cost = 0
        time = 0
        filteredDataHpt.forEach(item => {
          item.nodes.forEach(node => {
            total_cost =  total_cost + node.cost
            time = time + node.hours
          })
          platform_cost = platform_cost + (item.platform_cost ?? 0)
        })
        setPlatformHpt(platform_cost)
        setTotalHpt(total_cost)
        setTotalTimeHpt(time)
        setHptData(filteredDataHpt)

        const filteredDataWks = res.message.filter((item) => filter.includes(month[new Date(item.timestamp).getMonth()]) && (item.job_type!=undefined && item.job_type==='WORKSTATION'))
        total_cost = 0
        platform_cost = 0
        time = 0
        filteredDataWks.forEach(item => {
          item.nodes.forEach(node => {
            total_cost =  total_cost + node.cost
            time = time + node.hours
          })
          platform_cost = platform_cost + (item.platform_cost ?? 0)
        })
        setPlatformWks(platform_cost)
        setTotalWks(total_cost)
        setTotalTimeWks(time)
        setWksData(filteredDataWks)
      })
      .catch((error) => {
          dispatch(setError(error))
          setExpData([])
          setStartLoading(false)
      })
  }, [filter])

  const handleClick = (jobId) => {
    if(data){
      setShowDetails(data.filter(job => job.id === jobId))
    }
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.USAGE}
      </Typography>
      <hr />
      <br />
      {(isLoading || startLoading) && <LoadingOverlay />}
      <Box sx={{marginBlockEnd: 4, backgroundColor: '#292929', borderRadius: '8px'}}>
        <Stack direction="column" spacing={2}>
          {(
            <>
              {isSuccess && 
              <FormControl fullWidth size='small' sx={{width: 750, mt: '16px'}}>
                <InputLabel id={`month-filter-label`} sx={{ color: 'white' }}>{"Month"}</InputLabel>
                <Select
                  disabled={false}
                  id='month-filter'
                  sx={{ m: 1 }}
                  input={<OutlinedInput disabled={false} label={"Month"} size='small' />}
                  labelId={`month-filter-label`}
                  MenuProps={menuProps}
                  onChange={(e) => dispatch(setMonthsFilter(e.target.value))}
                  value={filter}
                >
                  {Array.isArray(Object.values(MONTHS)) && Object.values(MONTHS).length > 0 && Object.values(MONTHS).map(item => (
                    <MenuItem
                      key={item}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>}
              {/* {isSuccess && 
                <Stack direction="row" spacing={2} sx={{p: '8px'}}>
                    <Typography gutterBottom sx={{color: '#ABABAB'}}><b>Total Cost: </b></Typography>
                    <Typography gutterBottom sx={{color: '#9967DD'}}><b>${Math.round((totalJob + totalExp + totalHpt + totalWks + platformJob + platformExp + platformHpt + platformWks)*1000)/1000}</b></Typography>
                </Stack>
              } */}
            </>
          )}
        </Stack>
      </Box>
      <Container disableGutters maxWidth='lg' sx={{ marginBlockStart: 4 }}>
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end'}} spacing={2} >
            <Typography sx={{width: '100%'}}><b>Summary</b></Typography>
            <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}><b>USD</b></Typography>
        </Stack>
        <hr/>
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', m: 2}} spacing={2} >
          <Typography sx={{width: '100%'}}>Scaletorch</Typography>
          <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalJob + totalExp + totalHpt + totalWks + platformJob + platformExp + platformHpt + platformWks)*1000)/1000}</Typography>
        </Stack>
    
        {/* <Accordion sx={{'&:before': {
                display: 'none',
            },backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
          <AccordionSummary
            sx={{flexDirection: 'row-reverse', width: '100%'}}
            expandIcon={<ChevronRightIcon />}
          >
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2}>
                <Typography sx={{width: '100%'}}>Scaletorch</Typography>
                <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalJob + totalExp + totalHpt + totalWks + platformJob + platformExp + platformHpt + platformWks)*1000)/1000}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ml: 3}}>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end'}} spacing={2} >
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%'}}><b>Scaletorch Cost</b></Typography>
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%', display: 'flex', justifyContent: 'flex-end'}}><b>${Math.round((platformJob + platformExp + platformHpt + platformWks)*1000)/1000}</b></Typography>
            </Stack>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end'}} spacing={2} >
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%'}}><b>Total Services Used</b></Typography>
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%', display: 'flex', justifyContent: 'flex-end'}}><b>1</b></Typography>
            </Stack>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%'}}><b>Total Duration</b></Typography>
              <Typography gutterBottom sx={{color: '#ABABAB', width: '100%', display: 'flex', justifyContent: 'flex-end'}}><b>{Math.floor(((totalTimeJob + totalTimeHpt + totalTimeExp + totalTimeWks)*60)/60)}hrs {Math.floor(((totalTimeJob + totalTimeHpt + totalTimeExp + totalTimeWks)*60)%60)}mins</b></Typography>
            </Stack>
          </AccordionDetails>
        </Accordion> */}
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end'}} spacing={2} >
            <Typography sx={{width: '100%'}}><b>Details</b></Typography>
            {/* <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}><b>Expand All</b></Typography> */}
        </Stack>
        <hr/>
        <Accordion sx={{'&:before': {
                display: 'none',
            },backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
          <AccordionSummary
            sx={{flexDirection: 'row-reverse'}}
            expandIcon={<ChevronRightIcon />}
          >
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
            <Typography sx={{width: '100%'}}>Jobs</Typography>
            <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalJob + platformJob)*1000)/1000}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
                {jobData.map((item) => (
                  <div key={`JOB${item.id}`}>
                    {(item.job_type!=undefined && item.job_type==='SIMPLE_JOB') && 
                      <Accordion sx={{backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
                        <AccordionSummary
                          sx={{flexDirection: 'row-reverse', mr: 3}}
                          expandIcon={<ChevronRightIcon />}
                        >
                          <Stack direction="row"  sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
                              <Typography sx={{width: '100%', color: '#9967DD'}}><b>{item.id}</b></Typography>
                              <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round(item.platform_cost*1000)/1000}</Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JobUsageView data={item} />
                        </AccordionDetails>
                      </Accordion>
                    }
                  </div>
                ))}
                
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{'&:before': {
                display: 'none',
            },backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
          <AccordionSummary
            sx={{flexDirection: 'row-reverse'}}
            expandIcon={<ChevronRightIcon />}
          >
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
            <Typography sx={{width: '100%'}}>Experiments</Typography>
            <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalExp + platformExp)*1000)/1000}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
                {expData && expData.map((item) => (
                  <div key={`${item.job_type}${item.id}`}>
                    {(item.job_type!=undefined && item.job_type==='EXPERIMENT') && 
                      <Accordion key={`${item.job_type}${item.id}`} sx={{backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
                        <AccordionSummary
                          sx={{flexDirection: 'row-reverse',  mr: 3}}
                          expandIcon={<ChevronRightIcon />}
                        >
                          <Stack direction="row"  sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
                              <Typography sx={{width: '100%', color: '#9967DD'}}><b>{item.id}</b></Typography>
                              <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round(item.platform_cost*1000)/1000}</Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JobUsageView data={item} />
                        </AccordionDetails>
                      </Accordion>
                    }
                  </div>
                ))}
                
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{'&:before': {
                display: 'none',
            },backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
          <AccordionSummary
            sx={{flexDirection: 'row-reverse'}}
            expandIcon={<ChevronRightIcon />}
          >
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
            <Typography sx={{width: '100%'}}>Hyperparameter Tuning</Typography>
            <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalHpt + platformHpt)*1000)/1000}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
                {hptData.map((item) => (
                  <div key={`${item.job_type}${item.id}`}>
                    {(item.job_type!=undefined && item.job_type==='HPTOPT') && 
                      <Accordion sx={{backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
                        <AccordionSummary
                          sx={{flexDirection: 'row-reverse', mr: 3}}
                          expandIcon={<ChevronRightIcon />}
                        >
                          <Stack direction="row"  sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
                              <Typography sx={{width: '100%', color: '#9967DD'}}><b>{item.id}</b></Typography>
                              <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round(item.platform_cost*1000)/1000}</Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JobUsageView data={item} />
                        </AccordionDetails>
                      </Accordion>
                    }
                  </div>
                ))}
                
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{'&:before': {
                display: 'none',
            },backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
          <AccordionSummary
            sx={{flexDirection: 'row-reverse'}}
            expandIcon={<ChevronRightIcon />}
          >
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
            <Typography sx={{width: '100%'}}>Workstations</Typography>
            <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round((totalWks + platformWks)*1000)/1000}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
                {wksData.map((item) => (
                  <div key={`${item.job_type}${item.id}`}>
                    {(item.job_type!=undefined && item.job_type==='WORKSTATION') && 
                      <Accordion sx={{backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: "none"}}>
                        <AccordionSummary
                          sx={{flexDirection: 'row-reverse', mr: 3}}
                          expandIcon={<ChevronRightIcon />}
                        >
                          <Stack direction="row"  sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} spacing={2} >
                              <Typography sx={{width: '100%', color: '#9967DD'}}><b>{item.id}</b></Typography>
                              <Typography sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>${Math.round(item.platform_cost*1000)/1000}</Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JobUsageView data={item} />
                        </AccordionDetails>
                      </Accordion>
                    }
                  </div>
                ))}
                
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
}

export default AllUsageView
