import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './datastore/store'
import { Auth0Provider } from '@auth0/auth0-react'
import { GoogleOAuthProvider   } from '@react-oauth/google';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_ORIGIN_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_SENTRY_DSN
} = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
    <Auth0Provider  
        domain={REACT_APP_AUTH_DOMAIN}
        clientId={REACT_APP_AUTH_CLIENT_ID}
        redirectUri={location.protocol + '//' + location.host}
        audience={REACT_APP_AUTH0_AUDIENCE}
      >
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<App/>} />
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
    </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if(module.hot) {
  module.hot.accept();
}
