import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  error: null,
  leftDrawerShown: false,
  themeMode: 'dark'
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openLeftDrawer: (state) => {
      state.leftDrawerShown = true
    },
    closeLeftDrawer: (state) => {
      state.leftDrawerShown = false
    },
    toggleLeftDrawer: (state) => {
      if (state.leftDrawerShown === true) {
        state.leftDrawerShown = false
      } else {
        state.leftDrawerShown = true
      }
    },
    toggleThemeMode: (state) => {
      if (state.themeMode === 'light') {
        state.themeMode = 'dark'
      } else {
        state.themeMode = 'light'
      }
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    clearError: (state) => {
      state.error = null
    }
  }
})

export const {
  openLeftDrawer,
  closeLeftDrawer,
  toggleLeftDrawer,
  toggleThemeMode,
  setError,
  clearError
} = uiSlice.actions

export default uiSlice
