import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const cloudApi = createApi({
  reducerPath: 'cloudApi',
  ...platformAuthConfig(),
  tagTypes: ['UserClouds'],
  endpoints: builder => ({
    getUserClouds: builder.query({
      query: () => '/cloud',
      providesTags: ['UserClouds']
    }),
    getUserCloudSubscriptions: builder.query({
      query: () => '/cloud',
      transformResponse: (response) => {
        const cloudProviderOptions = new Set()
        const cloudRegionOptions = new Set()
        if(response) {
          response.forEach(provider => {
            cloudProviderOptions.add(provider.cloud_provider)
            provider.regions.forEach(region => {
              cloudRegionOptions.add(`${provider.cloud_provider}:${region}`)
            })
          })
          return [[...cloudProviderOptions], [...cloudRegionOptions]]
        }
      },
    }),
    getCloudProviderRegions: builder.query({
      query: (provider) => `/cloud_provider/regions?provider_name=${provider}`,
      transformResponse: (response) => {
        return response?.regions || []
      }
    }),
    getGpuTypes: builder.mutation({
      query: ({
        cloudProviders, cloudRegions
      }) => ({
        url: '/cloud_provider/gpus',
        method: 'POST',
        body: cloudProviders.map(provider => ({
          name: provider,
          regions: cloudRegions.reduce((acc, region) => {
            if (region.indexOf(provider) === 0) {
              acc.push(region.split(':')[1])
            }

            return acc
          }, [])
        }))
      })
    }),
    getInstanceTypes: builder.mutation({
      query: ({
        cloudProviders, cloudRegions
      }) => ({
        url: '/cloud_provider/instance_types',
        method: 'POST',
        body: cloudProviders.map(provider => ({
          name: provider,
          regions: cloudRegions.reduce((acc, region) => {
            if (region.indexOf(provider) === 0) {
              acc.push(region.split(':')[1])
            }

            return acc
          }, [])
        }))
      })
    }),
    addCloudProvider: builder.mutation({
      query: (config) => ({
        url: '/cloud',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ['UserClouds']
    }),
    deleteCloudProvider: builder.mutation({
      query: (cloudId) => ({
        url: `/cloud?setup_id=${cloudId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['UserClouds']
    })
  })
})

export const {
  useGetUserCloudsQuery,
  useGetUserCloudSubscriptionsQuery,
  useLazyGetCloudProviderRegionsQuery,
  useGetGpuTypesMutation,
  useGetInstanceTypesMutation,
  useAddCloudProviderMutation,
  useDeleteCloudProviderMutation
} = cloudApi

export default cloudApi
