import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Row from './Row'
import RowArray from './RowArray'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import { FIELD_LABELS } from '../../../constants/uiConstants'

const VirtualMountInput = ({
  id,
  disabled,
  label,
  onChange,
  type,
  value: valueProp,
  openModal,
  desc
}) => {
  const handleChange = (oldKey, newVal) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    obj[newVal.key] = newVal.value
    onChange(obj)
  }

  const handleCreate = (newVal) => {
    const obj = {...valueProp}
    obj[newVal.key] = newVal.value
    onChange(obj)
  }

  const handleDelete = (oldKey) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    onChange(obj)
  }

  const RowComponent = useMemo(() => ({
    'text': Row,
    'array': RowArray
  }[type]), [type])

  return (
    <fieldset style={{margin:'8px'}}>
      <Typography variant="h5" sx={{display: 'flex', m: 1}}>
        {label}
      </Typography>
      <hr/>
      {/* <FormLabel component='legend' id={`${id}-label`}>{label}</FormLabel> */}
      <Grid container spacing={2} sx={{m: 0.5}}>
        <Grid item xs={10}>
          <Typography variant="h6">{FIELD_LABELS.NAME}</Typography>
        </Grid>
        
        {Object.entries(valueProp ?? {}).map(([key, value]) => 
        <RowComponent
          disabled={disabled}
          key={key}
          onChange={handleChange.bind(this, key)}
          onDelete={handleDelete.bind(this, key)}
          value={{ key, value }}
        />)}
        <RowComponent disabled={disabled} create={true} onChange={handleCreate} value={{}} openModal = {openModal}/>
      </Grid>
    </fieldset>
  )
}

VirtualMountInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['array', 'text']),
  value: PropTypes.object,
  desc: PropTypes.string
}

VirtualMountInput.defaultProps = {
  onChange: () => null,
  type: 'text'
}

export default VirtualMountInput
