import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DataGrid from '../../components/DataGrid'
import DeleteButton from '../../components/DeleteButton'
import LoadingButton from '@mui/lab/LoadingButton'
import LoadingOverlay from '../../components/LoadingOverlay'
import { BUTTON_LABELS, VIEW_HEADERS, VIEW_ROUTES } from '../../constants/uiConstants'
import { setError } from '../../datastore/UI/uiSlice'
import { Button } from '@mui/material'
import Modal from '../../components/Modal'
import AddIcon from '@mui/icons-material/Add';
import { useAddAccessKeysMutation, useDeleteAccessKeysMutation, useGetAccessKeysQuery } from '../../services/accessKeysApi'
import { useNavigate } from 'react-router-dom'

const AccessKeyView = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [toCreateKey, setCreateKey] = useState(null)
  const [deletingRow, setDeletingRow] = useState(null)
  const [data, setData] = useState([])
  const [newData, setNewData] = useState(null)
  const { data: fetchedData, error, isError, isLoading, isSuccess, refetch } = useGetAccessKeysQuery()
  const [deleteAccessKeys] = useDeleteAccessKeysMutation()

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData)
    }
  }, [fetchedData, isLoading])

  const rows = useMemo(() => {
    if (isSuccess && data && data.length !== 0) {
      return data.map((element, index) => ({
        id: index,
        key_id: "access_key_id",
        key_value: element["access_key_id"]
      }))
    }

    return []
  }, [data, isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(setError(error))
    }
  }, [dispatch, error, isError])
  
  const handleCreate = useCallback(() => {
    setCreateKey(false);
    // refetch();
    setTimeout(() => {
      navigate(`../${VIEW_ROUTES.ACCESS_KEYS}`, { replace: true });
    }, 2000)
  }, [navigate])
   /**
    * API to submit form data
    */
   const [submit, { isLoading: isAddAccessKeyLoading }] = useAddAccessKeysMutation();
  
   /**
    * Function to submit the form data to `submit` API
    * @param {object} formData
    */
   const getSecretKey = useCallback(
     (formData) => {
       submit(formData)
         .unwrap()
         .then((d) => {
           setTimeout(() => {
             setNewData(d);
             setCreateKey(true);
           });
         })
         .catch((error) => dispatch(setError(error)));
     },
     [dispatch, submit]
   );

   const handleDelete = useCallback((row) => {
    setDeletingRow(row)
    deleteAccessKeys(row.key_value)
      .unwrap()
      .then(() => {
        setData((state) => state.filter((item) => item._id !== row._id))
        setDeletingRow(null)
      })
      .catch(err => {
        setDeletingRow(null)
        dispatch(setError(err))
      })
  }, [deleteAccessKeys, dispatch])

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.ACCESS_KEYS}
      </Typography>
      <hr />
      <Container disableGutters maxWidth="lg" sx={{ marginBlockStart: 4 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ marginBlockEnd: 4 }}
        >
          {/* <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={getSecretKey}
          >
            <AddIcon />
            {BUTTON_LABELS.CREATE_ACCESS_KEY}
          </Button> */}
          <LoadingButton
              color='primary'
              loading={isAddAccessKeyLoading}
              onClick={getSecretKey}
              variant='contained'
            >
              <AddIcon />
              {BUTTON_LABELS.CREATE_ACCESS_KEY}
          </LoadingButton>
          
        </Stack>
        <Modal
          onClose={() => handleCreate()}
          open={!!toCreateKey}
          title={VIEW_HEADERS.VIEW_ACCESS_KEY}
        >
          <Typography variant="h6" gutterBottom component="div">
            Access Key ID:
          </Typography>
          <Typography variant="subtitle2" gutterBottom component="div">
            {newData?.client_id ?? ""}
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            Access Key Secret:
          </Typography>
          <Typography variant="subtitle2" gutterBottom component="div">
            {newData?.client_secret ?? ""}
          </Typography>
          <br />
          <hr />
          <Typography variant="caption" display="block" gutterBottom>
            <i>
              *Please save the Access Key Secret as it will not be revealed
              again
            </i>
          </Typography>
          <p></p>
        </Modal>
        {isLoading && <LoadingOverlay />}
        {isError && (
          <Alert severity="error">{error?.data?.detail?.[0]?.msg}</Alert>
        )}
        {isSuccess && (
          <DataGrid
            
            columns={[
              {
                field: "id",
                headerName: "Id",
                width: "100",
              },
              {
                field: "key_id",
                headerName: "Key Name",
                width: "200",
              },
              {
                field: "key_value",
                headerName: "Key Value",
                flex: 1,
              },
              {
                field: "actions",
                headerName: "Delete",
                renderCell: ({ row }) => {
                  return (
                    <DeleteButton
                      color="error"
                      loading={
                        deletingRow ? deletingRow._id === row._id : false
                      }
                      onClick={() => handleDelete(row)}
                      size="small"
                      variant="contained"
                      description="Are you sure you want to delete this AccessKeys?"
                    >
                      {BUTTON_LABELS.DELETE_BUTTON}
                    </DeleteButton>
                  );
                },
              },
            ]}
            rows={rows}
          />
        )}
      </Container>
    </>
  );
}

export default AccessKeyView
