import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'
import { useAuth0 } from "@auth0/auth0-react";


const {
  NODE_ENV,
  REACT_APP_BASE_API_URL,
  REACT_APP_GLOBAL_KEY
} = process.env

const baseUrl = NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : REACT_APP_BASE_API_URL

const artifactsApi = createApi({
  reducerPath: 'artifactsApi',
  ...platformAuthConfig(),
  endpoints: builder => ({
    getArtifacts: builder.query({
      query: () => '/artifacts',
      transformResponse: (response) => {
        const tree = {}
        function set(arr, treeObj) {
          let obj = treeObj
          arr.forEach((item) => {
            obj[item] = obj[item] || {}
            obj = obj[item]
          })
        }

        response.map((path, index) => {
          if(path.startsWith('sftp://')) {
            return [...path.split('///')[1].slice(4).split('/'), response[index]]
          } else {
            return [...path.split('//')[1].split('/'), response[index]]
          }
        }).forEach(paths => set(paths, tree))

        return tree
      }
    })
  })
})
/**
 * Function to fetch the artifact data for a given artifact uri
 * @param {string} artifactUri 
 * @returns {promise}
 */
export function getArtifact(artifactUri, bearer) {

  return fetch(`${baseUrl}/artifact?artifact=${artifactUri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      "authorization": `Bearer ${bearer}`
      // 'user-email': user.email,
      // 'global-key': REACT_APP_GLOBAL_KEY
    }
  }).then((response) => {
    if (response.ok) {
      return response.blob().then(function(myBlob) {
        const url = URL.createObjectURL(myBlob)
        return {
          blob: myBlob,
          href: url,
          fileName: artifactUri.split('/').pop()
        }
      });
    } else {
      return response.json().then(function(jsonError) {
        return {
          json: jsonError
        };
      });
    }
  })
  .catch((err) => {
    return {
      error: err
    }
  })
}

export const { useGetArtifactsQuery } = artifactsApi

export default artifactsApi
