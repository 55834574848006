import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Form from "../../components/Form";
import LoadingOverlay from '../../components/LoadingOverlay';
import { setError } from "../../datastore/UI/uiSlice";
import {
  useUpdatePermissionMutation,
  useGetUserPermissionsQuery,
} from "../../services/userApi";
import {
  EDIT_GT2_CACHE,
  EDIT_GT2_CACHE_DEFAULT_VALUE,
  EDIT_ARTIFACTS_STORAGE,
  EDIT_ARTIFACTS_STORAGE_DEFAULT_VALUE,
  EDIT_SECRETS,
  EDIT_SECRETS_DEFAULT_VALUE,
  EDIT_VIRTUAL_MOUNTS,
  EDIT_VIRTUAL_MOUNTS_DEFAULT_VALUE,
  PERMISSION_CLOUD_PROVIDER_CONSTANTS,
  PERMISSION_CLOUD_PROVIDER_DEFAULT_VALUE,
  PERMISSION_VIRTUAL_MOUNT_CONSTANTS,
  PERMISSION_VIRTUAL_MOUNT_DEFAULT_VALUE,
} from "../../constants/FormConstants/EditMemberPermConstants";
import { useGetArtifactStoragePathsQuery } from "../../services/artifactStorageApi";
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi";
import { useGetSecretsQuery } from "../../services/secretsApi";
import { useGetUserCloudsQuery } from "../../services/cloudApi";

const EditMemberView = ({ member, close }) => {

  const dispatch = useDispatch();
  const { data, error, isLoading, isError } =
    useGetUserPermissionsQuery(member?.id);
  const [postUpdatePermission, { isLoading: isLoadingUpdate }] = useUpdatePermissionMutation();

  // GT2 Cache Form
  const [formConfigGT2, setGT2Value] = useState(
    EDIT_GT2_CACHE
  );
  const {
    control: controlGT2,
    handleSubmit: handleSubmitGT2,
    setValue: setValueGT2,
  } = useForm({
    defaultValues: EDIT_GT2_CACHE_DEFAULT_VALUE,
  });
  // fetch data for current GT2 settings

  // Artifact Storage Form
  const [formConfigArtifact, setArtifactOptions] = useState(
    EDIT_ARTIFACTS_STORAGE
  );
  const {
    control: controlAS,
    handleSubmit: handleSubmitAS,
    setValue: setValueAS,
  } = useForm({
    defaultValues: EDIT_ARTIFACTS_STORAGE_DEFAULT_VALUE,
  });
  const { data: allArtifactStoragePaths } = useGetArtifactStoragePathsQuery();

  // Virtual Mounts Form
  const [formConfigVirtualMounts, setVirtualMountsOptions] = useState(EDIT_VIRTUAL_MOUNTS);
  const {
    control: controlVM,
    handleSubmit: handleSubmitVM,
    setValue: setValueVM,
  } = useForm({
    defaultValues: EDIT_VIRTUAL_MOUNTS_DEFAULT_VALUE,
  });
  const { data: allVirtualMounts } = useGetVirtualMountsQuery();

  // Secrets Form
  const [formConfigSecrets, setSecretsOptions] = useState(EDIT_SECRETS);
  const {
    control: controlS,
    handleSubmit: handleSubmitS,
    setValue: setValueS,
  } = useForm({
    defaultValues: EDIT_SECRETS_DEFAULT_VALUE,
  });
  const { data: allSecrets } = useGetSecretsQuery();

  // Cloud Provider Form
  const [formConfigCP, setCPOptions] = useState(
    PERMISSION_CLOUD_PROVIDER_CONSTANTS
  );
  const {
    control: controlCP,
    handleSubmit: handleSubmitCP,
    setValue: setValueCP,
  } = useForm({
    defaultValues: PERMISSION_CLOUD_PROVIDER_DEFAULT_VALUE,
  });
  const { data: allCPs } = useGetUserCloudsQuery();

  

  useEffect(() => {
    if (isError) {
      dispatch(setError(error));
    }
  }, [dispatch, error, isError]);

  /**
   * Set the options for fields from API data and dependent field values
   */
  useEffect(() => {
    if (allArtifactStoragePaths) {
      const allArtifactStoragePathsNames = allArtifactStoragePaths.map(
        (i) => i.name
      );
      function update(state) {
        return {
          ...state,
          [EDIT_ARTIFACTS_STORAGE.entity_ids.id]: {
            ...state[EDIT_ARTIFACTS_STORAGE.entity_ids.id],
            options: Array.isArray(allArtifactStoragePathsNames)
              ? allArtifactStoragePathsNames
              : [],
          },
        };
      }
      setArtifactOptions(update);
    }
  }, [allArtifactStoragePaths]);

  useEffect(() => {
    if (allSecrets) {
      let modifiedSecretArray = []
      for(var i = 0, len = allSecrets.length; i < len; i++) {
        modifiedSecretArray.push(allSecrets[i].replace(/^ENV_/, ''));
      }
      function update(state) {
        return {
          ...state,
          [EDIT_SECRETS.entity_ids.id]: {
            ...state[EDIT_SECRETS.entity_ids.id],
            options: Array.isArray(modifiedSecretArray) ? modifiedSecretArray : [],
          },
        };
      }
      setSecretsOptions(update);
    }
  }, [allSecrets]);

  useEffect(() => {
    if (allVirtualMounts) {
      const allVirtualMountsNames = allVirtualMounts.map(
        (i) => i.name
      );
      function update(state) {
        return {
          ...state,
          [EDIT_VIRTUAL_MOUNTS.entity_ids.id]: {
            ...state[EDIT_VIRTUAL_MOUNTS.entity_ids.id],
            options: Array.isArray(allVirtualMountsNames) ? allVirtualMountsNames : [],
          },
        };
      }
      setVirtualMountsOptions(update);
    }
  }, [allVirtualMounts]);

  useEffect(() => {
    if (allCPs) {
      const allCPsNames = allCPs.map((i) => i.cloud_provider);
      function update(state) {
        return {
          ...state,
          [PERMISSION_CLOUD_PROVIDER_CONSTANTS.entity_ids.id]: {
            ...state[PERMISSION_CLOUD_PROVIDER_CONSTANTS.entity_ids.id],
            options: Array.isArray(allCPsNames) ? allCPsNames : [],
          },
        };
      }
      setCPOptions(update);
    }
  }, [allCPs]);


  // Set current assigned values
  useEffect(() => {
    if (data) {
      
      // once the data for GT2 is available use it to set the current value for the switch

      if (data["ARTIFACTS_STORAGE"]) {
        setValueAS(
          EDIT_ARTIFACTS_STORAGE.entity_ids.id,
          data["ARTIFACTS_STORAGE"]
        );
      }

      if (data["SECRET"]) {
        let modifiedSecretArray = []
        for(var i = 0, len =  data["SECRET"].length; i < len; i++) {
          modifiedSecretArray.push( data["SECRET"][i].replace(/^ENV_/, ''));
        }
        setValueS(EDIT_SECRETS.entity_ids.id, modifiedSecretArray);
      }
      if (data["VIRTUAL_MOUNT"]) {
        setValueVM(EDIT_VIRTUAL_MOUNTS.entity_ids.id, data["VIRTUAL_MOUNT"]);
      }

      if (data["CLOUD_PROVIDER"]) {
        setValueCP(
          PERMISSION_CLOUD_PROVIDER_CONSTANTS.entity_ids.id,
          data["CLOUD_PROVIDER"]
        );
      }

      if (data["VIRTUAL_MOUNT"]) {
        setValueVM(
          PERMISSION_VIRTUAL_MOUNT_CONSTANTS.entity_ids.id,
          data["VIRTUAL_MOUNT"]
        );
      }

      if (data["GT2"]) {
        setValueGT2(
          EDIT_GT2_CACHE.entity_ids.id,
          data["GT2"].length > 0 ? true : false
        );
      }
    }
  }, [data, setValueAS, setValueVM, setValueS, setValueCP, setValueGT2]);

  const updatePermission = useCallback(
    (formData) => {
      for(var i = 0, len =  formData.entity_ids.length; i < len; i++) {
        formData.entity_ids[i] = (formData.entity_type === EDIT_SECRETS_DEFAULT_VALUE.entity_type ? formData.entity_ids[i].replace(/^/, 'ENV_'): formData.entity_ids[i]);
      }
      const data = {
        member_email: member.email,
        ...formData,
      };
      if(data['entity_type']==="GT2"){
        let gt2Status =  data['entity_ids']
        data['entity_ids'] = [];
        if(gt2Status){
          data['entity_ids'].push('enable');
        }
      }
      // console.log(data)
      postUpdatePermission(data)
        .unwrap()
        .then(() => {
          close();
        })
        .catch((error) => dispatch(setError(error)));
    },
    [member.email, postUpdatePermission, close, dispatch]
  );

  return (
    <Container disableGutters maxWidth="sm" sx={{ marginBlockStart: 4 }}>
      {isLoading && <LoadingOverlay/>}
      <h3>GT2 Cache</h3>
      <hr />
      <Form
        control={controlGT2}
        fields={formConfigGT2}
        submit={false}
        loading={isLoadingUpdate}
        onSubmit={handleSubmitGT2(updatePermission)}
        buttonLabel="Update GT2 Cache Permissions"
      ></Form>
      <br />
      <br />
      <h3>Artifact Storage</h3>
      <hr />
      <Form
        control={controlAS}
        fields={formConfigArtifact}
        submit={false}
        loading={isLoadingUpdate}
        onSubmit={handleSubmitAS(updatePermission)}
        buttonLabel="Update Artifact Storage Permissions"
      ></Form>
      <br />
      <br />
      <h3>Secrets</h3>
      <hr />
      <Form
        control={controlS}
        fields={formConfigSecrets}
        submit={false}
        loading={isLoadingUpdate}
        onSubmit={handleSubmitS(updatePermission)}
        buttonLabel="Update Secret Permissions"
      ></Form>
      <br />
      <br />
      <h3>Virtual Mounts</h3>
      <hr />
      <Form
        control={controlVM}
        fields={formConfigVirtualMounts}
        submit={false}
        loading={isLoadingUpdate}
        onSubmit={handleSubmitVM(updatePermission)}
        buttonLabel="Update Virtual Mount Permissions"
      ></Form>
      <br />
      <br />
      <h3>Cloud Provider</h3>
      <hr />
      <Form
        control={controlCP}
        fields={formConfigCP}
        submit={false}
        loading={isLoadingUpdate}
        onSubmit={handleSubmitCP(updatePermission)}
        buttonLabel="Update Cloud Provider Permissions"
      ></Form>
    </Container>
  );
};

export default EditMemberView;
