import { deepFreeze } from '../../utils'

export const EMPTY_OPTION_LABEL = 'None'

// Input types
export const INPUT_TYPES = {
  BOOLEAN: 'BOOLEAN',
  FILE: 'FILE',
  CONFIG_FILE: 'CONFIG_FILE',
  MULTI_LINE_ARRAY: 'MULTI_LINE_ARRAY',
  MULTI_SELECT: 'MULTI_SELECT',
  NESTED_OBJECT: 'NESTED_OBJECT',
  NUMBER: 'NUMBER',
  OBJECT: 'OBJECT',
  OBJECT_ARRAY: 'OBJECT_ARRAY',
  VIRTUAL_MOUNT_ARRAY: 'VIRTUAL_MOUNT_ARRAY',
  SELECT: 'SELECT',
  STRING: 'STRING',
  TUNING: 'TUNING'
}
deepFreeze(INPUT_TYPES)