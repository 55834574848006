import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Global } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Navigate, Routes, Route } from 'react-router-dom'
import ErrorPopup from './components/ErrorPopup'
import LeftMenuBar from './components/LeftMenuBar'
import TopMenuBar from './components/TopMenuBar'
import SimpleJobView from './views/SimpleJobView'
import LaunchSimpleJobView from './views/SimpleJobView/LaunchSimpleJobView';
import ZeroLabelJobView from './views/ZeroLabelJobView'
import LaunchZeroLabelJobView from './views/ZeroLabelJobView/LaunchZeroLabelJobView';
import ExperimentsView from './views/ExperimentsView'
import HyperparameterTuningView from './views/HyperparameterTuningView'
import LaunchHPTView from './views/HyperparameterTuningView/LaunchHPTView'
import LaunchExperimentView from './views/ExperimentsView/LaunchExperimentView'
import JobsView from './views/JobView'
import WorkStationView from './views/WorkStationsView'
import OverallWorkStationView from './views/WorkStationsView/WorkStationView'
import CreateWorkstationView from './views/WorkStationsView/CreateWorkstationView'
import AdminView from './views/AdminView'
import SecretsView from './views/AdminView/SecretsView'
import AccessKeyView from './views/AccessKeyView'
import UsageView from './views/UsageView'
import JobUsageView from './views/UsageView/JobUsageView'
import { VIEW_ROUTES } from './constants/uiConstants'
import globalStyles from './styles/global'
import theme from './styles/theme'
import { useAuth0 } from '@auth0/auth0-react'
import LandingView from './views/LandingView'
import { setUser, setPlatformBearer, setStUser } from './datastore/userSlice'
import ArtifactsStorageView from './views/AdminView/ArtifactsStorageView'
import { useVerifyUserMutation } from './services/userApi'
import MemberView from './views/MemberView'
import VirtualMountView from './views/AdminView/VirtualMountView'
import TemplatesView from './views/TemplatesView'
import LaunchWorkStationTemplateView from './views/TemplatesView/LaunchWorkstationTemplate'
import LaunchExperimentTemplateView from './views/TemplatesView/LaunchExperimentTemplate'
import LaunchHPTTemplateView from './views/TemplatesView/LaunchHPTTemplate'
import BillingView from './views/AdminView/BillingView';
import LaunchSimpleJobTemplateView from './views/TemplatesView/LaunchSimpleJobTemplateView';
import * as Sentry from "@sentry/browser";

const App = () => {
  const dispatch = useDispatch()
  const themeMode = useSelector((state) => state.ui.themeMode)
  const userEmail = useSelector(state => state.userState.user?.email)
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const platformBearer = useSelector((state) => state.userState.platformBearer)
  const [ methName, { data: stUser, isLoading: isUserVerified, isSuccess }] = useVerifyUserMutation();
  const isAdmin = useSelector((state) => state.userState.stUser?.user_metadata?.is_admin);
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#9967DD',
        light: '#AD85E3',
      },
      secondary: {
        main: '#9967DD',
      },
      background: {
        default: '#181818',
        paper: '#313131',
      },
      success: {
        main: '#56C0AB',
        light: '#ACEFCE',
      }
    },
    zIndex: {
      drawer: 1150,
      modal: 1250
    },
    typography: {
      fontFamily: 'Space Grotesk',
    },
  });
  useEffect(
    async () => {
      if(!isLoading && isAuthenticated) {
        // console.log(platformBearer)
        dispatch(setUser(user))
        Sentry.setUser({ email: user.email });
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:all"
        });
        dispatch(setPlatformBearer(token))
        methName();
      }
    }, [user, isAuthenticated, isLoading, dispatch, platformBearer]
  );

  useEffect(() => {
    if(isSuccess) {
      dispatch(setStUser(stUser))
    }
  }, [stUser, isUserVerified, isSuccess, isAdmin]);

  if(isLoading) {
    return <div>Loading ...</div>;
  }
  
  return (
    // console.log(isAuthenticated, userEmail, platformBearer),
    <>
    {!isAuthenticated && !userEmail && !platformBearer && (<LandingView />)}
    {isAuthenticated && userEmail && platformBearer && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Global styles={globalStyles} />
          <Box sx={{ display: 'flex' }}>
            <TopMenuBar />
            <LeftMenuBar close />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
              <Toolbar />
              <Routes>
                {isAdmin && (
                  <>
                    <Route path={VIEW_ROUTES.ADMIN} element={<AdminView />} />
                    <Route path={VIEW_ROUTES.CLOUD_PROVIDERS} element={<AdminView />} />
                    <Route path={VIEW_ROUTES.SECRETS} element={<SecretsView />} />
                    <Route path={VIEW_ROUTES.VIRTUAL_MOUNTS} element={<VirtualMountView />} />
                    <Route path={VIEW_ROUTES.ARTIFACTS_STORAGE} element={<ArtifactsStorageView />} />
                    <Route path={VIEW_ROUTES.VIRTUAL_MOUNTS} element={<VirtualMountView />} />
                    <Route path={VIEW_ROUTES.BILLING} element={<BillingView />} />
                    <Route path={VIEW_ROUTES.MEMBERS} element={<MemberView />} />
                  </>
                )}
                <Route path={VIEW_ROUTES.JOBS} element={<SimpleJobView />} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_JOB} element={<LaunchSimpleJobView type='new'/>} />
                <Route path={VIEW_ROUTES.RETRY_JOB} element={<LaunchSimpleJobView type='retry'/>} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_JOB_WITH_TEMPLATE} element={<LaunchSimpleJobView type='templating'/>} />
                <Route path={VIEW_ROUTES.VIEW_JOB} element={<JobsView type='simple-job' />} />
                <Route path={VIEW_ROUTES.ZERO_LABEL_JOBS} element={<ZeroLabelJobView />} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_ZERO_LABEL_JOB} element={<LaunchZeroLabelJobView type='new'/>} />
                <Route path={VIEW_ROUTES.EXPERIMENTS} element={<ExperimentsView />} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_EXPERIMENT} element={<LaunchExperimentView type='new'/>} />
                <Route path={VIEW_ROUTES.RETRY_EXPERIMENT} element={<LaunchExperimentView type='retry'/>} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_EXPERIMENT_WITH_TEMPLATE} element={<LaunchExperimentView type='templating'/>} />
                <Route path={VIEW_ROUTES.VIEW_EXPERIMENT} element={<JobsView type='experiment' />} />
                <Route path={VIEW_ROUTES.WORKSTATIONS} element={<WorkStationView />} />
                <Route path={VIEW_ROUTES.CREATE_NEW_WORKSTATIONS} element={<CreateWorkstationView type='new'/>} />
                <Route path={VIEW_ROUTES.CREATE_NEW_WORKSTATIONS_WITH_TEMPLATE} element={<CreateWorkstationView type='templating'/>} />
                <Route path={VIEW_ROUTES.RELAUNCH_WORKSTATIONS} element={<CreateWorkstationView type='retry'/>} />
                <Route path={VIEW_ROUTES.VIEW_WORKSTATION} element={<OverallWorkStationView />} />
                <Route path={VIEW_ROUTES.HYPERPARAMETER_TUNING} element={<HyperparameterTuningView />} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB} element={<LaunchHPTView type='new'/>} />
                <Route path={VIEW_ROUTES.LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB_WITH_TEMPLATE} element={<LaunchHPTView type='templating'/>} />
                <Route path={VIEW_ROUTES.RELAUNCH_HYPERPARAMETER_TUNING_JOB} element={<LaunchHPTView type='retry'/>} />
                <Route path={VIEW_ROUTES.VIEW_HYPERPARAMETER_TRAINING} element={<JobsView type='training' />} />
                <Route path={VIEW_ROUTES.TEMPLATES} element={<TemplatesView />} />
                <Route path={VIEW_ROUTES.CREATE_WORKSTATION_TEMPLATE} element={<LaunchWorkStationTemplateView type='new'/>} />
                <Route path={VIEW_ROUTES.VIEW_WORKSTATION_TEMPLATE} element={<LaunchWorkStationTemplateView type='templating'/>} />
                <Route path={VIEW_ROUTES.CREATE_EXPERIMENT_TEMPLATE} element={<LaunchExperimentTemplateView type='new'/>} />
                <Route path={VIEW_ROUTES.VIEW_EXPERIMENT_TEMPLATE} element={<LaunchExperimentTemplateView type='templating'/>} />
                <Route path={VIEW_ROUTES.CREATE_HPT_TEMPLATE} element={<LaunchHPTTemplateView type='new'/>} />
                <Route path={VIEW_ROUTES.VIEW_HPT_TEMPLATE} element={<LaunchHPTTemplateView type='templating'/>} />
                <Route path={VIEW_ROUTES.CREATE_SIMPLE_JOB_TEMPLATE} element={<LaunchSimpleJobTemplateView type='new'/>} />
                <Route path={VIEW_ROUTES.VIEW_SIMPLE_JOB_TEMPLATE} element={<LaunchSimpleJobTemplateView type='templating'/>} />
                <Route path={VIEW_ROUTES.ACCESS_KEYS} element={<AccessKeyView />} />
                <Route path={VIEW_ROUTES.USAGE} element={<UsageView />} />
                <Route path={VIEW_ROUTES.VIEW_JOB_USAGE} element={<JobUsageView />} />
                {/* <Route path='*' element={<Navigate to={VIEW_ROUTES.EXPERIMENTS} replace />} /> */}
              </Routes>
            </Box>
          </Box>
          <ErrorPopup />
        </ThemeProvider>
      )}
      </>
  )
}

export default App
