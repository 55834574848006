import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Delete from '@mui/icons-material/Delete'
import Save from '@mui/icons-material/Save'
import Select from '../Select'
import { ERROR_MESSAGES } from '../../../constants/uiConstants'

const Row = ({
  create,
  disabled,
  onChange,
  onDelete,
  options,
  value: {
    key: keyProp,
    value: valueProp,
    type: typeProp
  }
}) => {
  const [key, setKey] = useState(keyProp ?? '')
  const [value, setValue] = useState(Array.isArray(valueProp) ? JSON.stringify(valueProp) : '')
  const [valueError, setValueError] = useState(false)
  const [type, setType] = useState(typeProp ?? '')

  useEffect(() => {
    setKey(keyProp ?? '')
    setValue(Array.isArray(valueProp) ? JSON.stringify(valueProp) : '')
    setType(typeProp ?? '')
  }, [keyProp, valueProp, typeProp])

  const getValueArray = () => {
    let valueArr = null
    try {
      valueArr = JSON.parse("[" + value + "]")
      if (!(Array.isArray(valueArr) && valueArr.length > 0)) {
        throw new Error()
      }
    } catch (err) {
      setValueError(true)
      return null
    }

    return valueArr
  }

  const onPropertyBlur = () => {
    if (!create) {
      const valueArr = getValueArray()
      if (key.trim().length > 0) {
        onChange({
          key,
          value: valueArr,
          type
        })
      } else {
        setKey(keyProp)
      }
    }
  }

  const onValueBlur = () => {
    if (!create) {
      const valueArr = getValueArray()
      if (valueArr) {
        onChange({
          key,
          value: valueArr,
          type
        })
      } else {
        setValueError(false)
        setValue(JSON.stringify(valueProp))
      }
    }
  }

  const onSave = () => {
    const valueArr = getValueArray()
    if (create && key.trim().length > 0 && valueArr && type) {
      onChange({
        key,
        value: valueArr,
        type
      })

      setKey('')
      setValue('')
      setType('')
    }
  }

  return (
    <>
      <Grid item xs={3}>
        <TextField
          data-testid='key_for_search_space'
          disabled={disabled}
          fullWidth
          onBlur={onPropertyBlur}
          onChange={(event) => setKey(event.target.value)}
          size='small'
          value={key}
          variant='outlined'
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          data-testid='type_for_search_space'
          disabled={disabled}
          fullWidth
          onChange={(event) => {
            setType(event.target.value)
            if (!create) {
              onChange({
                key,
                value: getValueArray(),
                type: event.target.value
              })
            }
          }}
          options={options}
          value={type}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          data-testid='value_for_search_space'
          disabled={disabled}
          error={valueError}
          fullWidth
          helperText={valueError ? ERROR_MESSAGES.ARRAY_FIELD_ERROR : ''}
          onBlur={onValueBlur}
          onChange={(event) => {
            setValue(event.target.value)
            if (valueError) {
              setValueError(false)
            }
          }}
          size='small'
          value={value}
          variant='outlined'
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton disabled={disabled} data-testid='button_to_add_search_space' onClick={() => create ? onSave() : onDelete()}>
          {create ? <Save color='primary' /> : <Delete color='primary' />}
        </IconButton>
      </Grid>
    </>
  )
}

Row.propTypes = {
  create: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object
}

Row.propTypes = {
  onChange: () => null,
  onDelete: () => null
}

export default Row
