import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import DataGrid from "../../components/DataGrid";
import Typography from '@mui/material/Typography'
import { STATUS_COLOR_MAP } from '../../constants/uiConstants'
import Container from '@mui/material/Container'

const JobsList = ({
  data,
  onClick,
  view
}) => {
  const theme = useTheme()
  return (
    <>
      {!view ?
        <Container disableGutters>
          <Box>
            <DataGrid onRowClick={(params) => onClick(params.row.id)}
              
              sx={{
                  border: 1, // also tried setting to none 
                  borderRadius: 2,
                  
                  minWidth: 300,
                  '.MuiDataGrid-columnSeparator': {
                    color: 'primary.light'
                  },
                  
              }}
              hideFooterPagination
              hideFooterSelectedRowCount
              columns={[
                {
                  field: 'id',
                  headerName: 'Job ID',
                  renderCell: ({ value }) => {
                    return <div>{value ?? 'None'}</div>
                  },
                  flex: 1,
                },
                {
                  field: 'start_time',
                  headerName: 'Start Time',
                  flex: 1,
                },
                {
                  field: 'end_time',
                  headerName: 'End Time',
                  flex: 1,
                },
                {
                  field: 'cost',
                  headerName: 'Cost',
                  renderCell: ({ value }) => {
                    return <div style={{color: '#56C0AB'}}>${Math.round(value*1000)/1000}</div>
                  },
                  flex: 1,
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  renderCell: ({ value }) => {
                    return <Chip key={value} 
                                label={value}
                                sx={{
                                  transition: 'all .2s ease-in-out',
                                  borderColor: theme.palette.primary.dark,
                                  backgroundColor: theme.palette.primary.dark,  
                                }}
                            />
                  },  
                  flex: 1,
                }
              ]}
              rows={data}
            />
          </Box>
        </Container>
      :
        <Grid container spacing={2}>
          {data.map(({
            id,
            cost,
            end_time,
            name,
            start_time,
            status
          }) => (
            <Grid item key={`job-${id}`} sm={12} md={4} lg={3}>
                <Card sx={{backgroundColor: '#292929', borderRadius: '20px'}} raised>
                  <CardActionArea onClick={() => onClick(id)}>
                    {STATUS_COLOR_MAP[status] === 'default' ? 
                      <CardHeader
                          avatar={<Chip sx={{backgroundColor: '#181818', color: '#727272'}} label={status} />}
                      />
                      :
                      <CardHeader
                        avatar={<Chip color={STATUS_COLOR_MAP[status]} label={status} />}
                      />
                    }
                    <CardContent>
                      <Typography gutterBottom sx={{color: '#ABABAB'}}><b>Job ID</b></Typography>
                      <Typography gutterBottom sx={{color: '#9967DD'}}><b>{name}</b></Typography>
                      <Grid container spacing={2}>
                          <Grid item>
                            <Typography gutterBottom sx={{color: '#ABABAB'}}>Started</Typography>
                          </Grid>
                          <Grid item>
                            <Typography gutterBottom>{new Date(start_time).toLocaleString()}</Typography>
                          </Grid>
                      </Grid>
                      {!!end_time && 
                        <Grid container spacing={2}>
                          <Grid item>
                          <Typography gutterBottom sx={{color: '#ABABAB'}}>Ended</Typography>
                          </Grid>
                          <Grid item>
                            <Typography gutterBottom>{new Date(end_time).toLocaleString()}</Typography>
                          </Grid>
                        </Grid>
                      }
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography gutterBottom sx={{color: '#ABABAB'}}>Cost</Typography>
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom sx={{color: '#56C0AB'}}>${cost.toFixed(2)}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>
          ))}
        </Grid>
      }
    </>
  )
}

JobsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  view: PropTypes.bool
}

JobsList.defaultProps = {
  data: [],
  onClick: () => null,
  view: 1
}

export default JobsList
