import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const TemplatesList = ({
  data,
  onClick
}) => {
  return (
    <Grid container spacing={2}>
      {data.map(({
        id,
        name,
        type
      }) => (
        <Grid item key={`job-${id}`} sm={12} md={4} lg={3}>
          <Card sx={{backgroundColor: '#292929', borderRadius: '20px'}}raised>
            <CardActionArea onClick={() => onClick(type,id)}>
              <CardHeader
                avatar={<Chip color={'primary'} label={type === "SIMPLE_JOB" ? 'JOB' : type} />}
              />
              <CardContent>
                <Typography gutterBottom><b>Name: {name}</b></Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

TemplatesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
}

TemplatesList.defaultProps = {
  data: [],
  onClick: () => null
}

export default TemplatesList
