import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DeleteButton from '../../components/DeleteButton'
import Table from '../../components/Table'
import { STATUS_COLOR_MAP, DELETE_DISABLED_STATUS, STOP_JOB_DISABLED_STATUS, VIEW_ROUTES } from '../../constants/uiConstants'
import { setError } from '../../datastore/UI/uiSlice'
import { getEllapsedTime, getGPUsRunning } from '../../utils'
import DataGrid from "../../components/DataGrid";

import {
  JOB_TYPE_EXPERIMENT
} from '../../constants/FormConstants/LaunchJobFormConstants'
const JobUsageView = ({
  data
}) => {

  return (
    <Container disableGutters>
      <Box>
        <DataGrid
          sx={{
            border: 0, // also tried setting to none 
            borderRadius: 2,
            p: 2,
            minWidth: 300,
          }}
          hideFooterPagination
          hideFooterSelectedRowCount
          columns={[
            {
              field: 'entity_id',
              headerName: 'VM Name',
              renderCell: ({ value }) => {
                return <div>{value ?? ''}</div>
              },
              flex: 1,
            },
            {
              field: 'gpu_type',
              headerName: 'GPU Type',
              renderCell: ({ value }) => {
                return <div>{value ?? 'None'}</div>
              },
              flex: 1,
            },
            {
              field: 'gpu_count',
              headerName: 'GPU Count',
              flex: 1,
            },
            {
              field: 'hours',
              headerName: 'Hours',
              renderCell: ({ value }) => {
                const hours = Math.floor(((value) * 60) / 60)
                const minutes = Math.floor(((value) * 60) % 60)
                const seconds = Math.floor(((value) * 60 * 60) % 60)
                return <div><b>{hours != 0 ? `${hours} hrs` : ''} {minutes != 0 ? `${minutes} mins` : ''} {seconds != 0 ? `${seconds} secs` : ''}</b></div>
              },
              flex: 1,
            },
            {
              field: 'cost',
              headerName: 'Cloud Cost',
              flex: 1,
              renderCell: ({value})=>{
                return <div>${Math.round(value * 1000) / 1000}</div>
              }
            },
            {
              field: 'platform_cost_calculated',
              headerName: 'Scaletorch Cost',
              renderCell: ({ row }) => {
                return <div>${Math.round((row.hours * row.gpu_count * 0.3) * 1000) / 1000}</div>
              },
              flex: 1,
              align:'right'
            },
          ]}
          rows={data?.nodes}
        />
      </Box>
    </Container>
  )
}

JobUsageView.propTypes = {
  data: PropTypes.object
}

JobUsageView.defaultProps = {
  data: {}
}

export default JobUsageView
