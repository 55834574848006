import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import FormFields from './FormFields'
import { BUTTON_LABELS } from '../../constants/uiConstants'

const Form = ({
  children,
  control,
  fields,
  loading,
  onSubmit,
  buttonLabel,
  modalOnly,
  submitAtEnd
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Stack direction='column' spacing={4}>
        <FormFields control={control} fields={fields} />
        {children}
        {!submitAtEnd && modalOnly && <LoadingButton sx={{borderRadius: '20px'}} role='SUBMIT' data-testid='submit' loading={loading} type='submit' variant='contained'>{buttonLabel || BUTTON_LABELS.SUBMIT_BUTTON}</LoadingButton>}
        {!submitAtEnd && !modalOnly && <LoadingButton sx={{borderRadius: '20px'}} role='SUBMIT' data-testid='submit' loading={loading} type='button' onClick={onSubmit} variant='contained'>{buttonLabel || BUTTON_LABELS.SUBMIT_BUTTON}</LoadingButton>}
      </Stack>
    </form>
  )
}

Form.propTypes = {
  control: PropTypes.object,
  fields: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  modalOnly: PropTypes.bool
}

Form.defaultProps = {
  fields: {},
  loading: false,
  onSubmit: () => null,
  modalOnly: true
}

export default Form
