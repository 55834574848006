import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography'
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit';
import { BUTTON_LABELS, VIEW_HEADERS } from '../../constants/uiConstants'
import { useGetSimpleJobsQuery } from '../../services/jobsApi'
import LoadingOverlay from '../../components/LoadingOverlay'
import JobsList from '../../components/JobsList'
import MultiSelect from '../../components/Form/MultiSelect'
import { setError } from '../../datastore/UI/uiSlice'
import { setExperimentFilter } from '../../datastore/filterSlice'
import { STATUS_OPTIONS_FOR_JOBS } from '../../constants/uiConstants'
import Modal from "../../components/Modal"
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useGetTemplatesQuery } from '../../services/templatesApi'
import FormFields from '../Form/FormFields'
import {
  IMPORT_YML
} from '../../constants/FormConstants/SimpleJobFormConfig'

const CreateNewView = ({type, showDetails, setName, navigateTo, setYamlConfig}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filter = useSelector((state) => state.filters.experiments)
  const { data: fetchedTemplateData, isLoading: isLoadingTemplates } = useGetTemplatesQuery()
  const [template, setTemplate] = React.useState(null)
  const [showTemplates, setShowTemplates] = React.useState(false)
  const [jobName, setJobName] = React.useState('')
  const handleChange = (event) => {
    setTemplate(event.target.value);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    reset
  } = useForm({
    defaultValues: {config: ""}
  })
  /**
   * Create watchers on specific fields
   */
  const watchConfigFileUpload = watch('config')

  useEffect(() => {
    if (watchConfigFileUpload){
      continueWithYml(watchConfigFileUpload)
    }
  },[watchConfigFileUpload])

  const templateData = useMemo(() => {
    if (Array.isArray(fetchedTemplateData)) {
      let filteredFetchedTemplateData = fetchedTemplateData.filter((item) => item.type === type)
      let filteredTemplateIds = filteredFetchedTemplateData.map(v => (v));
      if (filteredTemplateIds.length > 0){
        setTemplate(filteredTemplateIds[0].name ?? '')
      }else{
        setTemplate('')
      }
      return filteredTemplateIds;
    }
    setTemplate('Create from scratch')
    return ['Create from scratch']
  }, [fetchedTemplateData])

  const createFromScratch = () => {
    setName(jobName)
    showDetails(true)
    
  }

  const continueWithTemplate = () => {
    setShowTemplates(true)
  }

  const confirmTemplate = () => {
    setName(jobName)
    if(type==='WORKSTATION'){
      navigateTo(`../work-stations/new/${template}`, { replace: true })
    }else if(type==='EXPERIMENT'){
      navigateTo(`../experiments/new/${template}`, { replace: true })
    }else if(type==='SIMPLE_JOB'){
      navigateTo(`../simple-jobs/new/${template}`, { replace: true })
    }else if(type==='ZERO_LABEL_JOB'){
      navigateTo(`../zero-label-jobs/new/${template}`, { replace: true })
    }else
      navigateTo(`../hyperparameter-tuning/new/${template}`, { replace: true })
  }

  const continueWithYml = (file) => {
    setName(jobName)
    setYamlConfig(file)
    showDetails(true)
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {type === 'WORKSTATION' ? VIEW_HEADERS.WORKSTATIONS :
         type === 'EXPERIMENT' ? VIEW_HEADERS.EXPERIMENTS :
         type === 'SIMPLE_JOB' ? VIEW_HEADERS.JOBS :
         type === 'ZERO_LABEL_JOB' ? VIEW_HEADERS.ZERO_LABEL_JOBS :
         VIEW_HEADERS.HYPERPARAMETER_TUNING
        }
      </Typography>
      <hr />
      <br />
      {isLoadingTemplates && <LoadingOverlay />}
      <Box sx={{marginBlockEnd: 4, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Stack direction="column" spacing={2} sx={{ width: 750}}>
            <FormControl sx={{ m: 1 }} variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-job-name">Enter Name</InputLabel>
            <OutlinedInput
                id="outlined-adornment-job-name"
                type='text'
                endAdornment={
                <InputAdornment position="end">
                    <EditIcon />
                </InputAdornment>
                }
                label="Enter Job Name"
                onChange={(event) => {
                  setJobName(event.target.value)               
                }
              }
            />
            </FormControl>
            {showTemplates && 
                <>
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="template-select">Select</InputLabel>
                        <Select
                        sx={{ m: 1 }}
                        label='Type'
                        labelId="template-select"
                        id="demo-simple-select-filled"
                        onChange={handleChange}
                        value={template}
                        >
                        {Array.isArray(templateData) && templateData.length > 0 && templateData.map(item => (
                        <MenuItem
                        key={item.name}
                        value={item.id}
                        >
                        {item.name}
                        </MenuItem>
                    ))}
                        </Select>
                    </FormControl>
                    <Button
                        fullWidth={false}
                        variant="outlined"
                        color="secondary"
                        style={{margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        onClick={() => confirmTemplate()}
                        endIcon={<NavigateNextIcon style={{ position: "absolute", bottom: 9, right: 5 }} />}
                    >
                        Confirm Template
                    </Button>
                </>
            }
            {!showTemplates &&
                <>
                    <Button
                        fullWidth={false}
                        variant="outlined"
                        color="secondary"
                        style={{margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        onClick={() => createFromScratch()}
                        endIcon={<NavigateNextIcon style={{ position: "absolute", bottom: 9, right: 5 }}/>}
                    >
                        Create From Scratch
                    </Button>
                    <Button 
                        variant="outlined" 
                        fullWidth={false}
                        color="secondary"
                        style={{margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        onClick={() => continueWithTemplate()}
                        endIcon={<NavigateNextIcon style={{ position: "absolute", bottom: 9, right: 5 }}/>}
                    >
                        Continue With Template
                    </Button>
                    <FormFields control={control} fields={IMPORT_YML} />
                </>
            }
        </Stack>
      </Box>
    </>
  );
}

export default CreateNewView
