import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import YAML from 'yaml'
import yaml from 'js-yaml'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import Modal from "../../components/Modal"
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Tabs from '@mui/material/Tabs'
import Divider from '@mui/material/Divider'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import Form from '../../components/Form'
import { LoadingButton } from '@mui/lab'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay"
import AddVirtualMountView from "./AddVirtualMountView"
import AddAsTemplate from "./AddAsTemplate"
import { DIALOG_LABELS, VIEW_HEADERS, VIEW_ROUTES } from '../../constants/uiConstants'
import { uniqueNamesGenerator, adjectives, names } from 'unique-names-generator';
import {
  TAB_CONFIGS,
  CLOUD_PROVIDER_FIELDS_CONFIG,
  TEMPLATE_FIELDS
} from '../../constants/FormConstants/BaseFormConstants.js'
import {
  WS_CONTROL_CONFIGS,
  CUSTOM_IMAGE_CREDS_CONFIG,
  CREATE_WORKSTATION_FORM_DEFAULT_VALUE,
  WS_MANDATORY_FIELDS_CONFIG,
  MAX_CPU_WORKERS_CONFIG,
  WS_CPU_FIELDS_CONFIG,
  WS_GPU_FIELDS_CONFIG,
  WS_ADVANCE_FIELD_CONFIG,
  IMPORT_YML,
  NOT_BARE_BONE_FIELDS,
  ARTIFACTS_DESTINATION,
  VIRTUAL_MOUNT,
  VISUALISATION_CONFIG,
  CUDA_CONFIG,
  ENVIRONMENT_VAR
} from "../../constants/FormConstants/CreateWorkstationFormConstants";
import { useGetTemplatesQuery, useCreateTemplateMutation } from '../../services/templatesApi'
import { useCreateWorkstationMutation, useGetWorkstationQuery } from "../../services/workstationApi";
import {
  useGetVisualizationQuery,
  useRegisterVisualizationMutation
} from '../../services/visualizationApi'
import {
  useGetUserCloudSubscriptionsQuery,
  useGetGpuTypesMutation,
} from "../../services/cloudApi";
import { deletePropertyPath } from "../../utils";
import { setError } from '../../datastore/UI/uiSlice'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"
import { useGetSecretsQuery } from '../../services/secretsApi'

import { validatorFactory } from "../../utils/validator.js";
import { workstationSchema } from "../../utils/schema.js";

import CreateWorkstationForm from "./CreateWorkStationForm";
import CreateNewView from '../../components/CreateNewView';

const wksValidation = validatorFactory(workstationSchema)

const CreateWorkstationView = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState(false)
  const [name, setName] = useState('')
  const [skip, setSkip] = useState(type === 'new' ? false : true)
  const [watchConfigFileUpload, setWatchConfigFileUpload] = useState(null)
  /**
   * Get user cloud providers
   */
  const { data: cloudSubscriptionsData, error: cloudSubscriptionsApiError } =
    useGetUserCloudSubscriptionsQuery();

  /**
   * Get GPU types
   */
  const [getGpuTypes, { data: gpuTypesData }] = useGetGpuTypesMutation();
  /**
   * Get Templates
   */
  const {
    data: allTemplates, isLoading: isLoadingAllTemplates
  } = useGetTemplatesQuery()

  /**
   * Get Visualisation keys
   */
  const {
    data: allVizKeys
  } = useGetVisualizationQuery()

  const {
    data: artifactsStoragePaths
  } = useGetArtifactStoragePathsQuery()

  /**
   * Get Virtual Mounts
   */
  const {
    data: virtualMounts
  } = useGetVirtualMountsQuery()

  /**
   * Get Secret keys
   */
  const {
    data: allSecretKeys
  } = useGetSecretsQuery('TEMPLATE')
  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useCreateWorkstationMutation();
  const [createTemplate, { isLoading: isLoadingCreateTemplate }] = useCreateTemplateMutation()
  const { data: wk_data, isLoading: wkDataIsLoading } = useGetWorkstationQuery(location.pathname.split('/').pop(), { skip: type === "retry" ? false : true })

  const navigateTo = (base) => {
    setSkip(true)
    navigate(base)
  }

  return (
    <>
      {(!skip && !showDetails) ?
        <CreateNewView
          type='WORKSTATION'
          showDetails={setShowDetails}
          setName={setName}
          navigateTo={navigateTo}
          setYamlConfig={setWatchConfigFileUpload}
        /> 
      :
        <CreateWorkstationForm
          watchConfigFileUpload={watchConfigFileUpload}
          cloudSubscriptionsData={cloudSubscriptionsData}
          allSecretKeys={allSecretKeys}
          allTemplates={allTemplates}
          getGpuTypes={getGpuTypes}
          gpuTypesData={gpuTypesData}
          cloudSubscriptionsApiError={cloudSubscriptionsApiError}
          artifactsStoragePaths={artifactsStoragePaths}
          virtualMounts={virtualMounts}
          allVizKeys={allVizKeys}
          wk_data={wk_data}
          createTemplate={createTemplate}
          submit={submit}
          isLoading={isLoading}
          isLoadingCreateTemplate={isLoadingCreateTemplate}
          wkDataIsLoading={wkDataIsLoading}
          isLoadingAllTemplates={isLoadingAllTemplates}
          dispatch={dispatch}
          navigate={navigate}
          type={type}
          name={name}
        />
      }
    </>
  );
};
CreateWorkstationView.propTypes = {
  type: PropTypes.string
}

CreateWorkstationView.defaultProps = {
  type: 'new'
}
export default CreateWorkstationView;
