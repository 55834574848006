import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Row from './Row'
import RowArray from './RowArray'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import { FIELD_LABELS } from '../../../constants/uiConstants'

const ObjectInput = ({
  id,
  disabled,
  label,
  onChange,
  type,
  value: valueProp,
  desc,
  keyLabel,
  valueLabel,
  object_type
}) => {
  const [valueError,setValueError] = useState(false)
  const handleChange = (oldKey, newVal) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    obj[newVal.key] = newVal.value
    onChange(obj)
  }

  const handleCreate = (newVal) => {
    const obj = {...valueProp}
    obj[newVal.key] = newVal.value
    onChange(obj)
  }

  const handleDelete = (oldKey) => {
    const obj = {...valueProp}
    delete obj[oldKey]
    onChange(obj)
  }

  const RowComponent = useMemo(() => ({
    'text': Row,
    'array': RowArray
  }[type]), [type])

  return (
    <fieldset style={{width:'100%', margin:'8px'}}>
      {/* <FormLabel component='legend' id={`${id}-label`}>{label} </FormLabel> */}
      <Grid container spacing={2} sx={{mt: '1px'}}>
        <Grid item xs={4}>
          <Typography>{keyLabel ? keyLabel : FIELD_LABELS.KEY}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{valueLabel ? valueLabel : FIELD_LABELS.VALUE}</Typography>
        </Grid>
        <Grid item xs={4}>
          {object_type!='immutable' && <IconButton data-testid={`add-argument-button_for_${id}`} sx={{mt:-1}} disabled={disabled} onClick={() => handleCreate({key:"",value:""})}>
            <AddIcon color='primary' />
          </IconButton>}
        </Grid>
        {Object.entries(valueProp ?? {}).map(([key, value]) => 
        <RowComponent
          disabled={value?.disabled ? value?.disabled : disabled}
          key={key}
          onCreate={handleCreate.bind(this)}
          onChange={handleChange.bind(this, key)}
          onDelete={handleDelete.bind(this, key)}
          value={{ key, 'value': value?.disabled ? value?.value : value}}
          object_type={object_type}
        />)}

        {/* <RowComponent requiredProp={true} disabled={disabled} create={true} onChange={handleCreate} value={{key:"",value:""}} /> */}
      </Grid>
    </fieldset>
  )
}

ObjectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['array', 'text']),
  value: PropTypes.object,
  desc: PropTypes.string,
  keyLabel: PropTypes.string,
  valueLabel: PropTypes.string
}

ObjectInput.defaultProps = {
  onChange: () => null,
  type: 'text',
  keyLabel: null,
  valueLabel: null
}

export default ObjectInput
