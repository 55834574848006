import { CssBaseline } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react'
import Stack from '@mui/material/Stack'
import LoginButton from '../../components/Form/LoginButton'
import SignUpButton from '../../components/Form/SignUpButton'
import logo from '../../components/Icons/logo.png'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundColor: 'black',
  },
  centeredsd: {
    position: 'fixed',
    top: '50%',
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  centered: {
    position: 'fixed',
    top: '50%',
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15%',
    marginBottom: '1%',
    width: '40%',
    height: '12.5%',
  }
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9967DD',
      light: '#AD85E3',
    },
    secondary: {
      main: '#9967DD',
    },
    background: {
      default: '#181818',
      paper: '#313131',
    },
    success: {
      main: '#56C0AB',
      light: '#ACEFCE',
    }
  },
  zIndex: {
    drawer: 1150,
    modal: 1250
  },
  typography: {
    fontFamily: 'Space Grotesk',
  },
});

const LandingView = () => {
  const error = useState(new URL(window.location.href).searchParams.get("error"))
  const errorMessage = useState(new URL(window.location.href).searchParams.get("error_description"))

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack direction='column' alignItems='center' justifyContent='center' spacing='4'>
          <img src={logo} alt='ScaleTorch Logo' className={classes.center} />
          {error[0] && <Alert severity="error">
            <AlertTitle>Error: {error[0]}</AlertTitle>
            {errorMessage[0] ?? errorMessage[0]}
          </Alert>}
          <br/>
          <Stack direction='row'>
            <LoginButton/>
            <SignUpButton/>
          </Stack>
        </Stack>
      </ThemeProvider>
    </div>
  );
}

export default LandingView