import { deepFreeze } from "../../utils";
import { CLOUD_PROVIDER_FIELDS_CONFIG } from "./BaseFormConstants";
import { INPUT_TYPES } from ".";
var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
// Control configs
export const WS_CONTROL_CONFIGS = {
  IS_CUST_IMG_PRIVATE: {
    id: "IS_CUST_IMG_PRIVATE",
    label: "Private Image",
  },
  IS_BARE_BONE: {
    id: "bareBone",
    label: "Bare Bone VM",
    type: INPUT_TYPES.BOOLEAN,
  }
};
deepFreeze(WS_CONTROL_CONFIGS);

export const MAX_CPU_WORKERS_CONFIG = {
  maxCPUWorkers: {
    id: 'maxCPUWorkers',
    label: 'Max CPU Workers',
    required: true,
    disabled: true,
    description: 'Maximum number of CPU VMs that can be created for this job.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(MAX_CPU_WORKERS_CONFIG)

// CPU fields
export const WS_CPU_FIELDS_CONFIG = {
  minvCPUs: {
    id: 'minvCPUs',
    label: 'Minimum vCPUs',
    description: 'Min. amount of cores/vCPUs required in the worker VMs.',
    type: INPUT_TYPES.NUMBER
  },
  minMemory: {
    id: 'minMemory',
    label: 'Minimum Memory',
    description: 'Min. amount of memory/RAM required in the worker VMs.',
    type: INPUT_TYPES.NUMBER
  }
}
deepFreeze(WS_CPU_FIELDS_CONFIG)

// GPU fields
export const WS_GPU_FIELDS_CONFIG = {
  gpuTypes: {
    id: 'gpuTypes',
    disabled: true,
    required: true,
    label: 'GPU Types',
    placeholder: 'Select an option',
    description: 'Specify GPU Types you want to operate with. If this field is empty, we will use all available types by default.',
    type: INPUT_TYPES.MULTI_SELECT
  },
  gpuCount: {
    id: "gpuCount",
    label: "GPU Count",
    required: true,
    description: 'Specify how many GPUs do you need for the task.',
    type: INPUT_TYPES.NUMBER,
  }
}
deepFreeze(WS_GPU_FIELDS_CONFIG)
// Custom Image credentials form input config
export const CUSTOM_IMAGE_CREDS_CONFIG = {
  credentials: {
    id: "customImage.credentials",
    label: "Provide Custom Image Credentials",
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      registry: {
        id: "customImage.credentials.registry",
        label: "Registry URL",
        description: 'Enter Registry URL.',
        placeholder: "Enter registry url",
        autoComplete: 'off',
        type: INPUT_TYPES.STRING,
      },
      username: {
        id: "customImage.credentials.username",
        label: "User Name",
        description: 'Enter your user name.',
        placeholder: "Enter value",
        autoComplete: 'off',
        type: INPUT_TYPES.STRING,
      },
      password: {
        id: "customImage.credentials.password",
        label: "Password",
        description: 'Enter your password.',
        placeholder: "Enter value",
        autoComplete: 'off',
        type: INPUT_TYPES.STRING,
      },
    },
  },
};
deepFreeze(CUSTOM_IMAGE_CREDS_CONFIG);

export const WS_MANDATORY_FIELDS_CONFIG = {
  name: {
    id: 'name',
    label: 'Name',
    placeholder: 'Enter value',
    description: 'Give your workstation a unique name, e.g. "ScaleTorch Workstation". You can skip it, we will give it a random name automatically.',
    errorFunc: (v) => format.test(v),
    errorText: 'Names should only have alphabets, nums or hyphens',
    type: INPUT_TYPES.STRING,
    display: 'none'
  },
  useSpot: {
    id: 'useSpot',
    label: 'Use spot instances',
    description: 'Spot Instances are cheaper. Read more about spot instances. To use spot instances, you have to add checkpoints to your code and a restart feature.',
    link: 'https://www.scaletorch.ai/blog/spot-instances-automation-or-how-to-reduce-your-ai-training-cost-up-to-80-zh3hm',
    type: INPUT_TYPES.BOOLEAN
  },
  numWorkstations: {
    id: "numWorkstations",
    label: "Number Of Workstations",
    description: 'Specify how many workstations do you need.',
    type: INPUT_TYPES.NUMBER,
  },
}
deepFreeze(WS_MANDATORY_FIELDS_CONFIG)

// Advance fields
export const ENVIRONMENT_VAR = {
  environment: {
    id: 'environment',
    label: 'Environment Variables',
    description: 'Add third party API keys or other credentials that your training script requires.',
    type: INPUT_TYPES.OBJECT,
  }
}

deepFreeze(ENVIRONMENT_VAR)

export const WS_ADVANCE_FIELD_CONFIG = {
  pythonVersion: {
    id: 'pythonVersion',
    label: 'Python Version',
    description: 'Base python version to be used to install dependencies.',
    type: INPUT_TYPES.SELECT,
    options: ['3.7','3.8','3.9','3.10'],
    none: false
  },
  setupCommands: {
    id: "setupCommands",
    label: "Setup Commands",
    description: 'List of commands to run (without sudo). Each new command in new line.',
    placeholder: `
    List of commands to run (without sudo). Each new command in new line
    Example:
    apt-get update -y
    apt-get install net-tools
    `,
    type: INPUT_TYPES.MULTI_LINE_ARRAY,
  },
  requirements: {
    id: "requirements",
    label: "Requirements",
    placeholder: "Enter requirements file",
    description: '',
    accept: '.txt',
    type: INPUT_TYPES.FILE,
  }
}

deepFreeze(WS_ADVANCE_FIELD_CONFIG)

// Advance fields
export const IMPORT_YML = {
  configFile: {
    id: "configFile",
    label: "IMPORT YML CONFIG",
    description: 'Use a config file(.yml) to instantly start up your workstation.',
    type: INPUT_TYPES.FILE,
    accept: '.yml'
  }
}

deepFreeze(IMPORT_YML)

export const CUDA_CONFIG = {
  cuda: {
    id: 'cuda',
    label: 'Cuda Version',
    options: [11.8, 11.7, 11.6, 11.5, 11.4, 11.3, 11.2, 10.2, 10.1, 10.0],
    description: 'Specify a Cuda Version used in your code.',
    placeholder: 'Select an option',
    type: INPUT_TYPES.SELECT
  }
}

deepFreeze(CUDA_CONFIG)

export const NOT_BARE_BONE_FIELDS = {
  customImage: {
    id: "customImage.image",
    label: "Custom Image",
    description: 'Enter URL of your custom image.',
    placeholder: "Enter image url",
    type: INPUT_TYPES.STRING,
  }
}
deepFreeze(NOT_BARE_BONE_FIELDS)

export const ARTIFACTS_DESTINATION = {
  artifactsDestination: {
    id: "artifactsDestination",
    label: "Artifacts Destination",
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      name: {
        id: "artifactsDestination.name",
        label: "Name",
        placeholder: "Select Path",
        description: 'Choose Artifacts Storage for your experiments. Artifacts Storage are initially configured by your Administrator, contact them if you dont have any options to select.',
        type: INPUT_TYPES.SELECT,
      },
      filter: {
        id: "artifactsDestination.filter",
        label: "Filter",
        placeholder: "Enter filter value",
        description: '',
        type: INPUT_TYPES.STRING,
      }
    }
  }
}
deepFreeze(ARTIFACTS_DESTINATION)

export const VIRTUAL_MOUNT = {
  virtualMount: {
    id: 'virtualMounts',
    label: 'Virtual Mounts',
    description: 'Virtual mounts are a part of the Data Streaming engine. Add Scaletorch package to your code to use Virtual mounts. Virtual Mounts are initially configured by your Administrator, contact them if you donot have any options to select.',
    link: 'https://scaletorch.gitbook.io/scaletorch-docs/development-guide/data-streaming',
    placeholder: 'Select an option',
    type: INPUT_TYPES.VIRTUAL_MOUNT_ARRAY
  },
}
deepFreeze(VIRTUAL_MOUNT)

export const VISUALISATION_CONFIG = {
  visualisation: {
    id: 'visualisation',
    label: 'Visualisation',
    type: INPUT_TYPES.NESTED_OBJECT,
    fields: {
      type: {
        id: 'visualisation.type',
        label: 'Type',
        disabled: false,
        options: ['TENSORBOARD', 'AIM', 'WANDB', 'COMETML'],
        description: 'Choose Experiments tracking tool to visualize the results of your experiment. We have integration with Tensorboard, AIM and Weghts&Biases, so we can run those tools for you. If you already use other tools for the visualization, skip this field.',
        type: INPUT_TYPES.SELECT
      },
      viz_api_key: {
        id: 'visualisation.viz_api_key',
        label: 'API key',
        description: 'Enter your API key',
        disabled: false,
        autoComplete: 'off',
        errorText: 'Please enter a valid API key',
        error: false,
        edit: true,
        type: INPUT_TYPES.STRING
      }
    }
  }

}
deepFreeze(VISUALISATION_CONFIG)

export const CREATE_WORKSTATION_FORM_DEFAULT_VALUE = {
  name: '',
  cuda: null,
  useSpot: false,
  // -------------------------
  cloudProviders: [],
  cloudRegions: [],
  gpuTypes: [],
  // -------------------------
  minvCPUs: -1,
  minMemory: -1,
  gpuCount: 1,
  requirements: "",
  bareBone: false,
  numWorkstations: 1,
  maxCPUWorkers: 1,
  setupCommands: [],
  customImage: {
    image: "",
    credentials: {
      registry: "",
      username: "",
      password: "",
    },
  },
  virtualMounts: {},
  artifactsDestination: {
    name: null,
    filter: ''
  },
  visualisation: {
    type: '',
    viz_api_key: ''
  },
  configFile: "",
  pythonVersion: '3.8'
};

deepFreeze(CREATE_WORKSTATION_FORM_DEFAULT_VALUE);
