import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Delete from '@mui/icons-material/Delete'
import Save from '@mui/icons-material/Save'

const Row = ({
  create,
  disabled,
  onChange,
  onDelete,
  value: {
    key: keyProp,
    value: valueProp
  }
}) => {
  const [key, setKey] = useState(keyProp ?? '')
  const [value, setValue] = useState(valueProp ?? '')

  useEffect(() => {
    setKey(keyProp ?? '')
    setValue(valueProp ?? '')
  }, [keyProp, valueProp])

  const onBlur = () => {
    if (!create) {
      if (key.trim().length > 0) {
        onChange({
          key,
          value
        })
      } else {
        setKey(keyProp)
      }
    }
  }

  const onSave = () => {
    if (create && key.trim().length > 0 && value.trim().length > 0) {
      onChange({
        key,
        value
      })

      setKey('')
      setValue('')
    }
  }

  return (
    <>
      <Grid item xs={4}>
        <TextField
          disabled={disabled}
          fullWidth
          onBlur={onBlur}
          autoComplete='off'
          onChange={(event) => setKey(event.target.value)}
          size='small'
          value={key}
          variant='outlined'
        />
      </Grid>
      {/* <Grid item xs={5}>
        <TextField
          disabled={disabled}
          fullWidth
          onBlur={onBlur}
          onChange={(event) => setValue(event.target.value)}
          size='small'
          value={value}
          variant='outlined'
        />
      </Grid> */}
      <Grid item xs={8}>
        <IconButton disabled={disabled} onClick={() => create ? onSave() : onDelete()}>
          {create ? <Save color='primary' /> : <Delete color='primary' />}
        </IconButton>
      </Grid>
    </>
  )
}

Row.propTypes = {
  create: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.object
}

Row.propTypes = {
  onChange: () => null,
  onDelete: () => null
}

export default Row
