import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";

export const ADD_SECRET_CONFIG = {
  secretKey: {
    id: "secret_key",
    label: "Secret Key",
    required: true,
    autoComplete: 'off',
    type: INPUT_TYPES.STRING,
  },
  secretValue: {
    id: "secret_value",
    label: "Secret Value",
    required: true,
    autoComplete: 'off',
    type: INPUT_TYPES.STRING,
  }
};

deepFreeze(ADD_SECRET_CONFIG)

export const ADD_SECRET_DEFAULT_VALUE = {
    secret_key: "",
    secret_value: ""
};
deepFreeze(ADD_SECRET_DEFAULT_VALUE);

