import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Modal from '../../components/Modal'
import JobUsageView from '../../views/UsageView/JobUsageView'
import { STATUS_COLOR_MAP } from '../../constants/uiConstants'
import { Stack } from '@mui/material'

const UsageList = ({
    data,
    onClick
}) => {
    return (
        <Grid container spacing={2}>
            {data.map(({
                id,
                timestamp,
                type,
                nodes
            }) => (
                <Grid item key={`job-${id}`} sm={12} md={4} lg={3}>
                    <Card sx={{ backgroundColor: '#292929', borderRadius: '20px' }} raised>
                        <CardActionArea onClick={() => onClick(id)}>
                            <CardHeader
                                avatar={<Chip  sx={{backgroundColor: '#181818', color: '#727272'}} label={type ?? 'Job'} />}
                            />
                            <CardContent>
                                <Typography gutterBottom sx={{color: '#ABABAB'}}><b>Job ID</b></Typography>
                                <Typography gutterBottom sx={{color: '#9967DD'}}><b>{id}</b></Typography>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography gutterBottom sx={{color: '#ABABAB'}}><b>Time Started</b></Typography>
                                        <Typography gutterBottom>{new Date(timestamp).toLocaleString()}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom sx={{color: '#ABABAB'}}>VMs</Typography>
                                        <Typography gutterBottom>{nodes.length}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
            
        </Grid>
    )
}

UsageList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func
}

UsageList.defaultProps = {
    data: [],
    onClick: () => null
}

export default UsageList
