import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
    useGetInvoicesQuery
  } from "../../services/invoiceApi";
import { BUTTON_LABELS, VIEW_HEADERS } from "../../constants/uiConstants";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TickIcon from '@mui/icons-material/Done'
import CrossIcon from '@mui/icons-material/Close'
import Modal from "../../components/Modal";
import DataGrid from "../../components/DataGrid";
import DeleteButton from "../../components/DeleteButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { setError } from "../../datastore/UI/uiSlice";
import AddVirtualMountView from "./AddVirtualMountView";
import { Link } from 'react-router-dom';

const BillingView = () => {
  const dispatch = useDispatch();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);
  const [editRow, setEditingRow] = useState(null);
  const [data, setData] = useState([]);
  const {
    data: fetchedData,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetInvoicesQuery();


  useEffect(() => {
    if (fetchedData) setData(fetchedData);
  }, [fetchedData]);

  const rows = useMemo(() => {
    if (isSuccess && data) {
        // console.log(data)
        return data.map((item) => ({
            id: item.id + item.timestamp,
            timestamp: item.timestamp,
            amount: item.amount,
            payment_status: item.payment_status,
            invoice_url: item.invoice_url
          }));
    }
    return [];
  }, [data, isSuccess]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.BILLING}
      </Typography>
      <hr />
      <Container disableGutters maxWidth="lg" sx={{ marginBlockStart: 4 }}>
        
        {isLoading && <LoadingOverlay />}
        {isError && (
          <Alert severity="error">{error?.data?.detail?.[0]?.msg}</Alert>
        )}
        {isSuccess && (
          <DataGrid
            sx={{
              boxShadow: 4,
              border: 2,
              borderColor: 'primary.light',
              '.MuiDataGrid-columnSeparator': {
                color: 'primary.light'
              },
              '.MuiDataGrid-cell': {
                borderColor: 'primary.light'
              },
              '.MuiDataGrid-columnHeaders': {
                borderColor: 'primary.light'
              },
              '.MuiDataGrid-footerContainer': {
                borderColor: 'primary.light'
              }
            }}
            columns={[
              {
                field: "timestamp",
                headerName: "Date",
                renderCell: ({ value }) => {
                  return <div>{(new Date(value)).toString().split('00:00:00')[0]}</div>
                },
                sortable: true,
                flex: 1,
              },
              {
                field: "amount",
                headerName: "Amount",
                renderCell: ({ value }) => {
                  return <div>{parseFloat(value).toFixed(2)}</div>
                },
                sortable: true,
                flex: 1,
              },
              {
                field: "payment_status",
                headerName: "Status",
                sortable: true,
                flex: 1,
              },
              {
                field: "invoice_url",
                headerName: "Invoice URL",
                renderCell: ({ value }) => {
                  return value? <a href={value} download={value}>Link</a> : <b target="_blank">Unavailable</b>
                },
                sortable: true,
                flex: 1,
              },
            ]}
            rows={rows}
          />
        )}
      </Container>
    </>
  );
};

export default BillingView;
