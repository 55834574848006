import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const uploadApi = createApi({
  reducerPath: 'uploadApi',
  ...platformAuthConfig(),
  tagTypes: ['uploadUrl'],
  endpoints: builder => ({
    getUploadUrl: builder.mutation({
        query: (file) => ({
          url: `/get_presigned_url?file_name=${file}`,
          method: 'POST'
        }),
        invalidatesTags: ['uploadUrl']
      }),
  })
})

export const {
  useGetUploadUrlMutation
} = uploadApi

export default uploadApi
