import { isRejectedWithValue } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';


export const rtkQueryErrorLogger = api => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // catch error and capture it in sentry
    // console.log(action)
    const data = action.payload.error ?? action.payload.data?.detail
    Sentry.captureException(new Error(`${action.meta.baseQueryMeta.request.method} ${action.meta.baseQueryMeta.request.url} ${action.payload.status} (${data})`));
  }
  return next(action)
}
