import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import ErrorIcon from '@mui/icons-material/Error'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { BUTTON_LABELS, DIALOG_LABELS } from '../../constants/uiConstants'
import { getErrorMessage } from '../../utils'
import { clearError } from '../../datastore/UI/uiSlice'

const ErrorPopup = () => {
  const [open, setOpen] = useState(false)
  const [collapse, setCollapse] = useState(true)
  const error = useSelector(state => state.ui.error)
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      setOpen(true)
    } else {
      setOpen(false)
      setCollapse(true)
    }
  }, [error])

  const rawError = useMemo(() => {
    if (error && typeof error === 'object') {
      return JSON.stringify(error, null, 2)
    }

    return error
  }, [error])

  const toggleCollapse = () => {
    setCollapse(state => !state)
  }

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(clearError())}
      role="ERROR"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ErrorIcon color='error' sx={{ mr: 2 }}/>
        <Typography
          color='error'
          sx={{
            flexGrow: 1
          }}
          variant='span'
        >{DIALOG_LABELS.ERROR}</Typography>
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={() => {
            dispatch(clearError())
          }}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <DialogContentText sx={{
          whiteSpace: 'pre-line'
        }}>
          {getErrorMessage(error)}
        </DialogContentText>
        <Collapse in={!collapse} timeout='auto' unmountOnExit>
          <Paper elevation={0} sx={{ maxHeight: 500, marginTop: 2, p: 1, overflow: 'auto' }}>
            <Typography component='pre' variant='body2'>
              {rawError}
            </Typography>
          </Paper>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleCollapse}>{collapse ? BUTTON_LABELS.VIEW_RAW_ERROR : BUTTON_LABELS.HIDE_RAW_ERROR}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorPopup
