import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Modal from "../../components/Modal"
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Form from '../../components/Form'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay";
import AddVirtualMountView from "./AddVirtualMountView"
import { VIEW_HEADERS, VIEW_ROUTES, BUTTON_LABELS } from '../../constants/uiConstants'
import {
  TAB_CONFIGS,
  CLOUD_PROVIDER_FIELDS_CONFIG,
} from '../../constants/FormConstants/BaseFormConstants.js'
import {
  DOCKER_IMAGE_SETTINGS_FORM_CONFIG,
  DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG,
  CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG,
  JOB_CPU_FIELDS_CONFIG,
  JOB_ADVANCE_FIELD_CONFIG,
  JOB_TYPE_SIMPLE_JOB,
  ARTIFACTS_DESTINATION,
  VIRTUAL_MOUNT,
  SOURCE_VIRTUAL_MOUNT,
  TARGET_VIRTUAL_MOUNT,
  USE_DAP
} from '../../constants/FormConstants/LaunchJobFormConstants'

import {
  LAUNCH_EXPERIMENT_FORM_DEFAULT_VALUE,
  VISUALISATION_CONFIG,
  MAX_GPU_CONFIG,
  UPLOAD_CODE,
  JOB_GPU_FIELDS_CONFIG,
  INSTANCE_TYPES,
  ZERO_LABEL_CONFIG
} from '../../constants/FormConstants/ZeroLabelJobFormConfig'
import { deletePropertyPath } from '../../utils'
import { LoadingButton } from '@mui/lab'
import { simpleJobSchema } from "../../utils/schema.js";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { validatorFactory } from "../../utils/validator.js";


const expValidation = validatorFactory(simpleJobSchema)

const LaunchZeroLabelJobForm = ({
  name,
  watchConfigFileUpload,
  cloudSubscriptionsApiError,
  artifactsStoragePaths,
  allVirtualMounts,
  submit,
  isLoading,
  isLoadingCreateTemplate,
  jobDataIsLoading,
  isLoadingAllTemplates,
  dispatch,
  navigate,
  type
}) => {
  const [openCreateSourceVirtualMountModal, setOpenCreateSourceVirtualMountModal] = useState(false);
  const [openCreateTargetVirtualMountModal, setOpenCreateTargetVirtualMountModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null)
  const [currentNameOfJob, SetCurrentNameOfJob] = useState(name);
  const [dontUpdateVMounts, setDontUpdateVMounts] = useState(false);
  const [formErrors, setFormErrors] = useState(null)
  // State variables for all the controlled accordions
  const [expandedConfig, setExpandedConfig] = React.useState(false);

  const handleChangeConfig = () => (event) => {
    setExpandedConfig(expandedConfig ? false : true);
  };

  /**
   * State to toggle between manual and code upload form fields
   */
  const [imageSettings, setImageSettings] = useState(TAB_CONFIGS.MANUAL_SETTINGS.id)
  /**
   * State to toggle between docker and requirements file form fields
   */
  const [dockerSettings, setDockerSettings] = useState(TAB_CONFIGS.REQUIREMENTS.id)
  /**
   * State to toggle between CPU and GPU fields
   */
  const [cpuOrGpu, setCpuOrGpu] = useState(TAB_CONFIGS.GPU_SETTINGS.id)

  /**
   * State to toggle between pick instance fields and gpu fields
   */
  const [pickInstance, setPickInstance] = useState(TAB_CONFIGS.PICK_GPU.id)
  /**
   * State to toggle credential fields
   */
  const [showCredentials, setShowCredentials] = useState(false)
  /**
   * State to toggle credential fields
   */
  const [showDockerCredentials, SetShowDockerCredentials] = useState(false)
  /**
   * State to toggle Viz fields
   */
  /**
   * State to hold the form config. We keep it in a separate state as its sbjected to changes 
   */
  const [artifactsDestinationConfig, setArtifactsDestinationConfig] = useState(ARTIFACTS_DESTINATION)
  const [sourceVirtualMountConfig, setSourceVirtualMountConfig] = useState(SOURCE_VIRTUAL_MOUNT)
  const [targetVirtualMountConfig, setTargetVirtualMountConfig] = useState(TARGET_VIRTUAL_MOUNT)
  const [templateData, setTemplateData] = useState(null)
  /**
   * Initialize form state
   */
  const {
    control,
    register,
    formState,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    reset,
    trigger
  } = useForm({
    defaultValues: LAUNCH_EXPERIMENT_FORM_DEFAULT_VALUE
  })

  /**
   * Set the options for fields for ArtifactStoragePath
   */
  useEffect(() => {
    if (artifactsStoragePaths) {

      const optionValues = artifactsStoragePaths.map((item) => item.name + ":" + item.path)
      // optionValues.push('WANDB');
      // optionValues.push('COMETML');
      function update(state) {
        const newObject = structuredClone(state)
        newObject.artifactsDestination.fields.name.options = Array.isArray(artifactsStoragePaths) ?
          optionValues : [] //['WANDB', 'COMETML']

        return newObject
      }

      setArtifactsDestinationConfig(update)
      setValue(ARTIFACTS_DESTINATION.artifactsDestination.fields.name.id,
        optionValues[0])
    }
  }, [artifactsStoragePaths, setValue])

  useEffect(() => {
    if (allVirtualMounts) {
      const allNames = Array.isArray(allVirtualMounts) ? allVirtualMounts.map(i => i.name) : []
      setSourceVirtualMountConfig((state) => {
        const newObject = structuredClone(state)
        newObject.virtualMount.options = Array.isArray(allNames) ? allNames : []
        return newObject
      })
      if (allVirtualMounts.length > 0 && type === "new") {
        addSourceData(allVirtualMounts[0])
      }
    }
  }, [allVirtualMounts, setValue]);
  
  useEffect(() => {
    if (allVirtualMounts) {
      const allNames = Array.isArray(allVirtualMounts) ? allVirtualMounts.map(i => i.name) : []
      setTargetVirtualMountConfig((state) => {
        const newObject = structuredClone(state)
        newObject.virtualMount.options = Array.isArray(allNames) ? allNames : []
        return newObject
      })
      if (allVirtualMounts.length > 0 && type === "new") {
        addTargetData(allVirtualMounts[0])
      }
    }
  }, [allVirtualMounts, setValue]);

  // Add the selected Virtual Mount to the object array
  function addSourceData(virtualMount) {
    const currentVirtualMountValues = getValues()[SOURCE_VIRTUAL_MOUNT.virtualMount.id];
    virtualMount = [virtualMount]
    let virtualMountValues = Object.assign(currentVirtualMountValues ?? {}, ...virtualMount.map((x) => ({ [x.name]: x })));
    setValue(SOURCE_VIRTUAL_MOUNT.virtualMount.id, virtualMountValues);
    setOpenCreateSourceVirtualMountModal(false);
  }
  
  // Add the selected Virtual Mount to the object array
  function addTargetData(virtualMount) {
    const currentVirtualMountValues = getValues()[TARGET_VIRTUAL_MOUNT.virtualMount.id];
    virtualMount = [virtualMount]
    let virtualMountValues = Object.assign(currentVirtualMountValues ?? {}, ...virtualMount.map((x) => ({ [x.name]: x })));
    setValue(TARGET_VIRTUAL_MOUNT.virtualMount.id, virtualMountValues);
    setOpenCreateTargetVirtualMountModal(false);
  }
  
  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData 
   */
  const onSubmit = useCallback((formData) => {
    const data = {
      ...formData,
    };
    /**
     * Cleaning the data as per user selections
     */
    delete data['config'];
    data[MAX_GPU_CONFIG.maxGpus.id] = data[JOB_GPU_FIELDS_CONFIG.gpusPerTrial.id]
    data['name'] = currentNameOfJob;

    if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
      deletePropertyPath(data, UPLOAD_CODE.codeZip.id)
    } else{
      deletePropertyPath(data, UPLOAD_CODE.codeZip.id)
    }

    if (dockerSettings === TAB_CONFIGS.REQUIREMENTS.id) {
      deletePropertyPath(data, DOCKER_IMAGE_SETTINGS_FORM_CONFIG.customImage.id)
      deletePropertyPath(data, DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (!showCredentials && imageSettings != TAB_CONFIGS.UPLOAD_CODE.id) {
      deletePropertyPath(data, CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (!showDockerCredentials && dockerSettings != TAB_CONFIGS.REQUIREMENTS.id) {
      deletePropertyPath(data, DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
      Object.values(JOB_GPU_FIELDS_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      Object.values(MAX_GPU_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      Object.values(INSTANCE_TYPES).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      deletePropertyPath(data, USE_DAP.useDAPP.id)
    } else {
      Object.values(JOB_CPU_FIELDS_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      if(pickInstance === TAB_CONFIGS.PICK_INSTANCE.id){
        data[JOB_GPU_FIELDS_CONFIG.gpusPerTrial.id] = 1;
        Object.values(JOB_GPU_FIELDS_CONFIG).forEach(element => {
          deletePropertyPath(data, element.id)
        })
      }
    }


    // creating the cloudProvider object
    let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
    let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
    const getRegions = (cp) => {
      return crs
        .filter((cr) => cr.startsWith(cp))
        .map((cr) => cr.split(":")[1]);
    };
    const cloudProviders = cps.map((cp) => ({
      name: cp,
      regions: getRegions(cp),
    }));
    data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
    deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);

    if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
      deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);;


    data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
      (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

    if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
      deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

    data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})
    deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);

    if (data[JOB_ADVANCE_FIELD_CONFIG.environment.id] === null) {
      deletePropertyPath(data, JOB_ADVANCE_FIELD_CONFIG.environment.id);
    }

    if (data[JOB_ADVANCE_FIELD_CONFIG.environment.id] && data[JOB_ADVANCE_FIELD_CONFIG.environment.id][""] != undefined) {
      delete data[JOB_ADVANCE_FIELD_CONFIG.environment.id][""];
    }
    // console.log(data)
    if (data[USE_DAP.useDAPP.id] === false) {
      data['dapp'] = {'epochs' : -1}
    }else{
      data['dapp'] = {'epochs' : data['epochs']}
    }
    delete data['epochs'];
    if (type === 'templating') {
      data['from_template'] = templateData.name;
    }
    // console.log(data)
    submit({
      config: data,
      type: JOB_TYPE_SIMPLE_JOB,
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate(`../${VIEW_ROUTES.JOBS}`, { replace: true })
        }, 1000)
      })
      .catch((error) => {
        dispatch(setError(error))
      });
  }, [cpuOrGpu, dispatch, navigate, currentNameOfJob, allVirtualMounts, imageSettings, dockerSettings, pickInstance, uploadedFileId, showCredentials, showDockerCredentials, submit]);

  
  function openSourceVirtualMountModal() {
    setOpenCreateSourceVirtualMountModal(true);
  }

  function openTargetVirtualMountModal() {
    setOpenCreateTargetVirtualMountModal(true);
  }

  return (
    <>
      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Typography variant="h5">
            {VIEW_HEADERS.LAUNCH_NEW_ZERO_LABEL_JOB}
          </Typography>
          <FormControl sx={{ m: 1 }} variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-job-name">Enter Name</InputLabel>
            <OutlinedInput
                id="outlined-adornment-job-name"
                type='text'
                value={currentNameOfJob}
                endAdornment={
                <InputAdornment position="end">
                    <EditIcon />
                </InputAdornment>
                }
                label="Enter Job Name"
                onChange={(event) => {
                  SetCurrentNameOfJob(event.target.value)  
                  setValue('name', event.target.value)             
                }}
            />
          </FormControl>
        </Stack>
      </Container>
      <br />
      {/* {stage > -1 && <CustomizedSteppers step={stage} steps={['Code', 'Data', 'Configure', 'Deploy']} setStage={setStage}/>} */}
      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        {formErrors &&
          <Alert spacing={4} severity="error">
            <AlertTitle>Form Error</AlertTitle>
            Please check the following fields — <strong>[{Object.keys(formErrors).join(', ')}]</strong>
          </Alert>
        }
        {(jobDataIsLoading || isLoadingAllTemplates) && (type === "retry" || type === "templating") && <LoadingOverlay />}
        <Form
          control={control}
          fields={{}}
          loading={isLoading || isLoadingCreateTemplate}
          submitAtEnd={true}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormFields control={control} fields={ZERO_LABEL_CONFIG} />
          <FormFields control={control} fields={sourceVirtualMountConfig} openModal={openSourceVirtualMountModal}/>
          <Modal
              onClose={() => setOpenCreateSourceVirtualMountModal(false)}
              open={openCreateSourceVirtualMountModal}
              title="Add Source Data Virtual Mount"
            >
              <AddVirtualMountView
                addData={addSourceData}
              />
          </Modal>
          <FormFields control={control} fields={targetVirtualMountConfig} openModal={openTargetVirtualMountModal}/>
          <Modal
              onClose={() => setOpenCreateTargetVirtualMountModal(false)}
              open={openCreateTargetVirtualMountModal}
              title="Add Target Data Virtual Mount"
            >
              <AddVirtualMountView
                addData={addTargetData}
              />
          </Modal>
          <FormFields control={control} fields={artifactsDestinationConfig} />
          <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                fullWidth
                color='success'
                onClick={
                  handleSubmit(onSubmit)
                }
                variant='contained'
                sx={{ borderRadius: '20px',}}
                role='SUBMIT' 
                data-testid='submit' 
                loading={isLoading || isLoadingCreateTemplate} 
                type='submit' 
              >
                <PlayArrowIcon/>
                Run
              </LoadingButton>
            </Stack>
        </Form>
      </Container>
    </>
  )
}

export default LaunchZeroLabelJobForm