import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Toolbar from '@mui/material/Toolbar'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Link, useLocation } from 'react-router-dom'
import { ADMIN_LEFT_MENU_ITEMS, DRAWER_WIDTH, LEFT_MENU_ITEMS, VIEW_ROUTES } from '../../constants/uiConstants'
import {closeLeftDrawer } from '../../datastore/UI/uiSlice'
import { isCollection } from 'yaml'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DescriptionIcon from '@mui/icons-material/Description';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CloudIcon from '@mui/icons-material/Cloud';
import KeyIcon from '@mui/icons-material/Key';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';

const ENUM_STATES = {
  'ADMIN' :<Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Code'>
                <AdminPanelSettingsIcon fontSize='small'/>
              </IconButton>
      
          </Stack>,
  'MEMBERS': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Data'>
                <SupervisorAccountIcon fontSize='small'/>
              </IconButton>
              
          </Stack>,
  'JOBS': <Stack direction="row" alignItems="center" gap={1}>
                  <IconButton aria-label="delete" data-testid='Configure'>
                    <IntegrationInstructionsIcon fontSize='small'/>
                  </IconButton>
                  
              </Stack>,
  'ZERO_LABEL_JOBS': <Stack direction="row" alignItems="center" gap={1}>
                  <IconButton aria-label="delete" data-testid='Configure'>
                    <LabelOffIcon fontSize='small'/>
                  </IconButton>
                  
              </Stack>,
  // 'EXPERIMENTS': <Stack direction="row" alignItems="center" gap={1}>
  //             <IconButton aria-label="delete" data-testid='Deploy'>
  //                   <AccountTreeIcon fontSize='small'/>
  //                 </IconButton>
             
  //         </Stack>,
  // 'HYPERPARAMETER_TUNING': <Stack direction="row" alignItems="center" gap={1}>
  //             <IconButton aria-label="delete" data-testid='Deploy'>
  //                   <DeviceHubIcon fontSize='small'/>
  //                 </IconButton>
             
  //         </Stack>,
  // 'WORKSTATIONS': <Stack direction="row" alignItems="center" gap={1}>
  //             <IconButton aria-label="delete" data-testid='Deploy'>
  //                   <DeveloperBoardIcon fontSize='small'/>
  //                 </IconButton>
              
  //         </Stack>,
  'TEMPLATES': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Deploy'>
                    <DescriptionIcon fontSize='small'/>
                  </IconButton>
              
          </Stack>,
  'ACCESS_KEYS': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Deploy'>
                    <VpnKeyIcon fontSize='small'/>
                  </IconButton>
             
          </Stack>,
  'USAGE': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Deploy'>
                    <FactCheckIcon fontSize='small'/>
                  </IconButton>
              
          </Stack>,
  'CLOUD_PROVIDERS': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Deploy'>
                    <CloudIcon fontSize='small'/>
                  </IconButton>
              
          </Stack>,
  'ARTIFACTS_STORAGE': <Stack direction="row" alignItems="center" gap={1}>
              <IconButton aria-label="delete" data-testid='Deploy'>
                    <StorageIcon fontSize='small'/>
                  </IconButton>
              
          </Stack>,
  'SECRETS': <Stack direction="row" alignItems="center" gap={1}>
            <IconButton aria-label="delete" data-testid='Deploy'>
                  <KeyIcon fontSize='small'/>
                </IconButton>
            
        </Stack>,
  'VIRTUAL_MOUNTS': <Stack direction="row" alignItems="center" gap={1}>
            <IconButton aria-label="delete" data-testid='Deploy'>
                  <StorageIcon fontSize='small'/>
                </IconButton>
            
        </Stack>,
  'BILLING': <Stack direction="row" alignItems="center" gap={1}>
            <IconButton aria-label="delete" data-testid='Deploy'>
                  <AttachMoneyIcon fontSize='small'/>
                </IconButton>
            
        </Stack>,
};
function EnumState({ state }) {
  return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center'}} >{ENUM_STATES[state]}</div>;
}

const LeftMenuBar = ({
  open
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(null)
  const navPath = useMemo(() => {
    return location.pathname.replace('/', '')
  }, [location.pathname])
  const isAdmin = useSelector((state) => state.userState.stUser?.user_metadata?.is_admin);


  const onMenuClick = (id) => {
    if (menuOpen === id) {
      setMenuOpen(null)
    } else {
      setMenuOpen(id)
    }
  }
  useEffect(()=>{
    window.addEventListener('resize', ()=>{
      if(window.innerWidth >= 900){
        dispatch(closeLeftDrawer());
      }
    })
  })
  const finalMenuItems = []
  if(isAdmin) {
    finalMenuItems.push(...Object.values(ADMIN_LEFT_MENU_ITEMS));
  }
  finalMenuItems.push(...Object.values(LEFT_MENU_ITEMS));
  const drawer = (
    <div>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <nav aria-label='left menu'>
          <List>
            {finalMenuItems.map(({ id, label, items }) =>
              
            (
              <React.Fragment key={id}>
                <ListItem
                  button
                  onClick={() => items ? onMenuClick(id) : onMenuClick(null)}
                  key={`left-menu-${id}`}
                  selected={VIEW_ROUTES[id] === navPath.split('/')[0]}
                  {...(items ? {} : {
                    component: Link,
                    to: VIEW_ROUTES[id]
                  })}
                >
                  <ListItemIcon>
                      {<EnumState state={id}/>}
                  </ListItemIcon>
                  <ListItemText primary={label} />
                  {items && <>{menuOpen === id ? <ExpandLess /> : <ExpandMore />}</>}
                </ListItem>
                {items && (
                  <Collapse key={`left-menu-collapse-${id}`} in={menuOpen === id} timeout='auto' unmountOnExit>
                    <List disablePadding>
                      {Object.values(items).map(({ id: id_1, label: label_1 }) => (
                        <ListItem
                          button
                          component={Link}
                          key={`left-menu-${id_1}`}
                          selected={VIEW_ROUTES[id_1] === navPath}
                          sx={{ pl: 3 }}
                          to={VIEW_ROUTES[id_1]}
                        >
                          <ListItemIcon>
                              {<EnumState state={id_1}/>}
                          </ListItemIcon>
                          <ListItemText primary={label_1} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </nav>
      </Box>
    </div>
                        
  )
  return (
    <Box
      component="nav"
      sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
    >
      <Drawer
      anchor='left'
      open={useSelector((state)=>state.ui.leftDrawerShown)}
      SlideProps={{ unmountOnExit: true }}
      variant="temporary"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
      }}
    >
      {drawer}
    </Drawer>
    <Drawer
      anchor='left'
      variant='permanent'
      sx={{
        display: { xs: 'none', md: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
      }}
      open
      
    >
      {drawer}
    </Drawer>
    </Box>
    
  )
}

LeftMenuBar.propTypes = {
  open: PropTypes.bool
}

export default LeftMenuBar
