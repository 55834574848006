import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetArtifactStoragePathsQuery,
  useCreateArtifactStoragePathMutation,
  useEditArtifactStoragePathMutation,
  useDeleteArtifactStoragePathMutation,
} from "../../services/artifactStorageApi";
import { BUTTON_LABELS, VIEW_HEADERS } from "../../constants/uiConstants";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../components/Modal";
import DataGrid from "../../components/DataGrid";
import DeleteButton from "../../components/DeleteButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { setError } from "../../datastore/UI/uiSlice";
import AddArtifactsStorageView from "./AddArtifactsStorageView";

const ArtifactsStorageView = () => {
  const dispatch = useDispatch();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);
  const [editRow, setEditingRow] = useState(null);
  const [data, setData] = useState([]);
  const {
    data: fetchedData,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetArtifactStoragePathsQuery();

  /**
   * Service APIs
   */
  const [createSubmit, { isLoading: isLoadingCreate }] =
    useCreateArtifactStoragePathMutation();
  const [editSubmit, { isLoading: isLoadingEdit }] = useEditArtifactStoragePathMutation();
  const [deletePath] = useDeleteArtifactStoragePathMutation();

  useEffect(() => {
    if (fetchedData) setData(fetchedData);
  }, [fetchedData]);

  const rows = useMemo(() => {
    if (isSuccess && data) {
      return data.map((item) => ({
        id: item._id,
        ...item,
      }));
    }
    return [];
  }, [data, isSuccess]);

  const handleCreate = useCallback(() => {
    setOpenCreateModal(false);
    refetch();
  }, [refetch]);

  const handleDelete = useCallback(
    (row) => {
      setDeletingRow(row);
      deletePath(row.id)
        .unwrap()
        .then(() => {
          setData((state) => state.filter((item) => item.id !== row._id));
          setDeletingRow(null);
        })
        .catch((err) => {
          setDeletingRow(null);
          dispatch(setError(err));
        });
    },
    [deletePath, dispatch]
  );

  const handleEdit = useCallback(
    (row) => {
      setEditingRow(null);
      refetch();
    },
    [refetch]
  );

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.ADD_ARTIFACTS_STORAGE}
      </Typography>
      <hr />
      <Container disableGutters maxWidth="lg" sx={{ marginBlockStart: 4 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ marginBlockEnd: 4 }}
        >
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={() => setOpenCreateModal(true)}
          >
            <AddIcon />
            {BUTTON_LABELS.ADD_ARTIFACTS_STORAGE}
          </Button>
        </Stack>
        <Modal
          onClose={() => setOpenCreateModal(false)}
          open={openCreateModal}
          title={VIEW_HEADERS.ADD_ARTIFACTS_STORAGE}
        >
          <AddArtifactsStorageView
            edit={false}
            submit={createSubmit}
            isLoading={isLoadingCreate}
            close={handleCreate}
          />
        </Modal>
        {isLoading && <LoadingOverlay />}
        {isError && (
          <Alert severity="error">{error?.data?.detail?.[0]?.msg}</Alert>
        )}
        {isSuccess && (
          <DataGrid
            
            columns={[
              {
                field: "name",
                headerName: "Name",
                sortable: true,
                flex: 1,
              },
              {
                field: "path",
                headerName: "Path",
                sortable: true,
                flex: 1,
              },
              // {
              //   field: "actions_edit",
              //   headerName: "Edit",
              //   width: "150",
              //   renderCell: ({ row }) => {
              //     return (
              //       <Button
              //         fullWidth={false}
              //         size="small"
              //         variant="contained"
              //         color="secondary"
              //         style={{ height: 40 }}
              //         onClick={() => {
              //           var paddedCreds = {};
              //           Object.entries(row.credentials).map(([key,value])=> {
              //             paddedCreds[key] = {value, disabled: true};
              //           })
              //           var copiedRow = structuredClone(row)
              //           copiedRow.credentials = paddedCreds;
              //           setEditingRow(copiedRow)
              //         }}
              //       >
              //         {BUTTON_LABELS.EDIT_BUTTON}
              //       </Button>
              //     );
              //   },
              // },
              {
                field: "actions",
                headerName: "Delete",
                width: "150",
                renderCell: ({ row }) => {
                  return (
                    <DeleteButton
                      color="error"
                      loading={deletingRow ? deletingRow.id === row.id : false}
                      onClick={() => handleDelete(row)}
                      size="small"
                      variant="contained"
                      description="Are you sure you want to delete this Path?"
                    >
                      {BUTTON_LABELS.DELETE_BUTTON}
                    </DeleteButton>
                  );
                },
              },
            ]}
            rows={rows}
          />
        )}
        <Modal
          onClose={() => setEditingRow(null)}
          open={!!editRow}
          title="Edit Artifact Storage" //{VIEW_HEADERS.ADD_ARTIFACTS_STORAGE}
        >
          {!!editRow && (
            <AddArtifactsStorageView
              edit={true}
              submit={editSubmit}
              isLoading={isLoadingEdit}
              defaultData={{ ...editRow }}
              close={handleEdit}
            />
          )}
        </Modal>
      </Container>
    </>
  );
};

export default ArtifactsStorageView;
