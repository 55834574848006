import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { BUTTON_LABELS, VIEW_HEADERS } from '../../constants/uiConstants'
import LoadingOverlay from '../../components/LoadingOverlay'
import TemplatesList from '../../components/TemplatesList'
import { setError } from '../../datastore/UI/uiSlice'
import { useGetTemplatesQuery } from '../../services/templatesApi'
const TemplatesView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filter = ["JOB"]
  const { data: fetchedData, error, isError, isLoading, isSuccess } = useGetTemplatesQuery()

  useEffect(() => {
    if (isError) {
      dispatch(setError(error))
    }
  }, [dispatch, error, isError])

  const data = useMemo(() => {
    if (Array.isArray(fetchedData)) {
      if (filter.length > 0) {
        const newFilter = JSON.parse(JSON.stringify(filter))
        if(newFilter.indexOf('JOB') != -1){
          newFilter[newFilter.indexOf('JOB')] = 'SIMPLE_JOB'
        }
        return fetchedData.filter((item) => newFilter.includes(item.type))
      }
      return fetchedData
    }

    return []
  }, [fetchedData, filter])

  const handleClick = (type, templateId) => {
    navigate(`../templates/simple-job/view/${templateId}`, { replace: true })
  }

  const redirectLaunchForm = () => {
    navigate(`../templates/simple-job/new`, { replace: true })
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {VIEW_HEADERS.TEMPLATES}
      </Typography>
      <hr />
      <br />
      {isLoading && <LoadingOverlay />}
      <Box sx={{ width: 750, marginBlockEnd: 4 }}>
        {isSuccess && (
          <Button
              fullWidth={false}
              variant="contained"
              color="primary"
              style={{ height: 40, width: 400, margin: '10px'}}
              onClick={() => redirectLaunchForm()}
          >
            <AddIcon />
            {BUTTON_LABELS.LAUNCH_NEW_TEMPLATE}
          </Button>
        )}
      </Box>
      {isSuccess && <TemplatesList data={data || []} onClick={handleClick} />}
    </>
  );
}

export default TemplatesView