import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  user: {},
  platformBearer: undefined,
  stUser: {}
}

export const userSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setPlatformBearer: (state, action) => {
      state.platformBearer = action.payload
    },
    setStUser: (state, action) => {
      state.stUser = action.payload
    }
  }
})

export const {
    setUser,
    getUser,
    setPlatformBearer,
    getPlatformBearer,
    setStUser,
    getStUser
} = userSlice.actions

export default userSlice
