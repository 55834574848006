import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const templatesApi = createApi({
  reducerPath: 'templatesApi',
  ...platformAuthConfig(),
  tagTypes: ['UserTemplates'],
  endpoints: builder => ({
    getTemplates: builder.query({
      query: () => '/templates',
      providesTags: ['UserTemplates']
    }),
    createTemplate: builder.mutation({
      query: (config) => ({
        url: '/templates',
        method: 'POST',
        body: config
      }),
      invalidatesTags: ['UserTemplates']
    }),
    editTemplate: builder.mutation({
        query: ({id, config}) => ({
          url: `/template/${id}`,
          method: 'PUT',
          body: config
        }),
        invalidatesTags: ['UserTemplates']
      }),
    deleteTemplate: builder.mutation({
      query: (id) => ({
        url: `/template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserTemplates']
    })
  })
})

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation
} = templatesApi

export default templatesApi
