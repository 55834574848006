import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";

export const ADD_USER_CONFIG = {
  userEmail: {
    id: "email",
    label: "User Email",
    required: true,
    type: INPUT_TYPES.STRING,
  }
};
deepFreeze(ADD_USER_CONFIG)

export const ADD_USER_DEFAULT_VALUE = {
  email: "",
};
deepFreeze(ADD_USER_DEFAULT_VALUE);