import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import YAML from 'yaml'
import yaml from 'js-yaml'
import Button from '@mui/material/Button'
import Modal from "../../components/Modal"
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Form from '../../components/Form'
import InfoIcon from '@mui/icons-material/Info'
import Grid from '@mui/material/Grid'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay";
import AddVirtualMountView from "./AddVirtualMountView"
import AddAsTemplate from "./AddAsTemplate"
import { VIEW_HEADERS, VIEW_ROUTES, BUTTON_LABELS } from '../../constants/uiConstants'
import { uniqueNamesGenerator, adjectives, names } from 'unique-names-generator';
import {
  TAB_CONFIGS,
  CLOUD_PROVIDER_FIELDS_CONFIG,
  CPU_FIELDS_CONFIGS,
  TEMPLATE_FIELDS
} from '../../constants/FormConstants/BaseFormConstants.js'
import {
  MANUAL_IMAGE_SETTINGS_FORM_CONFIG,
  MANUAL_PRE_POST_CMD_CONFIG,
  DOCKER_IMAGE_SETTINGS_FORM_CONFIG,
  DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG,
  CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG,
  CODE_DIRECTORY_CREDENTIALS_FIELDS_CONFIG,
  JOB_CONTROL_CONFIGS,
  JOB_CPU_FIELDS_CONFIG,
  JOB_GPU_FIELDS_CONFIG,
  JOB_ADVANCE_FIELD_CONFIG,
  JOB_TYPE_EXPERIMENT,
  ARTIFACTS_DESTINATION,
  VIRTUAL_MOUNT,
  USE_DAP,
  DAP_SETTINGS
} from '../../constants/FormConstants/LaunchJobFormConstants'

import {
  LAUNCH_EXPERIMENT_FORM_DEFAULT_VALUE,
  EXP_MAIN_CONFIG,
  SOURCE_CODE_CONFIG,
  VISUALISATION_CONFIG,
  ENVIRONMENT_CONFIG,
  COST_MANAGEMENT_CONFIG,
  MAX_GPU_CONFIG,
  MAX_CPU_WORKERS_CONFIG,
  IMPORT_YML
} from '../../constants/FormConstants/ExperimentFormConfig'
import { useGetSecretsQuery } from '../../services/secretsApi'
import { useGetTemplatesQuery, useCreateTemplateMutation } from '../../services/templatesApi'
import { useCreateJobMutation, useGetJobQuery } from '../../services/jobsApi'
import {
  useGetVisualizationQuery,
} from '../../services/visualizationApi'
import { useGetUserCloudSubscriptionsQuery, useGetGpuTypesMutation, useGetInstanceTypesMutation } from '../../services/cloudApi'
import { deletePropertyPath } from '../../utils'
import { clearError, setError } from '../../datastore/UI/uiSlice'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"
import { LoadingButton } from '@mui/lab'
import axios from 'axios';
import { validatorFactory } from "../../utils/validator.js";
import { simpleJobSchema } from "../../utils/schema.js";
import LaunchSimpleJobForm from './LaunchSimpleJobForm';
import CreateNewView from '../../components/CreateNewView';


const expValidation = validatorFactory(simpleJobSchema)

const LaunchSimpleJobView = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState(false)
  const [name, setName] = useState('')
  const [skip, setSkip] = useState(type === 'new' ? false : true)
  const [watchConfigFileUpload, setWatchConfigFileUpload] = useState(null)
  
  const {
    data: cloudSubscriptionsData,
    error: cloudSubscriptionsApiError
  } = useGetUserCloudSubscriptionsQuery()
  /**
   * Get GPU types
   */
  const [getGpuTypes, {
    data: gpuTypesData
  }] = useGetGpuTypesMutation()

  /**
   * Get GPU types
   */
  const [getInstances, {
    data: getInstanceTypes
  }] = useGetInstanceTypesMutation()

  /**
   * Get Templates
   */
  const {
    data: allTemplates, isLoading: isLoadingAllTemplates
  } = useGetTemplatesQuery()
  /**
   * Get Artifacts Storage
   */
  const {
    data: artifactsStoragePaths
  } = useGetArtifactStoragePathsQuery()
  /**
   * Get Virtual Mounts
   */
  const {
    data: allVirtualMounts
  } = useGetVirtualMountsQuery()

  /**
   * Get Visualisation keys
   */
  const {
    data: allVizKeys
  } = useGetVisualizationQuery()

  /**
   * Get Secret keys
   */
  const {
    data: allSecretKeys
  } = useGetSecretsQuery('TEMPLATE')

  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useCreateJobMutation()
  const [createTemplate, { isLoading: isLoadingCreateTemplate }] = useCreateTemplateMutation()
  const { data: job_data, isLoading: jobDataIsLoading } = useGetJobQuery(location.pathname.split('/').pop(), { skip: type === "retry" ? false : true })
  const navigateTo = (base) => {
    setSkip(true)
    navigate(base)
  }
  

  return (
    <>
      {(!skip && !showDetails) ?
        <CreateNewView
          type='SIMPLE_JOB'
          showDetails={setShowDetails}
          setName={setName}
          navigateTo={navigateTo}
          setYamlConfig={setWatchConfigFileUpload}
        /> 
      :
        <LaunchSimpleJobForm
          name={name}
          watchConfigFileUpload={watchConfigFileUpload}
          cloudSubscriptionsData={cloudSubscriptionsData}
          allTemplates={allTemplates}
          getInstances={getInstances}
          getInstanceTypes={getInstanceTypes}
          getGpuTypes={getGpuTypes}
          gpuTypesData={gpuTypesData}
          cloudSubscriptionsApiError={cloudSubscriptionsApiError}
          artifactsStoragePaths={artifactsStoragePaths}
          allVirtualMounts={allVirtualMounts}
          allSecretKeys={allSecretKeys}
          allVizKeys={allVizKeys}
          job_data={job_data}
          createTemplate={createTemplate}
          submit={submit}
          isLoading={isLoading}
          isLoadingCreateTemplate={isLoadingCreateTemplate}
          jobDataIsLoading={jobDataIsLoading}
          isLoadingAllTemplates={isLoadingAllTemplates}
          dispatch={dispatch}
          navigate={navigate}
          type={type}
        />
      }
    </>
  )
}
LaunchSimpleJobView.propTypes = {
  type: PropTypes.string
}

LaunchSimpleJobView.defaultProps = {
  type: 'new'
}
export default LaunchSimpleJobView
