import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import LoadingOverlay from '../../components/LoadingOverlay'
import { useGetJobQuery } from '../../services/jobsApi'
import { TAB_CONFIGS } from '../../constants/uiConstants'
import { setError } from '../../datastore/UI/uiSlice'

const WorkStationDetailsView = React.lazy(() => import('./WorkStationDetailsView'))
const EventLogs = React.lazy(() => import('./EventLogs'))
const BuildLogs = React.lazy(() => import('./BuildLogs'))


const OverallWorkStationView = ({
}) => {
  const dispatch = useDispatch()
  const [view, setView] = useState(
    TAB_CONFIGS.WORKSTATION_INFO.id
  )

  return (
    <>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={view} onChange={(e, value) => setView(value)}>
          <Tab label={TAB_CONFIGS.WORKSTATION_INFO.label} value={TAB_CONFIGS.WORKSTATION_INFO.id} />
          <Tab label={TAB_CONFIGS.EVENT_LOGS.label} value={TAB_CONFIGS.EVENT_LOGS.id} />
          <Tab label={TAB_CONFIGS.BUILD_LOGS.label} value={TAB_CONFIGS.BUILD_LOGS.id} />
        </Tabs>
      </Box>
      {view === TAB_CONFIGS.WORKSTATION_INFO.id && (
        <Suspense fallback={null}>
          <WorkStationDetailsView/>
        </Suspense>
      )}
      {view === TAB_CONFIGS.EVENT_LOGS.id && (
        <Suspense fallback={null}>
          <EventLogs/>
        </Suspense>
      )}
      {view === TAB_CONFIGS.BUILD_LOGS.id && (
        <Suspense fallback={null}>
          <BuildLogs/>
        </Suspense>
      )}

    </>
  )
}

OverallWorkStationView.propTypes = {
 
}

export default OverallWorkStationView
