import { deepFreeze } from '../utils'
// Left drawer width
export const DRAWER_WIDTH = 240
// View keys
const ADMIN = 'ADMIN'
const CLOUD_PROVIDERS = 'CLOUD_PROVIDERS'
const SECRETS = 'SECRETS'
const ARTIFACTS_STORAGE = 'ARTIFACTS_STORAGE'
const VIRTUAL_MOUNTS = 'VIRTUAL_MOUNTS'
const BILLING = 'BILLING'
const ACCESS_KEYS = 'ACCESS_KEYS'
const ADD_SECRET = 'ADD_SECRET'
const ADD_CLOUD_PROVIDER = 'ADD_CLOUD_PROVIDER'
const ADD_ARTIFACTS_STORAGE = 'ADD_ARTIFACTS_STORAGE'
const ADD_VIRTUAL_MOUNT = 'ADD_VIRTUAL_MOUNT'
const JOBS= 'JOBS'
const LAUNCH_NEW_JOB = 'LAUNCH_NEW_JOB'
const LAUNCH_NEW_JOB_WITH_TEMPLATE = 'LAUNCH_NEW_JOB_WITH_TEMPLATE'
const RETRY_JOB = 'RETRY_JOB'
const VIEW_JOB = 'VIEW_JOB'
const ZERO_LABEL_JOBS = 'ZERO_LABEL_JOBS'
const LAUNCH_NEW_ZERO_LABEL_JOB = 'LAUNCH_NEW_ZERO_LABEL_JOB'
const LAUNCH_NEW_ZERO_LABEL_JOB_WITH_TEMPLATE = 'LAUNCH_NEW_ZERO_LABEL_JOB_WITH_TEMPLATE'
const RETRY_ZERO_LABEL_JOB = 'RETRY_ZERO_LABEL_JOB'
const VIEW_ZERO_LABEL_JOB = 'VIEW_ZERO_LABEL_JOB'
const EXPERIMENTS = 'EXPERIMENTS'
const VIEW_EXPERIMENT = 'VIEW_EXPERIMENT'
const ALL_EXPERIMENTS = 'ALL_EXPERIMENTS'
const LAUNCH_NEW_EXPERIMENT = 'LAUNCH_NEW_EXPERIMENT'
const LAUNCH_NEW_EXPERIMENT_WITH_TEMPLATE = 'LAUNCH_NEW_EXPERIMENT_WITH_TEMPLATE'
const RETRY_EXPERIMENT = 'RETRY_EXPERIMENT'
const WORKSTATIONS = 'WORKSTATIONS'
const VIEW_WORKSTATION = 'VIEW_WORKSTATION'
const ALL_WORKSTATIONS = 'ALL_WORKSTATIONS'
const CREATE_NEW_WORKSTATIONS = 'CREATE_NEW_WORKSTATIONS'
const CREATE_NEW_WORKSTATIONS_WITH_TEMPLATE = 'CREATE_NEW_WORKSTATIONS_WITH_TEMPLATE'
const RELAUNCH_WORKSTATIONS = 'RELAUNCH_WORKSTATIONS'
const VIEW_HYPERPARAMETER_TRAINING = 'VIEW_HYPERPARAMETER_TRAINING'
const HYPERPARAMETER_TUNING = 'HYPERPARAMETER_TUNING'
const ALL_HYPERPARAMETER_TUNING_JOBS = 'ALL_HYPERPARAMETER_TUNING_JOBS'
const LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB = 'LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB'
const LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB_WITH_TEMPLATE = 'LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB_WITH_TEMPLATE'
const RELAUNCH_HYPERPARAMETER_TUNING_JOB = 'RELAUNCH_HYPERPARAMETER_TUNING_JOB'
const ARTIFACTS = 'ARTIFACTS'
const VIEW_ACCESS_KEY = 'VIEW_ACCESS_KEY'
const MEMBERS = 'MEMBERS'
const ADD_MEMBER = 'ADD_MEMBER'
const EDIT_PERMISSIONS = 'EDIT_PERMISSIONS'
const TEMPLATES = 'TEMPLATES'
const CREATE_WORKSTATION_TEMPLATE = 'CREATE_WORKSTATION_TEMPLATE'
const VIEW_WORKSTATION_TEMPLATE = 'VIEW_WORKSTATION_TEMPLATE'
const CREATE_EXPERIMENT_TEMPLATE = 'CREATE_EXPERIMENT_TEMPLATE'
const VIEW_EXPERIMENT_TEMPLATE = 'VIEW_EXPERIMENT_TEMPLATE'
const CREATE_HPT_TEMPLATE = 'CREATE_HPT_TEMPLATE'
const CREATE_SIMPLE_JOB_TEMPLATE = 'CREATE_SIMPLE_JOB_TEMPLATE'
const VIEW_SIMPLE_JOB_TEMPLATE = 'VIEW_SIMPLE_JOB_TEMPLATE'
const VIEW_HPT_TEMPLATE = 'VIEW_HPT_TEMPLATE'
const USAGE = 'USAGE'
const VIEW_JOB_USAGE = 'VIEW_JOB_USAGE'

export const ADMIN_LEFT_MENU_ITEMS = {
  [ADMIN]: {
    id: ADMIN,
    label: 'Admin',
    items: {
      [CLOUD_PROVIDERS]: {
        id: CLOUD_PROVIDERS,
        label: 'Cloud Providers'
      },
      [SECRETS]: {
        id: SECRETS,
        label: 'Secrets'
      },
      [ARTIFACTS_STORAGE]: {
        id: ARTIFACTS_STORAGE,
        label: 'Artifacts Storage'
      },
      [VIRTUAL_MOUNTS]: {
        id: VIRTUAL_MOUNTS,
        label: 'Virtual Mounts'
      },
      [BILLING]: {
        id: BILLING,
        label: 'Billing'
      }
    }
  },
  [MEMBERS]: {
    id: MEMBERS,
    label: 'Members'
  }
}
deepFreeze(ADMIN_LEFT_MENU_ITEMS)
// Left menu items
export const LEFT_MENU_ITEMS = {
  [JOBS]: {
    id: JOBS,
    label: 'Jobs',
  },
  [ZERO_LABEL_JOBS]: {
    id: ZERO_LABEL_JOBS,
    label: 'Zero Label Jobs',
  },
  // [EXPERIMENTS]: {
  //   id: EXPERIMENTS,
  //   label: 'Experiments',
  // },
  // [HYPERPARAMETER_TUNING]: {
  //   id: HYPERPARAMETER_TUNING,
  //   label: 'Hyperparameter Tuning',
  // },
  // [WORKSTATIONS]: {
  //   id: WORKSTATIONS,
  //   label: 'Workstations',
  // },
  [TEMPLATES]: {
    id: TEMPLATES,
    label: 'Templates'
  },
  [ACCESS_KEYS]: {
    id: ACCESS_KEYS,
    label: 'Access Keys'
  },
  [USAGE]: {
    id: USAGE,
    label: 'Usage'
  }
}
deepFreeze(LEFT_MENU_ITEMS)
// View headers
export const VIEW_HEADERS = {
  [JOBS]: 'Jobs',
  [LAUNCH_NEW_JOB]: 'Launch New Job',
  [ZERO_LABEL_JOBS]: 'Zero Label Jobs',
  [LAUNCH_NEW_ZERO_LABEL_JOB]: 'Launch New Zero Label Job',
  [EXPERIMENTS]: 'Experiments',
  [LAUNCH_NEW_EXPERIMENT]: 'Launch New Experiment',
  [WORKSTATIONS]: 'Workstations',
  [CREATE_NEW_WORKSTATIONS]: 'Create New Workstation',
  [HYPERPARAMETER_TUNING]: 'Hyperparameter Tuning',
  [LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB]: 'Launch New Hyperparameter Tuning',
  [ARTIFACTS]: 'Artifacts',
  [CLOUD_PROVIDERS]: 'Cloud Providers',
  [SECRETS]: 'Secrets',
  [ARTIFACTS_STORAGE]: 'Artifacts Storage',
  [VIRTUAL_MOUNTS]: 'Virtual Mounts',
  [BILLING]: 'Billing',
  [ACCESS_KEYS]: 'Access Keys',
  [VIRTUAL_MOUNTS]: 'Virtual Mounts',
  [ADD_CLOUD_PROVIDER]: 'Add Cloud Provider',
  [ADD_ARTIFACTS_STORAGE]: 'Add Artifacts Storage',
  [ADD_VIRTUAL_MOUNT]: 'Add Virtual Mount',
  [ADD_SECRET]: 'Add Secret',
  [VIEW_ACCESS_KEY]: 'View Access Key',
  [MEMBERS]: 'Users',
  [ADD_MEMBER]: 'Add Member',
  [EDIT_PERMISSIONS]: 'Edit Permissions',
  [TEMPLATES]: 'Templates',
  [CREATE_WORKSTATION_TEMPLATE]: 'Create New Template',
  [VIEW_WORKSTATION_TEMPLATE]: 'View Template',
  [CREATE_EXPERIMENT_TEMPLATE]: 'Create New Template',
  [VIEW_EXPERIMENT_TEMPLATE]: 'View Template',
  [CREATE_HPT_TEMPLATE]: 'Create New Template',
  [VIEW_HPT_TEMPLATE]: 'View Template',
  [CREATE_SIMPLE_JOB_TEMPLATE]: 'Create New Template',
  [VIEW_SIMPLE_JOB_TEMPLATE]: 'View Template',
  [USAGE]: 'Usage'
}
deepFreeze(VIEW_HEADERS)
// View Routes
export const VIEW_ROUTES = {
  [ADMIN]: 'admin',
  [CLOUD_PROVIDERS]: 'profile/cloud-providers',
  [SECRETS]: 'profile/user-secrets',
  [VIRTUAL_MOUNTS]: 'profile/virtual-mounts',
  [BILLING]: 'profile/billing',
  [ARTIFACTS_STORAGE]: 'profile/artifacts-storage',
  [ACCESS_KEYS]: 'access-keys',
  [ADD_CLOUD_PROVIDER]: 'admin/add-cloud-provider',
  [JOBS]: 'simple-jobs',
  [LAUNCH_NEW_JOB]: 'simple-jobs/new',
  [LAUNCH_NEW_JOB_WITH_TEMPLATE]: 'simple-jobs/new/:template_id',
  [RETRY_JOB]: 'simple-jobs/retry/:id',
  [VIEW_JOB]: 'simple-jobs/view/:id',
  [ZERO_LABEL_JOBS]: 'zero-label-jobs',
  [LAUNCH_NEW_ZERO_LABEL_JOB]: 'zero-label-jobs/new',
  [LAUNCH_NEW_ZERO_LABEL_JOB_WITH_TEMPLATE]: 'zero-label-jobs/new/:template_id',
  [RETRY_ZERO_LABEL_JOB]: 'zero-label-jobs/retry/:id',
  [VIEW_ZERO_LABEL_JOB]: 'zero-label-jobs/view/:id',
  [EXPERIMENTS]: 'experiments',
  [ALL_EXPERIMENTS]: 'experiments',
  [LAUNCH_NEW_EXPERIMENT]: 'experiments/new',
  [LAUNCH_NEW_EXPERIMENT_WITH_TEMPLATE]: 'experiments/new/:template_id',
  [RETRY_EXPERIMENT]: 'experiments/retry/:id',
  [VIEW_EXPERIMENT]: 'experiments/view/:id',
  [WORKSTATIONS]: 'work-stations',
  [ALL_WORKSTATIONS]: 'work-stations',
  [VIEW_WORKSTATION]: 'work-stations/view/:id',
  [CREATE_NEW_WORKSTATIONS]: 'work-stations/new',
  [CREATE_NEW_WORKSTATIONS_WITH_TEMPLATE]: 'work-stations/new/:template_id',
  [RELAUNCH_WORKSTATIONS]: 'work-stations/retry/:id',
  [HYPERPARAMETER_TUNING]: 'hyperparameter-tuning',
  [ALL_HYPERPARAMETER_TUNING_JOBS]: 'hyperparameter-tuning',
  [LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB]: 'hyperparameter-tuning/new',
  [LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB_WITH_TEMPLATE]: 'hyperparameter-tuning/new/:template_id',
  [RELAUNCH_HYPERPARAMETER_TUNING_JOB]: 'hyperparameter-tuning/retry/:id',
  [VIEW_HYPERPARAMETER_TRAINING]: 'hyperparameter-tuning/view/:id',
  [ARTIFACTS]: 'art',
  [MEMBERS]: 'members',
  [TEMPLATES]: 'templates',
  [CREATE_WORKSTATION_TEMPLATE]: 'templates/workstation/new',
  [VIEW_WORKSTATION_TEMPLATE]: 'templates/workstation/view/:template_id',
  [CREATE_EXPERIMENT_TEMPLATE]: 'templates/experiment/new',
  [VIEW_EXPERIMENT_TEMPLATE]: 'templates/experiment/view/:template_id',
  [CREATE_HPT_TEMPLATE]: 'templates/hpt/new',
  [VIEW_HPT_TEMPLATE]: 'templates/hpt/view/:template_id',
  [CREATE_SIMPLE_JOB_TEMPLATE]: 'templates/simple-job/new',
  [VIEW_SIMPLE_JOB_TEMPLATE]: 'templates/simple-job/view/:template_id',
  [USAGE]: 'usage',
  [VIEW_JOB_USAGE]: 'usage/:job_id'
}
deepFreeze(VIEW_ROUTES)
// Button labels
export const BUTTON_LABELS = {
  CANCEL_BUTTON: 'Cancel',
  DELETE_BUTTON: 'Delete',
  STOP_BUTTON: 'Stop',
  EDIT_BUTTON: 'Edit',
  DOWNLOAD_BUTTON: 'Download',
  HIDE_RAW_ERROR: 'Hide raw error',
  LAUNCH_NEW_WORKSTATION_BUTTON: 'Launch New Workstation',
  LAUNCH_NEW_EXPERIMENT_BUTTON: 'Launch New Experiment',
  LAUNCH_NEW_JOB: 'Launch New Job',
  LAUNCH_NEW_ZERO_LABEL_JOB: 'Launch New Zero Label Job',
  LAUNCH_NEW_HPT_BUTTON: 'Launch New HPT',
  SELECT_ARTIFACT_BUTTON: 'Select artifact to view and download',
  SUBMIT_BUTTON: 'Submit',
  VIEW_LOGS_BUTTON: 'View Logs',
  DOWNLOAD_LOGS_BUTTON: 'Download Logs',
  VIEW_RAW_ERROR: 'View raw error',
  ADD_CLOUD_PROVIDER: 'Add Cloud Provider',
  CREATE_ACCESS_KEY: 'Create Access Key',
  ADD_SECRET: 'Add Secret',
  ADD_ARTIFACTS_STORAGE: 'Add Artifact Storage',
  ADD_VIRTUAL_MOUNT: 'Add Virtual Mount',
  VIEW_ARTIFACTS: 'View Artifacts',
  RESTART_WORKSTATION: 'Restart Workstation',
  ADD_MEMBER: 'Add New Member',
  LAUNCH_NEW_TEMPLATE: 'Create New Template',
  LAUNCH_NEW_EXPERIMENT_TEMPLATE: 'Experiment Template',
  LAUNCH_NEW_HPT_TEMPLATE: 'HPT Template'
}
deepFreeze(BUTTON_LABELS)
// Field labels
export const FIELD_LABELS = {
  TYPE: 'Type',
  KEY: 'Key',
  NAME: 'Name',
  PROPERTY: 'Property',
  VALUE: 'Value'
}
deepFreeze(FIELD_LABELS)
// Dialog labes
export const DIALOG_LABELS = {
  DELETE: 'Confirm Deletion',
  ERROR: 'Error'
}
deepFreeze(DIALOG_LABELS)
// Dialog messages
export const DIALOG_MESSAGES = {
  DELETE: 'Are you sure you want to stop the process?'
}
deepFreeze(DIALOG_MESSAGES)

// Time Filters
export const START_TIME_FILTERS = {
  '1HR': 'Past 1 hour',
  '6HR': 'Past 6 hours',
  '12HR': 'Past 12 hours',
  '1DAY': 'Yesterday',
  '3DAY': 'Past 3 days',
  '7DAY': 'Past week',
  '1MONTH': 'Past month'
}
deepFreeze(START_TIME_FILTERS)

export const END_TIME_FILTERS = {
  '1HR': 'Past 1 hour',
  '6HR': 'Past 6 hours',
  '12HR': 'Past 12 hours',
  '1DAY': 'Yesterday',
  '3DAY': 'Past 3 days',
  '7DAY': 'Past week',
  '1MONTH': 'Past month'
}
deepFreeze(END_TIME_FILTERS)

// Cost Filter
export const COST_FILTERS = {
  '0_100' : '0-100',
  '100_300': '100-300',
  '300_500': '300-500',
  '>_500': 'Above 500',
}
deepFreeze(COST_FILTERS)

// Status map
export const STATUS_OPTIONS = {
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  DELETING: 'DELETING',
  DELETED: 'DELETED',
  RESTARTING: 'RESTARTING',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
  SPOT_FAILURE: 'SPOT_FAILURE'
}
deepFreeze(STATUS_OPTIONS)

export const STATUS_OPTIONS_FOR_JOBS = {
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  DELETING: 'DELETING',
  DELETED: 'DELETED',
  RESTARTING: 'RESTARTING',
  FAILED: 'FAILED'
}
deepFreeze(STATUS_OPTIONS_FOR_JOBS)

export const MONTHS = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  JUNE: 'JUNE',
  JULY: 'JULY',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER'
}
deepFreeze(MONTHS)

export const STATUS_OPTIONS_FOR_WKS = {
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  DELETING: 'DELETING',
  DELETED: 'DELETED',
  RESTARTING: 'RESTARTING',
  FAILED: 'FAILED',
  SPOT_FAILURE: 'SPOT_FAILURE'
}
deepFreeze(STATUS_OPTIONS_FOR_WKS)

//template_option map
export const TEMPLATE_OPTIONS = {
  WORKSTATION: 'WORKSTATION',
  EXPERIMENT: 'EXPERIMENT',
  TUNING_JOB: 'HPTOPT',
  SIMPLE_JOB: 'JOB',
  ZERO_LABEL_JOB: 'ZERO_LABEL_JOB'
}
deepFreeze(TEMPLATE_OPTIONS)

// Status to color map
export const STATUS_COLOR_MAP = {
  FAILED: 'error',
  QUEUED: 'primary',
  RUNNING: 'primary',
  SUCCEEDED: 'success',
  STOPPING: 'warning',
  DELETING: 'warning',
  STOPPED: 'default',
  DELETED: 'default',
  SPOT_FAILURE: 'error'
}
deepFreeze(STATUS_COLOR_MAP)

export const DELETE_DISABLED_STATUS = [
  STATUS_OPTIONS.DELETING,
  STATUS_OPTIONS.DELETED,
  STATUS_OPTIONS.STOPPING,
  STATUS_OPTIONS.RESTARTING,
  STATUS_OPTIONS.FAILED,
  STATUS_OPTIONS.SPOT_FAILURE
  
]

export const STOP_DISABLED_STATUS = [
  STATUS_OPTIONS.QUEUED,
  STATUS_OPTIONS.DELETING,
  STATUS_OPTIONS.DELETED,
  STATUS_OPTIONS.STOPPING,
  STATUS_OPTIONS.STOPPED,
  STATUS_OPTIONS.RESTARTING,
  STATUS_OPTIONS.FAILED,
  STATUS_OPTIONS.SPOT_FAILURE
]

export const STOP_JOB_DISABLED_STATUS = [
  STATUS_OPTIONS.DELETING,
  STATUS_OPTIONS.DELETED,
  STATUS_OPTIONS.STOPPING,
  STATUS_OPTIONS.RESTARTING,
  STATUS_OPTIONS.FAILED,
  STATUS_OPTIONS.STOPPED
  
]

export const STOP_TRIAL_DISABLED_STATUS = [
  STATUS_OPTIONS.SUCCEEDED,
  STATUS_OPTIONS.FAILED
  
]
// Error messages
export const ERROR_MESSAGES = {
  FILE_SIZE_ERROR: 'File size sholud be less than ',
  FILE_READ_ERROR: 'Failed to read file',
  ARRAY_FIELD_ERROR: 'Please enter the value as an array e.g [1,2,"some string"]',
  FIELD_REQUIRED_ERROR: 'This field is required'
}
deepFreeze(ERROR_MESSAGES)

// UI tab configs
export const TAB_CONFIGS = {
  WORKSTATION_INFO: {
    id: 'WORKSTATION_INFO',
    label: 'Workstation Info'
  },
  EXPERIMENT_INFO: {
    id: 'EXPERIMENT_INFO',
    label: 'Experiment Info'
  },
  JOB_INFO: {
    id: 'JOB_INFO',
    label: 'Job Info'
  },
  TUNNING_INFO: {
    id: 'TUNNING_INFO',
    label: 'Tuning Info'
  },
  LIVE_LOGS: {
    id: 'LIVE_LOGS',
    label: 'Live Logs'
  },
  TRIAL_INFO: {
    id: 'TRIAL_INFO',
    label: 'Trial Info'
  },
  EVENT_LOGS: {
    id: 'EVENT_LOGS',
    label: 'Event Logs'
  },
  BUILD_LOGS: {
    id: 'BUILD_LOGS',
    label: 'Build Logs'
  },
  ARGS: {
    id: 'JOB_ARGS',
    label: 'Job Args'
  },
}
deepFreeze(TAB_CONFIGS)

// File types
export const FILE_EXTENSION_MAP = {
  JSON: ['json'],
  IMAGE: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'],
  PDF: ['pdf'],
  TABLE: ['csv'],
  TEXT: ['log', 'md', 'txt']
}
deepFreeze(TAB_CONFIGS)
