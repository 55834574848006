import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const {
  NODE_ENV,
  REACT_APP_LOGS_URL_PROXY
} = process.env;

export const logsViewerConfig = () => {
  // console.log(REACT_APP_LOGS_URL_PROXY)
  return {
    baseQuery: fetchBaseQuery({
      baseUrl: NODE_ENV === "development" ? "http://127.0.0.1:9000/" : REACT_APP_LOGS_URL_PROXY,
    }),
  };
};

export default logsViewerConfig;
