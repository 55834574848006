import { deepFreeze } from "../../utils";
import { INPUT_TYPES } from ".";


export const SELECT_VIRTUAL_MOUNT_CONFIG = {
  name: {
    id: "name",
    label: "VirtualMount Name",
    required: true,
    type: INPUT_TYPES.SELECT,
  },
  
};

deepFreeze(SELECT_VIRTUAL_MOUNT_CONFIG);

export const SELECTED_VIRTUAL_MOUNT_CONFIG = {
    src: {
      id: "src",
      label: "VirtualMount src",
      disabled: true,
      required: true,
      type: INPUT_TYPES.STRING,
    },
    dest: {
      id: "dest",
      label: "VirtualMount dest",
      required: true,
      type: INPUT_TYPES.STRING,
    },
    filter: {
      id: "filter",
      label: "VirtualMount filter",
      required: false,
      type: INPUT_TYPES.STRING,
    },
    unravelArchives: {
      id: "unravelArchives",
      label: "VirtualMount unravelArchives",
      required: false,
      type: INPUT_TYPES.BOOLEAN,
    },
    prefetch: {
      id: "prefetch",
      label: "VirtualMount prefetch",
      required: false,
      type: INPUT_TYPES.BOOLEAN,
    }
  };

deepFreeze(SELECTED_VIRTUAL_MOUNT_CONFIG);


export const SELECTED_VIRTUAL_MOUNT_DEFAULT_VALUE = {
  src: "",
  dest: "",
  filter: "",
  prefetch: false,
  unravelArchives: false,
};
deepFreeze(SELECTED_VIRTUAL_MOUNT_DEFAULT_VALUE);