import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const creditsApi = createApi({
  reducerPath: 'creditsApi',
  ...platformAuthConfig(),
  endpoints: builder => ({
    getCredits: builder.query({
      query: () => '/credits',
      method: 'GET'
    })
  })
})

export const {
    useGetCreditsQuery
} = creditsApi

export default creditsApi