import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const TableComponent = ({
  columns,
  rows
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ field, headerName }) => (
              <TableCell key={field} variant='head'><b>{headerName}</b></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length>0 && rows.map((data, index) => (
            <TableRow key={index}>
              {columns.map(({ field }) => (
                <TableCell key={`${field}-${index}`} variant='body'>{data[field] ?? 'None'}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.node
  })),
  rows: PropTypes.arrayOf(PropTypes.object)
}

TableComponent.defaultProps = {
  columns: [],
  rows: []
}

export default TableComponent
