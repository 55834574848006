import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const exchangeAuthCodeApi = createApi({
  reducerPath: 'exchangeAuthCodeApi',
  tagTypes: ['Tokens'],
  ...platformAuthConfig(),
  endpoints: builder => ({
    getTokens: builder.mutation({
      query: (code) => ({
        url: `/exchange_auth_code?auth_code=${code}`,
        method: 'POST',
      }),
      invalidatesTags: ['Tokens']
    })
  })
})

export const {
  useGetTokensMutation
} = exchangeAuthCodeApi

export default exchangeAuthCodeApi
