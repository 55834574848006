import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Icons/logo.png'
import FormFields from "../Form/FormFields";
import {
  EDIT_GT2_CACHE,
  EDIT_GT2_CACHE_DEFAULT_VALUE
} from "../../constants/FormConstants/AdminConstants";
import { toggleLeftDrawer,toggleThemeMode } from '../../datastore/UI/uiSlice'
import LogoutButton from '../Form/LogoutButton'
import {Popper,Fade,ClickAwayListener,Paper,Divider} from '@mui/material'
import { useUpdateGT2CacheMutation } from "../../services/userApi"
import { useGetUserCloudsQuery } from "../../services/cloudApi"
import { useGetCreditsQuery } from "../../services/creditsApi"
const Image = styled.img`
  height: 20px;
`

const TopMenuBar = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector(state => state.userState.user)
  const isAdmin = useSelector((state) => state.userState.stUser?.user_metadata?.is_admin);
  const gt2Value = useSelector((state) => state.userState.stUser?.user_metadata?.gt2.enabled); 
  const [gt2Stage, setGt2Stage] = useState(0);
  const [accountLevel,setAccountLevel] = useState("Member")
  const [putUpdateGT2Cache, { isLoading: isLoadingUpdate }] = useUpdateGT2CacheMutation();
  const { data: userCloudData, isLoading: isLoadingGetUserClouds } = useGetUserCloudsQuery();
  const { data: userCreditData, isLoading: isLoadingGetUserCredits } = useGetCreditsQuery();
  const [creditsVisibility, setCreditsVisibility] = useState(false);
  // GT2 Cache Form
  const [GT2config, setGT2Config] = useState(
    EDIT_GT2_CACHE
  );
  const {
    control: controlGT2,
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: EDIT_GT2_CACHE_DEFAULT_VALUE,
  });
  const watchGT2Toggle = watch('G2Cache');
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
      }
      setOpen(false);
  };
  const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
      if (prevOpen.current === true && open === false) {
          anchorRef.current.focus();
      }
      if(isAdmin)
        setAccountLevel("Admin");
      prevOpen.current = open;
  }, [open,isAdmin]);

  useEffect(() => {
    if(gt2Value != undefined){
      setValue(EDIT_GT2_CACHE.G2Cache.id,gt2Value)
    }
  },[gt2Value])

  const onSubmit = (action) => {
      // console.log("called");
      putUpdateGT2Cache(action)
      .unwrap()
      .then(() => {
        // console.log("GT2 updated!")
      })
    }
  useEffect(() => {
    // console.log(gt2Stage)
    if(user && !isLoadingUpdate && gt2Value != undefined && isAdmin){
      if((gt2Value && gt2Stage >1) || (!gt2Value && gt2Stage >0)){
        onSubmit(watchGT2Toggle ? 'enable' : 'disable');
      }
    }
    setGt2Stage(gt2Stage + 1)
  },[watchGT2Toggle])

  useEffect(() => {
    if (userCloudData && userCreditData) {
      // console.log(userCreditData)
      if (userCloudData.some(e => e.cloud_provider === 'SCALETORCH')) {
        setCreditsVisibility(true)
      }
    }
  }, [userCloudData, userCreditData])
  // useEffect(() => {
  //   if (userCreditData ) {
  //    console.log(userCreditData)
  //   }
  // }, [userCreditData])


  return (
    <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1}} color="background">
      <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => dispatch(toggleLeftDrawer())}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
        </IconButton>
        <Image src={Logo} alt='ScaleTorch Logo' />
        {/* <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ ml: 2, mr: 2, display: { xs: 'none', sm: 'flex' } }}
        >
          <b>SCALETORCH</b>
        </Typography> */}
        <Box sx={{ flexGrow: 1, }}></Box>
        <Box sx={{  flexGrow: 0, mr: 2 }}>
          <IconButton sx={{ ml: 1 }} onClick={() => dispatch(toggleThemeMode())} color="inherit">
                              {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                            </IconButton>
        </Box>
        <Box sx={{  flexGrow: 0, mr: 2 }}>
          {creditsVisibility && 
            <Chip icon={<AttachMoneyIcon />} label={userCreditData?.credits ?? '0.00'}/>
          }
        </Box>
        <Box sx={{ flexGrow: 0, mr: 1 }}>
          <Chip
              sx={{
                  height: '48px',
                  alignItems: 'center',
                  borderRadius: '27px',
                  transition: 'all .2s ease-in-out',
                  borderColor: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.light,
                  '&[aria-controls="menu-list-grow"], &:hover': {
                      borderColor: theme.palette.primary.main,
                      background: `${theme.palette.primary.main}!important`,
                      color: theme.palette.primary.light,
                      '& svg': {
                          stroke: theme.palette.primary.light
                      }
                  },
                  '& .MuiChip-label': {
                      lineHeight: 0
                  }
              }}
              icon={
                <Avatar alt={user.nickname} src={user.picture} ref={anchorRef}/>
              }
              label={<SettingsIcon stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
              variant="outlined"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="primary"
          />
        </Box>
        <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box sx={{ p: 2, mr:1, ml:1}}>
                          <Stack>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Typography variant="h5" sx={{ fontWeight: 600 }}>Hello,</Typography>
                                <Typography component="span" variant="h5" sx={{ fontWeight: 400 }}>
                                  {user.nickname}
                                </Typography>
                            </Stack>
                          </Stack>
                          <Typography variant="subtitle2">{accountLevel}</Typography>
                          <Divider />
                          <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt:1}}>
                              <Typography variant="subtitle1">Email : </Typography>
                              <Typography component="span" variant="subtitle1">
                                {user.email}
                              </Typography>
                          </Stack>
                          {/* <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt:1}}>
                              <Typography variant="subtitle1">GT2 : </Typography>
                              <FormFields disabled={(isLoadingUpdate || gt2Value === undefined || !isAdmin) ? true : false} control={controlGT2} fields={GT2config}/>
                          </Stack> */}
                          <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt:1}}>
                              <LogoutButton />
                          </Stack>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Fade>
              )}
            </Popper>
        
      </Toolbar>
    </AppBar>
  )
}

export default TopMenuBar
