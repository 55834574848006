import { css } from '@emotion/react'


const globalStyles = css`
  a {
    color: #4DA6FF;
    text-decoration: none;

    &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }
  }

  fieldset {
    border: 1px solid #C8C8C8;
    border-radius: 4px;
  }

  textarea::placeholder {
    text-indent: 0;
  }
`

export default globalStyles
