import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Form from '../../components/Form'
import FormFields from '../../components/Form/FormFields'
import LoadingOverlay from "../../components/LoadingOverlay"
import { LoadingButton } from '@mui/lab'
import Modal from "../../components/Modal"
import AddVirtualMountView from "./AddVirtualMountView"
import { VIEW_HEADERS, VIEW_ROUTES } from '../../constants/uiConstants'

import {
  TAB_CONFIGS,
  CLOUD_PROVIDER_FIELDS_CONFIG,
  CPU_FIELDS_CONFIGS,
  TEMPLATE_FIELDS
} from '../../constants/FormConstants/BaseFormConstants.js'
import {
  MANUAL_IMAGE_SETTINGS_FORM_CONFIG,
  MANUAL_PRE_POST_CMD_CONFIG,
  DOCKER_IMAGE_SETTINGS_FORM_CONFIG,
  DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG,
  CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG,
  CODE_DIRECTORY_CREDENTIALS_FIELDS_CONFIG,
  JOB_CONTROL_CONFIGS,
  JOB_CPU_FIELDS_CONFIG,
  JOB_GPU_FIELDS_CONFIG,
  CUDA,
  JOB_ADVANCE_FIELD_CONFIG,
  JOB_TYPE_HYPERPARAMETER_TRAINING,
  ARTIFACTS_DESTINATION,
  VIRTUAL_MOUNT,
  DAP_SETTINGS,
  REQUIREMENTS_FILE
} from '../../constants/FormConstants/LaunchJobTemplateFormConstants'

import {
  HPT_MANDATORY_FIELDS_CONFIG,
  LAUNCH_HYPERPARAMETER_TUNING_JOB_FORM_DEFAULT_VALUE,
  SOURCE_CODE_CONFIG,
  VISUALISATION_CONFIG,
  MAX_GPU_CONFIG,
  COST_MANAGEMENT_CONFIG,
  USE_DAP,
  MAX_TRIALS,
  UPLOAD_CODE,
  INSTANCE_TYPES
} from '../../constants/FormConstants/HyperparameterTemplateFormConfig'
import { useGetTemplatesQuery, useCreateTemplateMutation, useEditTemplateMutation, useDeleteTemplateMutation } from '../../services/templatesApi'
import { useCreateJobMutation, useGetJobQuery } from '../../services/jobsApi'
import {
  useGetVisualizationQuery
} from '../../services/visualizationApi'
import { useGetUserCloudSubscriptionsQuery, useGetGpuTypesMutation, useGetInstanceTypesMutation } from '../../services/cloudApi'
import { deletePropertyPath } from '../../utils'
import { setError } from '../../datastore/UI/uiSlice'
import { useGetSecretsQuery } from '../../services/secretsApi'
import { useGetArtifactStoragePathsQuery } from '../../services/artifactStorageApi'
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CustomizedSteppers from '../../components/Stepper';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const LaunchHPTTemplateView = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const platformBearer = useSelector((state) => state.userState.platformBearer)
  const [templateCredentials, setTemplateCredentials] = useState(null)
  const [autoFillData, setAutoFillData] = useState(type === "templating")
  const [openAccordions, setOpenAccordions] = useState(type === "templating")
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null)
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [stage, setStage] = useState(0);
  const [config, setConfig] = useState(TAB_CONFIGS.CONFIG.id)
  const [currentNameOfJob, SetCurrentNameOfJob] = useState('');
  const [formErrors, setFormErrors] = useState(null)
  // State variables for all the controlled accordions
  const [expandedConfig, setExpandedConfig] = React.useState(false);

  const handleChangeConfig = () => (event) => {
    setExpandedConfig(expandedConfig ? false : true);
  };

  /**
   * State to toggle between manual and docker image form fields
   */
  const [imageSettings, setImageSettings] = useState(TAB_CONFIGS.MANUAL_SETTINGS.id)
  /**
   * State to toggle between docker and requirements file form fields
   */
  const [dockerSettings, setDockerSettings] = useState(TAB_CONFIGS.REQUIREMENTS.id)
  /**
 * State to toggle between CPU and GPU fields
 */
  const [cpuOrGpu, setCpuOrGpu] = useState(TAB_CONFIGS.GPU_SETTINGS.id)
  /**
   * State to toggle credential fields
   */
  const [showCredentials, setShowCredentials] = useState(false)
  const [pickInstance, setPickInstance] = useState(TAB_CONFIGS.PICK_GPU.id)

  /**
   * State to toggle credential fields
   */
  const [showDockerCredentials, SetShowDockerCredentials] = useState(false)
  /**
   * Sate to hold the form config. We keep it in a separate state as its sbjected to changes 
   */
  const [gpuFieldsConfig, setGpuFieldsConfig] = useState(JOB_GPU_FIELDS_CONFIG)
  const [instanceConfig, setInstanceConfig] = useState(INSTANCE_TYPES)

  const [cloudFieldsConfig, setCloudFieldsConfig] = useState(CLOUD_PROVIDER_FIELDS_CONFIG)
  const [artifactsDestinationConfig, setArtifactsDestinationConfig] = useState(ARTIFACTS_DESTINATION)
  const [virtualMountConfig, setVirtualMountConfig] = useState(VIRTUAL_MOUNT)
  const [visualisationConfig, setVisualisationConfig] = useState(VISUALISATION_CONFIG)
  const [templateData, setTemplateData] = useState(null)
  const [credentials, setCredentials] = useState(null)
  const [showDAPSettings, SetShowDAPSettings] = useState(false)
  /**
  * State to toggle advance fields collapse
  */
  const [showAdvanceFields, setShowAdvanceFields] = useState(false)
  /**
   * Code directory credentials form config
   */
  const [codeCredentialFormConfig, setCodeCredentialFormConfig] = useState(CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG)
  /**
   * Get user cloud providers
   */
  const {
    data: cloudSubscriptionsData,
    error: cloudSubscriptionsApiError
  } = useGetUserCloudSubscriptionsQuery()
  /**
   * Get GPU types
   */
  const [getGpuTypes, {
    data: gpuTypesData
  }] = useGetGpuTypesMutation()

  const [getInstances, {
    data: getInstanceTypes
  }] = useGetInstanceTypesMutation()
  /**
   * Get Templates
   */
  const {
    data: allTemplates, isLoading: isLoadingAllTemplates
  } = useGetTemplatesQuery()
  /**
   * Get Artifacts Storage
   */
  const {
    data: artifactsStoragePaths
  } = useGetArtifactStoragePathsQuery()

  /**
   * Get Virtual Mounts
   */
  const {
    data: virtualMounts
  } = useGetVirtualMountsQuery()

  /**
   * Get Visualisation keys
   */
  const {
    data: allVizKeys
  } = useGetVisualizationQuery()

  /**
   * Get Secret keys
   */
  const {
    data: allSecretKeys
  } = useGetSecretsQuery('TEMPLATE')

  /**
   * API to submit form data
   */
  const [submit, { isLoading }] = useCreateTemplateMutation()
  /**
   * API to update template
   */
  const [update, { isLoading: isLoadingUpdate }] = useEditTemplateMutation();
  /**
  * API to delete template
  */
  const [deleteTemplate, { isLoading: isLoadingDelete }] = useDeleteTemplateMutation();
  /**
   * Initialize form state
   */
  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    watch
  } = useForm({
    defaultValues: LAUNCH_HYPERPARAMETER_TUNING_JOB_FORM_DEFAULT_VALUE
  })
  /**
   * Create watchers on specific fields
   */
  const watchCloudProviders = watch('cloudProviders')
  const watchCloudRegions = watch('cloudRegions')
  const watchCodeTransferType = watch('codeTransfer.type')
  const watchVisualisationSelect = watch('visualisation.type')
  const watchUseDAP = watch('useDAPP')
  /**
   * Set the initial values for fields from API
   */
  useEffect(() => {
    if (cloudSubscriptionsData) {
      if (getValues()[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id].length === 0) {
        const [cloudProviderOptions] = cloudSubscriptionsData;
        setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id, cloudProviderOptions)
      }
    }
  }, [cloudSubscriptionsData, setValue])

  useEffect(() => {
    if (cloudSubscriptionsData && Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0) {
      const { 1: cloudRegionOptions } = cloudSubscriptionsData;
      setValue(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id, cloudRegionOptions);
    }
  }, [cloudSubscriptionsData, setValue, watchCloudProviders])

  useEffect(() => {
    if (allTemplates) {
      const templateObject = allTemplates.filter(s => s.id === location.pathname.split('/').pop())
      if (templateObject.length > 0) {
        setTemplateData(templateObject[0])
      }
    }
  }, [allTemplates])

  useEffect(()=>{
    if(allSecretKeys && type==="templating"){
      const templateSecrets = allSecretKeys.filter(s => s.split(':')[1].slice(0,s.split(':')[1].indexOf('_'))===location.pathname.split('/').pop())
      let requests = []
      const baseUrl = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : process.env.REACT_APP_BASE_API_URL;
      templateSecrets.forEach((key)=>{
        requests.push(axios.get(baseUrl + `/secret/${key}`,
        {
          headers: {
            Authorization: `Bearer ${platformBearer}`,
          },
        }
        ))
      })
      axios.all(requests)
      .then(axios.spread((...res) => {
        // Both requests are now complete
        setTemplateCredentials(Object.assign({}, ...res.map(r => r.data.secret)));
      }));
    }
  },[allSecretKeys])
  /**
   * Set the options for fields from API data and dependent field values
   */
  useEffect(() => {
    if (cloudSubscriptionsData) {
      const [cloudProviderOptions, cloudRegionOptions] = cloudSubscriptionsData
      function update(state) {
        return {
          ...state,
          [CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id]: {
            ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id],
            options: Array.isArray(cloudProviderOptions) ? cloudProviderOptions : [],
          },
          [CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id]: {
            ...state[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id],
            disabled: !(Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0),
            options: Array.isArray(cloudRegionOptions) ?
              cloudRegionOptions.filter(region => watchCloudProviders.some(provider => region.indexOf(provider) === 0))
              : []
          },
          [JOB_GPU_FIELDS_CONFIG.gpuTypes.id]: {
            ...state[JOB_GPU_FIELDS_CONFIG.gpuTypes.id],
            disabled: !(Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0) ||
              !(Array.isArray(gpuTypesData) && gpuTypesData.length > 0),
            options: Array.isArray(gpuTypesData) ? gpuTypesData : []
          },
          [INSTANCE_TYPES.instanceTypes.id]: {
            ...state[INSTANCE_TYPES.instanceTypes.id],
            disabled: !(Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0) ||
              !(Array.isArray(getInstanceTypes) && getInstanceTypes.length > 0),
            options: Array.isArray(getInstanceTypes) ? getInstanceTypes : []
          }
        }
      }

      setGpuFieldsConfig(update)
      setInstanceConfig(update)
      setCloudFieldsConfig(update)
    }
  }, [cloudSubscriptionsData, gpuTypesData, getInstanceTypes, watchCloudProviders, watchCloudRegions])
  /**
   * Filter the values of cloud regions on cloud provider change
   */
  useEffect(() => {
    setValue(
      CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id,
      getValues(CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id)
        .filter(region => watchCloudProviders.some(provider => region.indexOf(provider) === 0)))
  }, [getValues, setValue, watchCloudProviders])
  /**
   * Reset the value of `gpuTypes` when `cloudProvider` or `cloudRegion` data changes
   */
  useEffect(() => {
    if (gpuTypesData) {
      const updatedGpus = getValues()[JOB_GPU_FIELDS_CONFIG.gpuTypes.id]?.filter(item => gpuTypesData.includes(item));
      setValue(JOB_GPU_FIELDS_CONFIG.gpuTypes.id, updatedGpus)
    }
  }, [setValue, gpuTypesData, watchCloudProviders, watchCloudRegions])

  useEffect(() => {
    if (getInstanceTypes) {
      const updatedInstances = getValues()[INSTANCE_TYPES.instanceTypes.id]?.filter(item => getInstanceTypes.includes(item));
      setValue(INSTANCE_TYPES.instanceTypes.id, updatedInstances)
    }
  }, [setValue, getInstanceTypes, watchCloudProviders, watchCloudRegions])
  /**
   * Call getGpuTypes API in response to the changes in dependent fields
   */
  useEffect(() => {
    if (Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0 && Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0) {
      getGpuTypes({
        cloudProviders: watchCloudProviders,
        cloudRegions: watchCloudRegions
      })
        .unwrap()
        .catch(error => console.log(error?.data))
    }
  }, [getGpuTypes, watchCloudProviders, watchCloudRegions])

  useEffect(() => {
    if (Array.isArray(watchCloudProviders) && watchCloudProviders.length > 0 && Array.isArray(watchCloudRegions) && watchCloudRegions.length > 0) {
      getInstances({
        cloudProviders: watchCloudProviders,
        cloudRegions: watchCloudRegions
      })
        .unwrap()
        .catch(error => console.log(error?.data))
    }
  }, [getInstances, watchCloudProviders, watchCloudRegions])

  /**
   * Set code credential fields as per code repo host
   * Reset the value of credentials when codeTransfer field changes
   */
  useEffect(() => {
    setCodeCredentialFormConfig(state => ({
      ...state,
      credentials: {
        ...CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG.credentials,
        fields: watchCodeTransferType ? CODE_DIRECTORY_CREDENTIALS_FIELDS_CONFIG[watchCodeTransferType] : {}
      }
    }))
    setValue(CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG.credentials.id, {})
  }, [setValue, watchCodeTransferType])

  useEffect(() => {
    if (credentials) {
      setValue(DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG.credentials.id, credentials);
      setValue(CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG.credentials.id, credentials);
      setCredentials(null);
    }
  }, [setValue, credentials])

  /**
   * Set error message to `cloudSubscriptionsApiError`
   */
  useEffect(() => {
    if (cloudSubscriptionsApiError) {
      dispatch(setError(cloudSubscriptionsApiError))
    }
  }, [cloudSubscriptionsApiError, dispatch])
  /**
   * Set the options for fields for ArtifactStoragePath
   */
  useEffect(() => {
    if (artifactsStoragePaths) {

      const optionValues = artifactsStoragePaths.map((item) => item.name + ":" + item.path)
      // optionValues.push('WANDB');
      // optionValues.push('COMETML');
      function update(state) {
        const newObject = structuredClone(state)
        newObject.artifactsDestination.fields.name.options = Array.isArray(optionValues) ?
          optionValues : [] //['WANDB', 'COMETML']

        return newObject
      }

      setArtifactsDestinationConfig(update)
      setValue(ARTIFACTS_DESTINATION.artifactsDestination.fields.name.id,
        optionValues[0])
    }
  }, [artifactsStoragePaths, setValue])

  /**
   * Set the options for fields for Virtual Mounts
   */
  useEffect(() => {
    if (virtualMounts) {
      const allNames = Array.isArray(virtualMounts) ? virtualMounts.map(i => i.name) : []
      setVirtualMountConfig((state) => {
        const newObject = structuredClone(state)
        newObject.virtualMount.options = Array.isArray(allNames) ? allNames : []
        return newObject
      })
      if(virtualMounts.length > 0 && type==='new'){
        addData(virtualMounts[0])
      }
    }
  }, [virtualMounts, setValue]);

  useEffect(() => {
    if (allVizKeys) {
      if (watchVisualisationSelect) {
        const selectedVizArray = allVizKeys.filter(s => s.type.includes(watchVisualisationSelect));
        setVisualisationConfig((state) => {
          const newObject = structuredClone(state)
          if (watchVisualisationSelect === "WANDB" || watchVisualisationSelect === "COMETML") {
            newObject.visualisation.fields.viz_api_key.display = "";
          } else {
            newObject.visualisation.fields.viz_api_key.display = "none";
          }
          newObject.visualisation.fields.viz_api_key.disabled = true;
          return newObject
        })
        setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, selectedVizArray.length ? selectedVizArray[0].key : "");

      } else {
        setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
      }
    }
  }, [watchVisualisationSelect, allVizKeys])

  // Add the selected Virtual Mount to the object array
  function addData(virtualMount) {
    const currentVirtualMountValues = getValues()[VIRTUAL_MOUNT.virtualMount.id];
    virtualMount = [virtualMount]
    let virtualMountValues = Object.assign(currentVirtualMountValues ?? {}, ...virtualMount.map((x) => ({ [x.name]: x })));
    setValue(VIRTUAL_MOUNT.virtualMount.id, virtualMountValues);
    setOpenCreateModal(false);
  }

  // Toggle disability of api key field
  function disableField() {
    setVisualisationConfig((state) => {
      const newObject = structuredClone(state)
      newObject.visualisation.fields.viz_api_key.disabled = !newObject.visualisation.fields.viz_api_key.disabled;
      return newObject
    })
    setValue(VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id, "");
  }

  useEffect(() => {
    SetShowDAPSettings(watchUseDAP)
  }, [watchUseDAP])

  // Auto fill the form if experiment is being retried
  useEffect(() => {
    if (autoFillData && artifactsStoragePaths && templateData && templateCredentials) {
      // console.log(templateData)
      let dataToBeFilled = JSON.parse(JSON.stringify(templateData.config));
      if (dataToBeFilled.codeTransfer) {
        Object.entries(dataToBeFilled.codeTransfer).forEach(
          ([name, value]) => {
            if(name === 'type' && value === 'B2'){
              setImageSettings(TAB_CONFIGS.UPLOAD_CODE.id);
            }
            setValue('codeTransfer.' + name, value ?? '')
          });
      }
      if (dataToBeFilled.customImage) {
        setDockerSettings(TAB_CONFIGS.DOCKER_SETTINGS.id);
        Object.entries(dataToBeFilled.customImage).forEach(
          ([name, value]) => {
            setValue('customImage.' + name, value ?? '')
          });
      }

      if (templateData && templateCredentials) {
        const envSecretNames = (dataToBeFilled.environment ? Object.keys(dataToBeFilled.environment) : []);
        let newTemplateCredsObject = {}
        let envArgObject = {}
        Object.keys(templateCredentials).forEach(key => {
          const tempKey = key.split(':')[1].slice(key.split(':')[1].indexOf('_') + 1)
          if (tempKey.split('_')[0] === 'DOCKER') {
            SetShowDockerCredentials(true);
            newTemplateCredsObject[tempKey.split('_')[1].toLowerCase()] = templateCredentials[key]
          } else if (envSecretNames.indexOf(tempKey) > -1) {
            envArgObject[tempKey] = templateCredentials[key]
          } else {
            newTemplateCredsObject[tempKey] = templateCredentials[key]
          }
        })
        setValue('environment', envArgObject);
        setShowCredentials(true);
        setCredentials(newTemplateCredsObject);
      }
      let cldProviders = []
      let cldRegions = []
      dataToBeFilled.cloudProviders.forEach(
        (cldObject) => {
          cldProviders.push(cldObject.name)
          cldObject.regions.forEach(r => {
            cldRegions.push(cldObject.name + ':' + r)
          })
        });
      setValue('cloudProviders', cldProviders)
      setValue('cloudRegions', cldRegions)
      Object.entries(dataToBeFilled.visualisation).forEach(
        ([name, value]) => {
          setValue('visualisation.' + name, value ?? '')
        });
      if (dataToBeFilled.artifactsDestination) {
        const selectedartifactsDestination = artifactsStoragePaths.filter(s => s.name.includes(dataToBeFilled.artifactsDestination.name))[0];
        if (selectedartifactsDestination) {
          setValue('artifactsDestination.name', selectedartifactsDestination.name + ':' + selectedartifactsDestination.path)
          setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
        } else {
          setValue('artifactsDestination.name', dataToBeFilled.artifactsDestination.name)
          setValue('artifactsDestination.filter', dataToBeFilled.artifactsDestination.filter ?? "")
        }

      }
      if (dataToBeFilled.virtualMounts) {
        // console.log(dataToBeFilled.virtualMounts)
        setValue(VIRTUAL_MOUNT.virtualMount.id, {})
        dataToBeFilled.virtualMounts.forEach(
          (vMount) => {
            addData(vMount);
          });
      }
      let tuningObject = {}
      if (dataToBeFilled.tuning?.searchSpace) {
        Object.entries(dataToBeFilled.tuning.searchSpace).forEach(
          ([name, value]) => {
            tuningObject[name] = value;
          });
        setValue('tuning.searchSpace', tuningObject)
      }
      if (dataToBeFilled.tuning?.tuner) {
        setValue('tuning.tuner', dataToBeFilled.tuning?.tuner)
      }
      setValue('maxCostPerHour', dataToBeFilled['maxPricePerHour'] ?? '')

      if (dataToBeFilled.maxCPUWorkers) {
        setCpuOrGpu(TAB_CONFIGS.CPU_SETTINGS.id)
      } else {
        setCpuOrGpu(TAB_CONFIGS.GPU_SETTINGS.id)
        if(dataToBeFilled.instanceTypes){
          setPickInstance(TAB_CONFIGS.PICK_INSTANCE.id)
        }
        delete dataToBeFilled['maxCPUWorkers'];
      }
      if (dataToBeFilled['dapp']['epochs'] != -1){
        dataToBeFilled['epochs'] = dataToBeFilled['dapp']['epochs'];
        SetShowDAPSettings(true);
        delete dataToBeFilled['dapp'];
      }
      SetCurrentNameOfJob(dataToBeFilled['name'])
      // delete dataToBeFilled['name'];
      delete dataToBeFilled['experiment'];
      delete dataToBeFilled['environment'];
      delete dataToBeFilled['tuning'];
      delete dataToBeFilled['maxPricePerHour'];
      delete dataToBeFilled['codeTransfer'];
      delete dataToBeFilled['cloudProviders'];
      delete dataToBeFilled['visualisation'];
      delete dataToBeFilled['artifactsDestination'];
      delete dataToBeFilled['virtualMounts'];
      Object.entries(dataToBeFilled).forEach(
        ([name, value]) => {
          setValue(name, value)
        });
      setAutoFillData(null);
    }
  }, [templateData, templateCredentials, autoFillData, artifactsStoragePaths, setValue])

  useEffect(() => {
    if (formState.errors
      && Object.keys(formState.errors).length > 0
      && Object.getPrototypeOf(formState.errors) === Object.prototype) {
      setFormErrors(formState.errors)
    } else {
      setFormErrors(null)
    }
  }, [formState])

  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData 
   */
  const onSubmit = useCallback((formData) => {
    const data = {
      ...formData
    }
    /**
     * Cleaning the data as per user selections
     */
    data['name'] = currentNameOfJob;
    if (data['name'] === '' && type === 'new'){
        setStage(0)
        dispatch(setError("Please enter a name for your template."))
        return;
    }
    if (imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id) {
      deletePropertyPath(data, UPLOAD_CODE.codeZip.id)
    } else{
      deletePropertyPath(data, UPLOAD_CODE.codeZip.id)
    }

    if (dockerSettings === TAB_CONFIGS.REQUIREMENTS.id) {
      deletePropertyPath(data, DOCKER_IMAGE_SETTINGS_FORM_CONFIG.customImage.id)
      deletePropertyPath(data, DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (!showCredentials && imageSettings != TAB_CONFIGS.UPLOAD_CODE.id) {
      deletePropertyPath(data, CODE_DIRECTORY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (!showDockerCredentials && dockerSettings != TAB_CONFIGS.REQUIREMENTS.id) {
      deletePropertyPath(data, DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG.credentials.id)
    }

    if (cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id) {
      Object.values(JOB_GPU_FIELDS_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      Object.values(MAX_GPU_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      Object.values(INSTANCE_TYPES).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      deletePropertyPath(data, USE_DAP.useDAPP.id)
    } else {
      Object.values(JOB_CPU_FIELDS_CONFIG).forEach(element => {
        deletePropertyPath(data, element.id)
      })
      if(pickInstance === TAB_CONFIGS.PICK_INSTANCE.id){
        data[JOB_GPU_FIELDS_CONFIG.gpusPerTrial.id] = 1;
        Object.values(JOB_GPU_FIELDS_CONFIG).forEach(element => {
          deletePropertyPath(data, element.id)
        })
      }else{
        Object.values(INSTANCE_TYPES).forEach(element => {
          deletePropertyPath(data, element.id)
        })
      }
    }

    // creting the cloudProvider object
    let cps = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id];
    let crs = data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id];
    const getRegions = (cp) => {
      return crs
        .filter((cr) => cr.startsWith(cp))
        .map((cr) => cr.split(":")[1]);
    };
    const cloudProviders = cps.map((cp) => ({
      name: cp,
      regions: getRegions(cp),
    }));
    data[CLOUD_PROVIDER_FIELDS_CONFIG.cloudProviders.id] = cloudProviders;
    deletePropertyPath(data, CLOUD_PROVIDER_FIELDS_CONFIG.cloudRegions.id);

    if (data[VISUALISATION_CONFIG.visualisation.id].type === '')
      deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.id);

    data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] =
      (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] ?? ":").split(":")[0]

    if (data[ARTIFACTS_DESTINATION.artifactsDestination.id]['name'] === "")
      deletePropertyPath(data, ARTIFACTS_DESTINATION.artifactsDestination.id);

    //creating the selected virtualMount object
    data[VIRTUAL_MOUNT.virtualMount.id] = Object.values(data[VIRTUAL_MOUNT.virtualMount.id] ?? {})

    deletePropertyPath(data, VISUALISATION_CONFIG.visualisation.fields.viz_api_key.id);

    // deletePropertyPath(data, JOB_ADVANCE_FIELD_CONFIG.environment.id);
    if (data[USE_DAP.useDAPP.id] === false) {
      data['dapp'] = {'epochs' : -1}
    }else{
      data['dapp'] = {'epochs' : data['epochs']}
    }
    delete data['epochs'];
    // console.log(data);
    if (type === 'templating') {
      update({
        id: location.pathname.split('/').pop(),
        config: data
      })
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
          }, 1000)
        })
        .catch((error) => {
          dispatch(setError(error))
        });
    } else {
      submit({
        id: data.name,
        name: data.name,
        config: data,
        type: JOB_TYPE_HYPERPARAMETER_TRAINING
      })
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
          }, 1000)
        })
        .catch(error => dispatch(setError(error)))
    }
  }, [cpuOrGpu, dispatch, imageSettings, dockerSettings, pickInstance, navigate, showCredentials, showDockerCredentials, virtualMounts, submit])
  const onDelete =
    () => {
      deleteTemplate(
        location.pathname.split('/').pop()
      )
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(`../${VIEW_ROUTES.TEMPLATES}`, { replace: true })
          }, 1000)
        })
        .catch((error) => {
          dispatch(setError(error))
        });
    }
  function openModal() {
    setOpenCreateModal(true);
  }

  return (
    <>
      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Typography variant="h5">
            {VIEW_HEADERS.LAUNCH_NEW_HYPERPARAMETER_TUNING_JOB}
          </Typography>
          <FormControl sx={{ m: 1 }} variant="outlined" size="small">
            <InputLabel htmlFor="outlined-adornment-job-name">Enter Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-job-name"
              type='text'
              value={currentNameOfJob}
              endAdornment={
                <InputAdornment position="end">
                  <EditIcon />
                </InputAdornment>
              }
              label="Enter Job Name"
              onChange={(event) => {
                SetCurrentNameOfJob(event.target.value)
                setValue('name', event.target.value)
              }}
            />
          </FormControl>
        </Stack>
      </Container>
      <br />
      <CustomizedSteppers step={stage} steps={['Code', 'Data', 'Configure', 'Deploy']} setStage={setStage} />
      
      <Container disableGutters maxWidth='md' sx={{ marginBlockStart: 4 }}>
        {formErrors &&
          <Alert severity="error">
            <AlertTitle>Form Error</AlertTitle>
            Please check the following fields — <strong>[{Object.keys(formErrors).join(', ')}]</strong>
          </Alert>
        }
        {isLoadingAllTemplates && type === "templating" && <LoadingOverlay />}
        <Form
          control={control}
          fields={{}}
          loading={isLoading || isLoadingUpdate || isLoadingDelete}
          buttonLabel={type === "new" ? 'Create HPT Template' : 'Update HPT Template'}
          onSubmit={handleSubmit(onSubmit)}
          submitAtEnd={true}
        >
          {<Box sx={stage != 0 ?{display: 'none'} : {}}>
            <Typography gutterBottom variant="h5" component="div">
              Source Code
            </Typography>
            <Stack>
              <FormFields control={control} fields={SOURCE_CODE_CONFIG} />
              {stage===0 && <Tabs sx={{ m: 1 }} value={imageSettings} onChange={(e, value) => {setValue("codeZip", null);setImageSettings(value)}}>
                <Tab  style={{background: "#292929"}} label={TAB_CONFIGS.UPLOAD_CODE.label} value={TAB_CONFIGS.UPLOAD_CODE.id} />
                <Tab  style={{background: "#292929"}} label={TAB_CONFIGS.MANUAL_SETTINGS.label} value={TAB_CONFIGS.MANUAL_SETTINGS.id} />
                {/* <Tab  style={{background: "#292929"}} label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} /> */}
              </Tabs>}
              
              {imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id && (
                <>
                  <FormFields control={control} fields={MANUAL_IMAGE_SETTINGS_FORM_CONFIG} />
                  <FormControlLabel
                    id={JOB_CONTROL_CONFIGS.IS_REPO_PRIVATE.id}
                    label={JOB_CONTROL_CONFIGS.IS_REPO_PRIVATE.label}
                    control={<Switch sx={{ m: 1 }} checked={showCredentials} onChange={(e) => setShowCredentials(e.target.checked)} />}
                  />
                  {showCredentials && watchCodeTransferType && <FormFields control={control} fields={codeCredentialFormConfig} />}
                </>
              )}
              {imageSettings === TAB_CONFIGS.UPLOAD_CODE.id && (
                <>
                  <FormFields control={control} fields={UPLOAD_CODE} updateUploadedFileId={setUploadedFileId}/>
                </>
              )}
            </Stack>
            <Stack>
              {stage===0 && <Tabs sx={{ m: 1 }} value={dockerSettings} onChange={(e, value) => {setDockerSettings(value)}}>
                <Tab  style={{background: "#292929"}} label={TAB_CONFIGS.REQUIREMENTS.label} value={TAB_CONFIGS.REQUIREMENTS.id} />
                <Tab  style={{background: "#292929"}} label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} />
              </Tabs>}
              
              {dockerSettings === TAB_CONFIGS.REQUIREMENTS.id && (
                <>
                  <FormFields control={control} fields={REQUIREMENTS_FILE} />
                </>
              )}
              {dockerSettings === TAB_CONFIGS.DOCKER_SETTINGS.id && (
                <>
                  <FormFields control={control} fields={DOCKER_IMAGE_SETTINGS_FORM_CONFIG} />
                  <FormControlLabel
                    id={JOB_CONTROL_CONFIGS.IS_DOCKER_PRIVATE.id}
                    label={JOB_CONTROL_CONFIGS.IS_DOCKER_PRIVATE.label}
                    control={<Switch sx={{ m: 1 }} checked={showDockerCredentials} onChange={(e) => SetShowDockerCredentials(e.target.checked)} />}
                  />
                  {showDockerCredentials && <FormFields control={control} fields={DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG} />}
                </>
              )}
            </Stack>
            <br />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                color='secondary'
                onClick={() => {
                  setStage(stage + 1)
                }}
                variant='contained'
                sx={{ borderRadius: '20px' }}
              >
                Configure Data
                <ArrowForwardIcon />
              </Button>
            </Stack>
          </Box>}
          {<Box sx={stage != 1 ?{display: 'none'} : {}}>
            <Typography gutterBottom variant="h5" component="div">
              Data Sources
            </Typography>
            <FormFields control={control} fields={virtualMountConfig} openModal={openModal} />
            <Modal
              onClose={() => setOpenCreateModal(false)}
              open={openCreateModal}
              title="Add Virtual Mount"
            >
              <AddVirtualMountView
                addData={addData}
              />
            </Modal>
            <br />
            <Typography gutterBottom variant="h5" component="div">
              Artifact Storage
            </Typography>
            <FormFields control={control} fields={artifactsDestinationConfig} />
            <br />
            <Typography gutterBottom variant="h5" component="div">
              Experiments Tracking
            </Typography>
            <FormFields control={control} fields={visualisationConfig} disableField={disableField} toValidate={watchVisualisationSelect} validateInput={true} />
            <br />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                color='secondary'
                onClick={() => {
                  setStage(stage + 1)
                }}
                variant='contained'
                sx={{ borderRadius: '20px' }}
              >
                Next
                <ArrowForwardIcon />
              </Button>
            </Stack>
          </Box>}
          {<Box sx={stage != 2 ?{display: 'none'} : {}}>
            {stage===2 && <Tabs sx={{ m: 1 }} value={config} onChange={(e, value) => setConfig(value)}>
              <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CONFIG.label} value={TAB_CONFIGS.CONFIG.id} />
              <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.ENVIRONMENT_CONFIG.label} value={TAB_CONFIGS.ENVIRONMENT_CONFIG.id} />
            </Tabs>}
            {config === TAB_CONFIGS.CONFIG.id && (
              <>
                <Stack>
                  <FormFields control={control} fields={HPT_MANDATORY_FIELDS_CONFIG} />
                  {stage===2 && <Tabs sx={{ m: 1 }} value={cpuOrGpu} onChange={(e, value) => setCpuOrGpu(value)}>
                    <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.CPU_SETTINGS.label} value={TAB_CONFIGS.CPU_SETTINGS.id} />
                    <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.GPU_SETTINGS.label} value={TAB_CONFIGS.GPU_SETTINGS.id} />
                  </Tabs>}
                  {cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id && (
                    <FormFields control={control} fields={JOB_CPU_FIELDS_CONFIG} />
                  )}
                  {cpuOrGpu === TAB_CONFIGS.GPU_SETTINGS.id && (
                    <>
                      <FormFields control={control} fields={USE_DAP} />
                      {showDAPSettings && <FormFields control={control} fields={DAP_SETTINGS} />}
                      <br />
                      {stage === 2 && <Tabs sx={{ m: 1 }} value={pickInstance} onChange={(e, value) => setPickInstance(value)}>
                        <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.PICK_INSTANCE.label} value={TAB_CONFIGS.PICK_INSTANCE.id} />
                        <Tab style={{ background: "#292929" }} label={TAB_CONFIGS.PICK_GPU.label} value={TAB_CONFIGS.PICK_GPU.id} />
                      </Tabs>}
                      {pickInstance === TAB_CONFIGS.PICK_GPU.id && (
                        <>
                          <FormFields control={control} fields={gpuFieldsConfig} />
                          <FormFields control={control} fields={CUDA} />
                        </>
                      )}
                      {pickInstance === TAB_CONFIGS.PICK_INSTANCE.id && (
                        <>
                          <FormFields control={control} fields={instanceConfig} />
                          <FormFields control={control} fields={CUDA} />
                        </>
                      )}
                      <FormFields control={control} fields={MAX_GPU_CONFIG} />
                    </>
                  )}
                  <FormFields control={control} fields={MAX_TRIALS} />
                </Stack>
                <br />
                <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#292929', borderRadius: '8px' }} expanded={expandedConfig} onChange={handleChangeConfig()}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Stack>
                      <Typography gutterBottom variant="h5" component="div">
                        Advanced
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Specify necessary parameters to start the experiment
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails >
                    <Divider sx={{ m: 1 }}>
                    </Divider>
                    <FormFields control={control} fields={cloudFieldsConfig} />
                    <FormFields control={control} fields={CPU_FIELDS_CONFIGS} />
                  </AccordionDetails>
                </Accordion>
                <br />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color='secondary'
                    onClick={() => {
                      setStage(stage + 1)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Review & Deploy
                    <ArrowForwardIcon />
                  </Button>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setConfig(TAB_CONFIGS.ENVIRONMENT_CONFIG.id)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Next
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </>

            )}
            {config === TAB_CONFIGS.ENVIRONMENT_CONFIG.id && (
              <>
                <Stack>
                  <FormFields control={control} fields={JOB_ADVANCE_FIELD_CONFIG} />
                  <FormFields control={control} fields={MANUAL_PRE_POST_CMD_CONFIG} />
                </Stack>
                <br />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    color='secondary'
                    onClick={() => {
                      setStage(stage + 1)
                    }}
                    variant='contained'
                    sx={{ borderRadius: '20px', m: 1 }}
                  >
                    Deploy
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </>
            )}
          </Box>}
          {<Box sx={stage != 3 ?{display: 'none'} : {}}>
            <FormFields control={control} fields={COST_MANAGEMENT_CONFIG} />
            <br/>
            <br/>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                fullWidth
                color='success'
                onClick={handleSubmit(onSubmit)}
                variant='contained'
                sx={{ borderRadius: '20px',}}
                role='SUBMIT' 
                data-testid='submit' 
                loading={isLoading || isLoadingUpdate} 
                type='submit' 
              >
                {type==="new" ?
                    <>
                        <CreateIcon sx={{mr:1}}/>
                    </> :
                    <>
                        <UpdateIcon sx={{mr:1}}/>
                    </>
                }
                {type==="new" ? 'Create Experiment Template' : 'Update Experiment Template'}
              </LoadingButton>
            </Stack>
            <br/>
            {type==='templating' && 
                <Stack direction="row" justifyContent="flex-end">
                    <LoadingButton
                        fullWidth
                        color='error'
                        loading={isLoadingDelete}
                        onClick={() => onDelete()}
                        variant='contained'
                        sx={{borderRadius: '20px'}}
                    >
                        <DeleteIcon sx={{mr:1}}/>
                        Delete Template
                    </LoadingButton>
                </Stack>
            }
          </Box>}
          {/* <Divider textAlign="left">
            <Typography variant="h4" component="h2">
              Basic Configuration
            </Typography>
          </Divider>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Main Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Specify necessary parameters to start the experiment
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={HPT_MANDATORY_FIELDS_CONFIG} />
                <Tabs sx={{ m: 1 }} value={cpuOrGpu} onChange={(e, value) => setCpuOrGpu(value)}>
                  <Tab label={TAB_CONFIGS.CPU_SETTINGS.label} value={TAB_CONFIGS.CPU_SETTINGS.id} />
                  <Tab label={TAB_CONFIGS.GPU_SETTINGS.label} value={TAB_CONFIGS.GPU_SETTINGS.id} />
                </Tabs>
                {cpuOrGpu === TAB_CONFIGS.CPU_SETTINGS.id && (
                  <FormFields control={control} fields={JOB_CPU_FIELDS_CONFIG} />
                )}
                {cpuOrGpu === TAB_CONFIGS.GPU_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={USE_DAP} />
                    {showDAPSettings && <FormFields control={control} fields={DAP_SETTINGS} />}
                    <br />
                    <FormFields control={control} fields={gpuFieldsConfig} />
                    <FormFields control={control} fields={MAX_GPU_CONFIG} />
                  </>
                )}
                <FormFields control={control} fields={CPU_FIELDS_CONFIGS} />
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Source Code
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do we get your code from?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={SOURCE_CODE_CONFIG} />
                <Tabs sx={{ m: 1 }} value={imageSettings} onChange={(e, value) => setImageSettings(value)}>
                  <Tab label={TAB_CONFIGS.MANUAL_SETTINGS.label} value={TAB_CONFIGS.MANUAL_SETTINGS.id} />
                  <Tab label={TAB_CONFIGS.DOCKER_SETTINGS.label} value={TAB_CONFIGS.DOCKER_SETTINGS.id} />
                </Tabs>
                {imageSettings === TAB_CONFIGS.MANUAL_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={MANUAL_IMAGE_SETTINGS_FORM_CONFIG} />
                    <FormControlLabel
                      id={JOB_CONTROL_CONFIGS.IS_REPO_PRIVATE.id}
                      label={JOB_CONTROL_CONFIGS.IS_REPO_PRIVATE.label}
                      control={<Switch sx={{ m: 1 }} checked={showCredentials} onChange={(e) => setShowCredentials(e.target.checked)} />}
                    />
                    {showCredentials && watchCodeTransferType && <FormFields control={control} fields={codeCredentialFormConfig} />}
                  </>
                )}
                {imageSettings === TAB_CONFIGS.DOCKER_SETTINGS.id && (
                  <>
                    <FormFields control={control} fields={DOCKER_IMAGE_SETTINGS_FORM_CONFIG} />
                    <FormControlLabel
                      id={JOB_CONTROL_CONFIGS.IS_DOCKER_PRIVATE.id}
                      label={JOB_CONTROL_CONFIGS.IS_DOCKER_PRIVATE.label}
                      control={<Switch sx={{ m: 1 }} checked={showCredentials} onChange={(e) => setShowCredentials(e.target.checked)} />}
                    />
                    {showCredentials && <FormFields control={control} fields={DOCKER_REGISTRY_CREDENTIALS_FORM_CONFIG} />}
                  </>
                )}

              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Data Streaming
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do we get your data from?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={virtualMountConfig} openModal={openModal} />
              <Modal
                onClose={() => setOpenCreateModal(false)}
                open={openCreateModal}
                title="Add Virtual Mount"
              >
                <AddVirtualMountView
                  addData={addData}
                />
              </Modal>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Artifact Storage
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do you want to see the results?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={artifactsDestinationConfig} />
            </AccordionDetails>
          </Accordion>
          <Divider textAlign="left">
            <Typography variant="h4" component="h2">
              Advanced Configuration
            </Typography>
          </Divider>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Experiments Tracking
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Where do you want to see the results?
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <FormFields control={control} fields={visualisationConfig} disableField={disableField} toValidate={watchVisualisationSelect} validateInput={true} />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Cloud Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Cloud Specification
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={cloudFieldsConfig} />
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Environment Config
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Environment Specification
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Divider sx={{ m: 1 }}>
              </Divider>
              <Stack>
                <FormFields control={control} fields={MANUAL_PRE_POST_CMD_CONFIG} />
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ '&:before': { display: 'none' }, backgroundColor: '#68697C', borderRadius: '20px' }} defaultExpanded={openAccordions}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Stack>
                <Typography gutterBottom variant="h5" component="div">
                  Cost Management
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Limit the cost
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails >
              <Stack>
                <Divider sx={{ m: 1 }}>
                </Divider>
                <FormFields control={control} fields={COST_MANAGEMENT_CONFIG} />
              </Stack>
            </AccordionDetails>
          </Accordion>
          {type === 'templating' && <LoadingButton
            color='error'
            loading={isLoadingDelete}
            onClick={() => onDelete()}
            variant='contained'
            sx={{ borderRadius: '20px' }}
          >
            Delete Template
          </LoadingButton>} */}
        </Form>
      </Container>
    </>
  )
}

LaunchHPTTemplateView.propTypes = {
  type: PropTypes.string
}

LaunchHPTTemplateView.defaultProps = {
  type: 'name'
}

export default LaunchHPTTemplateView
