import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Button from '@mui/material/Button'
import Form from "../../components/Form";
import AddIcon from "@mui/icons-material/Add";
import FormFields from '../../components/Form/FormFields'
import {
    TAB_CONFIGS,
    TEMPLATE_FIELDS
  } from '../../constants/FormConstants/BaseFormConstants.js'
import {
    SELECT_VIRTUAL_MOUNT_CONFIG,
    SELECTED_VIRTUAL_MOUNT_CONFIG,
    SELECTED_VIRTUAL_MOUNT_DEFAULT_VALUE,
} from "../../constants/FormConstants/SelectVirtualMountConstants";
import { setError } from "../../datastore/UI/uiSlice";
import { useGetVirtualMountsQuery } from "../../services/virtualMountsApi"

const AddAsTemplate = ({ defaultData, close, isLoading, saveTemplate }) => {
  const dispatch = useDispatch();
  
  /**
   * Initialize form state
   */
  const { control: controlAddAsTemplate, handleSubmit: handleAddAsTemplate, setValue, getValues } = useForm({
    defaultValues: !!defaultData
      ? defaultData
      : {'templateName' : ''},
  });

  /**
   * Function to submit the form data to `submit` API
   * @param {object} formData
   */

  // function handleAdd(){
  //   addData(getValues());
  // }

  const onAdd = useCallback((formData) => {
    const data = {
      ...formData,
    };
    saveTemplate(data);

    
  });

  return (
    <Container disableGutters maxWidth="sm" sx={{ marginBlockStart: 4 }}>
      <Form
        control={controlAddAsTemplate}
        loading={isLoading}
        fields={TEMPLATE_FIELDS}
        buttonLabel="Confirm"
        onSubmit={handleAddAsTemplate(onAdd)}
        modalOnly= {false}
      >
      </Form>
    </Container>
  );
};

export default AddAsTemplate;
