import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText'
import UploadFile from '@mui/icons-material/UploadFile'
import { ERROR_MESSAGES } from '../../constants/uiConstants'
import axios from 'axios';
import {useGetUploadUrlMutation} from '../../services/uploadApi'

const Input = styled('input')({
  display: 'none'
})

const ConfigFileUpload = ({
  accept,
  disabled,
  label,
  onChange,
  maxSize,
  upload,
  updateUploadedFileId
}) => {
  const user = useSelector(state => state.userState.user)
  const dispatch = useDispatch()
  const [fileName, setFileName] = useState(null)
  const [error, setError] = useState(null)
  const [getUploadUrl, { isLoading }] = useGetUploadUrlMutation()
  const [uploading, setUploading] = useState(false)

  const setFileId = (fileId) => {
    if(fileId){
      // console.log(fileId)
      updateUploadedFileId(fileId)
    }
  }

  const getFileMeta = (file) => {
    const sizeInBytes = file.size
    const sizeInKB = (sizeInBytes / 1024).toFixed(2)
    if (sizeInKB <= maxSize) {
      setFileName(file.name)
      return true
    } else {
      setError(`${ERROR_MESSAGES.FILE_SIZE_ERROR}${maxSize}KB`)
      return false
    }
  }

  const readFile = (file) => {
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      try {
        let result = event.target.result
        if (result && file.type === 'application/json') {
          result = JSON.parse(result)
        }

        onChange({
          target: {
            value: result
          }
        })
      } catch (err) {
        setFileName(null)
        setError(ERROR_MESSAGES.FILE_READ_ERROR)
      }
    })

    reader.readAsText(file)
  }

  const handleFileSelect = (event) => {
    // console.log(event.target.files)
    const file = event.target.files[0]
    setFileName(null)
    setError(null)
    if (file) {
      const pass = getFileMeta(file)
      if (pass) {
        readFile(file)
      }
    }

  }

  const handleUpload = async (event) => {
    setUploading(true)
    const file = event.target.files[0]
    setFileName(null)
    setError(null)
    if (file) {
      const pass = getFileMeta(file)
      if (pass) {
        readFile(file)
        getUploadUrl(file.name)
          .unwrap()
          .then((res) => {
            if(res.error){
              dispatch(setError(res.error))
              setUploading(false)
              return
            }
            const formData = new FormData();
            formData.append("file", file);
            const presignedPostData = {
              "url": res.data.uploadUrl,
            }
            // post the data on the s3 url
            axios.post(presignedPostData.url, file,
              {
                headers : {
                  'Authorization' : res.data.token,
                  'X-Bz-File-Name' :  encodeURIComponent('B2_' + user.email + '_' + file.name),
                  'X-Bz-Content-Sha1' : "do_not_verify",
                }
              })
            .then(function (response) {
                // console.log(response)
                setFileId(response.data.fileName)
                setUploading(false)
              })
              .catch(function (error) {
                console.log(error);
                setUploading(false)
              });
            
          })
          .catch((error) => {
            dispatch(setError(error))
            setUploading(false)
          });
      }else
        setUploading(false)
    }else{
      setUploading(false)
      onChange({
        target: {
          value: null
        }
      })
    }
  }

  return (
    <label htmlFor={`${upload ? 'zip' : ''}-${accept}-contained-button-file`}>
      <Input accept={accept} disabled={disabled} id={`${upload ? 'zip' : ''}-${accept}-contained-button-file`} onChange={upload ? handleUpload : handleFileSelect} type='file' />
      <LoadingButton 
        loading={upload ? uploading : false}  
        disabled={disabled} 
        component='span'
        endIcon={<UploadFile style={{ position: "absolute", bottom: 9, right: 5 }}/>} 
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} 
        variant='outlined'
        fullWidth={true}
      >
        {label}  ({accept})
      </LoadingButton>
      {fileName && <FormHelperText>{fileName}</FormHelperText>}
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </label>
  )
}

ConfigFileUpload.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  maxSize: PropTypes.number, // Size in KB
  upload: PropTypes.bool
}

ConfigFileUpload.defaultProps = {
  accept: '.txt',
  onChange: () => null,
  maxSize: 10,
  upload: false
}

export default ConfigFileUpload
