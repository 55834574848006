import { createApi } from '@reduxjs/toolkit/query/react'
import { platformAuthConfig } from './platformAuthConfig.js'

const workstationApi = createApi({
  reducerPath: 'workstationApi',
  tagTypes: ['WSations'],
  ...platformAuthConfig(),
  endpoints: builder => ({
    getWorkstations: builder.query({
      query: () => '/workstations',
      providesTags: ['WSations']
    }),
    getWorkstation: builder.query({
      query: (id) => ({
        url: `/workstation/${id}`
      }),
      providesTags: ['WSations']
    }),
    createWorkstation: builder.mutation({
      query: (wsConfig) => ({
        url: '/workstation',
        method: 'POST',
        body: wsConfig
      }),
      invalidatesTags: ['WSations']
    }),
    deleteWorkstation: builder.mutation({
      query: (id) => ({
        url: `/workstation/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['WSations']
    }),
    restartWorkstation: builder.mutation({
      query: (id) => ({
        url: `/workstation/${id}?action=RESTART`,
        method: 'PUT',
      }),
      invalidatesTags: ['WSations']
    }),
    stopWorkstation: builder.mutation({
      query: (id) => ({
        url: `/workstation/${id}?action=STOP`,
        method: 'PUT',
      }),
      invalidatesTags: ['WSations']
    })
  })
})

export const {
  useGetWorkstationsQuery,
  useGetWorkstationQuery,
  useCreateWorkstationMutation,
  useDeleteWorkstationMutation,
  useRestartWorkstationMutation,
  useStopWorkstationMutation
} = workstationApi

export default workstationApi
