import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from "@mui/material";
import Stack from '@mui/material/Stack'
import AddIcon from "@mui/icons-material/Add";
import { BUTTON_LABELS, VIEW_HEADERS } from '../../constants/uiConstants'
import { useGetHyperparameterTrainingsQuery } from '../../services/jobsApi'
import LoadingOverlay from '../../components/LoadingOverlay'
import JobsList from '../../components/JobsList'
import MultiSelect from '../../components/Form/MultiSelect'
import { setError } from '../../datastore/UI/uiSlice'
import { setTrainingFilter, setStartTimeFilter, setEndTimeFilter, setCostFilter } from '../../datastore/filterSlice'
import { STATUS_OPTIONS_FOR_JOBS, END_TIME_FILTERS, START_TIME_FILTERS, COST_FILTERS } from '../../constants/uiConstants'
import Modal from "../../components/Modal"
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import SelectComponent from '../../components/Form/Select'
import { useGetTemplatesQuery } from '../../services/templatesApi'
import IconButton from '@mui/material/IconButton';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
const HyperparameterTuningView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [view, setView] = useState(true);
  const filter = useSelector((state) => state.filters.trainings)
  const startTimeFilter = useSelector((state) => state.filters.startTime)
  const endTimeFilter = useSelector((state) => state.filters.endTime)
  const costFilter = useSelector((state) => state.filters.cost)
  const [openCreateModal, setOpenCreateModal] = React.useState(false)
  const { data: fetchedData, error, isError, isLoading, isSuccess } = useGetHyperparameterTrainingsQuery({pollingInterval:20000, refetchOnMountOrArgChange: true})
  const { data: fetchedTemplateData, isLoading: isLoadingTemplates } = useGetTemplatesQuery()
  const [template, setTemplate] = React.useState('Create from scratch')
  const handleChange = (event) => {
    setTemplate(event.target.value);
  };
  useEffect(() => {
    if (isError) {
      dispatch(setError(error))
    }
  }, [dispatch, error, isError])

  const data = useMemo(() => {
    if (Array.isArray(fetchedData)) {
      let filtered = fetchedData
      if (filter.length > 0) {
        filtered =  filtered.filter((item) => filter.includes(item.status))
      }
      if (startTimeFilter) {
        if(startTimeFilter === 'Past 1 hour'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60)
        }

        if(startTimeFilter === 'Past 6 hours'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 6)
        }

        if(startTimeFilter === 'Past 12 hours'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 12)
        }

        if(startTimeFilter === 'Yesterday'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 24)
        }

        if(startTimeFilter === 'Past 3 days'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 24 * 3)
        }

        if(startTimeFilter === 'Past week'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 24 * 7)
        }

        if(startTimeFilter === 'Past month'){
          filtered =  filtered.filter((item) => new Date(item.start_time) > Date.now() - 1000 * 60 * 60 * 24 * 30)
        }
      }
      if (endTimeFilter) {
        if(endTimeFilter === 'Past 1 hour'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60)
        }

        if(endTimeFilter === 'Past 6 hours'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 6)
        }

        if(endTimeFilter === 'Past 12 hours'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 12)
        }

        if(endTimeFilter === 'Yesterday'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 24)
        }

        if(endTimeFilter === 'Past 3 days'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 24 * 3)
        }

        if(endTimeFilter === 'Past week'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 24 * 7)
        }

        if(endTimeFilter === 'Past month'){
          filtered =  filtered.filter((item) => new Date(item.end_time) > Date.now() - 1000 * 60 * 60 * 24 * 30)
        }
      }
      if (costFilter) {
        if(costFilter === '0-100'){
          filtered =  filtered.filter((item) => item.cost >= 0 && item.cost < 100)
        }
        if(costFilter === '100-300'){
          filtered =  filtered.filter((item) => item.cost >= 100 && item.cost < 300)
        }
        if(costFilter === '300-500'){
          filtered =  filtered.filter((item) => item.cost >= 300 && item.cost < 500)
        }
        if(costFilter === 'Above 500'){
          filtered =  filtered.filter((item) => item.cost >= 500)
        }
      }
      return filtered
    }
    return []
  }, [fetchedData, filter, startTimeFilter, endTimeFilter, costFilter])

  const templateData = useMemo(() => {
    if (Array.isArray(fetchedTemplateData)) {
      let filteredFetchedTemplateData = fetchedTemplateData.filter((item) => item.type === 'HPTOPT')
      let filteredTemplateIds = filteredFetchedTemplateData.map(v => (v.id));
      filteredTemplateIds.unshift('Create from scratch');
      return filteredTemplateIds;
    }
    return ['Create from scratch']
  }, [fetchedTemplateData])

  const handleClick = (jobId) => {
    navigate(`../hyperparameter-tuning/view/${jobId}`)
  }

  const redirectLaunchForm = () => {
    if(template === 'Create from scratch')
      navigate('../hyperparameter-tuning/new')
    else
      navigate(`../hyperparameter-tuning/new/${template}`)
  }

  return (
    <>
      <Typography variant='h5' gutterBottom>{VIEW_HEADERS.HYPERPARAMETER_TUNING}</Typography>
      <hr/>
      <br/>
      {isLoading && <LoadingOverlay/>}
      <Box sx={{ marginBlockEnd: 4 }}>
        <Stack direction="row" spacing={2}>
        {isSuccess && <MultiSelect
          label='Filter'
          onChange={(e) => dispatch(setTrainingFilter(e.target.value))}
          options={Object.values(STATUS_OPTIONS_FOR_JOBS)}
          value={filter}
        />}
        <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            style={{ height: 40, width: 450, margin: '10px' }}
            onClick={() => redirectLaunchForm()}
          >
            <AddIcon />
            {BUTTON_LABELS.LAUNCH_NEW_HPT_BUTTON}
          </Button>
        </Stack>
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end'}} spacing={2} >
            <Stack direction="row" sx={{width: '100%'}}>
              <SelectComponent
                sx={{m: 1}}
                label="Start Filter"
                onChange={(e) => dispatch(setStartTimeFilter(e.target.value))}
                options={Object.values(START_TIME_FILTERS)}
                value={startTimeFilter}
                fullWidth
                showLabel={true}
              />
              <SelectComponent
                sx={{m: 1}}
                label="End Filter"
                onChange={(e) => dispatch(setEndTimeFilter(e.target.value))}
                options={Object.values(END_TIME_FILTERS)}
                value={endTimeFilter}
                fullWidth
                showLabel={true}
              />
              <SelectComponent
                sx={{m: 1}}
                label="Cost Filter"
                onChange={(e) => dispatch(setCostFilter(e.target.value))}
                options={Object.values(COST_FILTERS)}
                value={costFilter}
                fullWidth
                showLabel={true}
              />
            </Stack>
            <Stack direction="row" sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
              <Typography variant="h7" sx={{m: '15px', color: '#ABABAB'}}>
                <b>View:</b> 
              </Typography>
              <IconButton sx={{m: 1}} onClick={() => {setView(true)}}>
                {view && <ViewModuleIcon fontSize='small' sx={{color: '#9967DD'}}/>}
                {!view && <ViewModuleIcon fontSize='small' sx={{color: '#ABABAB'}}/>}
              </IconButton>
              <IconButton sx={{m: 1}} onClick={() => {setView(false)}}>
                {!view && <FormatListBulletedIcon fontSize='small' sx={{color: '#9967DD'}}/>}
                {view && <FormatListBulletedIcon fontSize='small' sx={{color: '#ABABAB'}}/>}
              </IconButton>
            </Stack>
        </Stack>
      </Box>
      {isSuccess && <JobsList view={view} data={data || []} onClick={handleClick} />}
    </>
  )
}

export default HyperparameterTuningView
