import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import LoadingOverlay from '../../components/LoadingOverlay'
import { useGetJobQuery } from '../../services/jobsApi'
import { TAB_CONFIGS } from '../../constants/uiConstants'
import { setError } from '../../datastore/UI/uiSlice'

const ExperimentInfo = React.lazy(() => import('./ExperimentInfo'))
const TrainingInfo = React.lazy(() => import('./TrainingInfo'))
const SimpleJobInfo = React.lazy(() => import('./SimpleJobInfo'))
const TrialInfo = React.lazy(() => import('./TrialInfo'))
const EventLogs = React.lazy(() => import('./EventLogs'))
const BuildLogs = React.lazy(() => import('./BuildLogs'))
const LiveLogs = React.lazy(() => import('./LiveLogs'))
const JobArgs = React.lazy(() => import('./JobArgs'))

const typeExperiment = 'experiment'
const typeTraining = 'training'
const typeSimpleJob = 'simple-job'

const JobView = ({
  type
}) => {
  const dispatch = useDispatch()
  const isExperiment = type === typeExperiment
  const isTraining = type === typeTraining
  const isSimpleJob = type === typeSimpleJob

  const [view, setView] = useState(
    isExperiment ? TAB_CONFIGS.EXPERIMENT_INFO.id : isTraining ? TAB_CONFIGS.TUNNING_INFO.id : TAB_CONFIGS.JOB_INFO.id
  )
  const location = useLocation()
  const { data, error, isError, isLoading, isSuccess } = useGetJobQuery(location.pathname.split('/').pop(),{pollingInterval: 20000, refetchOnMountOrArgChange: true})

  useEffect(() => {
    if (isError) {
      dispatch(setError(error))
    }
  }, [dispatch, error, isError])

  return (
    <>
      {isLoading && <LoadingOverlay/>}
      {isSuccess && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={view} onChange={(e, value) => setView(value)}>
              {isExperiment && <Tab label={TAB_CONFIGS.EXPERIMENT_INFO.label} value={TAB_CONFIGS.EXPERIMENT_INFO.id} />}
              {isTraining && <Tab label={TAB_CONFIGS.TUNNING_INFO.label} value={TAB_CONFIGS.TUNNING_INFO.id} />}
              {isSimpleJob && <Tab label={TAB_CONFIGS.JOB_INFO.label} value={TAB_CONFIGS.JOB_INFO.id} />}
              <Tab label={TAB_CONFIGS.ARGS.label} value={TAB_CONFIGS.ARGS.id} />
              {/* <Tab label={TAB_CONFIGS.EVENT_LOGS.label} value={TAB_CONFIGS.EVENT_LOGS.id} /> */}
              <Tab label={TAB_CONFIGS.BUILD_LOGS.label} value={TAB_CONFIGS.BUILD_LOGS.id} />
              {!isSimpleJob && <Tab label={TAB_CONFIGS.TRIAL_INFO.label} value={TAB_CONFIGS.TRIAL_INFO.id} />}
              {isSimpleJob && <Tab label={TAB_CONFIGS.LIVE_LOGS.label} value={TAB_CONFIGS.LIVE_LOGS.id} />}
            </Tabs>
          </Box>
          {view === TAB_CONFIGS.EXPERIMENT_INFO.id && (
            <Suspense fallback={null}>
              <ExperimentInfo job={data}/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.JOB_INFO.id && (
            <Suspense fallback={null}>
              <SimpleJobInfo job={data}/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.TUNNING_INFO.id && (
            <Suspense fallback={null}>
              <TrainingInfo job={data} />
            </Suspense>
          )}
          {view === TAB_CONFIGS.TRIAL_INFO.id && (
            <Suspense fallback={null}>
              <TrialInfo job={data}/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.ARGS.id && (
            <Suspense fallback={null}>
              <JobArgs job={data} type={type}/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.EVENT_LOGS.id && (
            <Suspense fallback={null}>
              <EventLogs job={data}/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.BUILD_LOGS.id && (
            <Suspense fallback={null}>
              <BuildLogs/>
            </Suspense>
          )}
          {view === TAB_CONFIGS.LIVE_LOGS.id && (
            <Suspense fallback={null}>
              <LiveLogs job={data}/>
            </Suspense>
          )}
        </>
      )}
    </>
  )
}

JobView.propTypes = {
  type: PropTypes.oneOf([typeExperiment, typeTraining, typeSimpleJob])
}

export default JobView
